import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Stack, Tab, Tabs, Typography } from '@mui/material';

import { Campaign } from 'pages/InfluencerMatcher/Campaign/Campaign';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { ManageCollection } from 'pages/InfluencerMatcher/Manage/ManageCollection';
import { SearchInfluencer } from 'pages/InfluencerMatcher/Search/SearchInfluencer';
import classes from './InfluencerMatcher.module.scss';
import { useCategories } from 'common/hooks/Extension/useCategories';
import { useCollections } from 'common/hooks/Extension/useCollections';
import { useLanguages } from 'common/hooks/Extension/useLanguages';
import { useLocation } from 'react-router-dom';
import { useLocations } from 'common/hooks/Extension/useLocations';
import { useMetricsRange } from 'common/hooks/Extension/useMetricsRange';

const tabs: string[] = ['Search', 'Lists', 'Campaign'];

export const InfluencerMatcher: FC = () => {
  const { state, search } = useLocation();
  const { selectedTab$ } = useContext(ExtensionContext);
  const [tab, setTab] = useState<number>(
    tabs.findIndex((tab) => tab === state?.tab || tab === selectedTab$.getValue()) > -1
      ? tabs.findIndex((tab) => tab === state?.tab || tab === selectedTab$.getValue())
      : 0,
  );
  const [collectionId, setCollectionId] = useState<number | undefined>(undefined);

  useCategories();
  useMetricsRange();
  useCollections();
  useLanguages();
  useLocations();

  useEffect(() => {
    const s = new URLSearchParams(search);
    const tab = s.get('tab');
    const collectionId = s.get('collectionId');
    if (tab === 'collections') setTab(1);
    if (collectionId && !Number.isNaN(parseInt(collectionId))) {
      setCollectionId(parseInt(collectionId));
    }
  }, [search]);

  useEffect(() => {
    if (tab > tabs.length) return;
    selectedTab$.next(tabs[tab]);
  }, [tab, selectedTab$]);

  const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setCollectionId(undefined);
  }, []);

  const mainContent = useMemo(() => {
    switch (tab) {
      case 0: // Search
        return <SearchInfluencer />;
      case 1: // Manage
        return <ManageCollection collectionId={collectionId} />;
      case 2: // Campaign
        return <Campaign />;
      default:
        return <></>;
    }
  }, [tab, collectionId]);

  return (
    <Stack className={classes.root}>
      <Stack className={classes.top}>
        <Stack className={classes.content}>
          <Typography variant='h2'>Influencers Matcher</Typography>
          <Typography>Discover top Web3 influencers in the world with powerful search tools.</Typography>
        </Stack>
      </Stack>
      <Stack className={classes.bottom} alignItems='center'>
        <Stack className={classes.content} spacing={4}>
          <Tabs value={tab} onChange={handleTabChange} centered className={classes.tabs}>
            {tabs.map((t) => (
              <Tab
                id={`tab-influencer-matcher-${t.toLowerCase()}`}
                key={t}
                className={classes.tab}
                style={{ width: `${100 / tabs.length}%` }}
                label={
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='subtitle2'>{t}</Typography>
                  </Stack>
                }
              />
            ))}
          </Tabs>
          {mainContent}
        </Stack>
      </Stack>
    </Stack>
  );
};
