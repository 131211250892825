import { useCallback, useContext, useEffect, useState } from 'react';

import { Collection } from 'common/types/Extension/Collection';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { useMessage } from 'components/message/useMessage';
import { useObservable } from 'react-use';

export const useCollections = (ignoreError?: boolean) => {
  const { showMessage } = useMessage();
  const { collections$, refetchCollections$ } = useContext(ExtensionContext);
  const [collections, setCollections] = useState<Collection[] | undefined>(collections$.getValue());
  const [loading, setLoading] = useState<boolean>(true);
  const refetchCollections = useObservable(refetchCollections$);

  const fetchCollections = useCallback(async () => {
    setLoading(true);
    await ExtensionAPI.getCollections()
      .then(({ data }) => {
        collections$.next(data);
        setCollections(data);
        refetchCollections$.next(false);
        return;
      })
      .catch((error) => {
        if (!ignoreError) showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ignoreError, showMessage, collections$, refetchCollections$]);

  useEffect(() => {
    if (!refetchCollections) return;
    fetchCollections();
  }, [refetchCollections, fetchCollections]);

  return { loading, value: collections };
};
