import { FC, useContext, useEffect, useState } from 'react';

import { ExtensionContext } from 'contexts/ExtensionContext';
import { InfluencerCampaign } from 'common/types/Extension/InfluencerCampaign';
import { InfluencerCampaignDetail } from 'components/InfluencerCampaign/InfluencerCampaignDetail';
import { InfluencerCampaignOverview } from 'components/InfluencerCampaign/InfluencerCampaignOverview';
import { Stack } from '@mui/material';
import classes from './Campaign.module.scss';

interface CampaignProps {}

export const Campaign: FC<CampaignProps> = () => {
  const { selectedCampaign$ } = useContext(ExtensionContext);
  const [selectedInfluencerCampaign, setSelectedInfluencerCampaign] = useState<InfluencerCampaign | undefined>(
    selectedCampaign$.getValue(),
  );

  useEffect(() => {
    selectedCampaign$.next(selectedInfluencerCampaign);
  }, [selectedInfluencerCampaign, selectedCampaign$]);

  return (
    <Stack className={classes.root} spacing={6}>
      {selectedInfluencerCampaign ? (
        <InfluencerCampaignDetail
          campaignId={selectedInfluencerCampaign.id || 0}
          onBackClicked={() => setSelectedInfluencerCampaign(undefined)}
        />
      ) : (
        <InfluencerCampaignOverview
          onInfluencerCampaignViewClicked={(influencerCampaign) => setSelectedInfluencerCampaign(influencerCampaign)}
        />
      )}
    </Stack>
  );
};
