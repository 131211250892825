import { ArrowBackOutlined, ArrowForwardOutlined } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { colorNeutral30, colorPrimary10 } from 'common/params';

import { Audience } from 'common/types/Audience';
import ClientAPI from 'common/ClientAPI';
import { Cohort } from 'common/types/Cohort';
import { GhostIcon } from 'components/icons/GhostIcon';
import { JobStatus } from 'common/types/JobStatus';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './AudienceInfoEdit.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';

interface AudienceInfoEditProps {
  audience: Audience;
  onAudienceInfoEdit?: (audience: Audience) => void;
  onNext?: () => void;
  onBack?: () => void;
}

export const AudienceInfoEdit: FC<AudienceInfoEditProps> = ({ audience, onAudienceInfoEdit, onNext, onBack }) => {
  const navigate = useNavigate();
  const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(false);

  const {
    error,
    value: cohorts,
    loading,
  } = useAsync(async () => {
    return (await ClientAPI.getCohorts()).data;
  }, []);

  const { showMessage } = useMessage();

  useEffect(() => {
    if (error) showMessage(error.message, 'error');
  }, [error, showMessage]);

  const audienceValid = useMemo(() => {
    return audience.name && audience.cohorts.length;
  }, [audience]);

  const onNextBtnClicked = useCallback(() => {
    setNextBtnClicked(true);
    if (!audienceValid) return;
    onNext?.();
  }, [audienceValid, onNext]);

  const handleCohortsChange = useCallback(
    (ids: string | number[]) => {
      if (typeof ids === 'string') return;
      const newCohorts: { id: number; name: string }[] = [];
      ids.forEach((id) => {
        const cohort = cohorts?.find((cohort) => cohort.id === id);
        if (cohort && cohort.id) newCohorts.push({ id: cohort.id, name: cohort.name });
      });
      onAudienceInfoEdit?.({ ...audience, cohorts: newCohorts });
    },
    [audience, cohorts, onAudienceInfoEdit],
  );

  const renderCohortMenuItem = useCallback(
    (cohort: Cohort) => {
      if (cohort.status !== JobStatus.COMPLETED) {
        return (
          <MenuItem key={cohort.id} value={cohort.id} disabled>
            <Stack direction='row' alignItems='center' style={{ width: '100%' }} justifyContent='space-between'>
              <Stack direction='row' alignItems='center'>
                <Checkbox checked={audience.cohorts.findIndex((c) => c.id === cohort.id) > -1} />
                <ListItemText primary={cohort.name} />
              </Stack>
              <Typography variant='label2'>Syncing</Typography>
            </Stack>
          </MenuItem>
        );
      }
      return (
        <MenuItem key={cohort.id} value={cohort.id}>
          <Stack direction='row' alignItems='center' style={{ width: '100%' }} justifyContent='space-between'>
            <Stack direction='row' alignItems='center'>
              <Checkbox checked={audience.cohorts.findIndex((c) => c.id === cohort.id) > -1} />
              <ListItemText primary={cohort.name} />
            </Stack>
            <Link
              color={colorNeutral30}
              onClick={(e) => {
                e.stopPropagation();
                window
                  .open(
                    Uris.Pages.WalletSelector.CohortDetail.replace(':cohortId', cohort.id?.toString() || ''),
                    '_blank',
                  )
                  ?.focus();
              }}
            >
              <Typography variant='label2'>View Details</Typography>
            </Link>
          </Stack>
        </MenuItem>
      );
    },
    [audience.cohorts],
  );

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classNames(classes.container, classes.top)} spacing={2}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Chip color='primary' label={<Typography variant='h6'>1</Typography>} className={classes.step} />
          <Stack spacing={1} className={classes.title}>
            <Typography variant='h4' color={colorPrimary10}>
              Setting Up Basic Audience Details
            </Typography>
            <Typography variant='body1'>
              In this process, we aim to bridge the gap between potential clients on-chain and off-chain to optimize Ads
              targeting.
            </Typography>
            <Stack direction='row-reverse' spacing={2}>
              <Button
                variant='contained'
                disabled={nextBtnClicked && !audienceValid}
                onClick={onNextBtnClicked}
                endIcon={<ArrowForwardOutlined />}
              >
                Next
              </Button>
              <Button variant='outlined' onClick={onBack} startIcon={<ArrowBackOutlined />}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classNames(classes.container, classes.bottom)} spacing={2}>
        <FormControl>
          <TextField
            label='Audience Name'
            error={nextBtnClicked && !audience.name}
            className={classes.input}
            sx={{ borderColor: 'red' }}
            value={audience.name}
            inputProps={{ maxLength: 40 }}
            onChange={(e) => onAudienceInfoEdit?.({ ...audience, name: e.target.value })}
            helperText="This is the name you'll see in the main list, so use a descriptive name."
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label='Note'
            className={classes.input}
            value={audience.description}
            inputProps={{ maxLength: 250 }}
            onChange={(e) => onAudienceInfoEdit?.({ ...audience, description: e.target.value })}
            helperText='Please make a value less than 250 characters.'
          ></TextField>
        </FormControl>
        <FormControl>
          <InputLabel>Choose The Target Cohorts</InputLabel>
          <Select
            id='input-choose-target-cohorts'
            label='Choose The Target Cohorts'
            multiple
            error={nextBtnClicked && !audience.cohorts.length}
            className={classes.select}
            value={audience.cohorts.map((cohort) => cohort.id)}
            onChange={(e) => handleCohortsChange(e.target.value)}
            renderValue={(values) => {
              return (
                <Typography variant='body1'>
                  {values.map((value) => audience.cohorts.find((cohort) => cohort.id === value)?.name).join(', ')}
                </Typography>
              );
            }}
          >
            {loading ? (
              <Spinner />
            ) : cohorts?.length ? (
              cohorts
                .filter((cohort) => cohort.status !== JobStatus.FAILED)
                .map((cohort) => renderCohortMenuItem(cohort))
            ) : (
              <Stack className={classes.empty} alignItems='center' spacing={1}>
                <GhostIcon />
                <Typography>
                  <span className={classes.link} onClick={() => navigate(Uris.Pages.WalletSelector.CreateCohort)}>
                    Create cohort
                  </span>{' '}
                  to keep setting up your ads audience.
                </Typography>
              </Stack>
            )}
          </Select>
          <FormHelperText>
            We will match the cohort you choose with Twitter accounts to find your Twitter audience.
          </FormHelperText>
        </FormControl>
      </Stack>
    </Stack>
  );
};
