import { Button, Stack, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { FC, HTMLAttributes } from 'react';
import { FiberManualRecord, UploadOutlined } from '@mui/icons-material';

import { TwitterAudiencePublishRecord } from 'common/types/TwitterPublishRecord';
import { Uris } from 'Uris';
import classes from './TwitterAudiencePublishList.module.scss';
import { datetimeFormatter } from 'common/formatters';
import { numberToAbbreviation } from 'common/utils';

interface TwitterAudiencePublishListProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
  records: TwitterAudiencePublishRecord[];
  publishBtnDisabled?: boolean;
  onAudiencePublish?: () => void;
}

export const TwitterAudiencePublishList: FC<TwitterAudiencePublishListProps> = ({
  loading,
  records,
  publishBtnDisabled,
  onAudiencePublish,
  ...rest
}) => {
  const columnDefs: CommonTableColumn<TwitterAudiencePublishRecord>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'audience_name',
      label: 'Audience Name',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Typography
            variant='body1'
            className={classes.pointer}
            onClick={() =>
              window.open(`${Uris.External.TwitterAds}/audiences/${data?.account_id}/audience_manager`, '_blank')
            }
          >
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'account_name',
      label: 'Account Name',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'created_at',
      label: 'Publish Date',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>;
      },
    },
    {
      id: 'status',
      label: 'Status',
      tooltip:
        'The status syncs daily with Twitter Audience Manager at 00:00 UTC+0. For real-time results, click on "audience name" to view on Twitter Ads Console.',
      render: (value) => {
        if (!value) return null;
        const splitWords: string[] = value.split('_');
        const text = splitWords
          .map((word) => (word.length > 0 ? `${word[0]}${word.substring(1).toLowerCase()}` : word))
          .join(' ');
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            <FiberManualRecord
              fontSize='small'
              color={value === 'READY' ? 'success' : value === 'PROCESSING' ? 'warning' : 'error'}
            />
            <Typography variant='body1'>{text}</Typography>
          </Stack>
        );
      },
    },
    {
      id: 'audience_size',
      label: 'Twitter Size',
      tooltip:
        'The Twitter Size syncs daily with Twitter Audience Manager at 00:00 UTC+0. For real-time results, click on "audience name" to view on Twitter Ads Console.',
      render: (value) => {
        return <Typography variant='body1'>{numberToAbbreviation(value)}</Typography>;
      },
    },
    {
      id: 'updated_at',
      label: 'Last Updated Time',
      render: (value) => {
        return <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>;
      },
    },
  ];

  return (
    <Stack className={classes.root} spacing={4}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography {...rest} variant='h3'>
          Publish History
        </Typography>
        <Button
          variant='contained'
          startIcon={<UploadOutlined />}
          disabled={publishBtnDisabled}
          onClick={onAudiencePublish}
        >
          Publish
        </Button>
      </Stack>
      <CommonTable
        {...rest}
        loading={loading}
        data={records}
        totalDataCnt={records.length}
        columns={columnDefs}
        classes={classes}
      />
    </Stack>
  );
};
