import { ArrowBackOutlined, Message, SendOutlined } from '@mui/icons-material';
import { Button, Divider, Stack, Typography } from '@mui/material';
import {
  InfluencerCampaign,
  InfluencerThreadStatus,
  calculateEstimateTime,
} from 'common/types/Extension/InfluencerCampaign';

import { FC } from 'react';
import { SendPlaneIcon } from 'components/icons/SendPlaneIcon';
import classes from './CampaignLaunch.module.scss';
import { colorPrimary40 } from 'common/params';
import { minutesToString } from 'common/utils';

interface CampaignLaunchProps {
  draft?: boolean;
  saving?: boolean;
  campaign: InfluencerCampaign;
  onSendBtnClicked?: () => void;
  onBackBtnClicked?: () => void;
  onSaveBtnClicked?: () => void;
}

export const CampaignLaunch: FC<CampaignLaunchProps> = ({
  draft,
  saving,
  campaign,
  onSendBtnClicked,
  onBackBtnClicked,
  onSaveBtnClicked,
}) => {
  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>Launch Preview</Typography>
        <Stack id='btn-create-campaign-launch-actions' direction='row' spacing={1}>
          {/* show save btn only in draft mode */}
          {draft ? (
            <Button variant='outlined' disabled={saving} onClick={onSaveBtnClicked}>
              Save and exit
            </Button>
          ) : null}
          {!draft && onBackBtnClicked ? (
            <Button variant='outlined' disabled={saving} startIcon={<ArrowBackOutlined />} onClick={onBackBtnClicked}>
              Back
            </Button>
          ) : null}
          <Button variant='contained' disabled={saving} startIcon={<SendOutlined />} onClick={onSendBtnClicked}>
            Send
          </Button>
        </Stack>
      </Stack>
      <Stack id='area-create-campaign-launch-preview' className={classes.container} spacing={4}>
        <Stack direction='row' justifyContent='space-between' spacing={1}>
          <Typography variant='h6'>{campaign.name}</Typography>
          <Stack direction='row' spacing={1} className={classes.estimate}>
            <SendPlaneIcon className={classes.icon} />
            <Stack alignItems='flex-end'>
              <Typography>
                It will take {minutesToString(calculateEstimateTime(campaign))} for the system to process sending
              </Typography>
              <Typography variant='label2'>
                {(campaign.threads || []).filter((thread) => thread.status === InfluencerThreadStatus.SENDING).length}{' '}
                messages will be sent
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography>Campaign Note: {campaign.description || ''}</Typography>
        {(campaign.messages || []).map((message, idx) => {
          const threads = (campaign.threads || []).filter((thread) => thread.influencer_message_id === message.id);
          return (
            <Stack spacing={4} key={message.id}>
              <Divider />
              <Stack spacing={2}>
                <Stack direction='row' color={colorPrimary40} alignItems='center' spacing={1}>
                  <Message fontSize='small' />
                  <Typography>Message {idx + 1}</Typography>
                </Stack>
                <Stack className={classes.message} spacing={3}>
                  <Typography className={classes.content}>{message.content}</Typography>
                  <Stack direction='row' spacing={1}>
                    <Typography color={colorPrimary40}>Influencers: </Typography>
                    <Typography>
                      {threads
                        .slice(0, 3)
                        .map((thread) => thread.receiver?.username)
                        .join(', ') + (threads.length > 3 ? ` ... and ${threads.length - 3} more` : '')}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
