import { ArrowBackOutlined, ArrowForwardOutlined, LinkOutlined } from '@mui/icons-material';
import { Button, Card, Chip, Stack, Typography } from '@mui/material';
import { FC, HTMLAttributes, useState } from 'react';

import { CampaignLinkChannel } from 'common/types/CampaignLink';
import { XIcon } from 'components/icons/XIcon';
import classNames from 'classnames';
import classes from './SelectChannel.module.scss';
import { colorPrimary10 } from 'common/params';

interface SelectChannelProps extends HTMLAttributes<HTMLElement> {
  channel: CampaignLinkChannel;
  onNext?: (channel: CampaignLinkChannel) => void;
  onCancel?: () => void;
}

export const SelectChannel: FC<SelectChannelProps> = ({ channel, onNext, onCancel }) => {
  const [selectedChannel, setSelectedChannel] = useState<CampaignLinkChannel>(channel);
  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classNames(classes.container, classes.top)} spacing={2}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Chip color='primary' label={<Typography variant='h6'>1</Typography>} className={classes.step} />
          <Stack spacing={2} className={classes.title}>
            <Typography variant='h4' color={colorPrimary10}>
              Choose Promotional Channels
            </Typography>
            <Typography variant='body1'>
              Choose between Twitter Ad or Others (LinkedIn, Google or other promotional channels)
            </Typography>
            <Stack direction='row-reverse' spacing={2}>
              <Button
                variant='contained'
                disabled={!selectedChannel}
                onClick={() => onNext?.(selectedChannel)}
                endIcon={<ArrowForwardOutlined />}
              >
                Next
              </Button>
              <Button variant='outlined' onClick={onCancel} startIcon={<ArrowBackOutlined />}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' className={classNames(classes.container, classes.bottom)} spacing={2}>
        <Card
          variant='elevation'
          className={classNames(classes.card, selectedChannel === CampaignLinkChannel.TWITTER_ADS && classes.selected)}
          onClick={() => setSelectedChannel(CampaignLinkChannel.TWITTER_ADS)}
        >
          <Stack spacing={2} alignItems='center'>
            <Chip label={<XIcon className={classNames(classes.svg, classes.twitterAds)} />} className={classes.icon} />
            <Typography variant='h6'>Twitter Ads</Typography>
            <Typography variant='body1'>
              Build campaign url based on ads audience you've created. You can generate multiple links to track
              different ads creatives.
            </Typography>
          </Stack>
        </Card>
        <Card
          variant='elevation'
          className={classNames(classes.card, selectedChannel === CampaignLinkChannel.CUSTOMIZED && classes.selected)}
          onClick={() => setSelectedChannel(CampaignLinkChannel.CUSTOMIZED)}
        >
          <Stack spacing={2} alignItems='center'>
            <Chip
              label={<LinkOutlined className={classNames(classes.svg, classes.customized)} />}
              className={classes.icon}
            />
            <Typography variant='h6'>Customized</Typography>
            <Typography variant='body1'>
              Build your own campaign url by customizing parameters. You can customized each parameter for the result
              url.
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};
