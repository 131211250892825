import { SvgIcon, SvgIconProps } from '@mui/material';

export const AndIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M9.7 3C8.8 3 8 3.7 8 4.6v3.5H4.6C3.7 8 3 8.8 3 9.7v9.7c0 .9.7 1.6 1.6 1.6h9.7c.9 0 1.6-.7 1.6-1.6v-3.5h3.5c.9 0 1.6-.7 1.6-1.6V4.6c0-.9-.7-1.6-1.6-1.6H9.7Zm6.2 11.5h3.5c.1 0 .2 0 .2-.2V4.6c0-.1 0-.2-.2-.2H9.7c-.1 0-.2 0-.2.2v3.5h4.8c.9 0 1.6.7 1.6 1.6v4.8Zm-7.8-5v4.8c0 .9.7 1.6 1.6 1.6h4.8v3.5c0 .1 0 .2-.2.2H4.6a.2.2 0 0 1-.2-.2V9.7c0-.1 0-.2.2-.2h3.5Z'
    />
  </SvgIcon>
);
