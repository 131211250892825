import { Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { CommonChip } from 'components/chip/CommonChip';
import { HumanIcon } from 'components/icons/HumanIcon';
import { TwitterAuth } from 'common/types/TwitterAds';
import classNames from 'classnames';
import classes from './SetDefaultAuth.module.scss';

interface SetDefaultAuthProps {
  auths: TwitterAuth[];
  defaultAuth?: TwitterAuth;
  onBtnCancelClicked?: () => void;
  onBtnManageAuthClicked?: (auth: TwitterAuth) => void;
  onBtnNextClicked?: (auth: TwitterAuth) => void;
}

export const SetDefaultAuth: FC<SetDefaultAuthProps> = ({
  auths,
  defaultAuth,
  onBtnCancelClicked,
  onBtnManageAuthClicked,
  onBtnNextClicked,
}) => {
  const [selectedAuthId, setSelectedAuthId] = useState<string>('');

  useEffect(() => {
    setSelectedAuthId(defaultAuth?.id || '');
  }, [defaultAuth]);

  return (
    <Stack className={classes.root}>
      <Stack className={classes.content}>
        <Stack className={classes.title} alignItems='center' spacing={2}>
          <CommonChip>
            <HumanIcon fontSize='large' />
          </CommonChip>
          <Stack spacing={1} className={classes.info}>
            <Typography variant='h6'>Select default twitter account</Typography>
            <Typography variant='body1'>
              {auths.find((auth) => auth.id === selectedAuthId)?.name} is set as your default account. You can remove or
              add a new account here.
            </Typography>
          </Stack>
        </Stack>
        <FormControl className={classes.items}>
          <RadioGroup value={selectedAuthId} onChange={(e) => setSelectedAuthId(e.target.value)}>
            {auths.map((auth, idx) => (
              <Stack key={idx}>
                {idx > 0 ? <Divider /> : null}
                <FormControlLabel
                  labelPlacement='start'
                  value={auth.id}
                  control={<Radio />}
                  sx={{
                    width: '100%',
                    maxHeight: '60px',
                    marginLeft: '0px',
                    '.MuiFormControlLabel-label': {
                      flex: '1 1',
                    },
                  }}
                  className={classNames(classes.item, selectedAuthId === auth.id && classes.selected)}
                  label={
                    <Stack direction='row' alignItems='center' spacing={2} className={classes.option}>
                      <HumanIcon fontSize='large' />
                      <Stack>
                        <Typography textAlign='left' className={classes.text}>
                          {auth.name.trim()}
                        </Typography>
                        <Typography textAlign='left' variant='label1'>
                          user_id: {auth.user_id}
                        </Typography>
                      </Stack>
                    </Stack>
                  }
                />
              </Stack>
            ))}
          </RadioGroup>
        </FormControl>
        <Stack direction='row-reverse' className={classes.actions} spacing={1}>
          <Button
            variant='outlined'
            disabled={!selectedAuthId}
            onClick={() => {
              const selectedAuth = auths.find((auth) => auth.id === selectedAuthId);
              selectedAuth && onBtnManageAuthClicked?.(selectedAuth);
            }}
          >
            Manage Account
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction='row-reverse' spacing={1} className={classes.actions}>
        <Button
          variant='contained'
          disabled={!selectedAuthId}
          onClick={() => {
            const selectedAuth = auths.find((auth) => auth.id === selectedAuthId);
            selectedAuth && onBtnNextClicked?.(selectedAuth);
          }}
        >
          Next
        </Button>
        <Button variant='outlined' onClick={onBtnCancelClicked}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
