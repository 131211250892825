import { Button, Stack, Typography } from '@mui/material';

import { CohortProjectType } from 'common/types/Cohort';
import { FC } from 'react';
import { Uris } from 'Uris';
import classes from './ChooseProject.module.scss';

const projects = [
  {
    key: CohortProjectType.NFT,
    title: 'NFT',
    image: Uris.Public.Image.WalletSelector.CreateCohort.NFT,
  },
  {
    key: CohortProjectType.GAME,
    title: 'Game',
    image: Uris.Public.Image.WalletSelector.CreateCohort.Game,
  },
  {
    key: CohortProjectType.DEFI,
    title: 'DeFi',
    image: Uris.Public.Image.WalletSelector.CreateCohort.DeFi,
  },
];

interface ChooseProjectProps {
  onNext?: (projectType: CohortProjectType) => void;
}

export const ChooseProject: FC<ChooseProjectProps> = ({ onNext }) => {
  return (
    <Stack className={classes.root} spacing={4}>
      <Typography variant='h3'>What kind of project are you working on?</Typography>
      <Typography variant='h6'>Choose one project below</Typography>
      <Stack direction='row' spacing={4}>
        {projects.map((project) => (
          <Stack id={`area-create-cohort-project-${project.key}`} key={project.key} className={classes.card}>
            <img alt={`img-${project.key}`} src={project.image} className={classes.img} />
            <Stack className={classes.text} spacing={2} alignItems='center'>
              <Typography variant='h4'>{project.title}</Typography>
              <Stack direction='row'>
                <Button variant='contained' onClick={() => onNext?.(project.key)}>
                  Next
                </Button>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
