import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { FC, useMemo, useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { MoreVertOutlined, RefreshOutlined } from '@mui/icons-material';
import { colorPrimary40, pieChartColors } from 'common/params';

import { ChainIcon } from 'components/icons/ChainIcon';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { GhostIcon } from 'components/icons/GhostIcon';
import { Pie } from 'react-chartjs-2';
import { Spinner } from 'components/common/Spinner';
import classes from './WalletTotalAssetChart.module.scss';
import { getAggregatedPieChartData } from 'common/utils';
import { numberFormatter } from 'common/formatters';
import { useAsync } from 'react-use';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface WalletTotalAssetChartProps {
  address: string;
}

export const WalletTotalAssetChart: FC<WalletTotalAssetChartProps> = ({ address }) => {
  const [refetch, setRefetch] = useState<boolean>(false);
  const {
    loading,
    error,
    value: metrics,
  } = useAsync(async () => {
    return (await ClientAPI.getWalletTotalAsset(address)).data;
  }, [refetch]);

  const { labels, data } = useMemo(() => {
    const { labels, data } = getAggregatedPieChartData(metrics);
    const finalLabels = labels.map((l) => `${l.substring(0, 1).toUpperCase()}${l.substring(1)}`);
    return { labels: finalLabels, data };
  }, [metrics]);

  const dataSum = useMemo(() => {
    return data.reduce((prev, curr) => prev + curr, 0);
  }, [data]);

  const chartContent = useMemo(() => {
    if (loading) return <Spinner />;
    if (error)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <RefreshOutlined className={classes.refresh} onClick={() => setRefetch((old) => !old)} />
          <Typography>Refresh to load the chart</Typography>
        </Stack>
      );
    if (!data.length)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <GhostIcon />
          <Typography>No assets found for this wallet</Typography>
        </Stack>
      );
    return (
      <Pie
        width='100%'
        height='100%'
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: pieChartColors,
              borderWidth: 1,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              position: 'right',
            },
            datalabels: {
              align: 'start',
              anchor: 'end',
              formatter: (value) => {
                if (!value) return '';
                return `${Math.round((value * 10000) / dataSum) / 100}%`;
              },
              font: {
                size: 8,
              },
            },
            tooltip: {
              bodyFont: { weight: 'bold' },
              callbacks: {
                label: (context) => {
                  let label = context.dataset.label || '';

                  if (context.parsed !== null) {
                    return ` $${numberFormatter.format(context.parsed)}`;
                  }
                  return label;
                },
              },
            },
          },
        }}
      />
    );
  }, [labels, data, dataSum, loading, error]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classes.title} spacing={3}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={3} alignItems='center'>
            <CommonChip>
              <ChainIcon fontSize='large' style={{ color: colorPrimary40 }} />
            </CommonChip>
            <Stack>
              <Typography variant='h6'>Blockchain Asset Distribution</Typography>
              <Typography>
                This pie chart shows the USD value distribution of assets across different blockchains, arranged by
                percentage share.
              </Typography>
            </Stack>
          </Stack>
          <IconButton>
            <MoreVertOutlined />
          </IconButton>
        </Stack>
      </Stack>
      <Stack className={classes.chart} alignItems='center' justifyContent='center'>
        {chartContent}
      </Stack>
    </Stack>
  );
};
