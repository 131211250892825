import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { GAAccount, GAProperty } from 'common/types/GoogleAnalytics';
import { colorNeutralVariant30, colorSurface4 } from 'common/params';

import { AttributionSource } from 'common/types/AttributionSource';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

interface GAPropertiesSelectProps {
  accounts: GAAccount[];
  attributionSources: AttributionSource[];
  selectedPropertyIds: string[];
  onPropertyIdsChange?: (propertyIds: string[]) => void;
}

export const GAPropertiesSelect: FC<GAPropertiesSelectProps> = ({
  accounts,
  attributionSources,
  selectedPropertyIds,
  onPropertyIdsChange,
}) => {
  const [searchStr, setSearchStr] = useState<string>('');

  const availableAccounts = useMemo(() => {
    const availableAccounts: GAAccount[] = [];
    accounts.forEach((account) => {
      const availableProperties: GAProperty[] = [];
      account.properties.forEach((property) => {
        // if this property is already created (exists in attributionSources) -> filter it
        if (
          attributionSources.findIndex(
            (attributionSource) => attributionSource.property_id === property.property_id,
          ) === -1
        ) {
          availableProperties.push(property);
        }
      });
      if (availableProperties.length) {
        availableAccounts.push({ ...account, properties: availableProperties });
      }
    });
    return availableAccounts;
  }, [accounts, attributionSources]);

  const filteredAccounts = useMemo(() => {
    const filteredAccounts: GAAccount[] = [];
    availableAccounts.forEach((account) => {
      const search = searchStr.toUpperCase();
      if (account.account_name.toUpperCase().indexOf(search) > -1) {
        filteredAccounts.push(account);
        return;
      }
      if (account.account_id.toUpperCase().indexOf(search) > -1) {
        filteredAccounts.push(account);
        return;
      }
      const filteredProperties: GAProperty[] = [];
      account.properties.forEach((property) => {
        if (property.property_id.toUpperCase().indexOf(search) > -1) {
          filteredProperties.push(property);
          return;
        }
        if (property.property_name.toUpperCase().indexOf(search) > -1) {
          filteredProperties.push(property);
          return;
        }
      });
      if (filteredProperties.length) {
        filteredAccounts.push({ ...account, properties: filteredProperties });
      }
    });
    return filteredAccounts;
  }, [availableAccounts, searchStr]);

  const handlePropertyIdsChange = useCallback(
    (value: string | string[]) => {
      if (typeof value === 'object' && value[value.length - 1] === 'all') {
        const selectAll = filteredAccounts.reduce((prev, curr) => {
          return prev && curr.properties.every((property) => value.findIndex((v) => v === property.property_id) > -1);
        }, true);
        const propertyIds: string[] = [];
        if (!selectAll) {
          filteredAccounts.forEach((filteredAccount) => {
            filteredAccount.properties.forEach((property) => {
              propertyIds.push(property.property_id);
            });
          });
        }
        onPropertyIdsChange?.(propertyIds);
        return;
      }

      // when clicking search fields
      // this function will be called with value = [undefined]
      // in this case, selectedIds should be []
      // so we handle it by checking isEmptyValue additionally
      const isEmptyValue = typeof value === 'object' && value.length === 1 && value[0] === undefined;
      const selectedIds = typeof value === 'string' ? value.split(',') : isEmptyValue ? [] : value;
      onPropertyIdsChange?.(selectedIds);
    },
    [filteredAccounts, onPropertyIdsChange],
  );

  const isAllSelected = useMemo(() => {
    const filteredPropertiesLength = filteredAccounts.reduce((prev, curr) => prev + curr.properties.length, 0);
    return !!selectedPropertyIds.length && filteredPropertiesLength === selectedPropertyIds.length;
  }, [selectedPropertyIds, filteredAccounts]);

  const isPartialSelected = useMemo(() => {
    const filteredPropertiesLength = filteredAccounts.reduce((prev, curr) => prev + curr.properties.length, 0);
    return !!selectedPropertyIds.length && filteredPropertiesLength !== selectedPropertyIds.length;
  }, [selectedPropertyIds, filteredAccounts]);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>Property</InputLabel>
      <Select
        multiple
        label='Property'
        value={selectedPropertyIds}
        onChange={(e) => {
          handlePropertyIdsChange(e.target.value);
        }}
        MenuProps={{
          disableAutoFocusItem: true,
          sx: {
            height: '45vh',
          },
        }}
        renderValue={(v) => {
          const values = v.map((v) => {
            let propertyName = '';
            availableAccounts.forEach((account) =>
              account.properties.forEach((property) => {
                if (property.property_id === v) {
                  propertyName = property.property_name;
                }
              }),
            );
            return propertyName;
          });
          return (
            <Tooltip title={values.join(', ')} placement='top' arrow>
              <Typography variant='label1' style={{ textOverflow: 'ellipsis' }}>
                {values.join(', ')}
              </Typography>
            </Tooltip>
          );
        }}
      >
        <Stack>
          <OutlinedInput
            startAdornment={
              <InputAdornment position='start'>
                <SearchOutlinedIcon />
              </InputAdornment>
            }
            placeholder='Search'
            sx={{ width: '100%', '& fieldset': { borderColor: 'transparent' } }}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value.toUpperCase())}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Stack>
        <MenuItem value='all'>
          <Stack direction='row' alignItems='center'>
            <Checkbox checked={isAllSelected} indeterminate={isPartialSelected} />
            <Typography variant='body1'>Select All</Typography>
          </Stack>
        </MenuItem>
        {filteredAccounts.length ? (
          filteredAccounts.map((filteredAccount) => {
            // due to MenuItem should be direct child of Select and element map key issues
            // here we use jsxElements to prepare the elements to show
            const jsxElements = [];
            jsxElements.push(
              <ListSubheader style={{ backgroundColor: colorSurface4, padding: '16px 28px', color: 'black' }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography variant='body1'>{filteredAccount.account_name}</Typography>
                  <Typography variant='label2' color={colorNeutralVariant30}>
                    {filteredAccount.properties.length} Properties
                  </Typography>
                </Stack>
              </ListSubheader>,
            );
            filteredAccount.properties.forEach((property) =>
              jsxElements.push(
                <MenuItem key={property.property_id} value={property.property_id} style={{ paddingLeft: '32px' }}>
                  <Stack direction='row' alignItems='center'>
                    <Checkbox checked={selectedPropertyIds && selectedPropertyIds.indexOf(property.property_id) > -1} />
                    <Typography variant='body1'>{property.property_name}</Typography>
                  </Stack>
                </MenuItem>,
              ),
            );
            return jsxElements;
          })
        ) : (
          <MenuItem disabled>
            <Typography variant='body1'>No Result</Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
