import { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';

import { AccountsUser } from 'common/types/Accounts/AccountsUser';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import { User } from 'common/types/User';
import { useAccountsUserProfile } from 'common/hooks/Accounts/useAccountsUserProfile';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from 'common/hooks/useUserProfile';

export const ClientACL: FC<
  {
    adminOnly?: boolean;
    enabledUserOnly?: boolean;
    allowedUsers?: string[];
    forbidUsers?: string[];
    defaultAllowed?: boolean;
    redirectOnForbid?: boolean;
    redirectUrl?: string;
    onAuthorized?: () => void;
    onUnauthorized?: () => void;
  } & HTMLAttributes<HTMLElement>
> = ({
  adminOnly = false,
  enabledUserOnly = false,
  allowedUsers = [],
  forbidUsers = [],
  defaultAllowed = true,
  redirectOnForbid = false, // default not redirect url
  redirectUrl = Uris.Pages.Root,
  children,
  onAuthorized,
  onUnauthorized,
}) => {
  /** navigate */
  const navigate = useNavigate();

  /** User Profile */
  const { loading, value: user } = useUserProfile();

  /** Accounts User Profile */
  const { loading: accountsUserLoading, value: accountsUser } = useAccountsUserProfile();

  /** State */
  const [authorized, setAuthorized] = useState<boolean>(false);

  /** Authorization */
  const authorize = useCallback(
    (user?: User, accountsUser?: AccountsUser) => {
      let allowed = defaultAllowed;

      // TODO: add group filter

      // enabledUserOnly overwrite all above
      if (enabledUserOnly) {
        allowed = !!user?.enabled && !!accountsUser?.enabled;
      }

      // personal allow overwrite default
      if (user?.name && allowedUsers.indexOf(user.name) >= 0) {
        allowed = true;
      }

      // personal forbid overwrite personal allow
      if (!user || (user?.name && forbidUsers.indexOf(user.name) >= 0)) {
        allowed = false;
      }

      // adminOnly overwrite all above
      if (adminOnly) {
        allowed = !!user?.is_admin;
      }

      setAuthorized(allowed);

      if (allowed) {
        onAuthorized?.();
      } else {
        // redirect if not authorized
        redirectOnForbid && navigate(redirectUrl);

        // callback
        onUnauthorized?.();
      }
    },
    [
      adminOnly,
      enabledUserOnly,
      defaultAllowed,
      allowedUsers,
      forbidUsers,
      redirectOnForbid,
      redirectUrl,
      onAuthorized,
      onUnauthorized,
      navigate,
    ],
  );

  useEffect(() => {
    if (loading || accountsUserLoading) return;
    authorize(user, accountsUser);
  }, [loading, accountsUserLoading, user, accountsUser, authorize]);

  /** Render */
  if (loading) return <Spinner />;

  return <>{authorized ? children : null}</>;
};
