import {
  ArrowBackOutlined,
  ContactSupportOutlined,
  DeviceHubOutlined,
  FolderOutlined,
  LinkOutlined,
  LogoutOutlined,
  OutboundOutlined,
  PaymentOutlined,
  PersonOutline,
  Star,
} from '@mui/icons-material';
import { Button, Divider, Drawer, Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { appBarHeight, colorSurface1, userSideBarWidth } from 'common/params';
import { getCookie, redirectToExternalPage } from 'common/utils';
import { useLocation, useNavigate } from 'react-router-dom';

import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './UserSideBar.module.scss';
import { useUserProfile } from 'common/hooks/useUserProfile';

interface MenuLink {
  icon: React.ReactNode; // icon for the menu link
  label: string; // display label for the menu link
  uri: string; // uri for the menu link
  needUserEnabled?: boolean; // user need to be enabled to see this link
}

const menuLinks: MenuLink[] = [
  {
    icon: <PersonOutline />,
    label: 'Profile',
    uri: Uris.Pages.User.Info,
  },
  {
    icon: <DeviceHubOutlined />,
    label: 'Integration',
    uri: Uris.Pages.User.Integration.Index,
    needUserEnabled: true,
  },
  {
    icon: <FolderOutlined />,
    label: 'File management',
    uri: Uris.Pages.User.FileManagement,
    needUserEnabled: true,
  },
  {
    icon: <LinkOutlined />,
    label: 'Campaign links',
    uri: Uris.Pages.User.CampaignLink.Index,
    needUserEnabled: true,
  },
  {
    icon: <Star />,
    label: 'Subscription',
    uri: Uris.Pages.User.Subscription.Index,
    needUserEnabled: true,
  },
  {
    icon: <PaymentOutlined />,
    label: 'Payment',
    uri: Uris.Pages.User.Payment.Index,
    needUserEnabled: true,
  },
];

interface UserSideBarProps {
  open: boolean;
}

export const UserSideBar: FC<UserSideBarProps> = ({ open }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { value: userProfile } = useUserProfile();

  const signOut = useCallback(async () => {
    redirectToExternalPage(Uris.External.Logout, window.location.origin);
  }, []);

  /** Render */
  return (
    <Drawer
      className={classes.root}
      sx={{
        '& .MuiDrawer-paper': {
          width: userSideBarWidth,
          boxSizing: 'border-box',
          backgroundColor: colorSurface1,
          top: appBarHeight,
          height: `calc(100vh - ${appBarHeight})`,
        },
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <Stack className={classes.container}>
        <Stack direction='row' className={classes.btnBack}>
          <Button
            variant='outlined'
            startIcon={<ArrowBackOutlined />}
            onClick={() => {
              const return_url = getCookie('return_url');
              if (return_url) {
                navigate(return_url);
              } else {
                navigate(Uris.Pages.Root);
              }
            }}
          >
            Back
          </Button>
        </Stack>
        <Stack justifyContent='space-between' className={classes.content}>
          <Stack spacing={1}>
            <Typography variant='h3' className={classes.title}>
              Settings
            </Typography>
            <Stack>
              {menuLinks
                .filter((menuLink) => !menuLink?.needUserEnabled || (menuLink?.needUserEnabled && userProfile?.enabled))
                .map((menuLink) => {
                  const selected =
                    menuLink.uri.length > 1 ? pathname.startsWith(menuLink.uri) : pathname === menuLink.uri;
                  return (
                    <Stack
                      key={menuLink.label}
                      direction='row'
                      alignItems='center'
                      className={classNames(classes.link, selected && classes.selected)}
                      spacing={2}
                      onClick={() => navigate(menuLink.uri)}
                    >
                      {menuLink.icon}
                      <Typography variant='label1'>{menuLink.label}</Typography>
                    </Stack>
                  );
                })}
            </Stack>
            <div style={{ width: '100%' }}>
              <Divider />
            </div>
            <Stack direction='row' alignItems='center' className={classes.link} spacing={2} onClick={() => signOut()}>
              <LogoutOutlined />
              <Typography variant='label1'>Log out</Typography>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            className={classes.link}
            spacing={1}
            onClick={() => window.open(Uris.External.Support, '_blank')}
          >
            <Stack direction='row' spacing={2}>
              <ContactSupportOutlined />
              <Typography variant='label1'>Support</Typography>
            </Stack>
            <OutboundOutlined />
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};
