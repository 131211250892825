import {
  AddessInteraction,
  DefaultAddessInteractionData,
  InteractType,
  InteractTypeNameMap,
} from 'common/types/AddressInteraction';
import { FC, useCallback } from 'react';
import { MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { borderRadius, chipInputHeight } from 'common/params';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { label1 } from 'theme/typography';
import { validateAddress } from 'common/utils';

interface AddressSelectProps {
  data?: AddessInteraction;
  disabled?: boolean;
  onChange?: (data: AddessInteraction) => void;
}

const options = [
  {
    value: InteractType.TRUE,
    label: InteractTypeNameMap.get(InteractType.TRUE),
  },
  {
    value: InteractType.FALSE,
    label: InteractTypeNameMap.get(InteractType.FALSE),
  },
];

export const AddressSelect: FC<AddressSelectProps> = ({ data, disabled, onChange }) => {
  const onAddressChange = useCallback(
    (contract_addr: string) => {
      onChange?.({ ...(data || DefaultAddessInteractionData), contract_addr });
    },
    [data, onChange],
  );

  const onInteractChange = useCallback(
    (has_interaction: boolean) => {
      onChange?.({ ...(data || DefaultAddessInteractionData), has_interaction });
    },
    [data, onChange],
  );

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <OutlinedInput
        placeholder='0x11ea001fe942f96eea22ba08783140b9dcc18dda'
        disabled={disabled}
        error={!validateAddress((data || DefaultAddessInteractionData).contract_addr || '')}
        sx={{
          width: '160px',
          height: chipInputHeight,
          borderRadius: borderRadius,
          ...label1,
          '.MuiOutlinedInput-input:placeholder-shown': { textOverflow: 'ellipsis' },
        }}
        value={(data || DefaultAddessInteractionData).contract_addr}
        onChange={(e) => onAddressChange(e.target.value)}
      ></OutlinedInput>
      <Select
        value={(data || DefaultAddessInteractionData).has_interaction ? InteractType.TRUE : InteractType.FALSE}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.value === InteractType.TRUE) {
            onInteractChange(true);
          } else {
            onInteractChange(false);
          }
        }}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          width: '160px',
          height: chipInputHeight,
          borderRadius: borderRadius,
        }}
        renderValue={(v) => {
          return <Typography variant='label1'>{InteractTypeNameMap.get(v)}</Typography>;
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            <Typography variant='body1'>{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
