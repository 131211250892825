import { LastDays, LastDaysNameMap } from 'common/types/LastDays';
import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { borderRadius, chipInputHeight } from 'common/params';

import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface LastDaysSelectProps {
  data?: LastDays;
  disabled?: boolean;
  onChange?: (dateRange: LastDays) => void;
}

const options = [
  {
    value: LastDays.YESTERDAY,
    label: LastDaysNameMap.get(LastDays.YESTERDAY),
  },
  {
    value: LastDays.LAST_7_DAYS,
    label: LastDaysNameMap.get(LastDays.LAST_7_DAYS),
  },
  {
    value: LastDays.LAST_15_DAYS,
    label: LastDaysNameMap.get(LastDays.LAST_15_DAYS),
  },
  {
    value: LastDays.LAST_30_DAYS,
    label: LastDaysNameMap.get(LastDays.LAST_30_DAYS),
  },
  {
    value: LastDays.LAST_60_DAYS,
    label: LastDaysNameMap.get(LastDays.LAST_60_DAYS),
  },
];

export const LastDaysSelect: FC<LastDaysSelectProps> = ({ data, disabled, onChange }) => {
  return (
    <Select
      value={data}
      onChange={(e) => {
        onChange?.(e.target.value as LastDays);
      }}
      IconComponent={KeyboardArrowDownIcon}
      disabled={disabled}
      sx={{
        width: '160px',
        height: chipInputHeight,
        borderRadius: borderRadius,
      }}
      renderValue={(v) => {
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            <EventNoteOutlinedIcon style={{ width: '16px' }} />
            <Typography variant='label1'>{LastDaysNameMap.get(v)}</Typography>
          </Stack>
        );
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem value={value} key={value}>
          <Typography variant='body1'>{label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
