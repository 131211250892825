import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { ContentCopyOutlined, DeleteOutlineOutlined, InfoOutlined, MoreVertOutlined } from '@mui/icons-material';
import { FC, HTMLAttributes, useState } from 'react';
import { IconButton, MenuItem, MenuList, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { datetimeFormatter, numberFormatter } from 'common/formatters';

import { Cohort } from 'common/types/Cohort';
import { JobStatus } from 'common/types/JobStatus';
import { Uris } from 'Uris';
import classes from './CohortList.module.scss';
import { renderJobStatus } from 'components/JobStatus/renderJobStatus';
import { useNavigate } from 'react-router-dom';

interface CohortListProps extends HTMLAttributes<HTMLElement> {
  cohorts: Cohort[];
  onDelete?: (cohort: Cohort) => void;
  onDuplicate?: (cohort: Cohort) => void;
}

export const CohortList: FC<CohortListProps> = ({ cohorts, onDelete, onDuplicate, ...rest }) => {
  const navigate = useNavigate();
  const [anchorElement, setAnchor] = useState<HTMLElement>();
  const [selectedCohort, setSelectedCohort] = useState<Cohort | undefined>(undefined);

  const columnDefs: CommonTableColumn<Cohort>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Stack
            className={data?.status === JobStatus.COMPLETED ? classes.pointer : ''}
            onClick={() => {
              if (data?.status !== JobStatus.COMPLETED) return;
              navigate(Uris.Pages.WalletSelector.CohortDetail.replace(':cohortId', data?.id?.toString() || ''));
            }}
          >
            <Typography variant='subtitle2'>{value}</Typography>
            <Typography variant='body2' color='secondary'>
              no. {data?.id || ''}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'last_run_at',
      label: 'Last Run',
      sortable: true,
      render: (value) => {
        if (!value) return '-';
        return <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>;
      },
    },
    {
      id: 'total_balance',
      label: 'Balance (USD)',
      textAlign: 'right',
      sortable: true,
      render: (value) => {
        if (typeof value !== 'number') return '-';
        return (
          <Stack>
            <Typography variant='subtitle2'>${numberFormatter.format(value)}</Typography>
            <Typography variant='body2' color='secondary'>
              USD
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'wallet_count',
      label: 'Wallet Count',
      textAlign: 'right',
      sortable: true,
      render: (value) => {
        if (typeof value !== 'number') return '-';
        return <Typography variant='body1'>{numberFormatter.format(value || 0)}</Typography>;
      },
    },
    {
      id: 'status',
      label: 'Sync Status',
      sortable: true,
      render: (value, idx, data) => {
        return (
          <Stack direction='row' alignItems='center' spacing={1} id={`cohort-sync-status-${idx}`}>
            {renderJobStatus(value)}
            {value === JobStatus.QUEUEING || value === JobStatus.RUNNING ? (
              <Tooltip title='This process may take between 30 minutes to 1 hour. Please return later to check the results.'>
                <InfoOutlined fontSize='small' />
              </Tooltip>
            ) : null}
            <IconButton
              onClick={(event) => {
                setAnchor(event.currentTarget);
                setSelectedCohort(data);
              }}
              className={classes.moreButton}
            >
              <MoreVertOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <CommonTable
        {...rest}
        selected={cohorts.findIndex((cohort) => cohort.id === selectedCohort?.id)}
        data={cohorts}
        columns={columnDefs}
        classes={classes}
      />
      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchor(undefined);
          setSelectedCohort(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            disabled={cohorts.find((cohort) => cohort.id === selectedCohort?.id)?.status !== JobStatus.COMPLETED}
            onClick={() => {
              if (typeof selectedCohort?.id !== 'number') return;
              setAnchor(undefined);
              navigate(Uris.Pages.WalletSelector.CohortDetail.replace(':cohortId', selectedCohort.id.toString()));
              setSelectedCohort(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <InfoOutlined />
              <Typography>View</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (typeof selectedCohort?.id !== 'number') return;
              setAnchor(undefined);
              onDuplicate?.(selectedCohort);
              setSelectedCohort(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <ContentCopyOutlined />
              <Typography>Duplicate</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (typeof selectedCohort?.id !== 'number') return;
              setAnchor(undefined);
              onDelete?.(selectedCohort);
              setSelectedCohort(undefined);
            }}
          >
            <Stack direction='row' spacing={1} color='error'>
              <DeleteOutlineOutlined color='error' />
              <Typography color='error'>Delete</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
