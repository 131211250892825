import { Breadcrumbs, Button, Link, Stack, Tooltip, Typography } from '@mui/material';
import { CustomizedInfluencerJob, InfluencerUserStatus } from 'common/types/CustomizedInfluencer';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FileUploadOutlined, InfoOutlined, NavigateNextOutlined } from '@mui/icons-material';
import { colorNeutralVariant30, colorPrimary30 } from 'common/params';

import ClientAPI from 'common/ClientAPI';
import { InfluencerUploadDialog } from './dialog/InfluencerUploadDialog';
import { JobStatus } from 'common/types/JobStatus';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './InfluencerUploadList.module.scss';
import { datetimeFormatter } from 'common/formatters';
import { renderJobStatus } from 'components/JobStatus/renderJobStatus';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

enum FilterStatus {
  ALL = 'All',
  READY = 'Ready',
  SYNCING = 'Syncing',
  FAILED = 'failed',
}

interface InfluencerUploadListProps {
  onBack?: () => void;
}

const calculateEstimationTime = (job: CustomizedInfluencerJob) => {
  const MIN_WAIT_TIME_MINUTES = 20;
  const ROUND_DIGIT = 10;
  return Math.max(MIN_WAIT_TIME_MINUTES, Math.round(job.users.length / 3 / ROUND_DIGIT) * ROUND_DIGIT);
};

const MAX_SHOW_USERS = 10;

export const InfluencerUploadList: FC<InfluencerUploadListProps> = ({ onBack }) => {
  const { showMessage } = useMessage();
  const [uploadDialogOpened, setUploadDialogOpened] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [filterStatusValue, setFilterStatusValue] = useState<FilterStatus>(FilterStatus.ALL);

  const {
    loading,
    error,
    value: influencerJobs,
  } = useAsync(async () => {
    return (await ClientAPI.getInfluencerUploadJobs()).data;
  }, [refetch]);

  useEffect(() => {
    error && error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const onCategoryUpload = useCallback(() => {
    setUploadDialogOpened(true);
  }, []);

  const filteredInfluencerJobs = useMemo(() => {
    return (influencerJobs || []).filter((influencerJob) => {
      switch (filterStatusValue) {
        case FilterStatus.ALL:
          return true;
        case FilterStatus.READY:
          return influencerJob.status === JobStatus.COMPLETED;
        case FilterStatus.SYNCING:
          return influencerJob.status === JobStatus.QUEUEING || influencerJob.status === JobStatus.RUNNING;
        case FilterStatus.FAILED:
          return influencerJob.status === JobStatus.FAILED;
        default:
          return false;
      }
    });
  }, [filterStatusValue, influencerJobs]);

  return (
    <Stack className={classes.root} spacing={4}>
      <Breadcrumbs
        separator={<NavigateNextOutlined style={{ color: colorNeutralVariant30, opacity: 0.38 }} />}
        className={classes.breadcrumbs}
        aria-label='breadcrumb'
      >
        <Link
          variant='subtitle2'
          underline='none'
          color={colorNeutralVariant30}
          className={classes.link}
          onClick={onBack}
        >
          <Typography variant='label1'>File management</Typography>
        </Link>
        <Typography color={colorPrimary30} variant='label1'>
          Self-upload Influencers lists
        </Typography>
      </Breadcrumbs>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h3'>Self-upload Influencers lists</Typography>
        <Button variant='contained' startIcon={<FileUploadOutlined />} onClick={onCategoryUpload}>
          Upload
        </Button>
      </Stack>
      <Stack direction='row' spacing={3}>
        {Object.values(FilterStatus).map((filterStatus) => (
          <Button
            key={filterStatus}
            className={classNames(classes.filter, filterStatus === filterStatusValue && classes.selected)}
            onClick={() => setFilterStatusValue(filterStatus)}
          >
            {filterStatus}
          </Button>
        ))}
      </Stack>
      {loading ? (
        <Spinner />
      ) : filteredInfluencerJobs.length ? (
        filteredInfluencerJobs.map((influencerJob) => (
          <Stack key={influencerJob.id} className={classes.category} spacing={2}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack direction='row' spacing={1.5} alignItems='center'>
                <Stack direction='row' spacing={1} alignItems='center'>
                  {renderJobStatus(influencerJob.status)}
                </Stack>
                {influencerJob.status === JobStatus.QUEUEING ? (
                  <Tooltip title={`It may take up to ${calculateEstimationTime(influencerJob)} minutes to sync`}>
                    <InfoOutlined fontSize='small' />
                  </Tooltip>
                ) : null}
              </Stack>
              <Typography variant='body2'>
                Updated on {datetimeFormatter.format((influencerJob.updated_at || 0) * 1000)}
              </Typography>
            </Stack>
            <Stack direction='row' spacing={1} justifyContent='space-between'>
              <Typography
                variant='h6'
                className={classNames(classes.name, influencerJob.final_collection_id && classes.link)}
              >
                {influencerJob.name}
              </Typography>
              {influencerJob.final_collection_id ? (
                <Button
                  variant='outlined'
                  onClick={() => {
                    window.open(
                      `${Uris.Pages.InfluencerMatcher.Index}?tab=collections&collectionId=${influencerJob.final_collection_id}`,
                    );
                  }}
                >
                  View
                </Button>
              ) : null}
            </Stack>
            {influencerJob.status !== JobStatus.FAILED ? (
              <>
                <Stack>
                  <Typography>
                    <span className={classes.highlight}>
                      {influencerJob.status === JobStatus.COMPLETED
                        ? influencerJob.users.filter(
                            (user) =>
                              user.status === InfluencerUserStatus.EXIST_DB || user.status === InfluencerUserStatus.NEW,
                          ).length
                        : '-'}
                    </span>{' '}
                    / {influencerJob.users.length}
                  </Typography>
                  <Typography variant='body2'>Success / Uploaded</Typography>
                </Stack>
                <Typography className={classes.usernames} variant='body2'>
                  {influencerJob.users
                    .slice(0, MAX_SHOW_USERS)
                    .map((user) => user.username)
                    .join(', ')}
                  {influencerJob.users.length > MAX_SHOW_USERS
                    ? ` and ${influencerJob.users.length - MAX_SHOW_USERS} more`
                    : ''}
                </Typography>
              </>
            ) : null}
          </Stack>
        ))
      ) : (
        <Typography>No influencer lists available</Typography>
      )}
      {uploadDialogOpened ? (
        <InfluencerUploadDialog
          open
          onCreate={() => {
            setUploadDialogOpened(false);
            setRefetch((old) => !old);
          }}
          onCancel={() => setUploadDialogOpened(false)}
        />
      ) : null}
    </Stack>
  );
};
