import {
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, SearchOutlined } from '@mui/icons-material';
import { getIsoFromLang, getLangFromIso } from 'common/languages';

import classes from './LanguagesFilter.module.scss';

interface LanguagesFilterCollapseProps {
  data?: string[];
  languages?: string[];
  onChange?: (data?: string[]) => void;
}

export const LanguagesFilterCollapse: FC<LanguagesFilterCollapseProps> = ({ data, languages, onChange }) => {
  const [searchString, setSearchString] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const covertedData = useMemo(() => {
    if (!data?.length) return data;
    return data.map((d) => getLangFromIso(d));
  }, [data]);

  const covertedLanguages = useMemo(() => {
    if (!languages?.length) return languages;
    return languages.map((d) => getLangFromIso(d)).sort();
  }, [languages]);

  const filteredLanguages = useMemo(() => {
    if (!searchString) return covertedLanguages || [];
    return (covertedLanguages || []).filter((language) => {
      return language.toLowerCase().includes(searchString.toLowerCase());
    });
  }, [searchString, covertedLanguages]);

  const handleLanguagesChange = useCallback(
    (value: string | string[]) => {
      if (typeof value === 'object' && value[value.length - 1] === 'all') {
        const selectAll = filteredLanguages.every(
          (filteredLanguage) => value.findIndex((v) => v === filteredLanguage) > -1,
        );
        const languages: string[] = [];
        if (!selectAll) {
          filteredLanguages.forEach((filteredLanguage) => {
            languages.push(filteredLanguage);
          });
        }
        onChange?.(languages.map((l) => getIsoFromLang(l)));
        return;
      }

      // when clicking search fields
      // this function will be called with value = [undefined]
      // in this case, selectedIds should be []
      // so we handle it by checking isEmptyValue additionally
      const isEmptyValue = typeof value === 'object' && value.length === 1 && value[0] === undefined;
      const selectedLangs = typeof value === 'string' ? value.split(',') : isEmptyValue ? [] : value;
      onChange?.(selectedLangs.map((l) => getIsoFromLang(l)));
    },
    [filteredLanguages, onChange],
  );

  const isAllSelected = useMemo(() => {
    return !!covertedData?.length && filteredLanguages?.length === covertedData.length;
  }, [covertedData, filteredLanguages]);

  const isPartialSelected = useMemo(() => {
    return !!covertedData?.length && filteredLanguages?.length !== covertedData.length;
  }, [covertedData, filteredLanguages]);

  return (
    <Stack className={classes.root}>
      <Stack
        direction='row'
        className={classes.title}
        justifyContent='space-between'
        onClick={() => setOpen((old) => !old)}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Typography>Language</Typography>
          {covertedData?.length ? (
            <Typography className={classes.value} variant='label1'>
              {covertedData.length}
            </Typography>
          ) : null}
        </Stack>
        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Stack>
      <Collapse in={open} timeout='auto'>
        <Stack spacing={3}>
          <Divider className={classes.divider} />
          <Stack spacing={3} className={classes.input}>
            <FormControl>
              <InputLabel>Language selected</InputLabel>
              <Select
                multiple
                value={covertedData || []}
                label='Language selected'
                renderValue={(languages) => {
                  const string =
                    languages.length < 3
                      ? languages.join(', ')
                      : languages.slice(0, 2).join(', ') + `... and ${languages.length - 2} more`;
                  return <Typography>{string}</Typography>;
                }}
                onChange={(e) => handleLanguagesChange(e.target.value)}
              >
                <Stack>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position='start'>
                        <SearchOutlined />
                      </InputAdornment>
                    }
                    placeholder='Search'
                    sx={{ width: '100%', '& fieldset': { borderColor: 'transparent' } }}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </Stack>
                <MenuItem value='all'>
                  <Stack direction='row' alignItems='center'>
                    <Checkbox checked={isAllSelected} indeterminate={isPartialSelected} />
                    <Typography variant='body1'>All Languages</Typography>
                  </Stack>
                </MenuItem>
                {filteredLanguages.map((language) => (
                  <MenuItem key={language} value={language} className={classes.item}>
                    <Stack direction='row' alignItems='center'>
                      <Checkbox checked={(covertedData || []).findIndex((d) => d === language) > -1} />
                      <Typography variant='body1'>{language}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
