import { AdGroupPublishResult, PublishAdGroupResult } from 'components/TwitterAds/publish/content/PublishAdGroupResult';
import { Audience, AudienceTarget } from 'common/types/Audience';
import { Dialog, DialogProps, DialogTitle, IconButton, Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import ClientAPI from 'common/ClientAPI';
import { CloseOutlined } from '@mui/icons-material';
import { CreateTwitterAudience } from './content/CreateTwitterAudience';
import { PublishAudienceResult } from './content/PublishAudienceResult';
import { RecommendationLevel } from 'common/types/TwitterAccount';
import { SelectAccount } from './content/SelectAccount';
import { SelectAdGroup } from './content/SelectAdGroup';
import { SelectLevels } from './content/SelectLevels';
import { Spinner } from 'components/common/Spinner';
import classes from './TwitterAdsPublishDialog.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

interface TwitterAdsPublishDialogProps extends DialogProps {
  audience: Audience;
  accountId?: string;
  onDialogClose?: () => void;
}

enum TwitterAdsStep {
  INIT = 'init',
  SELECT_LEVELS = 'selectLevles',
  SELECT_ACCOUNT = 'selectAccount',
  SELECT_AD_GROUPS = 'selectAdGroups',
  CREATE_AUDIENCE = 'createAudience',
  PUBLISH_AD_GROUP_SUCCESS = 'publishAdGroupSuccess',
  PUBLISH_AUDIENCE_SUCCESS = 'publishAudienceSuccess',
}

export const TwitterAdsPublishDialog: FC<TwitterAdsPublishDialogProps> = ({
  audience,
  accountId,
  onDialogClose,
  ...props
}) => {
  const { showMessage } = useMessage();
  const { track } = useTracking();

  const [selectedLevels, setSelectedLevels] = useState<RecommendationLevel[]>([
    RecommendationLevel.LOW,
    RecommendationLevel.MEDIUM,
    RecommendationLevel.HIGH,
  ]);
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(accountId);
  const [publishedResult, setPublishedResult] = useState<AdGroupPublishResult[]>([]);
  const [step, setStep] = useState<TwitterAdsStep>(TwitterAdsStep.INIT);

  useEffect(() => {
    if (audience.target === AudienceTarget.INFLUENCER_BATCH) {
      setStep(TwitterAdsStep.SELECT_LEVELS);
      return;
    }
    setStep(TwitterAdsStep.SELECT_ACCOUNT);
  }, [audience]);

  const {
    loading,
    error,
    value: accounts,
  } = useAsync(async () => {
    return (await ClientAPI.getTwitterAdsAccounts()).data;
  }, []);

  const {
    loading: levelCountsLoading,
    error: levelCountsError,
    value: levelCounts,
  } = useAsync(async () => {
    if (audience.id === undefined) return undefined;
    if (audience.target !== AudienceTarget.INFLUENCER_BATCH) return [];
    return (await ClientAPI.getAudienceLevelCounts(audience.id)).data;
  }, []);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  useEffect(() => {
    levelCountsError && showMessage(levelCountsError?.message || 'Unknown Error', 'error');
  }, [levelCountsError, showMessage]);

  const content = useMemo(() => {
    // fetching accounts and level counts -> return spinner
    if (loading || levelCountsLoading)
      return (
        <Stack alignItems='center' className={classes.spinner}>
          <Spinner />
        </Stack>
      );
    switch (step) {
      case TwitterAdsStep.INIT:
        return <></>;
      case TwitterAdsStep.SELECT_LEVELS:
        return (
          <SelectLevels
            levels={selectedLevels}
            levelCounts={levelCounts}
            onBtnCancelClicked={onDialogClose}
            onBtnNextClicked={(levels: RecommendationLevel[]) => {
              setSelectedLevels(levels);
              setStep(accountId ? TwitterAdsStep.SELECT_AD_GROUPS : TwitterAdsStep.SELECT_ACCOUNT);
            }}
          />
        );
      case TwitterAdsStep.SELECT_ACCOUNT:
        return (
          <SelectAccount
            accounts={accounts || []}
            onBtnBackClicked={onDialogClose}
            onBtnNextClicked={(accountId: string) => {
              setSelectedAccountId(accountId);
              setStep(
                audience.target === AudienceTarget.INFLUENCER_BATCH
                  ? TwitterAdsStep.SELECT_AD_GROUPS
                  : TwitterAdsStep.CREATE_AUDIENCE,
              );
            }}
          />
        );
      case TwitterAdsStep.SELECT_AD_GROUPS:
        if (!selectedAccountId) return null;
        return (
          <SelectAdGroup
            audience={audience}
            accountId={selectedAccountId}
            accounts={accounts || []}
            levels={selectedLevels}
            onAccountIdChanged={(accountId) => setSelectedAccountId(accountId)}
            onAdGroupsPublished={(result: AdGroupPublishResult[]) => {
              setPublishedResult(result);
              setStep(TwitterAdsStep.PUBLISH_AD_GROUP_SUCCESS);
              track('form_submit', {
                sub_event: 'audience_published',
                custom_props: {
                  audience_id: audience.id,
                  audience_name: audience.name,
                  audience_approch: audience.target,
                  recommend_level: selectedLevels,
                  twitter_ads_account: selectedAccountId,
                  selected_adgroups: result.map((value) => value.adGroupId),
                },
              });
            }}
          ></SelectAdGroup>
        );
      case TwitterAdsStep.CREATE_AUDIENCE:
        if (!selectedAccountId) return null;
        return (
          <CreateTwitterAudience
            audience={audience}
            accountId={selectedAccountId}
            onBtnCancelClicked={onDialogClose}
            onAudiencePublished={(twitterAudienceName, twitterAudienceDescription) => {
              setStep(TwitterAdsStep.PUBLISH_AUDIENCE_SUCCESS);
              track('form_submit', {
                sub_event: 'audience_published',
                custom_props: {
                  audience_id: audience.id,
                  audience_name: audience.name,
                  audience_approch: audience.target,
                  twitter_audience_name: twitterAudienceName,
                  twitter_audience_description: twitterAudienceDescription,
                },
              });
            }}
          ></CreateTwitterAudience>
        );
      case TwitterAdsStep.PUBLISH_AD_GROUP_SUCCESS:
        return <PublishAdGroupResult results={publishedResult} onBtnBackClicked={onDialogClose}></PublishAdGroupResult>;
      case TwitterAdsStep.PUBLISH_AUDIENCE_SUCCESS:
        if (!selectedAccountId) return null;
        return (
          <PublishAudienceResult accountId={selectedAccountId} onBtnBackClicked={onDialogClose}></PublishAudienceResult>
        );
    }
  }, [
    loading,
    levelCountsLoading,
    levelCounts,
    accountId,
    audience,
    selectedLevels,
    selectedAccountId,
    accounts,
    publishedResult,
    step,
    onDialogClose,
    track,
  ]);

  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onDialogClose?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onDialogClose}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      {content}
    </Dialog>
  );
};
