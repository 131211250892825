import { AddCircleOutlineOutlined, CheckOutlined, FormatListBulletedOutlined } from '@mui/icons-material';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  MenuList,
  OutlinedInput,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { Collection, CollectionTwitterUser } from 'common/types/Extension/Collection';
import { FC, useCallback, useContext, useState } from 'react';

import { CollectionCreateLimitDialog } from 'components/FeatureLimit/CollectionCreateLimitDialog';
import { CollectionUserLimitDialog } from 'components/FeatureLimit/CollectionUserLimitDialog';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { DataContext } from 'contexts/DataContext';
import ExtensionAPI from 'common/ExtensionAPI';
import { Spinner } from 'components/common/Spinner';
import classNames from 'classnames';
import classes from './AddUserToCollectionPopover.module.scss';
import { dedupArray } from 'common/utils';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';
import { useUserFeatureLimits } from 'common/hooks/useUserFeatureLimits';

interface AddUserToCollectionPopoverProps {
  anchor: HTMLElement;
  twitterUsers: CollectionTwitterUser[];
  collections: Collection[];
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const AddUserToCollectionPopover: FC<AddUserToCollectionPopoverProps> = ({
  anchor,
  collections,
  twitterUsers,
  onSuccess,
  onCancel,
}) => {
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const [success, setSuccess] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] = useState<Collection | undefined>(undefined);
  const [newCollection, setNewCollection] = useState<Collection | undefined>(undefined);
  const [adding, setAdding] = useState<boolean>(false);

  const [collectionCreateLimitDialogOpened, setCollectionCreateLimitDialogOpened] = useState<boolean>(false);
  const [collectionUsersLimitDialogOpened, setCollectionUsersLimitDialogOpened] = useState<boolean>(false);

  const { value: createCollectionLimit } = useUserFeatureLimits('collection', 'create');
  const { value: collectionUsersLimit } = useUserFeatureLimits('collection', 'update');

  const addUserToCollection = useCallback(
    async (collection: Collection) => {
      setAdding(true);
      setSelectedCollection(collection);
      await ExtensionAPI.addUsersToCollection(
        collection,
        twitterUsers.map((twitterUser) => twitterUser.id),
      )
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            setSuccess(true);
            if (collection.id === 0) {
              track('click', {
                sub_event: 'list_created',
                custom_props: { entry_point: 'Drawer', list_id: data.id, list_name: collection.name },
              });
            }
            track('click', {
              sub_event: 'influencer_added',
              custom_props: {
                entry_point: 'Drawer',
                list_id: data.id,
                influencer_ids: collection.twitter_users.map((twitterUser) => twitterUser.id),
                influencer_names: collection.twitter_users.map((twitterUser) => twitterUser.username),
              },
            });
            return;
          }
          showMessage('Add user to collection failed', 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Add user to collection failed', 'error');
        })
        .finally(() => {
          setAdding(false);
        });
    },
    [twitterUsers, track, showMessage],
  );

  return (
    <>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => {
          onCancel?.();
        }}
      >
        <MenuList>
          <MenuItem
            divider
            onClick={(event) => {
              event.stopPropagation();
              if ((collections?.length || 0) >= createCollectionLimit && createCollectionLimit >= 0) {
                setCollectionCreateLimitDialogOpened(true);
                return;
              }
              setNewCollection({ id: 0, name: '', twitter_users: [] });
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={1}
              className={classes.item}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <AddCircleOutlineOutlined />
                <Typography variant='body1'>New list</Typography>
              </Stack>
              <Typography variant='label2'>
                <span
                  className={classNames(
                    classes.highlight,
                    collections.length >= createCollectionLimit && createCollectionLimit >= 0 && classes.error,
                  )}
                >
                  {collections.length}
                </span>
                /{createCollectionLimit >= 0 ? createCollectionLimit : 'unlimit'} lists
              </Typography>
            </Stack>
          </MenuItem>
          {collections.length ? (
            collections.map((collection) => (
              <MenuItem
                onClick={() => {
                  const ids = dedupArray([
                    ...collection.twitter_users.map((user) => user.id),
                    ...twitterUsers.map((user) => user.id),
                  ]);
                  if (ids.length > collectionUsersLimit && collectionUsersLimit >= 0) {
                    setCollectionUsersLimitDialogOpened(true);
                    return;
                  }
                  addUserToCollection(collection);
                }}
              >
                <Typography variant='body1'>{collection.name}</Typography>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <Typography variant='body1'>Currently no lists</Typography>
            </MenuItem>
          )}
        </MenuList>
      </Popover>
      <CommonDialog
        open={!!newCollection}
        mainContent={
          <Stack spacing={2} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <FormatListBulletedOutlined fontSize='large' />
            </CommonChip>
            <Typography variant='h6' className={classes.title}>
              Create and add profile to the list
            </Typography>
            <FormControl className={classes.input}>
              <InputLabel>Name of the list</InputLabel>
              <OutlinedInput
                size='medium'
                label='Name of the list'
                placeholder='Enter name of the list, eg. DeFi analysis'
                value={newCollection?.name}
                onChange={(e) => {
                  setNewCollection((old) =>
                    old ? { ...old, name: e.target.value } : { id: 0, name: '', twitter_users: [] },
                  );
                }}
              />
            </FormControl>
          </Stack>
        }
        footer={
          <Stack direction='row-reverse' spacing={1}>
            <Button
              variant='contained'
              startIcon={adding ? <Spinner size={24} /> : null}
              disabled={adding}
              onClick={() => {
                if (!newCollection) return;
                addUserToCollection(newCollection);
              }}
            >
              Save
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                onCancel?.();
              }}
            >
              Cancel
            </Button>
          </Stack>
        }
        onDialogClose={onCancel}
      />
      <CommonDialog
        open={success}
        mainContent={
          <Stack spacing={2} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <CheckOutlined fontSize='large' />
            </CommonChip>
            <Stack direction='row'>
              <Typography variant='h6' className={classes.title}>
                <span className={classes.highlight}>
                  {twitterUsers.map((twitterUser) => `"${twitterUser.display_name}"`).join(', ')}
                </span>{' '}
                is saved to the <span className={classes.highlight}>"{selectedCollection?.name}"</span> List
              </Typography>
            </Stack>
          </Stack>
        }
        footer={
          <Stack direction='row' justifyContent='center'>
            <Button variant='contained' onClick={onSuccess}>
              Back to List
            </Button>
          </Stack>
        }
        onDialogClose={onCancel}
      />

      {collectionCreateLimitDialogOpened ? (
        <CollectionCreateLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionCreateLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionCreateLimitDialogOpened(false)}
        />
      ) : null}

      {collectionUsersLimitDialogOpened ? (
        <CollectionUserLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionUsersLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionUsersLimitDialogOpened(false)}
        />
      ) : null}
    </>
  );
};
