import { Button, IconButton, MenuItem, MenuList, Popover, Stack, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn, PaginationOptions } from 'components/table/CommonTable';
import { FC, HTMLAttributes, useMemo, useState } from 'react';
import { FileDownloadOutlined, InfoOutlined, MoreVertOutlined } from '@mui/icons-material';
import { datetimeFormatter, numberFormatter } from 'common/formatters';

import { CohortWalletInfo } from 'common/types/WalletInfo';
import { Uris } from 'Uris';
import classes from './WalletList.module.scss';
import { useNavigate } from 'react-router-dom';

interface WalletListProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
  cohortId: number;
  cohortName: string;
  wallets: CohortWalletInfo[];
  totalWalletCnt: number;
  downloadDisabled?: boolean;
  onPageChange?: (paginationOptions: PaginationOptions) => void;
  onDownloadClicked?: () => void;
}

export const WalletList: FC<WalletListProps> = ({
  loading,
  cohortId,
  cohortName,
  wallets,
  totalWalletCnt,
  downloadDisabled,
  onPageChange,
  onDownloadClicked,
  ...rest
}) => {
  const navigate = useNavigate();
  const [anchorElement, setAnchor] = useState<HTMLElement>();
  const [address, setAddress] = useState<string>('');
  const [selected, setSelected] = useState<number | undefined>(undefined);

  const columnDefs: CommonTableColumn<CohortWalletInfo>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'account',
      label: 'Address',
      render: (value, _, data) => {
        return (
          <Stack
            className={classes.pointer}
            onClick={() => navigate(Uris.Pages.WalletSelector.WalletDetail.replace(':address', data?.account || ''))}
          >
            <Typography variant='subtitle2'>{data?.name || 'Unknown'}</Typography>
            <Typography variant='body2'>{value}</Typography>
          </Stack>
        );
      },
    },
    {
      id: 'total_asset_usd',
      label: 'Balance (USD)',
      textAlign: 'right',
      sortable: true,
      render: (value) => {
        return (
          <Stack>
            <Typography variant='subtitle2'>${numberFormatter.format(value)}</Typography>
            <Typography variant='body2' color='secondary'>
              USD
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'registration_days',
      label: 'Age(days)',
      textAlign: 'right',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{numberFormatter.format(value)}</Typography>;
      },
    },
    {
      id: 'current_nft_types',
      label: 'NFT Hold',
      textAlign: 'right',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{numberFormatter.format(value)}</Typography>;
      },
    },
    {
      id: 'last_trading_time',
      label: 'Last Active',
      sortable: true,
      render: (value, idx, data) => {
        return (
          <Stack direction='row' spacing={1}>
            <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>
            <IconButton
              onClick={(event) => {
                setAnchor(event.currentTarget);
                setAddress(data?.account || '');
                setSelected(idx);
              }}
              className={classes.moreButton}
            >
              <MoreVertOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const tableHeader = useMemo(() => {
    return (
      <Stack className={classes.tableHeader}>
        <Button
          variant='contained'
          startIcon={<FileDownloadOutlined />}
          disabled={!wallets.length || downloadDisabled}
          onClick={onDownloadClicked}
        >
          <Typography variant='label1'>Download</Typography>
        </Button>
      </Stack>
    );
  }, [wallets, downloadDisabled, onDownloadClicked]);

  return (
    <>
      <CommonTable
        {...rest}
        dataPagination
        loading={loading}
        selected={selected}
        data={wallets}
        totalDataCnt={totalWalletCnt}
        columns={columnDefs}
        classes={classes}
        header={tableHeader}
        onPageChange={onPageChange}
      />
      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchor(undefined);
          setSelected(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            disabled // TODO: open this when wallet page is ready
            onClick={() =>
              navigate(
                Uris.Pages.WalletSelector.WalletDetail.replace(':cohortId', cohortId.toString()).replace(
                  ':walletId',
                  address || '',
                ),
              )
            }
          >
            <Stack direction='row' spacing={1}>
              <InfoOutlined />
              <Typography>View</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
