export enum JobStatus {
  QUEUEING = 'queueing',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const JobStatusNameMap = new Map<string, string>([
  [JobStatus.QUEUEING, 'Syncing'],
  [JobStatus.RUNNING, 'Syncing'],
  [JobStatus.COMPLETED, 'Ready'],
  [JobStatus.FAILED, 'Failed'],
]);
