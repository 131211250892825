import {
  AddOutlined,
  ArrowBackOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ArrowForwardOutlined,
  ArrowOutwardOutlined,
  CloseOutlined,
  DataObjectOutlined,
  Message,
} from '@mui/icons-material';
import { Button, Collapse, Divider, Stack, TextField, Typography } from '@mui/material';
import {
  DefaultInfluencerMessage,
  InfluencerMessage,
  InfluencerThread,
} from 'common/types/Extension/InfluencerCampaign';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { InsertPlaceholderDialog } from 'components/InfluencerCampaign/Edit/Dialog/InsertPlaceholderDialog';
import { RemoveMessageDialog } from 'components/InfluencerCampaign/Edit/Dialog/RemoveMessageDialog';
import { SelectThreadDialog } from 'components/InfluencerCampaign/Edit/Dialog/SelectThreadDialog';
import { ViewInfluencersDialog } from 'components/InfluencerCampaign/Edit/Dialog/ViewInfluencersDialog';
import classes from './CampaignMessageEdit.module.scss';
import { colorPrimary40 } from 'common/params';
import { v4 as uuidv4 } from 'uuid';

interface CampaignMessageEditProps {
  draft?: boolean;
  saving?: boolean;
  threads: InfluencerThread[];
  messages: InfluencerMessage[];
  readonlyMessages: InfluencerMessage[];
  onNextBtnClicked?: () => void;
  onBackBtnClicked?: () => void;
  onSaveBtnClicked?: () => void;
  onCampaignThreadEdit?: (threads: InfluencerThread[]) => void;
  onCampaignMessageEdit?: (messages: InfluencerMessage[]) => void;
}

export const CampaignMessageEdit: FC<CampaignMessageEditProps> = ({
  draft,
  saving,
  threads,
  messages,
  readonlyMessages,
  onNextBtnClicked,
  onBackBtnClicked,
  onSaveBtnClicked,
  onCampaignThreadEdit,
  onCampaignMessageEdit,
}) => {
  const [messageOpens, setMessageOpens] = useState<boolean[]>([]);
  const [insertPlaceholderDialogOpened, setInsertPlaceholderDialogOpened] = useState<boolean>(false);
  const [viewInfluencersDialogOpened, setViewInfluencersDialogOpened] = useState<boolean>(false);
  const [selectThreadDialogOpened, setSelectThreadDialogOpened] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<InfluencerMessage | undefined>(undefined);
  const [initMessage, setInitMessage] = useState<boolean>(false);
  const [checkValid, setCheckValid] = useState<boolean>(false);

  const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
  const removeIdx = useRef<number>();

  useEffect(() => {
    if (initMessage) return;
    if (messages.length) return;
    onCampaignMessageEdit?.([{ ...DefaultInfluencerMessage, id: uuidv4() }]);
    setInitMessage(true);
  }, [initMessage, messages, onCampaignMessageEdit]);

  useEffect(() => {
    setMessageOpens((old) => {
      if (old.length === messages.length) {
        return old;
      }
      if (old.length > messages.length) {
        let arr = [...old];
        if (typeof removeIdx.current === 'number' && removeIdx.current < messages.length) {
          arr = [...old.slice(0, removeIdx.current), ...old.slice(removeIdx.current + 1)];
        } else {
          arr = old.slice(0, messages.length);
        }
        return arr;
      }
      return [...old, ...Array(messages.length - old.length).fill(true)];
    });
  }, [messages]);

  useEffect(() => {
    // currently the logic above will be called twice with unknown reason
    // so we clear the removeIdx here one message length is equal to message open length
    if (messages.length !== messageOpens.length) return;
    removeIdx.current = undefined;
  }, [messages, messageOpens]);

  const nextBtnDisabled = useMemo(() => {
    // check all user have binded to at least one message
    if (threads.some((thread) => !thread.influencer_message_id)) return true;

    // readonly messages do not need to bind at least one user
    // other messages should bind at least one user
    if (
      !messages.every(
        (message) =>
          readonlyMessages.findIndex((readonlyMessage) => readonlyMessage.id === message.id) > -1 ||
          threads.findIndex((thread) => thread.influencer_message_id === message.id) > -1,
      )
    )
      return true;

    // check message content not empty
    if (messages.some((message) => !message.content)) return true;

    return false;
  }, [readonlyMessages, messages, threads]);

  const saveBtnDisabled = useMemo(() => {
    // check all message bind at least one user
    return !messages.every(
      (message) => threads.findIndex((thread) => thread.influencer_message_id === message.id) > -1,
    );
  }, [messages, threads]);

  const isThreadsValid = useMemo(() => {
    // check all user have binded to at least one message
    return threads.every((thread) => !!thread.influencer_message_id);
  }, [threads]);

  const isMessageReadOnly = useCallback(
    (message: InfluencerMessage) => {
      // check whether message in readonlyMessages
      if (!readonlyMessages.length) return false;
      return readonlyMessages.findIndex((readonlyMessage) => readonlyMessage.id === message.id) > -1;
    },
    [readonlyMessages],
  );

  const isMessageContentValid = useCallback(
    (message: InfluencerMessage) => {
      if (!checkValid) return true;
      // message content should not be empty
      return !!message.content;
    },
    [checkValid],
  );

  const isMessageThreadsValid = useCallback(
    (message: InfluencerMessage) => {
      if (!checkValid) return true;

      // message in readonly message -> valid
      if (readonlyMessages.findIndex((readonlyMessage) => readonlyMessage.id === message.id) > -1) {
        return true;
      }

      // message thread has bind to at least one user -> valid
      if (threads.findIndex((thread) => thread.influencer_message_id === message.id) > -1) {
        return true;
      }

      return false;
    },
    [checkValid, readonlyMessages, threads],
  );

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack spacing={0.5}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>Compose Messages</Typography>
          <Stack direction='row' spacing={1}>
            {/* show save btn only in draft mode */}
            {draft ? (
              <Button
                variant='outlined'
                disabled={saving}
                onClick={() => {
                  setCheckValid(true);
                  if (saveBtnDisabled) return;
                  onSaveBtnClicked?.();
                }}
              >
                Save and exit
              </Button>
            ) : null}
            {!draft && onBackBtnClicked ? (
              <Button variant='outlined' disabled={saving} startIcon={<ArrowBackOutlined />} onClick={onBackBtnClicked}>
                Back
              </Button>
            ) : null}
            <Button
              variant='contained'
              disabled={saving}
              startIcon={<ArrowForwardOutlined />}
              onClick={() => {
                setCheckValid(true);
                if (nextBtnDisabled) return;
                onNextBtnClicked?.();
              }}
            >
              Next
            </Button>
          </Stack>
        </Stack>
        {checkValid && !isThreadsValid ? (
          <Stack direction='row-reverse'>
            <Typography variant='body2' color='error'>
              {threads.filter((thread) => !thread.influencer_message_id).length} influencers in the list are not
              assigned to messages
            </Typography>
          </Stack>
        ) : null}
      </Stack>
      <Stack className={classes.container} spacing={4}>
        <Typography>Messages Send</Typography>
        {messages.map((message, idx) => {
          const messageOpen = idx >= messageOpens.length ? false : messageOpens[idx];
          return (
            <Stack spacing={4} key={message.id}>
              <Divider />
              <Stack spacing={2}>
                <Stack direction='row' justifyContent='space-between'>
                  <Stack>
                    <Stack
                      className={classes.message}
                      direction='row'
                      color={colorPrimary40}
                      alignItems='center'
                      spacing={1}
                      onClick={() => {
                        setMessageOpens((old) => [...old.slice(0, idx), !old[idx], ...old.slice(idx + 1)]);
                      }}
                    >
                      <Message fontSize='small' />
                      <Typography>Message {idx + 1}</Typography>
                      {messageOpen ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
                    </Stack>
                    {!messageOpen ? (
                      <Stack direction='row' spacing={0.5}>
                        {!isMessageThreadsValid(message) ? (
                          <Typography variant='body2' color='error'>
                            Influencers are not assigned.
                          </Typography>
                        ) : null}
                        {!isMessageContentValid(message) ? (
                          <Typography variant='body2' color='error'>
                            Message content must not be empty.
                          </Typography>
                        ) : null}
                      </Stack>
                    ) : null}
                  </Stack>
                  {!isMessageReadOnly(message) && (idx !== 0 || messages.length > 1) ? (
                    <Button
                      variant='outlined'
                      startIcon={<CloseOutlined />}
                      onClick={() => {
                        setSelectedMessage(message);
                        setRemoveDialogOpened(true);
                      }}
                    >
                      Remove message
                    </Button>
                  ) : null}
                </Stack>
                <Collapse key={message.id} in={messageOpen} timeout='auto'>
                  <Stack direction='row' spacing={3}>
                    <Stack className={classes.edit} spacing={3}>
                      {!isMessageReadOnly(message) ? (
                        <Stack spacing={1} id={`area-create-campaign-compose-message-${idx}`}>
                          <Stack direction='row' spacing={1}>
                            <Button
                              variant='outlined'
                              startIcon={<DataObjectOutlined />}
                              onClick={() => {
                                setSelectedMessage(message);
                                setInsertPlaceholderDialogOpened(true);
                              }}
                            >
                              Name Placeholder
                            </Button>
                            {/* <Button variant='outlined' startIcon={<AddOutlined />}>
                              Add template
                            </Button>
                            <Button variant='outlined' startIcon={<FileDownloadOutlined />}>
                              Save as template
                            </Button> */}
                          </Stack>
                          <Stack>
                            <TextField
                              id={`textfield-${message.id}`}
                              error={!isMessageContentValid(message)}
                              multiline
                              maxRows={10}
                              value={message.content}
                              onChange={(e) =>
                                onCampaignMessageEdit?.([
                                  ...messages.slice(0, idx),
                                  { ...message, content: e.target.value },
                                  ...messages.slice(idx + 1),
                                ])
                              }
                            />
                            {!isMessageContentValid(message) ? (
                              <Typography variant='body2' color='error'>
                                Message must not be empty
                              </Typography>
                            ) : null}
                          </Stack>
                        </Stack>
                      ) : (
                        <Typography className={classes.preview}>{message.content}</Typography>
                      )}
                    </Stack>
                    <Stack
                      id={`area-create-campaign-assign-threads-${idx}`}
                      spacing={1.5}
                      className={classes.influencers}
                    >
                      <Stack>
                        <Stack direction='row' alignItems='center' spacing={0.5}>
                          <ArrowOutwardOutlined
                            style={{ transform: 'rotate(180deg)' }}
                            fontSize='small'
                            color='primary'
                          />
                          <Typography color='primary'>Assign influencers by messages</Typography>
                        </Stack>
                        <Typography>
                          {threads
                            .filter((thread) => thread.influencer_message_id === message.id)
                            .slice(0, 10)
                            .map((thread) => thread.receiver?.username)
                            .join(', ')}
                          {threads.filter((thread) => thread.influencer_message_id === message.id).length > 10 ? (
                            <span>
                              ... and{' '}
                              <span
                                className={classes.viewMore}
                                onClick={() => {
                                  setSelectedMessage(message);
                                  setViewInfluencersDialogOpened(true);
                                }}
                              >
                                {threads.length - 10} more
                              </span>
                            </span>
                          ) : null}
                        </Typography>
                      </Stack>
                      <Stack direction='row'>
                        <Button
                          className={classes.addInfluencerBtn}
                          variant='contained'
                          startIcon={<AddOutlined />}
                          onClick={() => {
                            setSelectedMessage(message);
                            setSelectThreadDialogOpened(true);
                          }}
                        >
                          Assign Influencers
                        </Button>
                      </Stack>
                      {!isMessageThreadsValid(message) ? (
                        <Typography variant='body2' color='error'>
                          Influencers are not assigned
                        </Typography>
                      ) : null}
                    </Stack>
                  </Stack>
                </Collapse>
              </Stack>
            </Stack>
          );
        })}
        <Stack direction='row-reverse'>
          <Button
            variant='contained'
            startIcon={<AddOutlined />}
            onClick={() => {
              onCampaignMessageEdit?.([...messages, { ...DefaultInfluencerMessage, id: uuidv4() }]);
            }}
          >
            Add new message
          </Button>
        </Stack>
      </Stack>
      {insertPlaceholderDialogOpened && selectedMessage ? (
        <InsertPlaceholderDialog
          onSave={(placeholder, fallback) => {
            const idx = messages.findIndex((message) => message.id === selectedMessage?.id);
            const textField = document.querySelector<HTMLInputElement>(`#textfield-${selectedMessage?.id}`);
            if (idx > -1 && textField) {
              const pos = textField.selectionStart || textField.value.length;
              const concatString = fallback ? ` {${placeholder}:${fallback}} ` : ` {${placeholder}} `;
              const finalstr = textField.value.substring(0, pos) + `${concatString}` + textField.value.substring(pos);
              const cursorPos = pos + concatString.length;

              onCampaignMessageEdit?.([
                ...messages.slice(0, idx),
                { ...messages[idx], content: finalstr },
                ...messages.slice(idx + 1),
              ]);

              setTimeout(() => {
                if (!textField) return;
                textField.setSelectionRange(cursorPos, cursorPos);
                textField.focus();
              }, 100);
            }
            setSelectedMessage(undefined);
            setInsertPlaceholderDialogOpened(false);
          }}
          onCancel={() => {
            setSelectedMessage(undefined);
            setInsertPlaceholderDialogOpened(false);
          }}
        />
      ) : null}

      {selectThreadDialogOpened && selectedMessage ? (
        <SelectThreadDialog
          threads={threads}
          message={selectedMessage}
          onAdd={(threads) => {
            onCampaignThreadEdit?.(threads);
            setSelectedMessage(undefined);
            setSelectThreadDialogOpened(false);
          }}
          onCancel={() => {
            setSelectedMessage(undefined);
            setSelectThreadDialogOpened(false);
          }}
        />
      ) : null}

      {removeDialogOpened && selectedMessage ? (
        <RemoveMessageDialog
          open
          message={selectedMessage}
          onRemove={() => {
            const idx = messages.findIndex((message) => message.id === selectedMessage.id);
            if (idx < 0) return;
            removeIdx.current = idx;
            const newThreads = threads.map((thread) => {
              if (thread.influencer_message_id !== selectedMessage.id) return { ...thread };
              return { ...thread, influencer_message_id: undefined };
            });
            onCampaignMessageEdit?.([...messages.slice(0, idx), ...messages.slice(idx + 1)]);
            onCampaignThreadEdit?.(newThreads);
            setSelectedMessage(undefined);
            setRemoveDialogOpened(false);
          }}
          onCancel={() => {
            setSelectedMessage(undefined);
            setRemoveDialogOpened(false);
          }}
        />
      ) : null}

      {viewInfluencersDialogOpened && selectedMessage ? (
        <ViewInfluencersDialog
          threads={threads}
          message={selectedMessage}
          onClose={() => {
            setSelectedMessage(undefined);
            setViewInfluencersDialogOpened(false);
          }}
        />
      ) : null}
    </Stack>
  );
};
