import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChainIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='-1 0 24 24'>
    <mask id='a' fill='#fff'>
      <path d='M15.4 4.5V2.3c0-.2.1-.3.3-.3h1.5c.2 0 .3.1.3.3v2.2c0 .6-.4 1-1 1a1 1 0 0 1-1.1-1Zm-10 5c0 .6.6 1 1.1 1 .6 0 1.1-.4 1.1-1v-5c0-.6-.5-1-1-1a1 1 0 0 0-1.1 1v5Zm0 10c0 .6.6 1 1.1 1 .6 0 1.1-.4 1.1-1v-5c0-.6-.5-1-1-1a1 1 0 0 0-1.1 1v5Zm11-4a1 1 0 0 1-1-1v-5c0-.6.5-1 1-1 .6 0 1.1.4 1.1 1v5c0 .6-.5 1-1 1Zm1.1 6.2v-2.2a1 1 0 0 0-1-1 1 1 0 0 0-1.1 1v2.2c0 .2.1.3.3.3h1.5c.2 0 .3-.1.3-.3ZM13 5.6c0-1.4.8-2.6 2-3.2v2c0 .4 0 .6.2.9V8.7c-.2.2-.2.5-.2.8v2.1a3.6 3.6 0 0 1-2-3.2V5.6Zm5.2-1c0 .2-.1.5-.3.7V8.7c.2.2.3.5.3.8v2.1A3.6 3.6 0 0 0 20 8.4V5.6c0-1.4-.8-2.6-2-3.2v2.1Zm1.9 11c0-1.4-.8-2.6-2-3.2v2c0 .4 0 .6-.2.9V18.7c.2.3.3.5.3.8v2.1a3.6 3.6 0 0 0 1.9-3.2v-2.8Zm-5.1-1.1c0 .3 0 .5.2.8V18.7c-.2.2-.2.5-.2.8v2.1a3.6 3.6 0 0 1-2-3.2v-2.8c0-1.4.8-2.6 2-3.2v2ZM5 2.7l.2.8c-.2.2-.4.6-.4 1v1.4A3.3 3.3 0 0 1 3 3v-.5c0-.2.2-.4.4-.4h1.3c.3 0 .4.2.4.4v.3Zm3 1.8v1.4a3.5 3.5 0 0 0 2-3.2v-.3c0-.2-.2-.4-.4-.4H8.3c-.2 0-.3.2-.3.4v.5l-.2.6c.2.3.3.6.3 1Zm-5.1 6c0-1.3.8-2.5 2-3.1v2c0 .4 0 .7.2.9V13.7c-.2.2-.3.5-.3.8v2.1A3.6 3.6 0 0 1 3 13.4v-2.8Zm5.1-1c0 .3 0 .5-.2.8V13.7c.2.2.2.5.2.8v2.1a3.6 3.6 0 0 0 2-3.2v-2.8a3.6 3.6 0 0 0-2-3.2v2ZM3 21.3A3.6 3.6 0 0 1 5 18v1.4c0 .4 0 .7.3 1l-.2.6v.5c0 .2-.1.4-.4.4H3.4a.4.4 0 0 1-.4-.4v-.3Zm5.1-1.8c0 .4-.1.7-.3 1l.2.8v.3c0 .2.1.4.3.4h1.4c.2 0 .4-.2.4-.4V21a3.4 3.4 0 0 0-2-3v1.4Z' />
    </mask>
    <path
      fill='#000'
      stroke='currentColor'
      stroke-width='2'
      d='M15.4 4.5V2.3c0-.2.1-.3.3-.3h1.5c.2 0 .3.1.3.3v2.2c0 .6-.4 1-1 1a1 1 0 0 1-1.1-1Zm-10 5c0 .6.6 1 1.1 1 .6 0 1.1-.4 1.1-1v-5c0-.6-.5-1-1-1a1 1 0 0 0-1.1 1v5Zm0 10c0 .6.6 1 1.1 1 .6 0 1.1-.4 1.1-1v-5c0-.6-.5-1-1-1a1 1 0 0 0-1.1 1v5Zm11-4a1 1 0 0 1-1-1v-5c0-.6.5-1 1-1 .6 0 1.1.4 1.1 1v5c0 .6-.5 1-1 1Zm1.1 6.2v-2.2a1 1 0 0 0-1-1 1 1 0 0 0-1.1 1v2.2c0 .2.1.3.3.3h1.5c.2 0 .3-.1.3-.3ZM13 5.6c0-1.4.8-2.6 2-3.2v2c0 .4 0 .6.2.9V8.7c-.2.2-.2.5-.2.8v2.1a3.6 3.6 0 0 1-2-3.2V5.6Zm5.2-1c0 .2-.1.5-.3.7V8.7c.2.2.3.5.3.8v2.1A3.6 3.6 0 0 0 20 8.4V5.6c0-1.4-.8-2.6-2-3.2v2.1Zm1.9 11c0-1.4-.8-2.6-2-3.2v2c0 .4 0 .6-.2.9V18.7c.2.3.3.5.3.8v2.1a3.6 3.6 0 0 0 1.9-3.2v-2.8Zm-5.1-1.1c0 .3 0 .5.2.8V18.7c-.2.2-.2.5-.2.8v2.1a3.6 3.6 0 0 1-2-3.2v-2.8c0-1.4.8-2.6 2-3.2v2ZM5 2.7l.2.8c-.2.2-.4.6-.4 1v1.4A3.3 3.3 0 0 1 3 3v-.5c0-.2.2-.4.4-.4h1.3c.3 0 .4.2.4.4v.3Zm3 1.8v1.4a3.5 3.5 0 0 0 2-3.2v-.3c0-.2-.2-.4-.4-.4H8.3c-.2 0-.3.2-.3.4v.5l-.2.6c.2.3.3.6.3 1Zm-5.1 6c0-1.3.8-2.5 2-3.1v2c0 .4 0 .7.2.9V13.7c-.2.2-.3.5-.3.8v2.1A3.6 3.6 0 0 1 3 13.4v-2.8Zm5.1-1c0 .3 0 .5-.2.8V13.7c.2.2.2.5.2.8v2.1a3.6 3.6 0 0 0 2-3.2v-2.8a3.6 3.6 0 0 0-2-3.2v2ZM3 21.3A3.6 3.6 0 0 1 5 18v1.4c0 .4 0 .7.3 1l-.2.6v.5c0 .2-.1.4-.4.4H3.4a.4.4 0 0 1-.4-.4v-.3Zm5.1-1.8c0 .4-.1.7-.3 1l.2.8v.3c0 .2.1.4.3.4h1.4c.2 0 .4-.2.4-.4V21a3.4 3.4 0 0 0-2-3v1.4Z'
      mask='url(#a)'
    />
  </SvgIcon>
);
