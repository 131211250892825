import { DefaultExpressionData, Expression, expressionToString } from 'common/types/Expression';
import { DefaultTargetDefiProject, TargetDefiProject, targetDefiProjectToString } from 'common/types/DefiProject';
import { InteractType, InteractTypeNameMap } from 'common/types/AddressInteraction';
import { LastDays, lastDaysToString } from 'common/types/LastDays';

import { LogicalOperator } from 'common/types/LogicalOperator';
import { cloneDeep } from 'lodash';

// base type for cohort filter -> define common fields
export interface CohortFilterBase {
  op: LogicalOperator;
  enabled: boolean;
}

/**
 * wallet assets filter type
 */
export interface CohortAssetFilter extends CohortFilterBase {
  operands: CohortAssetFilterOperand[];
}

export interface CohortAssetFilterOperand {
  chain: string;
  tokens: string[];
  all_tokens?: boolean;
  expression: Expression;
}

export const DefaultCohortAssetFilterData: CohortAssetFilter = {
  op: LogicalOperator.OR,
  enabled: true,
  operands: [],
};

export const DefaultCohortAssetFilterOperandData: CohortAssetFilterOperand = {
  chain: 'ethereum',
  tokens: [],
  expression: DefaultExpressionData,
};

export const assetFilterOperandToString = (operand: CohortAssetFilterOperand) => {
  const tokenStr =
    operand.tokens.length > 2
      ? [operand.tokens[0], operand.tokens[1], `+${operand.tokens.length}more`].join(', ')
      : operand.tokens.join(', ');
  return `${tokenStr} ${expressionToString(operand.expression)}`;
};

/**
 * defi engagement filter type
 */
export interface CohortDefiFilter extends CohortFilterBase {
  operands: CohortDefiFilterOperand[];
}

export interface CohortDefiFilterOperand {
  last_days: LastDays;
  project: TargetDefiProject;
  expression: Expression;
}

export const DefaultCohortDefiFilterData: CohortDefiFilter = {
  op: LogicalOperator.OR,
  enabled: false,
  operands: [],
};

export const DefaultCohortDefiFilterOperandData: CohortDefiFilterOperand = {
  last_days: LastDays.YESTERDAY,
  project: DefaultTargetDefiProject,
  expression: DefaultExpressionData,
};

export const defiFilterOperandToString = (operand: CohortDefiFilterOperand) => {
  return `${lastDaysToString(operand.last_days)} ${targetDefiProjectToString(operand.project)} ${expressionToString(
    operand.expression,
  )}`;
};

/**
 * contract interaction filter type
 */
export interface CohortContractFilter extends CohortFilterBase {
  operands: CohortContractFilterOperand[];
}

export interface CohortContractFilterOperand {
  last_days: LastDays;
  contract_addr: string;
  has_interaction: boolean;
}

export const DefaultCohortContractFilterData: CohortContractFilter = {
  op: LogicalOperator.OR,
  enabled: false,
  operands: [],
};

export const DefaultCohortContractFilterOperandData: CohortContractFilterOperand = {
  last_days: LastDays.YESTERDAY,
  contract_addr: '',
  has_interaction: true,
};

export const contractFilterOperandToString = (operand: CohortContractFilterOperand) => {
  return `${lastDaysToString(operand.last_days)} ${operand.contract_addr} ${InteractTypeNameMap.get(
    operand.has_interaction ? InteractType.TRUE : InteractType.FALSE,
  )}`;
};

export interface CohortFilter {
  op: LogicalOperator;
  operands: {
    asset_portfolio: CohortAssetFilter;
    defi_engagement: CohortDefiFilter;
    contract_interaction: CohortContractFilter;
  };
}

export const DefaultCohortFilterData: CohortFilter = {
  op: LogicalOperator.OR,
  operands: {
    asset_portfolio: cloneDeep(DefaultCohortAssetFilterData),
    defi_engagement: cloneDeep(DefaultCohortDefiFilterData),
    contract_interaction: cloneDeep(DefaultCohortContractFilterData),
  },
};
