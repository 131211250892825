import { FC, useEffect } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { AdsAudience } from 'pages/AdsAudience/AdsAudience';
import { AudienceDetail } from 'pages/AdsAudience/Audience/AudienceDetail';
import { CampaignSendMessage } from 'pages/InfluencerMatcher/Campaign/CampaignSendMessage';
import { ClientACL } from 'components/common/ClientACL';
import { CohortDetail } from 'pages/WalletSelector/Cohort/CohortDetail';
import { CollectionView } from 'pages/Public/CollectionView';
import { ConfigProvider } from 'config/ConfigProvider';
import { CreateAudience } from 'pages/AdsAudience/Audience/CreateAudience';
import { CreateCampaign } from 'pages/InfluencerMatcher/Campaign/CreateCampaign';
import { CreateCohort } from 'pages/WalletSelector/CohortV2/CreateCohort';
import { CreateUserCampaignLink } from './User/CampaignLink/CreateUserCampaignLink';
import { EditCampaign } from 'pages/InfluencerMatcher/Campaign/EditCampaign';
import { ExpiredPage } from 'pages/Auth/ExpiredPage';
import { FontDemo } from 'pages/FontDemo';
import { GAIntegration } from 'pages/User/Integration/GAIntegration';
import { HomePage } from 'pages/HomePage';
import { InfluencerMatcher } from 'pages/InfluencerMatcher/InfluencerMatcher';
import { LayoutProvider } from 'components/layout/LayoutProvider';
import { NotFoundPage } from 'pages/Common/NotFoundPage';
import { Outlet } from 'react-router';
import { Spinner } from 'components/common/Spinner';
import { SurveyPage } from 'pages/Auth/SurveyPage';
import { ThemeProvider } from 'theme/ThemeProvider';
import { Uris } from 'Uris';
import { UserCampaignLink } from 'pages/User/CampaignLink/UserCampaignLink';
import { UserDisabledPage } from 'pages/Auth/UserDisabledPage';
import { UserFileManagement } from 'pages/User/UserFileManagement';
import { UserInfo } from 'pages/User/UserInfo';
import { UserIntegration } from 'pages/User/UserIntegration';
import { UserPayment } from 'pages/User/UserPayment';
import { UserSubscription } from 'pages/User/Subscription/UserSubscription';
import { WalletDetail } from 'pages/WalletSelector/Wallet/WalletDetail';
import { WalletSelector } from 'pages/WalletSelector/WalletSelector';
import { redirectToExternalPage } from 'common/utils';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from 'common/hooks/useUserProfile';

const AuthorizedPage: FC = () => {
  /** wrap the Outlet inside ClientACL which will do authentication for user login or not */
  return (
    <ClientACL onUnauthorized={() => redirectToExternalPage(`${Uris.External.Login}?entry_point=console`)}>
      <Outlet />
    </ClientACL>
  );
};

const EnabledUserPage: FC = () => {
  /** wrap the Outlet inside ClientACL which will do authentication for enabled user only */
  return (
    <ClientACL enabledUserOnly redirectOnForbid redirectUrl={Uris.Pages.User.Expired}>
      {/* ConfigProvider should be inside authentication */}
      <ConfigProvider>
        <Outlet />
      </ConfigProvider>
    </ClientACL>
  );
};

// only not authorized user can access
const NotAuthorizedPage: FC = () => {
  /** User Profile */
  const { loading, value: user } = useUserProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    navigate(Uris.Pages.Root);
  }, [user, navigate]);

  if (loading) return <Spinner />;

  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    // shared layout
    <Route element={<LayoutProvider />}>
      <Route path={Uris.Pages.Root} element={<HomePage />}></Route>
      <Route element={<AuthorizedPage />}>
        {/* put pages that need to be authenticated inside here */}
        <Route element={<EnabledUserPage />}>
          {/* put pages that need to be enabled user inside here */}
          <Route path={Uris.Pages.WalletSelector.Index} element={<WalletSelector />}></Route>
          <Route path={Uris.Pages.WalletSelector.CreateCohort} element={<CreateCohort />}></Route>
          <Route path={Uris.Pages.WalletSelector.CohortDetail} element={<CohortDetail />} />
          <Route path={Uris.Pages.WalletSelector.WalletDetail} element={<WalletDetail />} />
          <Route path={Uris.Pages.AdsAudience.CreateAudience} element={<CreateAudience />}></Route>
          <Route path={Uris.Pages.AdsAudience.AudienceDetail} element={<AudienceDetail />}></Route>
          <Route path={Uris.Pages.AdsAudience.Index} element={<AdsAudience />}></Route>
          <Route path={Uris.Pages.InfluencerMatcher.Index} element={<InfluencerMatcher />}></Route>
          <Route path={Uris.Pages.User.Integration.Index} element={<UserIntegration />}></Route>
          <Route path={Uris.Pages.User.Integration.GA} element={<GAIntegration />}></Route>
          <Route path={Uris.Pages.User.FileManagement} element={<UserFileManagement />}></Route>
          <Route path={Uris.Pages.User.CampaignLink.Index} element={<UserCampaignLink />}></Route>
          <Route path={Uris.Pages.User.CampaignLink.Create} element={<CreateUserCampaignLink />}></Route>

          <Route path={Uris.Pages.User.Subscription.Index} element={<UserSubscription />}></Route>
          <Route path={Uris.Pages.User.Payment.Index} element={<UserPayment />}></Route>

          <Route path={Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign} element={<CreateCampaign />}></Route>
          <Route path={Uris.Pages.InfluencerMatcher.EditInfluencerCampaign} element={<EditCampaign />}></Route>
          <Route path={Uris.Pages.InfluencerMatcher.CampaignSendMessage} element={<CampaignSendMessage />}></Route>
        </Route>
        <Route path={Uris.Pages.User.Info} element={<UserInfo />}></Route>
        <Route path={Uris.Pages.User.Survey} element={<SurveyPage />}></Route>
        <Route path={Uris.Pages.User.Expired} element={<ExpiredPage />}></Route>
        <Route path={Uris.Pages.User.UserDisabled} element={<UserDisabledPage />}></Route>
      </Route>
      <Route element={<NotAuthorizedPage />}>{/* put pages that need to be not authenticated inside here */}</Route>
      {/* Put public pages here */}
      <Route path={Uris.Pages.Public.Collection} element={<CollectionView />}></Route>
      <Route path={Uris.Pages.FontDemo.Index} element={<FontDemo />}></Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>,
  ),
);

export const MainPage: FC = () => {
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
