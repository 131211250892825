import { FC, useCallback } from 'react';

import { CohortDefiFilterOperand } from 'common/types/CohortFilter';
import { DefiProjectSelect } from 'components/select/DefiProjectSelect';
import { Expression } from 'common/types/Expression';
import { ExpressionSelect } from 'components/select/ExpressionSelect';
import { LastDays } from 'common/types/LastDays';
import { LastDaysSelect } from 'components/select/LastDaysSelect';
import { Stack } from '@mui/material';
import { TargetDefiProject } from 'common/types/DefiProject';

export interface DefiEngagementOperandProps {
  data: CohortDefiFilterOperand;
  disabled?: boolean;
  onChange?: (data: CohortDefiFilterOperand) => void;
}

export const DefiEngagementOperand: FC<DefiEngagementOperandProps> = ({ data, disabled, onChange }) => {
  const onLastDaysChange = useCallback(
    (last_days: LastDays) => {
      onChange?.({ ...data, last_days });
    },
    [data, onChange],
  );
  const onDefiProjectChange = useCallback(
    (project: TargetDefiProject) => {
      onChange?.({ ...data, project });
    },
    [data, onChange],
  );
  const onExpressionChange = useCallback(
    (expression: Expression) => {
      onChange?.({ ...data, expression });
    },
    [data, onChange],
  );
  return (
    <Stack direction='column' spacing={1}>
      <LastDaysSelect data={data.last_days} disabled={disabled} onChange={onLastDaysChange} />
      <DefiProjectSelect data={data.project} disabled={disabled} onChange={onDefiProjectChange} />
      <ExpressionSelect data={data.expression} disabled={disabled} onChange={onExpressionChange} />
    </Stack>
  );
};
