import {
  AddOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  MoreVertOutlined,
  PushPin,
  PushPinOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Button, IconButton, MenuItem, MenuList, Popover, Stack, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { FC, HTMLAttributes, useState } from 'react';
import { InfluencerCampaign, InfluencerCampaignStatus } from 'common/types/Extension/InfluencerCampaign';

import classNames from 'classnames';
import classes from './InfluencerCampaignList.module.scss';
import { datetimeFormatter } from 'common/formatters';

interface InfluencerCampaignListProps extends HTMLAttributes<HTMLElement> {
  status: InfluencerCampaignStatus;
  campaigns: InfluencerCampaign[];
  onInfluencerCampaignEditClicked?: (campaign: InfluencerCampaign) => void;
  onInfluencerCampaignViewClicked?: (campaign: InfluencerCampaign) => void;
  onInfluencerCampaignPinClicked?: (campaign: InfluencerCampaign) => void;
  onInfluencerCampaignArchiveClicked?: (campaign: InfluencerCampaign) => void;
}

export const InfluencerCampaignList: FC<InfluencerCampaignListProps> = ({
  status,
  campaigns,
  onInfluencerCampaignEditClicked,
  onInfluencerCampaignViewClicked,
  onInfluencerCampaignPinClicked,
  onInfluencerCampaignArchiveClicked,
  ...rest
}) => {
  const [anchorElement, setAnchor] = useState<HTMLElement>();
  const [selectedInfluencerCampaign, setSelectedInfluencerCampaign] = useState<InfluencerCampaign | undefined>(
    undefined,
  );

  const columnDefs: CommonTableColumn<InfluencerCampaign>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            className={classNames(
              classes.username,
              (status === InfluencerCampaignStatus.IN_PROGRESS || status === InfluencerCampaignStatus.DONE) &&
                classes.clickable,
            )}
            onClick={() => {
              if (status === InfluencerCampaignStatus.IN_PROGRESS || status === InfluencerCampaignStatus.DONE) {
                data && onInfluencerCampaignViewClicked?.(data);
              }
            }}
          >
            {data?.pinned ? (
              <PushPin fontSize='small' className={classes.pinned} />
            ) : (
              <PushPinOutlined fontSize='small' />
            )}
            <Typography variant='body1'>{value}</Typography>
          </Stack>
        );
      },
    },
    {
      id: 'metrics.invited_count',
      label: 'Invited',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Typography variant='body1'>
            {typeof value === 'number' && data?.status !== InfluencerCampaignStatus.DRAFT ? value : '-'}
          </Typography>
        );
      },
    },
    {
      id: 'metrics.offered_count',
      label: 'Offered',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Typography variant='body1'>
            {typeof value === 'number' && data?.status !== InfluencerCampaignStatus.DRAFT ? value : '-'}
          </Typography>
        );
      },
    },
    {
      id: 'metrics.published_count',
      label: 'Published',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Typography variant='body1'>
            {typeof value === 'number' && data?.status !== InfluencerCampaignStatus.DRAFT ? value : '-'}
          </Typography>
        );
      },
    },
    {
      id: 'created_at',
      label: 'Create Time',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{datetimeFormatter.format((value || 0) * 1000)}</Typography>;
      },
    },
    {
      id: 'id',
      label: 'Action',
      render: (id, _, data) => {
        const btn = () => {
          switch (status) {
            case InfluencerCampaignStatus.DRAFT:
              return (
                <Button
                  variant='outlined'
                  startIcon={<EditOutlined />}
                  onClick={() => data && onInfluencerCampaignEditClicked?.(data)}
                >
                  Edit
                </Button>
              );
            case InfluencerCampaignStatus.IN_PROGRESS:
              return (
                <>
                  <Button
                    variant='outlined'
                    startIcon={<AddOutlined />}
                    onClick={() => data && onInfluencerCampaignEditClicked?.(data)}
                  >
                    Add influencers
                  </Button>
                  <Button
                    variant='contained'
                    startIcon={<VisibilityOutlined />}
                    onClick={() => data && onInfluencerCampaignViewClicked?.(data)}
                  >
                    View Details
                  </Button>
                </>
              );
            case InfluencerCampaignStatus.DONE:
              return (
                <Button
                  variant='contained'
                  startIcon={<VisibilityOutlined />}
                  onClick={() => data && onInfluencerCampaignViewClicked?.(data)}
                >
                  View Details
                </Button>
              );
          }
        };
        return (
          <Stack direction='row' alignItems='center' spacing={1} className={classes.actions}>
            {btn()}
            <IconButton
              onClick={(event) => {
                setAnchor(event.currentTarget);
                setSelectedInfluencerCampaign(data);
              }}
              className={classes.moreButton}
            >
              <MoreVertOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <CommonTable {...rest} data={campaigns} columns={columnDefs} classes={classes} />
      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchor(undefined);
          setSelectedInfluencerCampaign(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            disabled={selectedInfluencerCampaign === undefined}
            onClick={() => {
              selectedInfluencerCampaign &&
                onInfluencerCampaignPinClicked?.({
                  ...selectedInfluencerCampaign,
                  pinned: !selectedInfluencerCampaign.pinned,
                });
              setAnchor(undefined);
              setSelectedInfluencerCampaign(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              {selectedInfluencerCampaign?.pinned ? <PushPinOutlined /> : <PushPin className={classes.pinned} />}
              <Typography>{selectedInfluencerCampaign?.pinned ? 'Unpin' : 'Pin'} on Top</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={selectedInfluencerCampaign === undefined}
            onClick={() => {
              selectedInfluencerCampaign && onInfluencerCampaignArchiveClicked?.(selectedInfluencerCampaign);
              setAnchor(undefined);
              setSelectedInfluencerCampaign(undefined);
            }}
          >
            <Stack direction='row' spacing={1} color='error'>
              <DeleteOutlineOutlined color='error' />
              <Typography color='error'>Archive</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
