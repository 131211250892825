import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CustomizedCategory, Growing3Category } from 'common/types/Category';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { Cohort } from 'common/types/Cohort';
import { CommonChip } from 'components/chip/CommonChip';
import { JobStatus } from 'common/types/JobStatus';
import classNames from 'classnames';
import classes from './CategoriesSelectDialog.module.scss';
import { renderCategory } from 'components/Category/renderCategory';

interface CategoriesSelectDialogProps extends DialogProps {
  icon: ReactNode;
  title: string;
  subtitle?: string;
  cohort: Cohort;
  categoryIds: number[];
  cateogories: (CustomizedCategory | Growing3Category)[];
  onAdd?: () => void;
  onSave?: (categoryIds: number[]) => void;
  onCancel?: () => void;
}

export const CategoriesSelectDialog: FC<CategoriesSelectDialogProps> = ({
  icon,
  title,
  subtitle,
  cohort,
  categoryIds,
  cateogories,
  onAdd,
  onSave,
  onCancel,
  ...props
}) => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>(categoryIds);

  const onCategoryClicked = useCallback((id: number) => {
    setSelectedCategories((old) => {
      if (old.findIndex((v) => v === id) > -1) {
        return [...old.filter((c) => c !== id)];
      }
      return [...old, id];
    });
  }, []);

  const filterCategories = useMemo(() => {
    return cateogories
      .filter((category) => category.status === JobStatus.COMPLETED && category.disabled === false)
      .filter((category) => {
        if ('project_types' in category) {
          if (!category.project_types.length) return true;
          return category.project_types.findIndex((project_type) => project_type === cohort.project_type) > -1;
        }
        return true;
      });
  }, [cohort.project_type, cateogories]);

  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onCancel?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onCancel}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>{icon}</CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6'>{title}</Typography>
              {subtitle ? <Typography variant='body1'>{subtitle}</Typography> : null}
            </Stack>
          </Stack>
          <Stack direction='row' flexWrap='wrap' justifyContent='center'>
            {filterCategories.length ? (
              filterCategories.map((category) => (
                <Stack
                  className={classNames(
                    classes.category,
                    selectedCategories.findIndex((c) => c === category.id) > -1 && classes.selected,
                  )}
                  key={category.id}
                  onClick={() => onCategoryClicked(category.id)}
                >
                  {renderCategory(category)}
                </Stack>
              ))
            ) : (
              <Typography>No categories</Typography>
            )}
          </Stack>
          {onAdd ? (
            <Stack direction='row' justifyContent='center'>
              <Button startIcon={<AddOutlined />} onClick={onAdd}>
                Add New Category
              </Button>
            </Stack>
          ) : null}
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
          <Button variant='contained' onClick={() => onSave?.(selectedCategories)}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
