import { DataContext } from 'contexts/DataContext';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { useContext } from 'react';
import { useObservable } from 'react-use';

export const useAllFeatureLimits = () => {
  const { featureLimits$ } = useContext(DataContext);
  const { featureLimits$: extensionFeatureLimits$ } = useContext(ExtensionContext);
  const featureLimits = useObservable(featureLimits$);
  const extensionFeatureLimits = useObservable(extensionFeatureLimits$);

  return { value: (featureLimits || []).concat(extensionFeatureLimits || []) };
};
