import { CampaignTermRatioFilter, getValueFilterString } from 'common/types/Extension/InfluencerFilter';
import { Collapse, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

import { InfoOutlined } from '@mui/icons-material';
import { MetricsRange } from 'common/types/Extension/TwitterUserSearchResult';
import { MinMaxValueInput } from 'components/input/MinMaxValueInput';
import { PercentilesValueInput } from 'components/input/PercentilesValueInput';
import classes from './CampaignTermRatioFilter.module.scss';
import { numberToPercentage } from 'common/utils';

interface CampaignTermRatioFilterCollapseProps {
  data?: CampaignTermRatioFilter;
  metricsRange?: MetricsRange;
  onChange?: (data?: CampaignTermRatioFilter) => void;
}

export const CampaignTermRatioFilterCollapse: FC<CampaignTermRatioFilterCollapseProps> = ({
  data,
  metricsRange,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack className={classes.root}>
      <Stack
        direction='row'
        className={classes.title}
        justifyContent='space-between'
        onClick={() => setOpen((old) => !old)}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <Typography>Campaign Term Ratio</Typography>
            <Tooltip
              title='Campaign tweets (tweets related to campaign keywords like airdrop, giveaway or RTs) count / original tweet counts.'
              placement='top'
            >
              <InfoOutlined fontSize='small' />
            </Tooltip>
          </Stack>
          <Typography className={classes.value} variant='label1'>
            {getValueFilterString(data, numberToPercentage)}
          </Typography>
        </Stack>
        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Stack>
      <Collapse in={open} timeout='auto'>
        <Stack spacing={3}>
          <Divider className={classes.divider} />
          <Stack spacing={3} className={classes.input}>
            <MinMaxValueInput
              percentage
              roundLimit
              min={data?.min}
              max={data?.max}
              minLimit={metricsRange?.campaign_term_ratio_min}
              maxLimit={metricsRange?.campaign_term_ratio_max}
              onChange={(min, max) => {
                if (min === undefined && max === undefined) {
                  onChange?.(undefined);
                  return;
                }
                onChange?.({ ...data, min: min, max: max, top: undefined });
              }}
            />
            <Divider className={classes.divider} />
            <PercentilesValueInput
              percentiles={data?.percentiles}
              onChange={(percentiles) => {
                if (percentiles === undefined) {
                  onChange?.(undefined);
                  return;
                }
                onChange?.({
                  ...data,
                  min: undefined,
                  max: undefined,
                  top: undefined,
                  percentiles: percentiles,
                });
              }}
            />
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
