import { Button, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import classes from './UserPayment.module.scss';

export const UserPayment: FC = () => {
  return (
    <Stack className={classes.root} spacing={3}>
      <Typography variant='h5'>Payment</Typography>
      <Stack spacing={3} className={classes.container}>
        <Typography variant='h6'>Update card, downgrade or cancel plan</Typography>
        <Typography>Email us at info@growing3.ai</Typography>
        <Stack direction='row'>
          <Button variant='outlined' onClick={() => window.open('mailto: info@growing3.ai', '_blank')}>
            <Typography variant='label1'>Email Us</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
