const isoToLangMappings = new Map<string, string>([
  ['ab', 'Abkhazian'],
  ['aa', 'Afar'],
  ['af', 'Afrikaans'],
  ['ak', 'Akan'],
  ['sq', 'Albanian'],
  ['am', 'Amharic'],
  ['ar', 'Arabic'],
  ['an', 'Aragonese'],
  ['hy', 'Armenian'],
  ['as', 'Assamese'],
  ['av', 'Avaric'],
  ['ae', 'Avestan'],
  ['ay', 'Aymara'],
  ['az', 'Azerbaijani'],
  ['bm', 'Bambara'],
  ['ba', 'Bashkir'],
  ['eu', 'Basque'],
  ['be', 'Belarusian'],
  ['bn', 'Bengali'],
  ['bi', 'Bislama'],
  ['bs', 'Bosnian'],
  ['br', 'Breton'],
  ['bg', 'Bulgarian'],
  ['my', 'Burmese'],
  ['ca', 'Catalan'],
  ['ch', 'Chamorro'],
  ['ce', 'Chechen'],
  ['ny', 'Chichewa'],
  ['zh', 'Chinese'],
  ['cu', 'Church Slavonic'],
  ['cv', 'Chuvash'],
  ['kw', 'Cornish'],
  ['co', 'Corsican'],
  ['cr', 'Cree'],
  ['hr', 'Croatian'],
  ['cs', 'Czech'],
  ['da', 'Danish'],
  ['dv', 'Divehi'],
  ['nl', 'Dutch'],
  ['dz', 'Dzongkha'],
  ['en', 'English'],
  ['eo', 'Esperanto'],
  ['et', 'Estonian'],
  ['ee', 'Ewe'],
  ['fo', 'Faroese'],
  ['fj', 'Fijian'],
  ['fi', 'Finnish'],
  ['fr', 'French'],
  ['fy', 'Western Frisian'],
  ['ff', 'Fulah'],
  ['gd', 'Gaelic'],
  ['gl', 'Galician'],
  ['lg', 'Ganda'],
  ['ka', 'Georgian'],
  ['de', 'German'],
  ['el', 'Greek'],
  ['kl', 'Kalaallisut'],
  ['gn', 'Guarani'],
  ['gu', 'Gujarati'],
  ['ht', 'Haitian'],
  ['ha', 'Hausa'],
  ['he', 'Hebrew'],
  ['hz', 'Herero'],
  ['hi', 'Hindi'],
  ['ho', 'Hiri Motu'],
  ['hu', 'Hungarian'],
  ['is', 'Icelandic'],
  ['io', 'Ido'],
  ['ig', 'Igbo'],
  ['id', 'Indonesian'],
  ['ia', 'Interlingua'],
  ['ie', 'Interlingue'],
  ['iu', 'Inuktitut'],
  ['ik', 'Inupiaq'],
  ['ga', 'Irish'],
  ['it', 'Italian'],
  ['ja', 'Japanese'],
  ['jv', 'Javanese'],
  ['kn', 'Kannada'],
  ['kr', 'Kanuri'],
  ['ks', 'Kashmiri'],
  ['kk', 'Kazakh'],
  ['km', 'Central Khmer'],
  ['ki', 'Kikuyu'],
  ['rw', 'Kinyarwanda'],
  ['ky', 'Kirghiz'],
  ['kv', 'Komi'],
  ['kg', 'Kongo'],
  ['ko', 'Korean'],
  ['kj', 'Kuanyama'],
  ['ku', 'Kurdish'],
  ['lo', 'Lao'],
  ['la', 'Latin'],
  ['lv', 'Latvian'],
  ['li', 'Limburgan'],
  ['ln', 'Lingala'],
  ['lt', 'Lithuanian'],
  ['lu', 'Luba-Katanga'],
  ['lb', 'Luxembourgish'],
  ['mk', 'Macedonian'],
  ['mg', 'Malagasy'],
  ['ms', 'Malay'],
  ['ml', 'Malayalam'],
  ['mt', 'Maltese'],
  ['gv', 'Manx'],
  ['mi', 'Maori'],
  ['mr', 'Marathi'],
  ['mh', 'Marshallese'],
  ['mn', 'Mongolian'],
  ['na', 'Nauru'],
  ['nv', 'Navajo'],
  ['nd', 'North Ndebele'],
  ['nr', 'South Ndebele'],
  ['ng', 'Ndonga'],
  ['ne', 'Nepali'],
  ['no', 'Norwegian'],
  ['nb', 'Norwegian Bokmål'],
  ['nn', 'Norwegian Nynorsk'],
  ['ii', 'Sichuan Yi'],
  ['oc', 'Occitan'],
  ['oj', 'Ojibwa'],
  ['or', 'Oriya'],
  ['om', 'Oromo'],
  ['os', 'Ossetian'],
  ['pi', 'Pali'],
  ['ps', 'Pashto'],
  ['fa', 'Persian'],
  ['pl', 'Polish'],
  ['pt', 'Portuguese'],
  ['pa', 'Punjabi'],
  ['qu', 'Quechua'],
  ['ro', 'Romanian'],
  ['rm', 'Romansh'],
  ['rn', 'Rundi'],
  ['ru', 'Russian'],
  ['se', 'Northern Sami'],
  ['sm', 'Samoan'],
  ['sg', 'Sango'],
  ['sa', 'Sanskrit'],
  ['sc', 'Sardinian'],
  ['sr', 'Serbian'],
  ['sn', 'Shona'],
  ['sd', 'Sindhi'],
  ['si', 'Sinhala'],
  ['sk', 'Slovak'],
  ['sl', 'Slovenian'],
  ['so', 'Somali'],
  ['st', 'Southern Sotho'],
  ['es', 'Spanish'],
  ['su', 'Sundanese'],
  ['sw', 'Swahili'],
  ['ss', 'Swati'],
  ['sv', 'Swedish'],
  ['tl', 'Tagalog'],
  ['ty', 'Tahitian'],
  ['tg', 'Tajik'],
  ['ta', 'Tamil'],
  ['tt', 'Tatar'],
  ['te', 'Telugu'],
  ['th', 'Thai'],
  ['bo', 'Tibetan'],
  ['ti', 'Tigrinya'],
  ['to', 'Tonga'],
  ['ts', 'Tsonga'],
  ['tn', 'Tswana'],
  ['tr', 'Turkish'],
  ['tk', 'Turkmen'],
  ['tw', 'Twi'],
  ['ug', 'Uighur'],
  ['uk', 'Ukrainian'],
  ['ur', 'Urdu'],
  ['uz', 'Uzbek'],
  ['ve', 'Venda'],
  ['vi', 'Vietnamese'],
  ['vo', 'Volapük'],
  ['wa', 'Walloon'],
  ['cy', 'Welsh'],
  ['wo', 'Wolof'],
  ['xh', 'Xhosa'],
  ['yi', 'Yiddish'],
  ['yo', 'Yoruba'],
  ['za', 'Zhuang'],
  ['zu', 'Zulu'],
]);

const langToIsoMappings = new Map<string, string>([
  ['Abkhazian', 'ab'],
  ['Afar', 'aa'],
  ['Afrikaans', 'af'],
  ['Akan', 'ak'],
  ['Albanian', 'sq'],
  ['Amharic', 'am'],
  ['Arabic', 'ar'],
  ['Aragonese', 'an'],
  ['Armenian', 'hy'],
  ['Assamese', 'as'],
  ['Avaric', 'av'],
  ['Avestan', 'ae'],
  ['Aymara', 'ay'],
  ['Azerbaijani', 'az'],
  ['Bambara', 'bm'],
  ['Bashkir', 'ba'],
  ['Basque', 'eu'],
  ['Belarusian', 'be'],
  ['Bengali', 'bn'],
  ['Bislama', 'bi'],
  ['Bosnian', 'bs'],
  ['Breton', 'br'],
  ['Bulgarian', 'bg'],
  ['Burmese', 'my'],
  ['Catalan', 'ca'],
  ['Chamorro', 'ch'],
  ['Chechen', 'ce'],
  ['Chichewa', 'ny'],
  ['Chinese', 'zh'],
  ['Church Slavonic', 'cu'],
  ['Chuvash', 'cv'],
  ['Cornish', 'kw'],
  ['Corsican', 'co'],
  ['Cree', 'cr'],
  ['Croatian', 'hr'],
  ['Czech', 'cs'],
  ['Danish', 'da'],
  ['Divehi', 'dv'],
  ['Dutch', 'nl'],
  ['Dzongkha', 'dz'],
  ['English', 'en'],
  ['Esperanto', 'eo'],
  ['Estonian', 'et'],
  ['Ewe', 'ee'],
  ['Faroese', 'fo'],
  ['Fijian', 'fj'],
  ['Finnish', 'fi'],
  ['French', 'fr'],
  ['Western Frisian', 'fy'],
  ['Fulah', 'ff'],
  ['Gaelic', 'gd'],
  ['Galician', 'gl'],
  ['Ganda', 'lg'],
  ['Georgian', 'ka'],
  ['German', 'de'],
  ['Greek', 'el'],
  ['Kalaallisut', 'kl'],
  ['Guarani', 'gn'],
  ['Gujarati', 'gu'],
  ['Haitian', 'ht'],
  ['Hausa', 'ha'],
  ['Hebrew', 'he'],
  ['Herero', 'hz'],
  ['Hindi', 'hi'],
  ['Hiri Motu', 'ho'],
  ['Hungarian', 'hu'],
  ['Icelandic', 'is'],
  ['Ido', 'io'],
  ['Igbo', 'ig'],
  ['Indonesian', 'id'],
  ['Interlingua', 'ia'],
  ['Interlingue', 'ie'],
  ['Inuktitut', 'iu'],
  ['Inupiaq', 'ik'],
  ['Irish', 'ga'],
  ['Italian', 'it'],
  ['Japanese', 'ja'],
  ['Javanese', 'jv'],
  ['Kannada', 'kn'],
  ['Kanuri', 'kr'],
  ['Kashmiri', 'ks'],
  ['Kazakh', 'kk'],
  ['Central Khmer', 'km'],
  ['Kikuyu', 'ki'],
  ['Kinyarwanda', 'rw'],
  ['Kirghiz', 'ky'],
  ['Komi', 'kv'],
  ['Kongo', 'kg'],
  ['Korean', 'ko'],
  ['Kuanyama', 'kj'],
  ['Kurdish', 'ku'],
  ['Lao', 'lo'],
  ['Latin', 'la'],
  ['Latvian', 'lv'],
  ['Limburgan', 'li'],
  ['Lingala', 'ln'],
  ['Lithuanian', 'lt'],
  ['Luba-Katanga', 'lu'],
  ['Luxembourgish', 'lb'],
  ['Macedonian', 'mk'],
  ['Malagasy', 'mg'],
  ['Malay', 'ms'],
  ['Malayalam', 'ml'],
  ['Maltese', 'mt'],
  ['Manx', 'gv'],
  ['Maori', 'mi'],
  ['Marathi', 'mr'],
  ['Marshallese', 'mh'],
  ['Mongolian', 'mn'],
  ['Nauru', 'na'],
  ['Navajo', 'nv'],
  ['North Ndebele', 'nd'],
  ['South Ndebele', 'nr'],
  ['Ndonga', 'ng'],
  ['Nepali', 'ne'],
  ['Norwegian', 'no'],
  ['Norwegian Bokmål', 'nb'],
  ['Norwegian Nynorsk', 'nn'],
  ['Sichuan Yi', 'ii'],
  ['Occitan', 'oc'],
  ['Ojibwa', 'oj'],
  ['Oriya', 'or'],
  ['Oromo', 'om'],
  ['Ossetian', 'os'],
  ['Pali', 'pi'],
  ['Pashto', 'ps'],
  ['Persian', 'fa'],
  ['Polish', 'pl'],
  ['Portuguese', 'pt'],
  ['Punjabi', 'pa'],
  ['Quechua', 'qu'],
  ['Romanian', 'ro'],
  ['Romansh', 'rm'],
  ['Rundi', 'rn'],
  ['Russian', 'ru'],
  ['Northern Sami', 'se'],
  ['Samoan', 'sm'],
  ['Sango', 'sg'],
  ['Sanskrit', 'sa'],
  ['Sardinian', 'sc'],
  ['Serbian', 'sr'],
  ['Shona', 'sn'],
  ['Sindhi', 'sd'],
  ['Sinhala', 'si'],
  ['Slovak', 'sk'],
  ['Slovenian', 'sl'],
  ['Somali', 'so'],
  ['Southern Sotho', 'st'],
  ['Spanish', 'es'],
  ['Sundanese', 'su'],
  ['Swahili', 'sw'],
  ['Swati', 'ss'],
  ['Swedish', 'sv'],
  ['Tagalog', 'tl'],
  ['Tahitian', 'ty'],
  ['Tajik', 'tg'],
  ['Tamil', 'ta'],
  ['Tatar', 'tt'],
  ['Telugu', 'te'],
  ['Thai', 'th'],
  ['Tibetan', 'bo'],
  ['Tigrinya', 'ti'],
  ['Tonga', 'to'],
  ['Tsonga', 'ts'],
  ['Tswana', 'tn'],
  ['Turkish', 'tr'],
  ['Turkmen', 'tk'],
  ['Twi', 'tw'],
  ['Uighur', 'ug'],
  ['Ukrainian', 'uk'],
  ['Urdu', 'ur'],
  ['Uzbek', 'uz'],
  ['Venda', 've'],
  ['Vietnamese', 'vi'],
  ['Volapük', 'vo'],
  ['Walloon', 'wa'],
  ['Welsh', 'cy'],
  ['Wolof', 'wo'],
  ['Xhosa', 'xh'],
  ['Yiddish', 'yi'],
  ['Yoruba', 'yo'],
  ['Zhuang', 'za'],
  ['Zulu', 'zu'],
]);

export const getLangFromIso = (iso: string) => {
  return isoToLangMappings.get(iso) || iso;
};

export const getIsoFromLang = (lang: string) => {
  return langToIsoMappings.get(lang) || lang;
};
