import {
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, SearchOutlined } from '@mui/icons-material';

import classes from './CategoriesFilter.module.scss';

interface CategoriesFilterCollapseProps {
  categories?: string[];
  data?: string[];
  onChange?: (data?: string[]) => void;
}

export const CategoriesFilterCollapse: FC<CategoriesFilterCollapseProps> = ({ data, categories, onChange }) => {
  const [searchString, setSearchString] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const filteredCategories = useMemo(() => {
    if (!searchString) return categories || [];
    return (categories || []).filter((category) => {
      return category.toLowerCase().includes(searchString.toLowerCase());
    });
  }, [categories, searchString]);

  const handleCategoriesChange = useCallback(
    (value: string | string[]) => {
      if (typeof value === 'object' && value[value.length - 1] === 'all') {
        const selectAll = filteredCategories.every(
          (filteredCategory) => value.findIndex((v) => v === filteredCategory) > -1,
        );
        const categories: string[] = [];
        if (!selectAll) {
          filteredCategories.forEach((filteredCategory) => {
            categories.push(filteredCategory);
          });
        }
        onChange?.(categories);
        return;
      }

      // when clicking search fields
      // this function will be called with value = [undefined]
      // in this case, selectedIds should be []
      // so we handle it by checking isEmptyValue additionally
      const isEmptyValue = typeof value === 'object' && value.length === 1 && value[0] === undefined;
      const selectedIds = typeof value === 'string' ? value.split(',') : isEmptyValue ? [] : value;
      onChange?.(selectedIds);
    },
    [filteredCategories, onChange],
  );

  const isAllSelected = useMemo(() => {
    return !!data?.length && filteredCategories?.length === data.length;
  }, [data, filteredCategories]);

  const isPartialSelected = useMemo(() => {
    return !!data?.length && filteredCategories?.length !== data.length;
  }, [data, filteredCategories]);

  return (
    <Stack className={classes.root}>
      <Stack
        direction='row'
        className={classes.title}
        justifyContent='space-between'
        onClick={() => setOpen((old) => !old)}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Typography>Category</Typography>
          {data?.length ? (
            <Typography className={classes.value} variant='label1'>
              {data.length}
            </Typography>
          ) : null}
        </Stack>
        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Stack>
      <Collapse in={open} timeout='auto'>
        <Stack spacing={3}>
          <Divider className={classes.divider} />
          <Stack spacing={3} className={classes.input}>
            <FormControl>
              <InputLabel>Category selected</InputLabel>
              <Select
                multiple
                value={data || []}
                label='Category selected'
                renderValue={(categories) => {
                  const string =
                    categories.length < 3
                      ? categories.join(', ')
                      : categories.slice(0, 2).join(', ') + `... and ${categories.length - 2} more`;
                  return <Typography>{string}</Typography>;
                }}
                onChange={(e) => handleCategoriesChange(e.target.value)}
              >
                <Stack>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position='start'>
                        <SearchOutlined />
                      </InputAdornment>
                    }
                    placeholder='Search'
                    sx={{ width: '100%', '& fieldset': { borderColor: 'transparent' } }}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </Stack>
                <MenuItem value='all'>
                  <Stack direction='row' alignItems='center'>
                    <Checkbox checked={isAllSelected} indeterminate={isPartialSelected} />
                    <Typography variant='body1'>All Categories</Typography>
                  </Stack>
                </MenuItem>
                {filteredCategories.map((category) => (
                  <MenuItem key={category} value={category} className={classes.item}>
                    <Stack direction='row' alignItems='center'>
                      <Checkbox checked={(data || []).findIndex((d) => d === category) > -1} />
                      <Typography variant='body1'>{category}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
