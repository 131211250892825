import { Button, Stack, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { Logo } from 'components/layout/Logo';
import { SubscriptionPlanList } from 'components/Auth/SubscriptionPlanList';
import { Uris } from 'Uris';
import { UserAvatar } from 'components/User/UserAvatar';
import classNames from 'classnames';
import classes from './ExpiredPage.module.scss';
import { redirectToExternalPage } from 'common/utils';
import { useUserProfile } from 'common/hooks/useUserProfile';

export interface UsernameCheckResult {
  available: boolean;
  detail?: string;
}

export const ExpiredPage: FC = () => {
  const [monthly, setMonthly] = useState<boolean>(true);
  const { value: user } = useUserProfile();

  const avatarBtn = useMemo(() => {
    if (user) return <UserAvatar user={user} showLogoutOnly />;
    return (
      <Button variant='contained' onClick={() => redirectToExternalPage(`${Uris.External.Login}?entry_point=console`)}>
        Getting Started
      </Button>
    );
  }, [user]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack direction='row' className={classes.header} alignItems='center'>
        <Stack direction='row' className={classes.logo}>
          <Logo />
        </Stack>
        {avatarBtn}
      </Stack>
      <Stack className={classes.container} spacing={3}>
        <Stack className={classes.top} justifyContent='space-between'>
          <Stack spacing={1}>
            <Typography variant='h5'>Your trial has expired</Typography>
            <Typography variant='body2' className={classes.description}>
              We offer flexible plans and have a credit based system for targeting users. One credit is equal to one
              contact export, one automated DM or one email. You can purchase add-ons for additional integrations,
              seats, and credits to suit your needs.
            </Typography>
          </Stack>
          <Stack spacing={1} className={classes.info}>
            <Typography variant='body2' className={classes.tips}>
              Save up to 25% with an annual plan
            </Typography>
            <Stack direction='row' alignItems='center'>
              <Stack direction='row' justifyContent='center' className={classes.btn} onClick={() => setMonthly(true)}>
                <Typography variant='subtitle2' className={classNames(classes.btnText, monthly && classes.selected)}>
                  Monthly
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='center' className={classes.btn} onClick={() => setMonthly(false)}>
                <Typography variant='subtitle2' className={classNames(classes.btnText, !monthly && classes.selected)}>
                  Annual
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <SubscriptionPlanList monthly={monthly} />
      </Stack>
    </Stack>
  );
};
