import { SvgIcon, SvgIconProps } from '@mui/material';

export const Growing3Icon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 44 44'>
    <path d='m26.8 15.5-10.3-6-2.8 1.4 12 6.6a5 5 0 0 1 2.4 4.2l.3 12.8h2.3L30.4 22a5 5 0 0 1 2.6-4.5l3.2-1.7-1.1-2-3.5 1.8c-1.5.8-3.3.8-4.8 0Z' />
    <path d='m25.8 30 10.4-6.1-1.2-2-10.3 6c-1.5.9-3.4.9-4.9 0L8.6 22l-1.1 2 10.9 6a5 5 0 0 1 2.6 4.5l-.1 3.7h2.3l.1-4a5 5 0 0 1 2.5-4.2Z' />
    <path d='M13.3 22.1V34h2.3V22.1A5 5 0 0 1 18 18l12-6.6-2.4-2L17 15.7a5 5 0 0 1-5.2 0L9 14l-1.2 2 3.3 2a5 5 0 0 1 2.3 4.2Z' />
    <path d='M6.8 30.8V13.2L22 4.4l15.2 8.8v17.6L22 39.6 6.8 30.8ZM9 17V27c0 1.6.8 3 2.2 3.8l8.5 4.9c1.3.8 3 .8 4.4 0l8.5-4.9a4.5 4.5 0 0 0 2.2-3.8V17c0-1.6-.9-3-2.2-3.8l-8.5-4.9c-1.3-.8-3-.8-4.4 0l-8.5 4.9A4.5 4.5 0 0 0 9.1 17Z' />
  </SvgIcon>
);
