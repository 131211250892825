import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Chip,
  IconButton,
  Link,
  OutlinedInput,
  Paper,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  AccountCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExpandMoreOutlined,
  ManageAccountsOutlined,
  UploadOutlined,
} from '@mui/icons-material/';
import { Audience, AudienceTarget } from 'common/types/Audience';
import { DefaultPaginationOptions, PaginationOptions } from 'components/table/CommonTable';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TwitterAdGroupPublishRecord, TwitterAudiencePublishRecord } from 'common/types/TwitterPublishRecord';
import { body1, h1 } from 'theme/typography';
import { colorNeutral40, colorNeutral70, colorPrimary30, colorPrimary90 } from 'common/params';
import { downloadAsCsv, setCookie } from 'common/utils';
import { useNavigate, useParams } from 'react-router-dom';

import ClientAPI from 'common/ClientAPI';
import { CustomerList } from 'pages/AdsAudience/TwitterAccount/CustomerList';
import { InfluencerList } from 'pages/AdsAudience/TwitterAccount/InfluencerList';
import { InfluencerMatcherIcon } from 'components/icons/InfluencerMatcherIcon';
import { IntegrationAuthProvider } from 'common/types/IntegrationAuth';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NotFoundPage } from 'pages/Common/NotFoundPage';
import { Spinner } from 'components/common/Spinner';
import { TwitterAccount } from 'common/types/TwitterAccount';
import { TwitterAdGroupPublishList } from 'components/TwitterAds/history/TwitterAdGroupPublishList';
import { TwitterAdsPublishDialog } from 'components/TwitterAds/publish/TwitterAdsPublishDialog';
import { TwitterAudiencePublishList } from 'components/TwitterAds/history/TwitterAudiencePublishList';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './AudienceDetail.module.scss';
import { darkTheme } from 'theme/ThemeProvider';
import moment from 'moment';
import { numberFormatter } from 'common/formatters';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

enum BottomTab {
  UserList,
  PublishHistory,
}

export const AudienceDetail: FC<any> = () => {
  const navigate = useNavigate();
  const { audienceId } = useParams();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const [edit, setEdit] = useState<boolean>(false);
  const [audience, setAudience] = useState<Audience | undefined>(undefined);
  const [twitterAccounts, setTwitterAccounts] = useState<TwitterAccount[]>([]);
  const [cohortsExpanded, setCohortsExpanded] = useState<boolean>(false);
  const [refetchAudience, setRefetchAudience] = useState<boolean>(false);
  const [refetchPublishRecords, setRefetchPublishRecords] = useState<boolean>(false);
  const [totalTwitterAccountCnt, setTotalTwitterAccountCnt] = useState<number>(0);
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>(DefaultPaginationOptions);
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const [tab, setTab] = useState<BottomTab>(BottomTab.UserList);

  const [publishedAudience, setPublishedAudience] = useState<Audience | undefined>(undefined);
  const [twitterAdsAccountId, setTwitterAdsAccountId] = useState<string | undefined>(undefined);

  const {
    loading: audienceLoading,
    error: audienceError,
    value: audienceValue,
  } = useAsync(async () => {
    const id = Number.parseInt(audienceId || '');
    if (Number.isNaN(id)) return undefined;
    return (await ClientAPI.getAudience(id)).data;
  }, [refetchAudience]);

  const {
    loading: twitterAccountsLoading,
    error: twitterAccountsError,
    value: twitterAccountsValue,
  } = useAsync(async () => {
    const id = Number.parseInt(audienceId || '');
    if (Number.isNaN(id)) return undefined;
    return await ClientAPI.getTwitterAccounts(id, paginationOptions);
  }, [paginationOptions, refetchAudience]);

  const {
    loading: publishRecordsLoading,
    error: publishRecordsError,
    value: publishRecords,
  } = useAsync(async () => {
    if (!audience || typeof audience?.id !== 'number') return [];
    if (audience.target === AudienceTarget.CUSTOMER_LIST) {
      return (await ClientAPI.getTwitterAudiencePublishRecords(audience.id)).data;
    }
    return (await ClientAPI.getTwitterAdGroupPublishRecords(audience.id)).data;
  }, [audience, refetchPublishRecords]);

  const updateAudience = useCallback(
    async (audience: Audience) => {
      await ClientAPI.updateAudience(audience)
        .then(({ status, message }) => {
          if (status === 'success') {
            setEdit(false);
            setRefetchAudience((old) => !old);
            return;
          }
          showMessage(`Update Audience failed, ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    },
    [showMessage],
  );

  useEffect(() => {
    if (!audienceValue || typeof audienceValue.id !== 'number') return;
    setAudience(audienceValue);
  }, [audienceValue]);

  useEffect(() => {
    audienceError && showMessage(audienceError?.message || 'Unknown Error', 'error');
  }, [audienceError, showMessage]);

  useEffect(() => {
    if (!twitterAccountsValue) return;
    setTotalTwitterAccountCnt(twitterAccountsValue.total_rows || 0);
    setTwitterAccounts(twitterAccountsValue.data || []);
  }, [twitterAccountsValue]);

  useEffect(() => {
    twitterAccountsError && showMessage(twitterAccountsError?.message || 'Unknown Error', 'error');
  }, [twitterAccountsError, showMessage]);

  useEffect(() => {
    publishRecordsError && showMessage(publishRecordsError?.message || 'Unknown Error', 'error');
  }, [publishRecordsError, showMessage]);

  const onTwitterAccountTablePageChange = useCallback((paginationOptions: PaginationOptions) => {
    setPaginationOptions(paginationOptions);
  }, []);

  const onDownloadClicked = useCallback(async () => {
    if (!audience?.id) return;
    setDownloadDisabled(true);
    await ClientAPI.getTwitterAccounts(audience.id)
      .then(({ status, data }) => {
        if (status === 'success' && data?.length) {
          downloadAsCsv(data, `${audience.name}_${moment().format('YYYYMMDD')}.csv`);
          return;
        }
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Download Error', 'error');
      })
      .finally(() => {
        setDownloadDisabled(false);
      });
  }, [audience, showMessage]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }, []);

  const onAudiencePublish = useCallback(async () => {
    if (!audience) return;
    track('form_start', {
      sub_event: 'audience_publish_started',
      custom_props: { audience_id: audience.id, audience_name: audience.name, audience_approch: audience.target },
    });
    const twitterAuth = await ClientAPI.getDefaultTwitterAuth()
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      });

    if (twitterAuth) {
      // has default auth -> try to get default twitter ads account id
      const account = await ClientAPI.getDefaultTwitterAdsAccount()
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
      if (account) {
        setTwitterAdsAccountId(account.id);
      }
      setPublishedAudience(audience);
    } else {
      // no default auth -> redirect user to twitter authorization page
      await ClientAPI.integrationOauth1(IntegrationAuthProvider.TWITTER_ADS)
        .then(({ data: oauth_url }) => {
          if (!oauth_url) return;
          setCookie('redirect_url', window.location.href);
          window.location.href = oauth_url;
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    }
  }, [audience, showMessage, track]);

  const onTwitterAdsPublishDialogClose = useCallback(() => {
    setPublishedAudience(undefined);
    setRefetchPublishRecords((old) => !old);
  }, []);

  const tabs = useMemo(() => {
    if (audience?.target === AudienceTarget.INFLUENCER_BATCH) {
      return ['Twitter Influencer List', 'Publish History'];
    }

    return ['Twitter User List', 'Publish History'];
  }, [audience]);

  const bottomContent = useMemo(() => {
    if (!audience) return null;
    switch (tab) {
      case 0: // UserList
        if (audience.target === AudienceTarget.CUSTOMER_LIST) {
          return (
            <CustomerList
              className={classNames(classes.table, classes.small)}
              twitterAccounts={twitterAccounts}
              totalTwitterAccountCnt={totalTwitterAccountCnt}
              loading={twitterAccountsLoading}
              downloadDisabled={downloadDisabled}
              onPageChange={onTwitterAccountTablePageChange}
              onDownloadClicked={onDownloadClicked}
              onAudiencePublish={onAudiencePublish}
            />
          );
        }
        return (
          <InfluencerList
            className={classNames(classes.table, classes.large)}
            twitterAccounts={twitterAccounts}
            totalTwitterAccountCnt={totalTwitterAccountCnt}
            loading={twitterAccountsLoading}
            downloadDisabled={downloadDisabled}
            onPageChange={onTwitterAccountTablePageChange}
            onDownloadClicked={onDownloadClicked}
            onAudiencePublish={onAudiencePublish}
          />
        );
      case 1: // PublishHistory
        if (audience.target === AudienceTarget.CUSTOMER_LIST) {
          if (!publishRecords?.length)
            return (
              <Stack spacing={4} alignItems='center' className={classes.empty}>
                <Stack spacing={3}>
                  <Typography variant='h3'>No Customer List Published to Twitter</Typography>
                  <Typography variant='h6'>Publish the customer list to your Twitter Audience Manager.</Typography>
                </Stack>
                <Button
                  variant='contained'
                  startIcon={<UploadOutlined />}
                  disabled={!twitterAccounts.length}
                  onClick={onAudiencePublish}
                >
                  Publish
                </Button>
              </Stack>
            );
          return (
            <TwitterAudiencePublishList
              loading={publishRecordsLoading}
              records={(publishRecords || []) as TwitterAudiencePublishRecord[]}
              publishBtnDisabled={!twitterAccounts.length}
              onAudiencePublish={onAudiencePublish}
            />
          );
        }
        if (!publishRecords?.length)
          return (
            <Stack spacing={4} alignItems='center' className={classes.empty}>
              <Stack spacing={3}>
                <Typography variant='h3'>No Twitter Ad Group Assigned Yet</Typography>
                <Typography variant='h6'>Publish the influencer batch list to your Twitter ad group.</Typography>
              </Stack>
              <Button
                variant='contained'
                startIcon={<UploadOutlined />}
                disabled={!twitterAccounts.length}
                onClick={onAudiencePublish}
              >
                Publish
              </Button>
            </Stack>
          );
        return (
          <TwitterAdGroupPublishList
            loading={publishRecordsLoading}
            records={(publishRecords || []) as TwitterAdGroupPublishRecord[]}
            publishBtnDisabled={!twitterAccounts.length}
            onAudiencePublish={onAudiencePublish}
          />
        );
      default:
        return <></>;
    }
  }, [
    tab,
    audience,
    downloadDisabled,
    onDownloadClicked,
    onTwitterAccountTablePageChange,
    onAudiencePublish,
    totalTwitterAccountCnt,
    twitterAccounts,
    twitterAccountsLoading,
    publishRecords,
    publishRecordsLoading,
  ]);

  if (audienceLoading) return <Spinner />;
  if (!audience) return <NotFoundPage />;

  return (
    <Stack className={classes.root}>
      {/* top black area */}
      <ThemeProvider theme={darkTheme}>
        <Paper className={classes.blackArea}>
          {/* breadcrumbs */}
          <Breadcrumbs
            separator={<NavigateNextIcon style={{ color: colorNeutral40 }} />}
            className={classes.breadcrumbs}
            aria-label='breadcrumb'
          >
            <Link
              variant='subtitle2'
              underline='none'
              color={colorNeutral40}
              className={classes.link}
              onClick={() => navigate(Uris.Pages.AdsAudience.Index)}
            >
              Ads Audience
            </Link>
            <Typography color={colorPrimary30} variant='subtitle2'>
              {audience.name}
            </Typography>
          </Breadcrumbs>
          {/* main content */}
          <Stack className={classes.container}>
            <Stack spacing={3} className={classes.block}>
              {/* target audience */}
              <Stack direction='row'>
                {audience.target === AudienceTarget.CUSTOMER_LIST ? (
                  <Chip
                    icon={<AccountCircleOutlined className={classes.svg} />}
                    label={<Typography variant='subtitle2'>Customer List</Typography>}
                    className={classes.chip}
                  />
                ) : (
                  <Chip
                    icon={<InfluencerMatcherIcon className={classNames(classes.svg, classes.influencer)} />}
                    label={<Typography variant='subtitle2'>Influencer Batch</Typography>}
                    className={classes.chip}
                  />
                )}
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography variant='h5' color={colorPrimary90}>
                  {numberFormatter.format(totalTwitterAccountCnt)}
                </Typography>
                <Typography variant='h5' color={colorNeutral70}>
                  Twitter {audience.target === AudienceTarget.CUSTOMER_LIST ? 'Accounts' : 'Influencers'} Matched
                </Typography>
              </Stack>
              <Stack direction='row' spacing={4} className={classes.content} justifyContent='space-between'>
                {/* info content */}
                <Stack direction='column' spacing={1} className={classes.info}>
                  {edit ? (
                    <>
                      <OutlinedInput
                        multiline
                        className={classes.name}
                        sx={{
                          ...h1,
                        }}
                        value={audience.name}
                        onChange={(e) => setAudience((old) => (old ? { ...old, name: e.target.value } : old))}
                      />
                      <OutlinedInput
                        multiline
                        className={classes.description}
                        sx={{
                          ...body1,
                        }}
                        value={audience.description}
                        onChange={(e) => setAudience((old) => (old ? { ...old, description: e.target.value } : old))}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant='h1' className={classes.name}>
                        {audience.name}
                      </Typography>
                      <Typography variant='body1'>{audience.description}</Typography>
                    </>
                  )}
                </Stack>
                {/* edit button */}
                <Stack direction='column' spacing={2}>
                  {edit ? (
                    <>
                      <IconButton className={classes.actionBtn} onClick={() => updateAudience(audience)}>
                        <CheckOutlined className={classes.icon} />
                      </IconButton>
                      <IconButton
                        className={classes.actionBtn}
                        onClick={() => {
                          setAudience(audienceValue);
                          setEdit(false);
                        }}
                      >
                        <CloseOutlined className={classes.icon} />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton className={classes.actionBtn} onClick={() => setEdit(true)}>
                      <EditOutlined className={classes.icon} />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Accordion className={classes.createdFrom} expanded={cohortsExpanded}>
                <AccordionSummary
                  expandIcon={
                    <IconButton className={classes.expandBtn} onClick={() => setCohortsExpanded((old) => !old)}>
                      <ExpandMoreOutlined className={classes.icon} />
                    </IconButton>
                  }
                  className={classes.summary}
                >
                  <Stack direction='row' spacing={2}>
                    <ManageAccountsOutlined />
                    <Typography variant='body1'>Wallets selected from</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Stack spacing={2} direction='row' className={classes.cohorts}>
                    {audience.cohorts.map((cohort) => (
                      <Link
                        key={cohort.id}
                        variant='body1'
                        target='_blank'
                        className={classes.link}
                        href={Uris.Pages.WalletSelector.CohortDetail.replace(':cohortId', cohort.id.toString() || '')}
                      >
                        {cohort.name}
                      </Link>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Stack>
        </Paper>
      </ThemeProvider>
      {/* bottom detail area */}
      <Stack direction='column' spacing={4} alignItems='center' className={classes.accounts}>
        <Tabs value={tab} onChange={handleTabChange} centered className={classes.tabs}>
          {tabs.map((t, idx) => (
            <Tab
              id={`tab-ads-audience-${t.toLowerCase().replaceAll(' ', '-')}`}
              key={t}
              className={classNames(classes.tab, tab !== idx && classes.disabled)}
              label={
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography className={classNames(classes.step, tab !== idx && classes.disabled)} variant='subtitle2'>
                    {idx + 1}
                  </Typography>
                  <Typography variant='subtitle2'>{t}</Typography>
                </Stack>
              }
            />
          ))}
        </Tabs>
        {bottomContent}
      </Stack>

      {publishedAudience && (
        <TwitterAdsPublishDialog
          open
          audience={publishedAudience}
          accountId={twitterAdsAccountId}
          onDialogClose={onTwitterAdsPublishDialogClose}
        />
      )}
    </Stack>
  );
};
