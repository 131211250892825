import {
  Button,
  Drawer,
  DrawerProps,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Cohort, isCohortValid } from 'common/types/Cohort';
import { CohortAssetFilter, CohortContractFilter, CohortDefiFilter, CohortFilter } from 'common/types/CohortFilter';
import { CustomizedCategory, Growing3Category } from 'common/types/Category';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { LogicalOperator, LogicalOperatorNameMap } from 'common/types/LogicalOperator';

import { AssetPortfolioFilter } from 'components/filter/AssetPortfolioFilter';
import { CloseOutlined } from '@mui/icons-material';
import { ContractInteractionFilter } from 'components/filter/ContractInteractionFilter';
import { DefiEngagementFilter } from 'components/filter/DefiEngagementFilter';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './EditFilter.module.scss';
import { renderCategory } from 'components/Category/renderCategory';

interface EditFilterProps extends DrawerProps {
  cohort: Cohort;
  growing3Categories: Growing3Category[];
  customizedCategories: CustomizedCategory[];
  onNext?: (filter: CohortFilter) => void;
  onClose?: () => void;
}

export const EditFilter: FC<EditFilterProps> = ({
  open,
  cohort,
  growing3Categories,
  customizedCategories,
  onNext,
  onClose,
}) => {
  const [editCohort, setEditCohort] = useState<Cohort>(cohort);

  useEffect(() => {
    if (!open) return;
    setEditCohort(cohort);
  }, [open, cohort]);

  const editCohortValid = useMemo(() => {
    // check cohort is valid or not
    return isCohortValid(editCohort);
  }, [editCohort]);

  const onFilterOperatorChange = useCallback((data: LogicalOperator) => {
    setEditCohort((old) => {
      const newCohort = { ...old };
      newCohort.filter.op = data;
      return newCohort;
    });
  }, []);

  const onWalletFilterEdit = useCallback((filter: CohortAssetFilter) => {
    setEditCohort((old) => {
      const newCohort = { ...old };
      newCohort.filter.operands.asset_portfolio = filter;
      return newCohort;
    });
  }, []);

  const onDefiFilterEdit = useCallback((filter: CohortDefiFilter) => {
    setEditCohort((old) => {
      const newCohort = { ...old };
      newCohort.filter.operands.defi_engagement = filter;
      return newCohort;
    });
  }, []);

  const onContractFilterEdit = useCallback((filter: CohortContractFilter) => {
    setEditCohort((old) => {
      const newCohort = { ...old };
      newCohort.filter.operands.contract_interaction = filter;
      return newCohort;
    });
  }, []);

  return (
    <Drawer
      open={open}
      anchor='right'
      PaperProps={{ sx: { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' } }}
    >
      <Stack spacing={3} className={classes.root}>
        <Stack direction='row-reverse' className={classes.btnClose}>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Stack>
        <img alt='category' src={Uris.Public.Image.WalletSelector.CreateCohort.Filter} className={classes.img} />
        <Stack alignItems='center'>
          <Typography variant='h5'>Applying filter conditions to the selected categories.</Typography>
        </Stack>
        <Stack direction='row' flexWrap='wrap' justifyContent='center'>
          {cohort.growing3_categories?.length || cohort.customized_categories?.length ? (
            <>
              {(cohort.growing3_categories || []).map((categoryId) => {
                const category = growing3Categories.find((category) => category.id === categoryId);
                if (!category) return null;
                return (
                  <Stack
                    className={classNames(classes.category, category.disabled && classes.disabled)}
                    key={category.id}
                  >
                    {renderCategory(category)}
                  </Stack>
                );
              })}
              {(cohort.customized_categories || []).map((categoryId) => {
                const category = customizedCategories.find((category) => category.id === categoryId);
                if (!category) return null;
                return (
                  <Stack
                    className={classNames(classes.category, category.disabled && classes.disabled)}
                    key={category.id}
                  >
                    {renderCategory(category)}
                  </Stack>
                );
              })}
            </>
          ) : (
            <Stack className={classes.category}>
              <Typography variant='label1'>All Wallet Addresses</Typography>
            </Stack>
          )}
        </Stack>
        <Stack spacing={2} direction='row' justifyContent='center'>
          <Button variant='outlined' onClick={onClose}>
            Back
          </Button>
          <Button
            id='btn-create-cohort-filter-next'
            variant='contained'
            disabled={!editCohortValid}
            onClick={() => onNext?.(editCohort.filter)}
          >
            Next
          </Button>
        </Stack>
        <Stack className={classes.pool} spacing={2}>
          <Typography variant='h5'>Advanced Filter Conditions</Typography>
          <FormControl>
            <Stack spacing={1}>
              <FormLabel>
                <Typography variant='body1'>Relations between the filters</Typography>
              </FormLabel>
              <Select
                className={classes.select}
                value={cohort.filter.op}
                onChange={(e) => e.target.value && onFilterOperatorChange(e.target.value as LogicalOperator)}
              >
                <MenuItem value={LogicalOperator.OR}>{LogicalOperatorNameMap.get(LogicalOperator.OR)}</MenuItem>
                <MenuItem value={LogicalOperator.AND}>{LogicalOperatorNameMap.get(LogicalOperator.AND)}</MenuItem>
              </Select>
            </Stack>
          </FormControl>

          <AssetPortfolioFilter data={editCohort.filter.operands.asset_portfolio} onChange={onWalletFilterEdit} />
          <DefiEngagementFilter data={editCohort.filter.operands.defi_engagement} onChange={onDefiFilterEdit} />
          <ContractInteractionFilter
            data={editCohort.filter.operands.contract_interaction}
            onChange={onContractFilterEdit}
          />
        </Stack>
      </Stack>
    </Drawer>
  );
};
