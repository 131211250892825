import { SvgIcon, SvgIconProps } from '@mui/material';

export const BooleanIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M7.9 4.9c0-1 .8-1.9 1.9-1.9H19c1 0 1.9.8 1.9 1.9v9.3c0 1-.8 2-1.9 2h-3v3c0 1-.8 1.8-1.9 1.8H5c-1 0-1.9-.8-1.9-1.9V9.8c0-1 .8-2 1.9-2h3v-3ZM14 16H9.8c-1 0-2-.8-2-1.9V10H5V19h9.1v-2.9ZM10 8V5H19v9.1h-2.9V9.8c0-1-.8-2-1.9-2H10Zm0 6.2V10H14V14H10Z'
      clipRule='evenodd'
    />
  </SvgIcon>
);
