import { NodeProps, svgLabelTransform, svgNodeTransform } from "@nivo/treemap";
import { animated, to } from "@react-spring/web";

import { get } from "lodash";
import { useState } from "react";

/**
 * This file is copied from source below
 * https://github.com/plouc/nivo/blob/master/packages/treemap/src/TreeMapNode.tsx
 * There are few things below that we customized in this file
 * 1. Display two line information in each treemap block
 * 2. Do not show text if space is not enough for two line display
 * 3. Cropped label to show ... if oversize
 */

const lengthPerChar = 10;

export const TreeMapNode = <Datum extends object>({
  node,
  animatedProps,
  borderWidth,
  enableLabel,
  enableParentLabel,
  labelSkipSize,
}: NodeProps<Datum>) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const showLabel =
    enableLabel &&
    node.isLeaf &&
    (labelSkipSize === 0 ||
      (Math.min(node.width, node.height) > labelSkipSize &&
        Math.max(node.width, node.height) > labelSkipSize + 20));
  const showParentLabel = enableParentLabel && node.isParent;

  // this way of calculation is not precise
  // may have problem when dealing with chinese character
  const croppedLabel =
    node.label.toString().length * lengthPerChar >
    Math.max(node.width, node.height)
      ? node.label
          .toString()
          .substring(
            0,
            Math.round(Math.max(node.width, node.height) / lengthPerChar) - 4
          ) + "..."
      : node.label;

  return (
    <animated.g transform={svgNodeTransform(animatedProps.x, animatedProps.y)}>
      <animated.rect
        data-testid={`node.${node.id}`}
        width={to(animatedProps.width, (v) => Math.max(v, 0))}
        height={to(animatedProps.height, (v) => Math.max(v, 0))}
        fill={node.fill ? node.fill : animatedProps.color}
        strokeWidth={hovered ? borderWidth + 2 : borderWidth}
        stroke={node.borderColor}
        fillOpacity={node.opacity}
        onMouseEnter={(evt) => {
          setHovered(true);
          node.onMouseEnter?.(evt);
        }}
        onMouseMove={node.onMouseMove}
        onMouseLeave={(evt) => {
          setHovered(false);
          node.onMouseLeave?.(evt);
        }}
        onClick={node.onClick}
      />
      {showLabel && (
        <animated.text
          data-testid={`label.${node.id}`}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fill: node.labelTextColor,
            pointerEvents: "none",
          }}
          fillOpacity={animatedProps.labelOpacity}
          transform={svgLabelTransform(
            animatedProps.labelX,
            animatedProps.labelY,
            animatedProps.labelRotation
          )}
        >
          <tspan x={0} y={-10} fontSize={16}>
            {croppedLabel}
          </tspan>
          <tspan x={0} dy={20} fontSize={12}>
            &#9733; Score{" "}
            {Math.round((get(node.data, "score", 0) as number) * 100) / 100}
          </tspan>
        </animated.text>
      )}
      {showParentLabel && (
        <animated.text
          data-testid={`parentLabel.${node.id}`}
          dominantBaseline="central"
          style={{
            fill: node.parentLabelTextColor,
            pointerEvents: "none",
          }}
          fillOpacity={animatedProps.parentLabelOpacity}
          transform={svgLabelTransform(
            animatedProps.parentLabelX,
            animatedProps.parentLabelY,
            animatedProps.parentLabelRotation
          )}
        >
          {node.parentLabel}
        </animated.text>
      )}
    </animated.g>
  );
};
