import { Button, Stack, Typography } from '@mui/material';

import { CheckOutlined } from '@mui/icons-material';
import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import { IntegrationAuth } from 'common/types/IntegrationAuth';
import classes from './SetSuccess.module.scss';

interface SetSuccessProps {
  auth: IntegrationAuth;
  onBtnCloseClicked?: () => void;
  onBtnGettingStartedClicked?: () => void;
}

export const SetSuccess: FC<SetSuccessProps> = ({ auth, onBtnCloseClicked, onBtnGettingStartedClicked }) => {
  return (
    <Stack className={classes.root} spacing={1} alignItems='center'>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <CheckOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            <Typography variant='h6'>{auth.name} has been set as your default google drive account</Typography>
            <Typography variant='body1'>You can now export your list to your Google Drive under {auth.name}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
        <Button variant='outlined' onClick={onBtnCloseClicked}>
          Close
        </Button>
        <Button variant='contained' onClick={onBtnGettingStartedClicked}>
          Getting Started
        </Button>
      </Stack>
    </Stack>
  );
};
