import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  CohortContractFilter,
  CohortContractFilterOperand,
  DefaultCohortContractFilterOperandData,
} from 'common/types/CohortFilter';
import { FC, useCallback, useRef } from 'react';
import { LogicalOperator, LogicalOperatorNameMap } from 'common/types/LogicalOperator';

import { ContractInteractionOperand } from 'components/filter/operand/ContractInteractionOperand';
import { IOSSwitch } from 'components/switch/IOSSwitch';
import { LogicalOperatorSelect } from 'components/select/LogicalOperatorSelect';
import classNames from 'classnames';
import classes from './ContractInteractionFilter.module.scss';
import { cloneDeep } from 'lodash';
import { replaceArrayIdxWithValue } from 'common/utils';

interface ContractInteractionFilterProps {
  direction?: 'horizontal' | 'vertical';
  data: CohortContractFilter;
  onChange?: (data: CohortContractFilter) => void;
}

export const ContractInteractionFilter: FC<ContractInteractionFilterProps> = ({
  direction = 'horizontal',
  data,
  onChange,
}) => {
  const addBtnRef = useRef<HTMLDivElement | null>(null);
  const onEnabledChange = useCallback(
    (enabled: boolean) => {
      onChange?.({ ...data, enabled });
    },
    [data, onChange],
  );

  const onRelationChange = useCallback(
    (op: LogicalOperator) => {
      onChange?.({ ...data, op });
    },
    [data, onChange],
  );

  const onAddBtnClick = useCallback(() => {
    onChange?.({
      ...data,
      operands: [...data.operands, cloneDeep(DefaultCohortContractFilterOperandData)],
    });
  }, [data, onChange]);

  const onDeleteBtnClick = useCallback(
    (idx: number) => {
      if (idx < 0 || idx >= data.operands.length) return;
      onChange?.({
        ...data,
        operands: [...data.operands.slice(0, idx), ...data.operands.slice(idx + 1)],
      });
      setTimeout(() => {
        addBtnRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 100);
    },
    [data, onChange],
  );

  const onOperandChange = useCallback(
    (operand: CohortContractFilterOperand, idx: number) => {
      if (idx < 0 || idx >= data.operands.length) return;
      onChange?.({
        ...data,
        operands: replaceArrayIdxWithValue(data.operands, idx, operand),
      });
    },
    [data, onChange],
  );

  return (
    <Stack
      className={classNames(classes.root, direction === 'horizontal' ? classes.horizontal : classes.vertical)}
      direction={direction === 'horizontal' ? 'row' : 'column'}
    >
      <Stack
        className={classNames(
          classes.title,
          !data.enabled && classes.disabled,
          direction === 'horizontal' ? classes.horizontal : classes.vertical,
        )}
        spacing={2}
      >
        <IOSSwitch checked={data.enabled} onChange={(e) => onEnabledChange(e.target.checked)} />
        <Stack className={classes.label}>
          <FormControl disabled={!data.enabled}>
            <FormLabel>
              <Typography variant='h6'>Contract Interaction</Typography>
            </FormLabel>
          </FormControl>
        </Stack>
        <FormControl disabled={!data.enabled}>
          <FormLabel>
            <Typography variant='body1'>Relations within the filters</Typography>
          </FormLabel>
          <Select
            className={classes.select}
            value={data.op}
            onChange={(e) => e.target.value && onRelationChange(e.target.value as LogicalOperator)}
          >
            <MenuItem value={LogicalOperator.OR}>{LogicalOperatorNameMap.get(LogicalOperator.OR)}</MenuItem>
            <MenuItem value={LogicalOperator.AND}>{LogicalOperatorNameMap.get(LogicalOperator.AND)}</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack
        id='area-create-cohort-contract-interaction-condition'
        className={classNames(classes.operands, direction === 'horizontal' ? classes.horizontal : classes.vertical)}
        spacing={2}
      >
        <FormControl disabled={!data.enabled}>
          <Stack direction='row' justifyContent='space-between'>
            <FormLabel>
              <Typography variant='body1'>Condition</Typography>
            </FormLabel>
            <FormLabel>
              <Typography variant='body2' className={classNames(classes.built, !data.enabled && classes.disabled)}>
                {data.operands.length ? `${data.operands.length}/5 Condition Built` : 'Build Up to 5'}
              </Typography>
            </FormLabel>
          </Stack>
        </FormControl>
        <Divider />
        <Stack spacing={4}>
          {data.operands.map((operand, idx) => (
            <Stack key={idx} spacing={4}>
              {idx > 0 ? (
                <div>
                  <Divider sx={{ '.MuiDivider-wrapper': { padding: 0 } }}>
                    <LogicalOperatorSelect data={data.op} readonly disabled={!data.enabled} />
                  </Divider>
                </div>
              ) : null}
              <Stack spacing={2}>
                <FormControl disabled={!data.enabled}>
                  <Stack direction='row' justifyContent='space-between'>
                    <FormLabel>
                      <Typography variant='label2'>Condition {idx + 1}</Typography>
                    </FormLabel>
                    <IconButton
                      className={classes.btnDelete}
                      disabled={!data.enabled}
                      onClick={() => onDeleteBtnClick(idx)}
                    >
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </Stack>
                </FormControl>
                <ContractInteractionOperand
                  data={operand}
                  disabled={!data.enabled}
                  onChange={(d) => onOperandChange(d, idx)}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack ref={addBtnRef}>
          <Button
            className={classes.btnAdd}
            startIcon={<AddOutlined />}
            disabled={!data.enabled || data.operands.length >= 5}
            onClick={onAddBtnClick}
          >
            <Typography variant='label1'>Add Condition</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
