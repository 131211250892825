import { Button, Stack, Typography } from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { CustomizedCategoryList } from 'components/Category/CustomizedCategoryList';
import { DataContext } from 'contexts/DataContext';
import { InfluencerMatcherIcon } from 'components/icons/InfluencerMatcherIcon';
import { InfluencerUploadLimitDialog } from 'components/FeatureLimit/InfluencerUploadLimitDialog';
import { InfluencerUploadList } from 'components/InfluencerUpload/InfluencerUploadList';
import { SellOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import classes from './UserFileManagement.module.scss';
import { useLocation } from 'react-router-dom';
import { useUserFeatureLimits } from 'common/hooks/useUserFeatureLimits';

export enum FileManagementTab {
  Category = 'category',
  Influencer = 'influencer',
}

export const UserFileManagement: FC = () => {
  const { search } = useLocation();
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const [tab, setTab] = useState<FileManagementTab | undefined>(undefined);
  const [influencerUploadLimitDialogOpened, setInfluencerUploadLimitDialogOpened] = useState<boolean>(false);

  const { value: uploadInfluencerLimit } = useUserFeatureLimits('customized_influencer_job');

  useEffect(() => {
    const s = new URLSearchParams(search);
    const tab = s.get('tab');
    if (tab === FileManagementTab.Category) setTab(FileManagementTab.Category);
    else if (tab === FileManagementTab.Influencer) setTab(FileManagementTab.Influencer);
  }, [search]);

  const getTabContent = useMemo(() => {
    switch (tab) {
      case FileManagementTab.Category:
        return <CustomizedCategoryList onBack={() => setTab(undefined)} />;
      case FileManagementTab.Influencer:
        return <InfluencerUploadList onBack={() => setTab(undefined)} />;
    }
    return <></>;
  }, [tab]);

  if (!tab)
    return (
      <Stack className={classes.root} spacing={4}>
        <Typography variant='h3'>File management</Typography>
        <Stack direction='row' spacing={4}>
          <Stack className={classes.card}>
            <Stack
              className={classNames(classes.img, classes.darkBlue)}
              direction='row'
              alignItems='center'
              justifyContent='center'
            >
              <SellOutlined className={classes.icon} />
            </Stack>
            <Stack className={classes.content} spacing={3}>
              <Typography variant='h4'>Self-upload Category</Typography>
              <Typography variant='body1' className={classes.description}>
                Upload custom wallet addresses for further use with advanced filters or Ads Audience functionality.
              </Typography>
              <Stack direction='row-reverse'>
                <Button variant='contained' onClick={() => setTab(FileManagementTab.Category)}>
                  Manage
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack className={classes.card}>
            <Stack
              className={classNames(classes.img, classes.lightBlue)}
              direction='row'
              alignItems='center'
              justifyContent='center'
            >
              <InfluencerMatcherIcon className={classes.icon} />
            </Stack>
            <Stack className={classes.content} spacing={3}>
              <Typography variant='h4'>Self-upload Influencers lists</Typography>
              <Typography variant='body1' className={classes.description}>
                Upload local influencer lists to access detailed data metrics for local influencers.
              </Typography>
              <Stack direction='row-reverse'>
                <Button
                  variant='contained'
                  onClick={() => {
                    if (uploadInfluencerLimit === 0) {
                      setInfluencerUploadLimitDialogOpened(true);
                      return;
                    }
                    setTab(FileManagementTab.Influencer);
                  }}
                >
                  Manage
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {influencerUploadLimitDialogOpened ? (
          <InfluencerUploadLimitDialog
            open
            onBtnUpgradeClicked={() => {
              setInfluencerUploadLimitDialogOpened(false);
              openSubscriptionDialog$.next(true);
            }}
            onBtnCloseClicked={() => setInfluencerUploadLimitDialogOpened(false)}
          />
        ) : null}
      </Stack>
    );

  return <Stack className={classes.root}>{getTabContent}</Stack>;
};
