import { Button, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { KeywordSearch } from 'common/types/Extension/InfluencerFilter';
import { SearchOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import classes from './SearchBar.module.scss';

export enum SearchBarMenu {
  USER_INFO = 'user_info',
  SUMMARY = 'summary',
  SIMILAR_NICHE = 'similar_niche',
  TWEET_MENTIONED = 'tweet_mentioned',
  FOLLOWERS = 'followers',
  FOLLOWINGS = 'followings',
}

export const SearchBarMenuNameMap = new Map<SearchBarMenu, string>([
  [SearchBarMenu.USER_INFO, 'User Info'],
  [SearchBarMenu.SUMMARY, 'Summary'],
  [SearchBarMenu.SIMILAR_NICHE, 'Similar Niche'],
  [SearchBarMenu.TWEET_MENTIONED, 'Tweets Mentioned'],
  [SearchBarMenu.FOLLOWERS, 'Followers'],
  [SearchBarMenu.FOLLOWINGS, 'Followings'],
]);

interface SearchBarProps {
  mobileMode?: boolean;
  data?: KeywordSearch;
  menus?: SearchBarMenu[];
  disabled?: boolean;
  onSearchClicked?: () => void;
  onChange?: (data?: KeywordSearch, search?: boolean) => void;
}

export const SearchBar: FC<SearchBarProps> = ({
  mobileMode,
  data,
  disabled,
  menus = Object.values(SearchBarMenu),
  onSearchClicked,
  onChange,
}) => {
  const placeholder = useMemo(() => {
    switch (data?.type) {
      case SearchBarMenu.USER_INFO:
        return 'Search keywords of influencers name or bio description';
      case SearchBarMenu.SUMMARY:
        return 'Search keywords in influencers summary';
      case SearchBarMenu.SIMILAR_NICHE:
        return 'Profile URL, eg. https://x.com/vitalik';
      case SearchBarMenu.TWEET_MENTIONED:
        return 'Search keywords in influencers Tweets';
      case SearchBarMenu.FOLLOWERS:
        return 'Paste an X Profile URL and find their followers, eg. https://x.com/Uniswap';
      case SearchBarMenu.FOLLOWINGS:
        return 'Paste an X Profile URL and find their followings, eg. https://x.com/Uniswap';
      default:
        return '';
    }
  }, [data?.type]);
  return mobileMode ? (
    <Stack className={classes.mobileRoot} direction='row'>
      <OutlinedInput
        id='input-search-influencers'
        className={classes.input}
        startAdornment={
          <Stack className={classes.searchIcon}>
            <SearchOutlined />
          </Stack>
        }
        endAdornment={
          <Button
            className={classes.searchBtn}
            disabled={disabled}
            size='small'
            variant='contained'
            onClick={onSearchClicked}
          >
            Search
          </Button>
        }
        value={data?.keyword || ''}
        onChange={(e) => {
          onChange?.({ type: data?.type || SearchBarMenu.USER_INFO, keyword: e.target.value });
        }}
      />
    </Stack>
  ) : (
    <Stack spacing={3}>
      <Stack className={classes.root} direction='row'>
        <Select
          className={classes.select}
          value={data?.type || SearchBarMenu.USER_INFO}
          onChange={(e) => onChange?.({ ...data, type: e.target.value, keyword: data?.keyword || '' })}
        >
          {menus.map((menu) => (
            <MenuItem key={menu} value={menu}>
              {SearchBarMenuNameMap.get(menu)}
            </MenuItem>
          ))}
        </Select>
        <OutlinedInput
          id='input-search-influencers'
          className={classes.input}
          startAdornment={
            <Stack className={classes.searchIcon}>
              <SearchOutlined />
            </Stack>
          }
          endAdornment={
            <Button
              className={classes.searchBtn}
              disabled={disabled}
              size='small'
              variant='contained'
              onClick={onSearchClicked}
            >
              Search
            </Button>
          }
          placeholder={placeholder}
          value={data?.keyword || ''}
          onChange={(e) => {
            onChange?.({
              ...data,
              type: data?.type || SearchBarMenu.USER_INFO,
              keyword: e.target.value,
            });
          }}
        />
      </Stack>
      <Stack
        className={classNames(classes.tweets, data?.type !== SearchBarMenu.TWEET_MENTIONED && classes.hide)}
        direction='row'
        spacing={1.5}
        alignItems='center'
      >
        <Typography className={classes.text}>Tweets mentioned at least</Typography>
        <Select
          className={classes.button}
          value={data?.times || 1}
          onChange={(e) =>
            typeof e.target.value === 'number' &&
            onChange?.(
              {
                ...data,
                type: SearchBarMenu.TWEET_MENTIONED,
                keyword: data?.keyword || '',
                times: e.target.value,
              },
              !!data?.keyword,
            )
          }
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((time) => (
            <MenuItem key={time} value={time}>
              {time} times
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.text}>within</Typography>
        <Select
          className={classes.button}
          value={data?.days || 90}
          onChange={(e) =>
            typeof e.target.value === 'number' &&
            onChange?.(
              {
                ...data,
                type: SearchBarMenu.TWEET_MENTIONED,
                keyword: data?.keyword || '',
                days: e.target.value,
              },
              !!data?.keyword,
            )
          }
        >
          {[7, 30, 90].map((day) => (
            <MenuItem key={day} value={day}>
              {day} days
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};
