import { Button, IconButton, MenuItem, MenuList, Popover, Stack, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import {
  ContentCopyOutlined,
  CopyAllOutlined,
  DeleteOutlineOutlined,
  LinkOutlined,
  MoreVertOutlined,
  PushPin,
  PushPinOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { FC, HTMLAttributes, useContext, useState } from 'react';

import { Collection } from 'common/types/Extension/Collection';
import { CollectionCreateLimitDialog } from 'components/FeatureLimit/CollectionCreateLimitDialog';
import { DataContext } from 'contexts/DataContext';
import classes from './CollectionList.module.scss';
import { datetimeFormatter } from 'common/formatters';
import { useUserFeatureLimits } from 'common/hooks/useUserFeatureLimits';

interface CollectionListProps extends HTMLAttributes<HTMLElement> {
  collections: Collection[];
  onCollectionShareClicked?: (collection: Collection) => void;
  onCollectionViewClicked?: (collection: Collection) => void;
  onCollectionPinClicked?: (collection: Collection) => void;
  onCollectionDuplicateClicked?: (collection: Collection) => void;
  onCollectionCopyToClicked?: (collection: Collection) => void;
  onCollectionDeleteClicked?: (collection: Collection) => void;
}

export const CollectionList: FC<CollectionListProps> = ({
  collections,
  onCollectionShareClicked,
  onCollectionViewClicked,
  onCollectionPinClicked,
  onCollectionDuplicateClicked,
  onCollectionCopyToClicked,
  onCollectionDeleteClicked,
  ...rest
}) => {
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const [anchorElement, setAnchor] = useState<HTMLElement>();
  const [selectedCollection, setSelectedCollection] = useState<Collection | undefined>(undefined);

  const [collectionCreateLimitDialogOpened, setCollectionCreateLimitDialogOpened] = useState<boolean>(false);

  const { value: createCollectionLimit } = useUserFeatureLimits('collection', 'create');

  const columnDefs: CommonTableColumn<Collection>[] = [
    {
      id: 'idx',
      label: '#',
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            className={classes.name}
            onClick={() => data && onCollectionViewClicked?.(data)}
          >
            {data?.pinned ? (
              <PushPin fontSize='small' className={classes.pinned} />
            ) : (
              <PushPinOutlined fontSize='small' />
            )}
            <Typography variant='body1'>{value}</Typography>
          </Stack>
        );
      },
    },
    {
      id: 'twitter_users',
      label: 'Influencers',
      render: (value) => {
        return <Typography variant='body1'>{value?.length || 0}</Typography>;
      },
    },
    {
      id: 'created_at',
      label: 'Create Time',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{datetimeFormatter.format((value || 0) * 1000)}</Typography>;
      },
    },
    {
      id: 'id',
      label: 'Action',
      render: (_, idx, data) => {
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            <Button
              variant='outlined'
              startIcon={<VisibilityOutlined />}
              onClick={() => data && onCollectionViewClicked?.(data)}
            >
              View
            </Button>
            <IconButton
              onClick={(event) => {
                setAnchor(event.currentTarget);
                setSelectedCollection(data);
              }}
              className={classes.moreButton}
            >
              <MoreVertOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <CommonTable {...rest} data={collections} columns={columnDefs} classes={classes} />
      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchor(undefined);
          setSelectedCollection(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            disabled={selectedCollection === undefined}
            onClick={() => {
              selectedCollection && onCollectionShareClicked?.(selectedCollection);
              setAnchor(undefined);
              setSelectedCollection(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <LinkOutlined />
              <Typography>Copy link</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={selectedCollection === undefined}
            onClick={() => {
              selectedCollection && onCollectionPinClicked?.(selectedCollection);
              setAnchor(undefined);
              setSelectedCollection(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              {selectedCollection?.pinned ? <PushPinOutlined /> : <PushPin className={classes.pinned} />}
              <Typography>{selectedCollection?.pinned ? 'Unpin' : 'Pin'} on Top</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={selectedCollection === undefined}
            onClick={() => {
              if ((collections?.length || 0) >= createCollectionLimit && createCollectionLimit >= 0) {
                setCollectionCreateLimitDialogOpened(true);
                return;
              }
              selectedCollection && onCollectionDuplicateClicked?.(selectedCollection);
              setAnchor(undefined);
              setSelectedCollection(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <ContentCopyOutlined />
              <Typography>Duplicate</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={selectedCollection === undefined}
            onClick={() => {
              selectedCollection && onCollectionCopyToClicked?.(selectedCollection);
              setAnchor(undefined);
              setSelectedCollection(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <CopyAllOutlined />
              <Typography>Copy to</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={selectedCollection === undefined}
            onClick={() => {
              selectedCollection && onCollectionDeleteClicked?.(selectedCollection);
              setAnchor(undefined);
              setSelectedCollection(undefined);
            }}
          >
            <Stack direction='row' spacing={1} color='error'>
              <DeleteOutlineOutlined color='error' />
              <Typography color='error'>Delete</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>

      {collectionCreateLimitDialogOpened ? (
        <CollectionCreateLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionCreateLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionCreateLimitDialogOpened(false)}
        />
      ) : null}
    </>
  );
};
