import { SvgIcon, SvgIconProps } from '@mui/material';

export const GoogleDriveIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 87.3 78'>
    <path fill='#0066da' d='m6.6 66.8 3.9 6.7a9 9 0 0 0 3.2 3.3L27.6 53H0a9 9 0 0 0 1.2 4.5z' />
    <path fill='#00ac47' d='M43.6 25 30 1.2a9 9 0 0 0-3.3 3.3l-25.4 44A9 9 0 0 0 0 53h27.5z' />
    <path fill='#ea4335' d='M73.5 76.8a9 9 0 0 0 3.3-3.3l1.6-2.8 7.7-13.2a9 9 0 0 0 1.2-4.5H59.8l5.9 11.5z' />
    <path fill='#00832d' d='M43.6 25 57.5 1.2A8.8 8.8 0 0 0 52.9 0H34.4c-1.6 0-3.1.4-4.5 1.2z' />
    <path fill='#2684fc' d='M59.8 53H27.5L13.7 76.8c1.4.8 3 1.2 4.6 1.2H69c1.6 0 3.1-.5 4.5-1.2z' />
    <path fill='#ffba00' d='m73.4 26.5-12.7-22a9 9 0 0 0-3.3-3.3L43.7 25l16.1 28h27.5a9 9 0 0 0-1.2-4.5z' />
  </SvgIcon>
);
