import { Button, Dialog, DialogProps, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { CheckCircle, CloseOutlined, EmailOutlined } from '@mui/icons-material';
import { FC, useContext, useMemo } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { AccountsContext } from 'contexts/AccountsContext';
import { CommonChip } from 'components/chip/CommonChip';
import classes from './CollectionUserLimitDialog.module.scss';
import { useAllFeatureLimits } from 'common/hooks/useAllFeatureLimits';
import { useObservable } from 'react-use';

interface CollectionUserLimitDialogProps extends DialogProps {
  onBtnCloseClicked?: () => void;
  onBtnUpgradeClicked?: () => void;
}

export const CollectionUserLimitDialog: FC<CollectionUserLimitDialogProps> = ({
  open,
  onBtnCloseClicked,
  onBtnUpgradeClicked,
  ...props
}) => {
  const { subscriptionPlans$ } = useContext(AccountsContext);
  const subscriptionPlans = useObservable(subscriptionPlans$);

  const { value: featureLimits } = useAllFeatureLimits();

  const items = useMemo(() => {
    const items: { plan: string; count?: number }[] = [];
    subscriptionPlans
      ?.filter((subscriptionPlan) => subscriptionPlan.duration_months === 1)
      .forEach((subscriptionPlan) => {
        let limit = undefined;
        featureLimits?.forEach((featureLimit) => {
          if (featureLimit.feature_name !== 'collection') return;
          if (featureLimit.sub_module !== 'update') return;
          if (featureLimit.user_tier !== subscriptionPlan.user_tier) return;
          limit = featureLimit.max_amount;
        });
        items.push({ plan: subscriptionPlan.name, count: limit });
      });
    return items;
  }, [subscriptionPlans, featureLimits]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(_, reason) => {
        reason !== 'backdropClick' && onBtnCloseClicked?.();
      }}
      maxWidth='xl'
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCloseClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <EmailOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Upgrade to Enhance Your Workflow
              </Typography>
              <Typography textAlign='center' className={classes.subtitle}>
                Explore our various plans designed to fit your budget
              </Typography>
            </Stack>
          </Stack>
          <Stack className={classes.plans} spacing={1.5}>
            <Stack direction='row' justifyContent='space-between'>
              <Typography variant='subtitle2'>Plans</Typography>
              <Typography variant='subtitle2'>Influencers on the list</Typography>
            </Stack>
            <Divider />
            {items.map((item, idx) => (
              <Stack key={idx} spacing={1.5}>
                {idx !== 0 ? <Divider /> : null}
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <CheckCircle fontSize='small' color='success' className={classes.check} />
                    <Typography variant='body2'>{item.plan}</Typography>
                  </Stack>
                  <Typography variant='body2'>
                    {typeof item.count === 'number' ? (
                      <span>
                        <span className={classes.highlight}>{item.count === -1 ? 'Unlimited' : item.count}</span>{' '}
                        influencers
                      </span>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCloseClicked}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onBtnUpgradeClicked}>
            Upgrade
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
