import { SvgIcon, SvgIconProps } from '@mui/material';

export const GhostIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 32 32'>
    <g clipPath='url(#a)'>
      <path
        fillRule='evenodd'
        d='M16 5.3a9.3 9.3 0 0 0-9.3 9.4v11.5a.5.5 0 0 0 .8.4l1.2-.7a5.3 5.3 0 0 1 5.3 0l.7.5a2.7 2.7 0 0 0 2.6 0l.7-.4a5.3 5.3 0 0 1 5.3 0l1.2.6a.5.5 0 0 0 .8-.4V14.7A9.3 9.3 0 0 0 16 5.3ZM4 14.7a12 12 0 1 1 24 0v11.5c0 2.4-2.6 4-4.8 2.8l-1.2-.7a2.7 2.7 0 0 0-2.6 0l-.8.4a5.3 5.3 0 0 1-5.2 0l-.8-.4a2.7 2.7 0 0 0-2.6 0l-1.2.7A3.2 3.2 0 0 1 4 26.2V14.7Zm10-.7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm6 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </SvgIcon>
);
