import {
  ArrowBackOutlined,
  CheckOutlined,
  CloseOutlined,
  ContentCopyOutlined,
  CopyAllOutlined,
  EditOutlined,
  FileUploadOutlined,
  LinkOutlined,
  ListOutlined,
  LockOutlined,
  TuneOutlined,
} from '@mui/icons-material';
import { Button, IconButton, MenuItem, MenuList, OutlinedInput, Popover, Stack, Typography } from '@mui/material';
import { Collection, CollectionTwitterUser } from 'common/types/Extension/Collection';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IntegrationAuth, IntegrationAuthProvider } from 'common/types/IntegrationAuth';
import { SearchBar, SearchBarMenu } from 'pages/InfluencerMatcher/Search/SearchBar';
import {
  getFilteredTwitterUsers,
  getTwitterUsersCategories,
  getTwitterUsersLanguages,
  getTwitterUsersLocations,
  getTwitterUsersMetricsRange,
} from './collectionUtils';
import { useAsync, useObservable } from 'react-use';

import { AddUserToCollectionDialog } from 'components/InfluencerMatcher/collection/AddUserToCollectionDialog';
import ClientAPI from 'common/ClientAPI';
import { CollectionCreateLimitDialog } from 'components/FeatureLimit/CollectionCreateLimitDialog';
import { CollectionExportLimitDialog } from 'components/FeatureLimit/CollectionExportLimitDialog';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { CopyUserToCollectionDialog } from 'components/InfluencerMatcher/collection/CopyUserToCollectionDialog';
import { DataContext } from 'contexts/DataContext';
import { DeleteUserFromCollection } from 'components/InfluencerMatcher/collection/DeleteUserFromCollection';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { ExtensionDrawer } from 'pages/InfluencerMatcher/Drawer/ExtensionDrawer';
import { GoogleDriveExportDialog } from 'components/GoogleDrive/export/GoogleDriveExportDialog';
import { InfluencerFilter } from '../Influencer/InfluencerFilter';
import { InfluencerSearchParams } from 'common/types/Extension/InfluencerFilter';
import { TwitterUserList } from 'pages/InfluencerMatcher/Search/TwitterUserList';
import { TwitterUserSearchResult } from 'common/types/Extension/TwitterUserSearchResult';
import classNames from 'classnames';
import classes from './CollectionDetail.module.scss';
import { colorPrimary40 } from 'common/params';
import { setCookie } from 'common/utils';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';
import { useUserFeatureLimits } from 'common/hooks/useUserFeatureLimits';
import { useUserProfile } from 'common/hooks/useUserProfile';

const DefaultParams: InfluencerSearchParams = {
  keyword_search: {
    type: SearchBarMenu.USER_INFO,
    keyword: '',
  },
};

interface CollectionDetailProps {
  shared?: boolean;
  collection: Collection;
  onShareClicked?: (collection: Collection) => void;
  onMyListClicked?: (collection: Collection) => void;
  onCollectionView?: (collectionId?: number) => void;
  onBackClicked?: () => void;
}

export const CollectionDetail: FC<CollectionDetailProps> = ({
  shared,
  collection,
  onShareClicked,
  onMyListClicked,
  onCollectionView,
  onBackClicked,
}) => {
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { value: user } = useUserProfile();

  const { openSubscriptionDialog$ } = useContext(DataContext);
  const { collections$, refetchCollections$ } = useContext(ExtensionContext);
  const collections = useObservable(collections$);

  const [tmpSearchParams, setTmpSearchParams] = useState<InfluencerSearchParams>(DefaultParams);
  const [finalSearchParams, setFinalSearchParams] = useState<InfluencerSearchParams>(DefaultParams);
  const [twitterUsers, setTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [copiedCollection, setCopiedCollection] = useState<Collection | undefined>(undefined);
  const [addTwitterUsers, setAddTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [editName, setEditName] = useState<string | undefined>(undefined);

  const [twitterUserId, setTwitterUserId] = useState<string | undefined>(undefined);
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const [duplicating, setDuplicating] = useState<boolean>(false);
  const [duplicatedCollection, setDuplicatedCollection] = useState<Collection | undefined>(undefined);
  const [exportCollection, setExportCollection] = useState<Collection | undefined>(undefined);
  const [exportAuth, setExportAuth] = useState<IntegrationAuth | undefined>(undefined);

  const [clearSelected, setClearSelected] = useState<boolean>(false);

  const [addedTwitterUsers, setAddedTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [deleteTwitterUser, setDeleteTwitterUser] = useState<TwitterUserSearchResult | undefined>(undefined);

  const [refetchExportUsage, setRefetchExportUsage] = useState<boolean>(false);
  const [collectionCreateLimitDialogOpened, setCollectionCreateLimitDialogOpened] = useState<boolean>(false);
  const [collectionExportLimitDialogOpened, setCollectionExportLimitDialogOpened] = useState<boolean>(false);

  const { value: createCollectionLimit } = useUserFeatureLimits('collection', 'create');
  const { value: collectionUsersLimit } = useUserFeatureLimits('collection', 'update');
  const { value: exportCollectionLimit } = useUserFeatureLimits('export_collection_users');

  const { value: usage } = useAsync(async () => {
    return (await ClientAPI.getUserFeatureUsage('export_collection_users')).data;
  }, [refetchExportUsage]);

  const getCollectionTwitterUsers = useCallback(
    async (collectionTwitterUsers: CollectionTwitterUser[]) => {
      await ExtensionAPI.searchTwitterUser(
        { user_ids: collectionTwitterUsers.map((user) => user.id) },
        { page: 0, rowsPerPage: collectionTwitterUsers.length },
      )
        .then(({ status, data }) => {
          if (status === 'success') {
            if (!data?.length) return;
            const noDataUsers = collectionTwitterUsers.filter((collectionUser) =>
              data.every((user) => collectionUser.id !== user.id),
            );
            setTwitterUsers([...data, ...noDataUsers]);
            return;
          }
          showMessage(`Get collection twitter users failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    },
    [showMessage],
  );

  useEffect(() => {
    getCollectionTwitterUsers(collection.twitter_users);
  }, [collection.twitter_users, getCollectionTwitterUsers]);

  const updateCollectionName = useCallback(
    async (collection: Collection) => {
      const newCollection: Collection = { ...collection, name: editName || '' };
      await ExtensionAPI.updateCollection(newCollection)
        .then(({ status }) => {
          if (status === 'success') {
            refetchCollections$.next(true);
            setEditName(undefined);
            return;
          }
          showMessage('Pin collection failed', 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Pin collection failed', 'error');
        });
    },
    [refetchCollections$, editName, showMessage],
  );

  const duplicateCollection = useCallback(
    async (collection: Collection) => {
      const newCollection: Collection = { ...collection };
      const newName = `${newCollection.name} (Copy)`;
      newCollection.id = 0;
      newCollection.name = newName;
      setDuplicating(true);
      await ExtensionAPI.createCollection(newCollection)
        .then(({ status, data }) => {
          if (status === 'success' && data?.id) {
            setDuplicatedCollection({ ...newCollection, id: data.id });
            refetchCollections$.next(true);
            return;
          }
          showMessage('Duplicate collection failed', 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Duplicate collection failed', 'error');
        })
        .finally(() => {
          setDuplicating(false);
          setAnchorElement(undefined);
        });
    },
    [showMessage, refetchCollections$],
  );

  const collectionInfoBar = useMemo(() => {
    // not login yet -> return only basic info
    if (!user)
      return (
        <Stack direction='row' justifyContent='space-between' spacing={1}>
          <Stack className={classes.collectionInfo}>
            <Typography variant='h3'>{collection.name}</Typography>
          </Stack>
        </Stack>
      );
    // has login and in public page
    if (!onShareClicked) {
      return (
        <Stack direction='row' justifyContent='space-between' spacing={1}>
          <Stack className={classes.collectionInfo}>
            <Typography variant='h3'>{collection.name}</Typography>
          </Stack>
          {onMyListClicked ? (
            <Button
              startIcon={<ListOutlined />}
              variant='contained'
              onClick={() => {
                onMyListClicked?.(collection);
              }}
            >
              My List
            </Button>
          ) : null}
        </Stack>
      );
    }
    // has login and in private page
    return (
      <Stack direction='row' justifyContent='space-between' spacing={1}>
        <Stack className={classes.collectionInfo} direction='row' spacing={1}>
          {typeof editName === 'string' ? (
            <>
              <OutlinedInput size='medium' value={editName} onChange={(e) => setEditName(e.target.value)} />
              <IconButton onClick={() => setEditName(undefined)}>
                <CloseOutlined color='error' />
              </IconButton>
              <IconButton
                onClick={() => {
                  updateCollectionName(collection);
                }}
              >
                <CheckOutlined color='success' />
              </IconButton>
            </>
          ) : (
            <>
              <Typography variant='h3'>{collection?.name}</Typography>
              <IconButton onClick={() => setEditName(collection.name)}>
                <EditOutlined />
              </IconButton>
            </>
          )}
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Typography>
            <span
              className={classNames(
                classes.highlight,
                collection.twitter_users.length >= collectionUsersLimit && collectionUsersLimit >= 0 && classes.error,
              )}
            >
              {collection.twitter_users.length}
            </span>
            /{collectionUsersLimit >= 0 ? collectionUsersLimit : 'unlimit'} Influencers
          </Typography>
          <Button
            startIcon={shared ? null : <LinkOutlined />}
            onClick={() => {
              onShareClicked?.(collection);
            }}
          >
            {shared ? 'Link Copied!' : 'Copy link'}
          </Button>
          <IconButton onClick={(evt) => setAnchorElement(evt.currentTarget)}>
            <TuneOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </Stack>
    );
  }, [user, editName, collection, shared, collectionUsersLimit, onShareClicked, updateCollectionName, onMyListClicked]);

  const onCollectionExportGoogleDrive = useCallback(
    async (collection: Collection) => {
      const googleDriveAuth = await ClientAPI.getDefaultIntegrationAuth(IntegrationAuthProvider.GOOGLE_DRIVE)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });

      if (googleDriveAuth) {
        // has google drive default auth -> open dialog
        setExportCollection(collection);
        setExportAuth(googleDriveAuth);
      } else {
        // no default auth -> redirect user to google drive authorization page
        await ClientAPI.integrationOauth2(IntegrationAuthProvider.GOOGLE_DRIVE)
          .then(({ data: oauth_url }) => {
            if (!oauth_url) return;
            setCookie(
              'redirect_url',
              `${window.location.origin}${window.location.pathname}?tab=collections&collectionId=${collection.id}`,
            );
            window.location.href = oauth_url;
          })
          .catch((error) => {
            showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
          });
      }
    },
    [showMessage],
  );

  return (
    <Stack className={classes.root} spacing={2}>
      {onBackClicked ? (
        <Stack direction='row'>
          <Button startIcon={<ArrowBackOutlined />} onClick={onBackClicked}>
            Back to list
          </Button>
        </Stack>
      ) : null}
      {collectionInfoBar}
      <SearchBar
        menus={[SearchBarMenu.USER_INFO]}
        data={tmpSearchParams.keyword_search}
        onSearchClicked={() => setFinalSearchParams(tmpSearchParams)}
        onChange={(data) => setTmpSearchParams((old) => ({ ...old, keyword_search: data }))}
      />
      <Stack className={classes.table} spacing={2}>
        <InfluencerFilter
          showIsProject
          searchParams={finalSearchParams}
          metricsRange={getTwitterUsersMetricsRange(twitterUsers)}
          categories={getTwitterUsersCategories(twitterUsers)}
          languages={getTwitterUsersLanguages(twitterUsers)}
          locations={getTwitterUsersLocations(twitterUsers)}
          onSearchParamsChanged={(searchParams) => setFinalSearchParams(searchParams)}
        />
        <TwitterUserList
          clearSelected={clearSelected}
          twitterUsers={getFilteredTwitterUsers(twitterUsers, finalSearchParams)}
          addedTwitterUsers={addedTwitterUsers}
          onAddTwitterUsersToList={(twitterUsers) => {
            setAddTwitterUsers(twitterUsers);
          }}
          onDeleteTwitterUser={(twitterUser) => setDeleteTwitterUser(twitterUser)}
          onTwitterUserViewClicked={(twitterUser) => {
            track('click', {
              sub_event: 'influencer_clicked',
              custom_props: {
                entry_point: 'List page',
                influencer_id: twitterUser.id,
                influencer_username: twitterUser.username,
              },
            });
            setDrawerOpened(true);
            setTwitterUserId(twitterUser.id);
          }}
          onSelectedClear={() => setClearSelected(false)}
        />
      </Stack>

      {addTwitterUsers.length ? (
        <AddUserToCollectionDialog
          source='List table'
          twitterUsers={addTwitterUsers}
          collections={collections || []}
          onSuccess={(collectionId) => {
            setAddedTwitterUsers((old) => {
              const filteredTwitterUsers = addTwitterUsers.filter(
                (addTwitterUser) => old.findIndex((user) => user.id === addTwitterUser.id) === -1,
              );
              return [...old, ...filteredTwitterUsers];
            });
            setAddTwitterUsers([]);
            refetchCollections$.next(true);
            onCollectionView?.(collectionId);
            setClearSelected(true);
          }}
          onCancel={() => {
            setAddTwitterUsers([]);
          }}
        />
      ) : null}

      {copiedCollection ? (
        <CopyUserToCollectionDialog
          source='List table'
          collection={copiedCollection}
          collections={collections || []}
          onSuccess={(collectionId) => {
            setCopiedCollection(undefined);
            refetchCollections$.next(true);
            onCollectionView?.(collectionId);
            setClearSelected(true);
          }}
          onCancel={() => {
            setCopiedCollection(undefined);
          }}
        />
      ) : null}

      {drawerOpened ? (
        <ExtensionDrawer
          twitterUserId={twitterUserId}
          onClose={() => {
            setDrawerOpened(false);
            setTwitterUserId(undefined);
          }}
          onAddTwitterUser={(twitterUser) => setAddTwitterUsers([twitterUser])}
        />
      ) : null}

      {duplicatedCollection ? (
        <CommonDialog
          open={true}
          mainContent={
            <Stack spacing={2} alignItems='center' className={classes.dialog}>
              <CommonChip>
                <CheckOutlined fontSize='large' />
              </CommonChip>
              <Stack direction='row'>
                <Typography variant='h6' className={classes.title}>
                  <span className={classes.highlight}>"{duplicatedCollection.name}"</span> has been created successfully
                </Typography>
              </Stack>
            </Stack>
          }
          footer={
            <Stack direction='row' justifyContent='center' spacing={1}>
              <Button variant='outlined' onClick={() => setDuplicatedCollection(undefined)}>
                Close
              </Button>
              <Button
                variant='contained'
                onClick={() => {
                  onCollectionView?.(duplicatedCollection.id);
                  setDuplicatedCollection(undefined);
                  setClearSelected(true);
                }}
              >
                View list
              </Button>
            </Stack>
          }
          onDialogClose={() => setDuplicatedCollection(undefined)}
        />
      ) : null}

      {exportCollection && exportAuth ? (
        <GoogleDriveExportDialog
          open
          collection={exportCollection}
          defaultAuth={exportAuth}
          onDialogClose={(success?: boolean) => {
            if (success) {
              setRefetchExportUsage(true);
            }
            setExportCollection(undefined);
          }}
        />
      ) : null}

      {deleteTwitterUser ? (
        <DeleteUserFromCollection
          collection={collection}
          twitterUser={deleteTwitterUser}
          onSuccess={() => {
            setTwitterUsers((old) => [...old.filter((user) => user.id !== deleteTwitterUser.id)]);
            setDeleteTwitterUser(undefined);
            refetchCollections$.next(true);
          }}
          onCancel={() => {
            setDeleteTwitterUser(undefined);
          }}
        />
      ) : null}

      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => {
          setAnchorElement(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            disabled={duplicating}
            onClick={() => {
              if ((collections?.length || 0) >= createCollectionLimit && createCollectionLimit >= 0) {
                setCollectionCreateLimitDialogOpened(true);
                return;
              }
              duplicateCollection(collection);
            }}
          >
            <Stack direction='row' spacing={1}>
              <ContentCopyOutlined />
              <Typography>Duplicate</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCopiedCollection(collection);
              setAnchorElement(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <CopyAllOutlined />
              <Typography>Copy to</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if ((usage?.total_amount || 0) >= exportCollectionLimit && exportCollectionLimit >= 0) {
                setCollectionExportLimitDialogOpened(true);
                setAnchorElement(undefined);
                return;
              }
              onCollectionExportGoogleDrive(collection);
              setAnchorElement(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <FileUploadOutlined />
              <Typography>Export as Google Sheet</Typography>
              {exportCollectionLimit > 0 ? (
                <Typography>
                  <span
                    className={
                      classNames(classes.highlight, (usage?.total_amount || 0) >= exportCollectionLimit) &&
                      classes.error
                    }
                  >
                    {usage?.total_amount || 0}
                  </span>
                  /{exportCollectionLimit}
                </Typography>
              ) : null}
              {exportCollectionLimit === 0 ? <LockOutlined /> : null}
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>

      {collectionCreateLimitDialogOpened ? (
        <CollectionCreateLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionCreateLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionCreateLimitDialogOpened(false)}
        />
      ) : null}

      {collectionExportLimitDialogOpened ? (
        <CollectionExportLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionExportLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionExportLimitDialogOpened(false)}
        />
      ) : null}
    </Stack>
  );
};
