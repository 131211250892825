export interface UserSurvey {
  job: string;
  company: string;
  category: string;
  telegram_username: string;
  display_name: string;
}

export const DefaultUserSurveyData: UserSurvey = {
  job: '',
  company: '',
  category: '',
  telegram_username: '',
  display_name: '',
};
