import { SvgIcon, SvgIconProps } from '@mui/material';

export const FireIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 20 20'>
    <g clipPath='url(#a)'>
      <path
        fill='url(#b)'
        d='M5.6 6.7c-.1 1-.2 2.7.4 3.4 0 0-.3-1.9 2-4.2a3 3 0 0 0 1-3.1c-.3-.6-.6-1-1-1.3-.1-.2 0-.5.3-.5 1.5 0 4 .5 5 3.2.6 1.1.6 2.3.4 3.6-.2.7-.7 2.5.5 2.7.8.2 1.2-.5 1.4-1 0-.2.3-.2.4 0a5.7 5.7 0 0 1 1.2 5c-.5 3-3.6 5.2-6.7 5.2-3.9 0-7-2.2-7.7-6.2a7.8 7.8 0 0 1 2.3-7c.2-.2.5 0 .5.2Z'
      />
      <path
        fill='url(#c)'
        d='M11.9 12.5c-1.4-1.9-.8-4-.4-4.8 0 0-.1-.2-.2-.1a7 7 0 0 0-2.5 2.7c-.8 2-.7 2.8-.2 4 .3.6 0 .7-.2.8l-.5-.2c-.3-.3-.5-.8-.7-1.2 0-.1-.1-.1-.2 0-.4.5-.6 1.5-.6 2.2 0 2.1 1.7 3.8 3.8 3.8 2.7 0 4.6-3 3-5.4L12 12.5Z'
      />
    </g>
    <defs>
      <radialGradient
        id='b'
        cx='0'
        cy='0'
        r='1'
        gradientTransform='matrix(-11.0292 -.04793 .07865 -18.09673 9.7 19.8)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='.3' stopColor='#FF9800' />
        <stop offset='.7' stopColor='#FF6D00' />
        <stop offset='1' stopColor='#F44336' />
      </radialGradient>
      <radialGradient
        id='c'
        cx='0'
        cy='0'
        r='1'
        gradientTransform='rotate(90.6 .8 9.5) scale(11.5401 8.68476)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='.2' stopColor='#FFF176' />
        <stop offset='.3' stopColor='#FFF27D' />
        <stop offset='.5' stopColor='#FFF48F' />
        <stop offset='.7' stopColor='#FFF7AD' />
        <stop offset='.8' stopColor='#FFF9C4' />
        <stop offset='.8' stopColor='#FFF8BD' stopOpacity='.8' />
        <stop offset='.9' stopColor='#FFF6AB' stopOpacity='.5' />
        <stop offset='.9' stopColor='#FFF38D' stopOpacity='.2' />
        <stop offset='.9' stopColor='#FFF176' stopOpacity='0' />
      </radialGradient>
      <clipPath id='a'>
        <path fill='#fff' d='M0 .4h20v20H0z' />
      </clipPath>
    </defs>
  </SvgIcon>
);
