import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { CloseOutlined, FileUploadOutlined, Info, Volcano, Warning } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import ClientAPI from 'common/ClientAPI';
import { Collection } from 'common/types/Extension/Collection';
import { CommonChip } from 'components/chip/CommonChip';
import { CustomizedInfluencerJob } from 'common/types/CustomizedInfluencer';
import classes from './InfluencerUploadDialog.module.scss';
import { useMessage } from 'components/message/useMessage';

interface InfluencerUploadDialogProps extends DialogProps {
  onCreate?: (job: CustomizedInfluencerJob) => void;
  onCancel?: () => void;
}

enum Step {
  SET_NAME,
  INPUT_USERS,
}

const MAX_USER_NUM = 1000;

export const InfluencerUploadDialog: FC<InfluencerUploadDialogProps> = ({ open, onCreate, onCancel, ...props }) => {
  const { showMessage } = useMessage();
  const [step, setStep] = useState<Step>(Step.SET_NAME);
  const [name, setName] = useState<string>('');
  const [selectedCollection] = useState<Collection | undefined>(undefined);
  const [usernames, setUsernames] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(false);
  const [createBtnClicked, setCreateBtnClicked] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);

  const jobValid = useMemo(() => {
    return usernames.length && (name || selectedCollection?.id);
  }, [usernames, name, selectedCollection]);

  const addUsernames = useCallback((usernames: string[]) => {
    const dedupData = usernames.filter((d, i, a) => a.findIndex((d2) => d.toLowerCase() === d2.toLowerCase()) === i);
    setUsernames((old) =>
      [
        ...old,
        ...dedupData.filter((newName) => old.every((oldName) => oldName.toLowerCase() !== newName.toLowerCase())),
      ].slice(0, MAX_USER_NUM),
    );
  }, []);

  const createCategory = useCallback(async () => {
    if (!usernames.length) return;
    if (!name && !selectedCollection?.id) return;

    setCreating(true);
    await ClientAPI.createInfluencerUploadJob(usernames, name, selectedCollection?.id)
      .then(({ status, message, data }) => {
        if (status === 'success' && data) {
          onCreate?.(data);
          return;
        }
        showMessage(`Create influencer list failed, ${message}`, 'error');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setCreating(false);
      });
  }, [usernames, name, selectedCollection, showMessage, onCreate]);

  const onBtnCreateClicked = useCallback(() => {
    setCreateBtnClicked(true);
    if (!jobValid) return;
    createCategory();
  }, [jobValid, createCategory]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <FileUploadOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Upload Influencers as a new list
              </Typography>
              {step === Step.INPUT_USERS ? (
                <Typography className={classes.subtitle}>
                  Copy and paste @username separated by a comma or a new line.
                </Typography>
              ) : null}
            </Stack>
          </Stack>
          {step === Step.SET_NAME ? (
            <Stack spacing={2} className={classes.input}>
              <FormControl>
                <InputLabel error={nextBtnClicked && !name}>List Name</InputLabel>
                <OutlinedInput
                  label='List Name'
                  error={nextBtnClicked && !name}
                  sx={{ borderColor: 'red' }}
                  value={name}
                  inputProps={{ maxLength: 40 }}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <Stack direction='row' spacing={1} className={classes.description}>
                <Info fontSize='small' />
                <Stack>
                  <Typography>Note:</Typography>
                  <Stack direction='row' spacing={1}>
                    <Typography>1.</Typography>
                    <Typography>Influencers must have more than 1,000 followers.</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography>2.</Typography>
                    <Typography>Each upload can include a maximum of 1,000 influencers.</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography>3.</Typography>
                    <Typography>The list may contain fewer influencers than uploaded due to restrictions.</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : step === Step.INPUT_USERS ? (
            <Stack spacing={2} className={classes.input}>
              <Stack className={classes.chips} direction='row'>
                {usernames.map((username) => {
                  return (
                    <Stack className={classes.chip} key={username} direction='row' alignItems='center' spacing={1}>
                      <Typography className={classes.text} variant='label1'>
                        @{username}
                      </Typography>
                      <CloseOutlined
                        className={classes.cancel}
                        onClick={() => {
                          setUsernames((old) => [...old.filter((name) => name !== username)]);
                        }}
                      />
                    </Stack>
                  );
                })}
              </Stack>
              <OutlinedInput
                value={searchString}
                error={createBtnClicked && !usernames.length}
                placeholder='Type username here'
                onPaste={(e) => {
                  const rawText = e.clipboardData.getData('Text').replaceAll('@', '');
                  const usernames = Array.from(
                    new Set(rawText.split(/[,\n]+/).map((username) => username.trim().toLowerCase())),
                  );
                  setSearchString('');
                  e.preventDefault();

                  if (!usernames.length) return;
                  addUsernames(usernames);
                }}
                onKeyDown={(e) => {
                  if (e.key !== 'Enter' && e.key !== ',' && e.key !== ' ') return;
                  const username = searchString.replaceAll('@', '').trim();
                  setSearchString('');
                  e.preventDefault();

                  if (!username.length) return;
                  addUsernames([username]);
                }}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
              />
              <Stack spacing={0.5}>
                <Stack direction='row' spacing={1}>
                  <FileUploadOutlined fontSize='small' />
                  <Typography>{usernames.length} influencers have been entered</Typography>
                </Stack>
                <Stack direction='row' spacing={1}>
                  {usernames.length >= MAX_USER_NUM ? (
                    <Volcano color='error' fontSize='small' />
                  ) : (
                    <Warning fontSize='small' />
                  )}
                  <Typography color={usernames.length >= MAX_USER_NUM ? 'error' : undefined}>
                    The limit is 1,000 influencers per upload
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
          {step === Step.SET_NAME ? (
            <Button
              variant='contained'
              onClick={() => {
                setNextBtnClicked(true);
                if (name) setStep(Step.INPUT_USERS);
              }}
            >
              Next
            </Button>
          ) : step === Step.INPUT_USERS ? (
            <Button variant='contained' disabled={creating} onClick={onBtnCreateClicked}>
              Create
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Dialog>
  );
};
