import { Button, IconButton, MenuItem, MenuList, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { CampaignLink, generateCampaignLinkUrl } from 'common/types/CampaignLink';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import {
  ContentCopyOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  FileDownloadOutlined,
  LinkOutlined,
  MoreVertOutlined,
} from '@mui/icons-material';
import { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

import classes from './CampaignLinkList.module.scss';
import copy from 'copy-to-clipboard';
import { datetimeFormatter } from 'common/formatters';
import { downloadAsCsv } from 'common/utils';
import { useMessage } from 'components/message/useMessage';

interface CampaignLinkListProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
  campaignLinks: CampaignLink[];
  onLinkViewClicked?: (campaignLink: CampaignLink) => void;
  onLinkDuplicateClicked?: (campaignLink: CampaignLink) => void;
  onLinkDeleteClicked?: (campaignLink: CampaignLink) => void;
}

export const CampaignLinkList: FC<CampaignLinkListProps> = ({
  loading,
  campaignLinks,
  onLinkViewClicked,
  onLinkDuplicateClicked,
  onLinkDeleteClicked,
  ...rest
}) => {
  const { showMessage } = useMessage();
  const [anchorElement, setAnchor] = useState<HTMLElement>();
  const [selected, setSelected] = useState<number | undefined>(undefined);

  useEffect(() => {
    setSelected(undefined);
  }, [campaignLinks]);

  const downloadCampaignLinks = useCallback(async (campaignLinks: CampaignLink[]) => {
    downloadAsCsv(
      campaignLinks.map((campaignLink) => ({
        id: campaignLink.id,
        name: campaignLink.name,
        channel: campaignLink.channel,
        campaign: campaignLink.campaign,
        source: campaignLink.source,
        medium: campaignLink.medium,
        original_url: campaignLink.original_url,
        term: campaignLink.term,
        content: campaignLink.content,
        generated_url: generateCampaignLinkUrl(campaignLink),
        created_at: campaignLink.created_at,
        updated_at: campaignLink.updated_at,
      })),
      'campaign_links.csv',
    );
  }, []);

  const columnDefs: CommonTableColumn<CampaignLink>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.col}>
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.col}>
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'channel',
      label: 'Channel',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.col}>
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'campaign',
      label: 'Campaign',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.col}>
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'source',
      label: 'Source / Medium',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Tooltip title={`${data?.source} / ${data?.medium}`} arrow placement='top'>
            <Stack className={classes.col}>
              <Typography variant='body1'>
                {data?.source} / {data?.medium}
              </Typography>
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      id: 'original_url',
      label: 'Original URL',
      sortable: true,
      render: (value) => {
        return (
          <Tooltip title={value} arrow placement='top'>
            <Typography variant='body1' className={classes.col}>
              {value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'created_at',
      label: 'Create Time',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.col}>
            {datetimeFormatter.format(value * 1000)}
          </Typography>
        );
      },
    },
    {
      id: 'id',
      label: 'Action',
      render: (value, idx, data) => {
        if (!data) return null;
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Tooltip title={'copy link'} placement='top' arrow>
              <Stack direction='row'>
                <Button
                  variant='contained'
                  onClick={() => {
                    copy(generateCampaignLinkUrl(data));
                    showMessage('Link is copied', 'success');
                  }}
                  startIcon={<LinkOutlined />}
                >
                  Copy
                </Button>
              </Stack>
            </Tooltip>
            <IconButton
              onClick={(event) => {
                setAnchor(event.currentTarget);
                setSelected(idx);
              }}
              className={classes.moreButton}
            >
              <MoreVertOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const tableHeader = useMemo(() => {
    return (
      <Stack className={classes.tableHeader}>
        <Button
          variant='outlined'
          startIcon={<FileDownloadOutlined />}
          disabled={!campaignLinks.length}
          onClick={() => downloadCampaignLinks(campaignLinks)}
        >
          <Typography variant='label1'>Download</Typography>
        </Button>
      </Stack>
    );
  }, [campaignLinks, downloadCampaignLinks]);

  return (
    <>
      <CommonTable
        {...rest}
        loading={loading}
        selected={selected}
        data={campaignLinks}
        columns={columnDefs}
        classes={classes}
        header={tableHeader}
      />
      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchor(undefined);
          setSelected(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            disabled={selected === undefined}
            onClick={() => {
              typeof selected === 'number' && onLinkViewClicked?.(campaignLinks[selected]);
              setAnchor(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <EditOutlined />
              <Typography>Edit</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={selected === undefined}
            onClick={() => {
              typeof selected === 'number' && onLinkDuplicateClicked?.(campaignLinks[selected]);
              setAnchor(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <ContentCopyOutlined />
              <Typography>Duplicate</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={selected === undefined}
            onClick={() => {
              typeof selected === 'number' && onLinkDeleteClicked?.(campaignLinks[selected]);
              setAnchor(undefined);
            }}
          >
            <Stack direction='row' spacing={1} color='error'>
              <DeleteOutlineOutlined color='error' />
              <Typography color='error'>Delete</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
