import { ComputedNodeWithoutStyles, ResponsiveTreeMap } from '@nivo/treemap';
import { FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  colorPrimary10,
  colorPrimary20,
  colorPrimary30,
  colorPrimary40,
  colorPrimary50,
  colorPrimary60,
  colorPrimary70,
  colorPrimary80,
  colorPrimary90,
  colorPrimary95,
} from 'common/params';

import { Chip } from '@nivo/tooltip';
import { Topic } from 'common/types/Extension/Topic';
import { TreeMapNode } from 'components/InfluencerMatcher/twitter/TreeMapNode';
import classes from './TopicTreeMap.module.scss';

export interface TreeMapData {
  name: string;
  rank?: number;
  score?: number;
  brief?: string;
  children?: TreeMapData[];
}

interface TopicTreeMapProps {
  topics: Topic[];
}

export const TopicTreeMap: FC<TopicTreeMapProps> = ({ topics }) => {
  const data = useMemo(() => {
    const d: TreeMapData = {
      name: 'topics',
      children: topics
        .sort((a, b) => b.score - a.score)
        .map((topic, idx) => ({
          name: topic.name,
          rank: (topics.length - idx) / topics.length,
          score: topic.score,
          brief: topic.brief,
        })),
    };
    return d;
  }, [topics]);

  const labelRenderer = (node: Omit<ComputedNodeWithoutStyles<TreeMapData>, 'label' | 'parentLabel'>) => {
    return node.data.name;
  };

  const colorSetter = (node: ComputedNodeWithoutStyles<TreeMapData>) => {
    if (node.isParent) return 'white';
    let color = 'white';
    if (node.value > 0.9) {
      color = colorPrimary10;
    } else if (node.value > 0.8) {
      color = colorPrimary20;
    } else if (node.value > 0.7) {
      color = colorPrimary30;
    } else if (node.value > 0.6) {
      color = colorPrimary40;
    } else if (node.value > 0.5) {
      color = colorPrimary50;
    } else if (node.value > 0.4) {
      color = colorPrimary60;
    } else if (node.value > 0.3) {
      color = colorPrimary70;
    } else if (node.value > 0.2) {
      color = colorPrimary80;
    } else if (node.value > 0.1) {
      color = colorPrimary90;
    } else {
      color = colorPrimary95;
    }
    return color;
  };

  return (
    <Stack style={{ width: '100%', maxHeight: '300px', aspectRatio: 2 }}>
      <ResponsiveTreeMap
        data={data}
        leavesOnly={true}
        identity='name'
        value='rank'
        valueFormat='.02s'
        label={labelRenderer}
        colors={colorSetter}
        nodeOpacity={1}
        labelSkipSize={40}
        innerPadding={4}
        labelTextColor={'white'}
        nodeComponent={(node) => <TreeMapNode {...node} />}
        tooltip={(data) => (
          <Stack className={classes.tooltip} spacing={1}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Chip color={data.node.color} />
              <Typography>{data.node.data.name}</Typography>
            </Stack>
            <Typography className={classes.brief}>{data.node.data.brief}</Typography>
          </Stack>
        )}
      />
    </Stack>
  );
};
