export enum LastDays {
  YESTERDAY = '1',
  LAST_7_DAYS = '7',
  LAST_15_DAYS = '15',
  LAST_30_DAYS = '30',
  LAST_60_DAYS = '60',
}

export const DefaultLastDaysData = LastDays.YESTERDAY;

export const LastDaysNameMap = new Map<string, string>([
  [LastDays.YESTERDAY, 'Yesterday'],
  [LastDays.LAST_7_DAYS, 'Last 7 days'],
  [LastDays.LAST_15_DAYS, 'Last 15 days'],
  [LastDays.LAST_30_DAYS, 'Last 30 days'],
  [LastDays.LAST_60_DAYS, 'Last 60 days'],
]);

export const lastDaysToString = (lastDays: LastDays) => {
  switch (lastDays) {
    case LastDays.YESTERDAY:
      return `Last 1 day`;
    case LastDays.LAST_7_DAYS:
      return `Last 7 days`;
    case LastDays.LAST_15_DAYS:
      return `Last 15 days`;
    case LastDays.LAST_30_DAYS:
      return `Last 30 days`;
    case LastDays.LAST_60_DAYS:
      return `Last 60 days`;
    default:
      // unsupported type -> throw error to remind user/develoepr
      throw Error('Unsupported date range data');
  }
};
