import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { FC, useMemo, useState } from 'react';
import { IconButton, MenuItem, Tooltip as MuiTooltip, Select, Stack, Typography } from '@mui/material';
import { MoreVertOutlined, RefreshOutlined, TrendingUpOutlined } from '@mui/icons-material';

import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { GhostIcon } from 'components/icons/GhostIcon';
import { Line } from 'react-chartjs-2';
import { Spinner } from 'components/common/Spinner';
import classNames from 'classnames';
import classes from './WalletTrendChart.module.scss';
import { numberFormatter } from 'common/formatters';
import { numberToSecondFraction } from 'common/utils';
import { useAsync } from 'react-use';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface WalletTrendChartProps {
  address: string;
}

const ChainOptions = ['ethereum'];
const DaysOptions = [30, 60, 180, 365];

export const WalletTrendChart: FC<WalletTrendChartProps> = ({ address }) => {
  const [refetch, setRefetch] = useState<boolean>(false);
  const [selectedChain, setSelectedChain] = useState<string>(ChainOptions[0]);
  const [selectedDays, setSelectedDays] = useState<number>(DaysOptions[0]);
  const {
    loading,
    error,
    value: metrics,
  } = useAsync(async () => {
    return (await ClientAPI.getWalletHistoryAsset(address, selectedChain, selectedDays)).data;
  }, [selectedChain, selectedDays, refetch]);

  const { labels, data } = useMemo(() => {
    const labels = (metrics || []).map((m) => m.label);
    const data = (metrics || []).map((m) => m.value);
    return { labels, data };
  }, [metrics]);

  const dataSum = useMemo(() => {
    return data.reduce((prev, curr) => prev + curr, 0);
  }, [data]);

  const dataAvg = useMemo(() => {
    return dataSum / selectedDays;
  }, [dataSum, selectedDays]);

  const chartContent = useMemo(() => {
    if (loading) return <Spinner />;
    if (error)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <RefreshOutlined className={classes.refresh} onClick={() => setRefetch((old) => !old)} />
          <Typography>Refresh to load the chart</Typography>
        </Stack>
      );
    if (!data.length)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <GhostIcon />
          <Typography>No assets found for this wallet</Typography>
        </Stack>
      );
    return (
      <Line
        width='100%'
        height='100%'
        data={{
          labels: labels,
          datasets: [
            {
              label: '1',
              data: data,
              backgroundColor: '#1976d2',
              borderColor: '#1976d2',
              borderWidth: 1,
              hoverBackgroundColor: '#3f75a8',
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              displayColors: false,
              bodyFont: { weight: 'bold' },
              callbacks: {
                label: (context) => {
                  let label = context.dataset.label || '';

                  if (context.parsed.y !== null) {
                    return `$${numberFormatter.format(context.parsed.y)}`;
                  }
                  return label;
                },
              },
            },
          },
        }}
      />
    );
  }, [labels, data, loading, error]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classes.title} spacing={3}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={3} alignItems='center'>
            <CommonChip>
              <TrendingUpOutlined fontSize='large' />
            </CommonChip>
            <Stack>
              <Typography variant='h6'>Historical Asset Value Trend</Typography>
              <Typography>
                This line chart shows the daily USD value of total assets over specified intervals.
              </Typography>
            </Stack>
          </Stack>
          <IconButton>
            <MoreVertOutlined />
          </IconButton>
        </Stack>
        <Stack direction='row' justifyContent='space-between' spacing={1}>
          <Select
            value={selectedChain}
            renderValue={(chain) => {
              return <Typography>{`${chain.substring(0, 1).toUpperCase()}${chain.substring(1)}`}</Typography>;
            }}
            sx={{
              width: '180px',
              height: '48px',
            }}
            onChange={(e) => setSelectedChain(e.target.value)}
          >
            {ChainOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {`${option.substring(0, 1).toUpperCase()}${option.substring(1)}`}
              </MenuItem>
            ))}
          </Select>
          <Stack direction='row' alignItems='center'>
            {DaysOptions.map((option, idx) => (
              <Stack
                key={option}
                className={classNames(
                  classes.option,
                  selectedDays === option && classes.selected,
                  idx === 0 && classes.first,
                  idx === DaysOptions.length - 1 && classes.last,
                )}
                onClick={() => setSelectedDays(option)}
              >
                {option} D
              </Stack>
            ))}
          </Stack>
        </Stack>
        {loading || error ? null : (
          <Stack direction='row' spacing={1}>
            <Stack className={classes.chip}>
              <MuiTooltip arrow title={`$${numberToSecondFraction(dataSum)} USD`}>
                <Typography variant='label1' className={classes.text}>
                  Total: ${numberToSecondFraction(dataSum)} USD
                </Typography>
              </MuiTooltip>
            </Stack>
            <Stack className={classes.chip}>
              <MuiTooltip arrow title={`$${numberToSecondFraction(dataAvg)} USD`}>
                <Typography variant='label1' className={classes.text}>
                  Average: ${numberToSecondFraction(dataAvg)} USD
                </Typography>
              </MuiTooltip>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack className={classes.chart} alignItems='center' justifyContent='center'>
        {chartContent}
      </Stack>
    </Stack>
  );
};
