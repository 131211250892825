import { Button, FormControl, FormHelperText, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Audience } from 'common/types/Audience';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { Spinner } from 'components/common/Spinner';
import { UploadOutlined } from '@mui/icons-material';
import classes from './CreateTwitterAudience.module.scss';
import { debounce } from '@mui/material/utils';
import { useMessage } from 'components/message/useMessage';

interface CreateTwitterAudienceProps {
  audience: Audience;
  accountId: string;
  onBtnCancelClicked?: () => void;
  onAudiencePublished?: (audienceName: string, audienceDescription?: string) => void;
}

export const CreateTwitterAudience: FC<CreateTwitterAudienceProps> = ({
  audience,
  accountId,
  onBtnCancelClicked,
  onAudiencePublished,
}) => {
  const { showMessage } = useMessage();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [nameAvailable, setNameAvailable] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);
  const [publishing, setPublishing] = useState<boolean>(false);

  const checkAudienceNameAvailable = useMemo(
    () =>
      debounce(async (accountId: string, name: string, callback: (available?: boolean) => void) => {
        setChecking(true);
        await ClientAPI.checkAudienceNameAvailable(accountId, name)
          .then(({ data, message }) => {
            if (data === undefined) {
              showMessage(`Check audience name failed, ${message}`, 'error');
            }
            callback(data);
          })
          .catch((error) => {
            showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
          })
          .finally(() => {
            setChecking(false);
          });
      }, 500),
    [showMessage],
  );

  useEffect(() => {
    if (!name) return;
    let active = true;

    checkAudienceNameAvailable(accountId, name, (available) => {
      if (!active) return;
      setNameAvailable(!!available);
    });

    return () => {
      active = false;
    };
  }, [accountId, name, checkAudienceNameAvailable]);

  const onBtnPublishClicked = useCallback(async () => {
    setClicked(true);
    if (typeof audience.id !== 'number') return;
    if (!name) return;
    setPublishing(true);

    await ClientAPI.publishAudiences(audience.id, accountId, name, description)
      .then(({ data }) => {
        if (!data) {
          showMessage('publish failed');
          return;
        }
        onAudiencePublished?.(name, description);
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setPublishing(false);
      });
  }, [audience, accountId, name, description, showMessage, onAudiencePublished]);

  const nameValid = useMemo(() => {
    if (!clicked) return true;
    if (!name) return false;
    return nameAvailable;
  }, [clicked, name, nameAvailable]);

  const nameHelperText = useMemo(() => {
    if (!clicked) return <></>;
    if (!name) return <FormHelperText error>Audience name is required.</FormHelperText>;
    if (!nameAvailable)
      return (
        <FormHelperText error>An audience with this name already exists. Please use a different name.</FormHelperText>
      );
    return <></>;
  }, [clicked, name, nameAvailable]);

  return (
    <Stack className={classes.root} spacing={1}>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <UploadOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            <Typography variant='h6'>Save and publish</Typography>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Stack>
            <FormControl>
              <InputLabel error={!nameValid}>Audience name</InputLabel>
              <OutlinedInput
                size='medium'
                label='Audience name'
                error={!nameValid}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            {nameHelperText}
          </Stack>
          <Stack>
            <FormControl>
              <InputLabel>Description (Optional)</InputLabel>
              <OutlinedInput
                size='medium'
                label='Description (Optional)'
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row-reverse' spacing={1} className={classes.actions}>
        <Button
          variant='contained'
          startIcon={publishing ? <Spinner size={16} /> : null}
          disabled={checking || publishing || !nameValid}
          onClick={() => onBtnPublishClicked()}
        >
          Publish
        </Button>
        <Button variant='outlined' onClick={onBtnCancelClicked}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
