export interface DefiProject {
  chain: string;
  name: string;
  sector: string;
  actions: string[];
}

export interface TargetDefiProject {
  name: string;
  action: string;
}

export const DefaultTargetDefiProject: TargetDefiProject = {
  name: 'uniswap - V3',
  action: 'transfer',
};

export const targetDefiProjectToString = (project: TargetDefiProject) => {
  return `${project.name} ${project.action} `;
};
