import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { colorPrimary10, colorPrimary40, colorSurface1 } from 'common/params';

import { Audience } from 'common/types/Audience';
import { CommonChip } from 'components/chip/CommonChip';
import { Uris } from 'Uris';
import classes from './CohortDeleteFailedDialog.module.scss';

interface CohortDeleteFailedDialogProps extends DialogProps {
  audiences: Audience[];
  onCancel?: () => void;
}

export const CohortDeleteFailedDialog: FC<CohortDeleteFailedDialogProps> = ({
  open,
  audiences,
  onCancel,
  ...props
}) => {
  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <DeleteOutlineOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Delete Cohort
              </Typography>
              <Typography variant='body1' textAlign='center' color={colorPrimary10}>
                To maintain data consistency, please delete the bound ad audiences before removing any cohort.
              </Typography>
              <Stack direction='row' spacing={1} className={classes.audiences} justifyContent='center'>
                {audiences.map((audience) => (
                  <Typography
                    key={audience.id}
                    className={classes.audience}
                    variant='subtitle1'
                    onClick={() =>
                      window.open(
                        Uris.Pages.AdsAudience.AudienceDetail.replace(':audienceId', audience.id?.toString() || ''),
                        '_blank',
                      )
                    }
                  >
                    {audience.name}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
