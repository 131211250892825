import { Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { TwitterAdsAccount, TwitterAuth } from 'common/types/TwitterAds';

import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { HumanIcon } from 'components/icons/HumanIcon';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './SetDefaultAccount.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

interface SetDefaultAccountProps {
  auth: TwitterAuth;
  onBtnBackClicked?: () => void;
  onBtnNextClicked?: (account: TwitterAdsAccount) => void;
}

export const SetDefaultAccount: FC<SetDefaultAccountProps> = ({ auth, onBtnBackClicked, onBtnNextClicked }) => {
  const { showMessage } = useMessage();
  const [selectedAccountId, setSelectedAccountId] = useState<string>('');

  const {
    loading: accountsLoading,
    error: accountsError,
    value: accounts,
  } = useAsync(async () => {
    return (await ClientAPI.getTwitterAdsAccountsWithAuthId(auth.id)).data;
  }, []);

  const { error: defaultAccountError, value: defaultAccount } = useAsync(async () => {
    return (await ClientAPI.getDefaultTwitterAdsAccount()).data;
  }, []);

  useEffect(() => {
    accountsError && showMessage(accountsError?.message || 'Unknown Error', 'error');
  }, [accountsError, showMessage]);

  useEffect(() => {
    defaultAccountError && showMessage(defaultAccountError?.message || 'Unknown Error', 'error');
  }, [defaultAccountError, showMessage]);

  useEffect(() => {
    setSelectedAccountId(defaultAccount?.id || '');
  }, [defaultAccount]);

  const content = useMemo(() => {
    if (accountsLoading || accounts?.length)
      return (
        <>
          <Stack spacing={3} className={classes.content}>
            <Stack className={classes.title} alignItems='center' spacing={2}>
              <CommonChip>
                <HumanIcon fontSize='large' />
              </CommonChip>
              <Stack spacing={1}>
                <Typography variant='h6'>Choose the ad account you want to create a campaign with</Typography>
                <Typography variant='body1'>{auth.name} is connected to the following ad accounts</Typography>
              </Stack>
            </Stack>
            {accountsLoading ? (
              <Spinner />
            ) : (
              <FormControl className={classes.items}>
                <RadioGroup value={selectedAccountId} onChange={(e) => setSelectedAccountId(e.target.value)}>
                  {accounts?.map((account, idx) => (
                    <Stack key={idx}>
                      {idx > 0 ? <Divider /> : null}
                      <FormControlLabel
                        labelPlacement='start'
                        value={account.id}
                        control={<Radio />}
                        sx={{
                          marginLeft: '0px',
                          '.MuiFormControlLabel-label': {
                            flex: '1 1',
                          },
                        }}
                        className={classNames(classes.item, selectedAccountId === account.id && classes.selected)}
                        label={
                          <Stack direction='row' alignItems='center' spacing={2} className={classes.option}>
                            <HumanIcon fontSize='large' />
                            <Stack>
                              <Typography textAlign='left' className={classes.text}>
                                {account.name.trim()}
                              </Typography>
                              <Typography textAlign='left' variant='label1'>
                                ID: {account.id}
                              </Typography>
                            </Stack>
                          </Stack>
                        }
                      />
                    </Stack>
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </Stack>
          <Stack direction='row-reverse' spacing={1} className={classes.actions}>
            <Button
              variant='contained'
              disabled={!selectedAccountId}
              onClick={() => {
                const selectedAccount = accounts?.find((account) => account.id === selectedAccountId);
                selectedAccount && onBtnNextClicked?.(selectedAccount);
              }}
            >
              Next
            </Button>
            <Button variant='outlined' onClick={onBtnBackClicked}>
              Back
            </Button>
          </Stack>
        </>
      );

    return (
      <>
        <Stack spacing={3} className={classes.content}>
          <Stack className={classes.title} alignItems='center' spacing={2}>
            <CommonChip>
              <HumanIcon fontSize='large' />
            </CommonChip>
            <Stack spacing={1} className={classes.info}>
              <Typography variant='h6'>No ad accounts found under {auth.name}</Typography>
              <Typography variant='body1'>
                Before publishing your audience list to the ad group, make sure to:
              </Typography>
              <Typography variant='body1'>
                1. Create your Twitter Ads account
                <br />
                2. Set up the campaign
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row-reverse' spacing={1} className={classes.actions}>
          <Button variant='contained' onClick={() => window.open(Uris.External.TwitterAds, '_blank')}>
            Go to Twitter
          </Button>
          <Button variant='outlined' onClick={onBtnBackClicked}>
            Back
          </Button>
        </Stack>
      </>
    );
  }, [auth, accountsLoading, selectedAccountId, accounts, onBtnNextClicked, onBtnBackClicked]);

  return (
    <Stack className={classes.root} spacing={1}>
      {content}
    </Stack>
  );
};
