import { Button, Stack, Typography } from '@mui/material';

import { CommonChip } from 'components/chip/CommonChip';
import { DeleteIcon } from 'components/icons/DeleteIcon';
import { FC } from 'react';
import { Uris } from 'Uris';
import classes from './EmptyAuth.module.scss';

interface EmptyAuthProps {
  onBtnCancelClicked?: () => void;
}

export const EmptyAuth: FC<EmptyAuthProps> = ({ onBtnCancelClicked }) => {
  return (
    <Stack className={classes.root}>
      <Stack spacing={3} className={classes.content} alignItems='center'>
        <CommonChip>
          <DeleteIcon fontSize='large' />
        </CommonChip>
        <Stack spacing={1} className={classes.info}>
          <Typography variant='h6'>No Twitter AD account found</Typography>
          <Typography variant='body1'>
          To unlock our AD Audience feature, simply connect your Twitter account with us.
          </Typography>
              <Typography variant='body1'>
                1. Create your Twitter Ads account
                <br />
                2. Set up the campaign
              </Typography>
        </Stack>
      </Stack>
      <Stack direction='row' className={classes.actions} justifyContent='center' spacing={1}>
        <Button variant='outlined' onClick={onBtnCancelClicked}>
          Cancel
        </Button>
        <Button variant='contained' onClick={() => window.open(Uris.External.TwitterAds, '_blank')}>
            Go to Twitter
          </Button>
      </Stack>
    </Stack>
  );
};
