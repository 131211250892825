import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js';
import { FC, useMemo, useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { MoreVertOutlined, RefreshOutlined } from '@mui/icons-material';

import { BabyIcon } from 'components/icons/BabyIcon';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { GhostIcon } from 'components/icons/GhostIcon';
import { Pie } from 'react-chartjs-2';
import { Spinner } from 'components/common/Spinner';
import classes from './CohortWalletRegisterDaysChart.module.scss';
import { pieChartColors } from 'common/params';
import { useAsync } from 'react-use';

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

interface CohortWalletRegisterDaysChartProps {
  cohortId: number;
}

export const CohortWalletRegisterDaysChart: FC<CohortWalletRegisterDaysChartProps> = ({ cohortId }) => {
  const [refetch, setRefetch] = useState<boolean>(false);
  const {
    loading,
    error,
    value: metrics,
  } = useAsync(async () => {
    return (await ClientAPI.getCohortAddressMetrics(cohortId, 'registration_time')).data;
  }, [refetch]);

  const { labels, data } = useMemo(() => {
    const labels = (metrics || []).map((m) => m.label);
    const data = (metrics || []).map((m) => m.value);
    return { labels, data };
  }, [metrics]);

  const chartContent = useMemo(() => {
    if (loading) return <Spinner />;
    if (error)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <RefreshOutlined className={classes.refresh} onClick={() => setRefetch((old) => !old)} />
          <Typography>Refresh to load the chart</Typography>
        </Stack>
      );
    if (!data.length)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <GhostIcon />
          <Typography>Update cohort to load the chart</Typography>
        </Stack>
      );
    return (
      <Pie
        width='100%'
        height='100%'
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: pieChartColors,
              borderWidth: 1,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Wallet Age',
            },
            legend: {
              position: 'right',
            },
            datalabels: {
              align: 'start',
              anchor: 'end',
              formatter: (value) => {
                if (!value) return '';
                return value;
              },
              font: {
                size: 8,
              },
            },
          },
        }}
      />
    );
  }, [labels, data, loading, error]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack direction='row' className={classes.title} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={3} alignItems='center'>
          <CommonChip>
            <BabyIcon fontSize='large' />
          </CommonChip>
          <Stack>
            <Typography variant='h6'>Wallet Age</Typography>
            <Typography>The average creation days of wallets in the blockchain network.</Typography>
          </Stack>
        </Stack>
        <IconButton>
          <MoreVertOutlined />
        </IconButton>
      </Stack>
      <Stack className={classes.chart} alignItems='center' justifyContent='center'>
        {chartContent}
      </Stack>
    </Stack>
  );
};
