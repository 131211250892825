/**
 * Dimension of the SVG canvas. Please note that this is independent to the actual size of the SVG image.
 */
export const DIMENSION = 200;
export const HALF_DIMENSION = DIMENSION / 2;

/**
 * The stoken width of the bar.
 */
export const BAR_WIDTH = 20;
/**
 * The angle span of the bar component, which is equal to 3 / 4 of a full circle.
 */
export const BAR_ANGLE_DEG = 270;

/**
 * The line length of the each tick line.
 */
export const TICK_LENGTH = 3;
/**
 * Paddings between the tick and bar component.
 */
export const TICK_BAR_PADDING = 4;
/**
 * Total numbers of each tick. This separates the guage into 20 chunks with each chunk covers 5%.
 */
export const TICK_COUNT = 21;
/**
 * The color of the tick line.
 */
export const TICK_COLOR = '#5c6c81';

/**
 * Starting angle of the guage bar (from -135deg to 135deg).
 */
export const START_ANGLE_DEG = -135;

/**
 * The size of the arrow component.
 */
export const ARROW_SIZE = 16;
/**
 * Padding between bar and arrow component.
 */
export const ARROW_BAR_PADDING = 4;
