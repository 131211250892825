import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  LabelDisplayedRowsArgs,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
import { Spinner } from 'components/common/Spinner';
import { get } from 'lodash';

// common params
export type Order = 'asc' | 'desc';
const DEFAULT_PAGE = 0;
const ROWS_PER_PAGE_OPTIONS = [10, 20, 50];
const DEFAULT_ROW_VALUE = 10;

export const DefaultPaginationOptions: PaginationOptions = {
  page: DEFAULT_PAGE,
  rowsPerPage: DEFAULT_ROW_VALUE,
};

export const DefaultSortOptions: SortOptions = {
  order: 'asc',
  orderBy: '',
};

export interface SortOptions {
  order: Order;
  orderBy: string;
}

export interface PaginationOptions {
  page: number;
  rowsPerPage: number;
}

export interface CommonTableColumn<T> {
  id: string;
  label: string;
  textAlign?: 'left' | 'right';
  sortable?: boolean;
  tooltip?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  render?: (value: any, idx?: number, data?: T) => ReactNode;
  valueGetter?: (data?: T) => any;
}

interface CommonTableProps<T> extends TableContainerProps {
  data: T[];
  totalDataCnt?: number;
  columns: CommonTableColumn<T>[];
  pageNum?: number;
  rowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
  classes?: {
    readonly [key: string]: string;
  };
  header?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
  selected?: number;
  dataPagination?: boolean;
  dataFooter?: ReactNode;
  pageNumLimit?: number;
  labelDisplayedRows?: (paginationInfo: LabelDisplayedRowsArgs) => React.ReactNode;
  onPageChange?: (paginationOptions: PaginationOptions) => void;
  onSortChange?: (sortOptions: SortOptions) => void;
  onRowClicked?: (data: T) => void;
  onShowDataChange?: (data: T[]) => void;
}

export const getColumnValue = (c: any, columnId: string) => {
  const keys = columnId.split('.');
  let v = c;
  keys.forEach((key) => {
    v = typeof v === 'object' ? get(v, key) : undefined;
  });
  return v;
};

export const getSortedData = (data: any[], columns: CommonTableColumn<any>[], sort: SortOptions) => {
  if (sort.orderBy && columns.find((column) => column.id === sort.orderBy)?.sortable) {
    return [...data].sort((a, b) => {
      const column = columns.find((column) => column.id === sort.orderBy);
      if (column === undefined) return 0;
      const valueA = column.valueGetter ? column.valueGetter(a) : getColumnValue(a, column.id);
      const valueB = column.valueGetter ? column.valueGetter(b) : getColumnValue(b, column.id);
      // use natural sort for both string type
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        const num = valueA.localeCompare(valueB, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
        return sort.order === 'asc' ? num : -num;
      }
      if (valueA < valueB) {
        return sort.order === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sort.order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }
  return data;
};

export const getPaginatedData = (data: any[], pagination: PaginationOptions) => {
  return data.slice(pagination.page * pagination.rowsPerPage, (pagination.page + 1) * pagination.rowsPerPage);
};

export const CommonTable: FC<CommonTableProps<any>> = ({
  data,
  totalDataCnt,
  columns,
  pageNum = DEFAULT_PAGE,
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  defaultRowsPerPage,
  classes,
  loading,
  selected,
  dataPagination,
  header,
  footer,
  dataFooter,
  pageNumLimit,
  labelDisplayedRows,
  onPageChange,
  onSortChange,
  onRowClicked,
  onShowDataChange,
  ...props
}) => {
  const [sort, setSort] = useState<SortOptions>(DefaultSortOptions);
  const [pagination, setPagination] = useState<PaginationOptions>({
    page: pageNum,
    rowsPerPage: defaultRowsPerPage || DEFAULT_ROW_VALUE,
  });

  const [cacheData, setCacheData] = useState<any[]>([]);
  const [pageChanging, setPageChanging] = useState<boolean>(false);
  const columnsRef = useRef(columns);

  useEffect(() => {
    columnsRef.current = columns;
  }, [columns]);

  useEffect(() => {
    setPagination((old) => ({ ...old, page: pageNum }));
  }, [pageNum]);

  useEffect(() => {
    if (!dataPagination || loading) return;
    // once loading finish and dataPagination is enabled
    // clear pageChanging state
    setPageChanging(false);
  }, [dataPagination, loading]);

  useEffect(() => {
    // due to page state is inside this component
    // if user change page, we need to cache previous data
    if (loading || pageChanging) return;
    const baseIdx = dataPagination ? pagination.page * pagination.rowsPerPage : 0;
    setCacheData(data.map((d, idx) => ({ ...d, idx: baseIdx + idx + 1 })));
  }, [loading, pageChanging, dataPagination, pagination, data]);

  const handleSort = useCallback(
    (columnId: string) => {
      setSort((old) => {
        const sort = { ...old };
        if (old.orderBy === columnId) {
          sort.order = old.order === 'asc' ? 'desc' : 'asc';
        } else {
          sort.order = 'asc';
          sort.orderBy = columnId;
        }
        onSortChange?.(sort);
        return sort;
      });
    },
    [onSortChange],
  );

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (typeof pageNumLimit === 'number' && newPage > pageNumLimit) return;
    setPagination((old) => {
      const newPagination = { ...old };
      newPagination.page = newPage;
      onPageChange?.(newPagination);
      return newPagination;
    });
    dataPagination && setPageChanging(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination((old) => {
      const newPagination = { ...old };
      newPagination.rowsPerPage = parseInt(event.target.value, 10);
      onPageChange?.(newPagination);
      return newPagination;
    });
    dataPagination && setPageChanging(true);
  };

  const finalData = useMemo(() => {
    const sortedData = getSortedData(cacheData, columnsRef.current, sort);
    if (dataPagination) return sortedData;
    const paginatedData = getPaginatedData(sortedData, pagination);
    return paginatedData;
  }, [dataPagination, sort, pagination, cacheData]);

  useEffect(() => {
    // whenever show data changed -> inform parent component
    onShowDataChange?.(finalData);
  }, [finalData, onShowDataChange]);

  return (
    <TableContainer {...props} classes={classes?.container} style={{ position: 'relative' }}>
      {header ? header : null}
      <div style={{ width: '100%', overflow: 'auto', position: 'relative' }}>
        <Table className={classes?.table}>
          <TableHead className={classes?.header}>
            <TableRow className={classes?.row} style={{ position: 'relative' }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={classes?.cell}
                  style={{
                    textAlign: column.textAlign || 'inherit',
                    flexDirection: column.textAlign === 'right' ? 'row-reverse' : 'row',
                  }}
                >
                  <Stack direction='row' alignItems='center' spacing={0.5}>
                    {column.startAdornment ? column.startAdornment : null}
                    {column.sortable ? (
                      <TableSortLabel
                        active={sort.orderBy === column.id}
                        direction={sort.order}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                        {column.tooltip ? (
                          <Tooltip title={column.tooltip} placement='top'>
                            <InfoOutlined fontSize='small' style={{ marginLeft: '4px' }} />
                          </Tooltip>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      <>
                        {column.label}
                        {column.tooltip ? (
                          <Tooltip title={column.tooltip} placement='top'>
                            <InfoOutlined fontSize='small' style={{ marginLeft: '4px' }} />
                          </Tooltip>
                        ) : null}
                      </>
                    )}
                    {column.endAdornment ? column.endAdornment : null}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes?.body}>
            {finalData.map((row, index) => (
              <TableRow
                key={index}
                className={classes?.row}
                selected={selected === index}
                onClick={() => onRowClicked?.(row)}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className={classes?.cell}
                    style={{ textAlign: column.textAlign || 'inherit' }}
                  >
                    {column.render
                      ? column.render(
                          column.valueGetter ? column.valueGetter(row) : getColumnValue(row, column.id),
                          index,
                          row,
                        )
                      : column.valueGetter
                      ? column.valueGetter(row)
                      : getColumnValue(row, column.id)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {dataFooter ? (
          <Stack
            style={{
              position: 'absolute',
              bottom: '0px',
              height: '100%',
              width: '100%',
              justifyContent: 'flex-end',
              pointerEvents: 'none',
            }}
          >
            {dataFooter}
          </Stack>
        ) : null}
      </div>
      {/* black area to cover data once in loading state */}
      {loading ? (
        <Stack
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            opacity: '0.3',
          }}
        >
          <Spinner />
        </Stack>
      ) : null}
      <TablePagination
        rowsPerPage={pagination.rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        count={totalDataCnt || data.length}
        page={pagination.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes?.pagination}
        labelDisplayedRows={labelDisplayedRows}
      />
      {footer ? footer : null}
    </TableContainer>
  );
};
