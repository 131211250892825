import {
  AddCircleOutlineOutlined,
  CheckOutlined,
  CopyAllOutlined,
  FormatListBulletedOutlined,
} from '@mui/icons-material';
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { Collection, CollectionTwitterUser } from 'common/types/Extension/Collection';
import { FC, useCallback, useContext, useState } from 'react';

import { CollectionCreateLimitDialog } from 'components/FeatureLimit/CollectionCreateLimitDialog';
import { CollectionUserLimitDialog } from 'components/FeatureLimit/CollectionUserLimitDialog';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { DataContext } from 'contexts/DataContext';
import ExtensionAPI from 'common/ExtensionAPI';
import { Spinner } from 'components/common/Spinner';
import classNames from 'classnames';
import classes from './CopyUserToCollectionDialog.module.scss';
import { dedupArray } from 'common/utils';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';
import { useUserFeatureLimits } from 'common/hooks/useUserFeatureLimits';

interface CopyUserToCollectionDialogProps {
  source?: string;
  collection: Collection;
  collections: Collection[];
  onSuccess?: (collectionId?: number) => void;
  onCancel?: () => void;
}

export const CopyUserToCollectionDialog: FC<CopyUserToCollectionDialogProps> = ({
  source,
  collection,
  collections,
  onSuccess,
  onCancel,
}) => {
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const [success, setSuccess] = useState<boolean>(false);
  const [isNewCollection, setIsNewCollection] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] = useState<Collection | undefined>(undefined);
  const [newCollection, setNewCollection] = useState<Collection | undefined>(
    collections.length === 0 ? { id: 0, name: '', twitter_users: [] } : undefined,
  );
  const [adding, setAdding] = useState<boolean>(false);

  const [collectionCreateLimitDialogOpened, setCollectionCreateLimitDialogOpened] = useState<boolean>(false);
  const [collectionUsersLimitDialogOpened, setCollectionUsersLimitDialogOpened] = useState<boolean>(false);

  const { value: createCollectionLimit } = useUserFeatureLimits('collection', 'create');
  const { value: collectionUsersLimit } = useUserFeatureLimits('collection', 'update');

  const addUserToCollection = useCallback(
    async (collection: Collection, twitterUsers: CollectionTwitterUser[]) => {
      setAdding(true);
      await ExtensionAPI.addUsersToCollection(
        collection,
        twitterUsers.map((twitterUser) => twitterUser.id),
      )
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            setSuccess(true);
            if (collection.id === 0) {
              track('click', {
                sub_event: 'list_created',
                custom_props: { entry_point: source, list_id: data.id, list_name: collection.name },
              });
              setNewCollection((old) => (old ? { ...old, id: data.id } : old));
              setIsNewCollection(true);
            }
            track('click', {
              sub_event: 'influencer_added',
              custom_props: {
                entry_point: source,
                list_id: data.id,
                influencer_ids: collection.twitter_users.map((twitterUser) => twitterUser.id),
                influencer_names: collection.twitter_users.map((twitterUser) => twitterUser.username),
              },
            });
            return;
          }
          showMessage('Add user to collection failed', 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Add user to collection failed', 'error');
        })
        .finally(() => {
          setAdding(false);
        });
    },
    [source, track, showMessage],
  );

  if (success)
    return (
      <CommonDialog
        open={true}
        mainContent={
          <Stack spacing={2} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <CheckOutlined fontSize='large' />
            </CommonChip>
            <Stack direction='row'>
              {isNewCollection ? (
                <Typography variant='h6' className={classes.title}>
                  <span className={classes.highlight}>"{newCollection?.name}"</span> has been created successfully
                </Typography>
              ) : (
                <Typography variant='h6' className={classes.title}>
                  <span className={classes.highlight}>"{collection.name}"</span> influencers are copied to{' '}
                  <span className={classes.highlight}>"{selectedCollection?.name || newCollection?.name}"</span>
                </Typography>
              )}
            </Stack>
          </Stack>
        }
        footer={
          <Stack direction='row' justifyContent='center' spacing={1}>
            <Button id='btn-create-collection-success-close' variant='outlined' onClick={() => onSuccess?.()}>
              Close
            </Button>
            <Button
              id='btn-create-collection-success-view-list'
              variant='contained'
              onClick={() => {
                onSuccess?.(selectedCollection?.id || newCollection?.id);
              }}
            >
              View List
            </Button>
          </Stack>
        }
        onDialogClose={onCancel}
      />
    );

  if (newCollection)
    return (
      <CommonDialog
        open={true}
        mainContent={
          <Stack spacing={4} alignItems='center' className={classes.dialog}>
            <Stack alignItems='center' textAlign='center' spacing={2}>
              <CommonChip>
                {collection.name ? (
                  <CopyAllOutlined fontSize='large' />
                ) : (
                  <FormatListBulletedOutlined fontSize='large' />
                )}
              </CommonChip>
              <Typography variant='h6' className={classes.title}>
                Copy the list as a new list
              </Typography>
            </Stack>
            <FormControl className={classes.input}>
              <InputLabel>Name of the list</InputLabel>
              <OutlinedInput
                size='medium'
                label='Name of the list'
                placeholder='Enter name of the list, eg. DeFi analysis'
                value={newCollection.name || ''}
                onChange={(e) => {
                  setNewCollection((old) =>
                    old ? { ...old, name: e.target.value } : { id: 0, name: '', twitter_users: [] },
                  );
                }}
              />
            </FormControl>
          </Stack>
        }
        footer={
          <Stack direction='row-reverse' spacing={1}>
            <Button
              variant='contained'
              startIcon={adding ? <Spinner size={24} /> : null}
              disabled={adding}
              onClick={() => {
                if (!newCollection) return;
                addUserToCollection(newCollection, collection.twitter_users);
              }}
            >
              Create
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                onCancel?.();
              }}
            >
              Cancel
            </Button>
          </Stack>
        }
        onDialogClose={onCancel}
      />
    );

  return (
    <>
      <CommonDialog
        open={true}
        mainContent={
          <Stack spacing={2} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <CopyAllOutlined fontSize='large' />
            </CommonChip>
            <Stack alignItems='center' textAlign='center' spacing={1}>
              <Typography variant='h6' className={classes.title}>
                Copy the list
              </Typography>
              <Typography>Copy this entire list of results to your existing list or a new one</Typography>
            </Stack>
            <FormControl className={classes.input}>
              <InputLabel>Select a list</InputLabel>
              <Select
                value={selectedCollection?.id || ''}
                label='Select a list'
                renderValue={(collectionId) => {
                  return (
                    <Typography>{collections.find((collection) => collection.id === collectionId)?.name}</Typography>
                  );
                }}
                onChange={(e) => {
                  const selectedCollection = collections.find((collection) => collection.id === e.target.value);
                  const ids = dedupArray([
                    ...(selectedCollection?.twitter_users || []).map((user) => user.id),
                    ...collection.twitter_users.map((user) => user.id),
                  ]);
                  if (selectedCollection && ids.length > collectionUsersLimit && collectionUsersLimit >= 0) {
                    setCollectionUsersLimitDialogOpened(true);
                    return;
                  }
                  setSelectedCollection(collections.find((collection) => collection.id === e.target.value));
                }}
              >
                <MenuItem
                  divider
                  onClick={(event) => {
                    event.stopPropagation();
                    if ((collections?.length || 0) >= createCollectionLimit && createCollectionLimit >= 0) {
                      setCollectionCreateLimitDialogOpened(true);
                      return;
                    }
                    setNewCollection({
                      id: 0,
                      name: `Copy of ${collection.name}`,
                      twitter_users: [],
                    });
                  }}
                >
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={1}
                    className={classes.item}
                  >
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <AddCircleOutlineOutlined />
                      <Typography variant='body1'>New list</Typography>
                    </Stack>
                    <Typography variant='label2'>
                      <span
                        className={classNames(
                          classes.highlight,
                          collections.length >= createCollectionLimit && createCollectionLimit >= 0 && classes.error,
                        )}
                      >
                        {collections.length}
                      </span>
                      /{createCollectionLimit >= 0 ? createCollectionLimit : 'unlimit'} lists
                    </Typography>
                  </Stack>
                </MenuItem>
                {collections.length ? (
                  collections.map((collection, idx) => (
                    <MenuItem key={collection.id} divider={idx !== collections.length - 1} value={collection.id}>
                      <Typography variant='body1'>{collection.name}</Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    <Typography variant='body1'>Already in all lists</Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>
        }
        footer={
          <Stack direction='row-reverse' spacing={1}>
            <Button
              variant='contained'
              startIcon={adding ? <Spinner size={24} /> : null}
              disabled={adding}
              onClick={() => {
                if (!selectedCollection) return;
                addUserToCollection(selectedCollection, collection.twitter_users);
              }}
            >
              Save
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                onCancel?.();
              }}
            >
              Cancel
            </Button>
          </Stack>
        }
        onDialogClose={onCancel}
      />

      {collectionCreateLimitDialogOpened ? (
        <CollectionCreateLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionCreateLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionCreateLimitDialogOpened(false)}
        />
      ) : null}

      {collectionUsersLimitDialogOpened ? (
        <CollectionUserLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionUsersLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionUsersLimitDialogOpened(false)}
        />
      ) : null}
    </>
  );
};
