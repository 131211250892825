import { Button, Dialog, DialogProps, DialogTitle, Stack, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { Uris } from 'Uris';
import classes from './TutorialLandingDialog.module.scss';

interface TutorialLandingDialogProps extends DialogProps {
  onLandingCompleted?: () => void;
  onSkip?: () => void;
}

interface LandingContent {
  title: string;
  img?: string;
  text?: string;
  actionBtns?: {
    action: 'back' | 'next';
    text?: string;
    variant?: 'text' | 'contained' | 'outlined';
  }[];
}

const landingContents: LandingContent[] = [
  {
    title: 'We have gift for you!',
    img: Uris.Public.Image.Tutorial.LandingPage1,
    text: 'Unlock a $6,800 Growth Pack reward by completing our onboarding tasks in just 5 minutes!',
    actionBtns: [
      {
        text: 'Join Now',
        action: 'next',
      },
    ],
  },
  {
    title: '💰Task 1: Publish "Influencer Batch" on Twitter Ads',
    img: Uris.Public.Image.Tutorial.LandingPage2,
    text: 'Effortlessly Create Targeted Ad Lists',
    actionBtns: [
      {
        text: 'Back',
        variant: 'outlined',
        action: 'back',
      },
      {
        text: 'Next',
        action: 'next',
      },
    ],
  },
  {
    title: '💰Task2 : Connect with Influencers.',
    img: Uris.Public.Image.Tutorial.LandingPage3,
    text: 'Access a range of convenient services from selecting Influencers to executing bulk invitation send-outs',
    actionBtns: [
      {
        text: 'Back',
        variant: 'outlined',
        action: 'back',
      },
      {
        text: "Let's Go",
        action: 'next',
      },
    ],
  },
];

export const TutorialLandingDialog: FC<TutorialLandingDialogProps> = ({
  open,
  onSkip,
  onLandingCompleted,
  ...props
}) => {
  const [contentIdx, setContentIdx] = useState<number>(0);

  const percentage = useMemo(() => {
    return (contentIdx + 1) / landingContents.length;
  }, [contentIdx]);

  const currentContent = useMemo(() => {
    return landingContents[contentIdx];
  }, [contentIdx]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      PaperProps={{ sx: { backgroundColor: 'white', borderRadius: '8px' } }}
      {...props}
    >
      {typeof percentage === 'number' ? (
        <Stack className={classes.progressBar}>
          <Stack className={classes.progress} style={{ width: `${percentage * 100}%` }}></Stack>
        </Stack>
      ) : null}
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <Typography className={classes.skip} onClick={onSkip}>
            Skip
          </Typography>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            {currentContent.img ? <img alt='cover' className={classes.img} src={currentContent.img}></img> : null}
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center' color='#1c1b1f'>
                {currentContent.title}
              </Typography>
              <Typography variant='body1' textAlign='center' color='#49454f'>
                {currentContent.text}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          {currentContent.actionBtns?.map((actionBtn, idx) => (
            <Button
              key={`action-btn-${idx}`}
              variant={actionBtn.variant || 'contained'}
              onClick={() => {
                if (actionBtn.action === 'back') setContentIdx((old) => (old > 0 ? old - 1 : old));
                else if (contentIdx === landingContents.length - 1) {
                  onLandingCompleted?.();
                } else setContentIdx((old) => old + 1);
              }}
            >
              {actionBtn.text}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Dialog>
  );
};
