import { AccountsUser } from 'common/types/Accounts/AccountsUser';
import { Credit } from 'common/types/Accounts/Credit';
import { CreditPlan } from 'common/types/Accounts/CreditPlan';
import { CreditTransaction } from 'common/types/Accounts/CreditTransaction';
import { PaginationOptions } from 'components/table/CommonTable';
import { SubscriptionPlan } from 'common/types/Accounts/SubscriptionPlan';
import { Uris } from 'Uris';
import { get } from 'lodash';

interface ApiResponse<T> {
  status?: string;
  data?: T;
  message?: string;
  total_rows?: number;
  return_rows?: number;
}

class AccountsAPI {
  static async checkFetchResponse(res: Response): Promise<unknown | void> {
    let msg = '';
    try {
      const resJson = await res.json();
      const resJsonDetail = get(resJson, 'detail');
      if (resJsonDetail) msg = resJsonDetail;
      else {
        return resJson;
      }
    } catch (err) {
      if (err instanceof SyntaxError) {
        msg = 'Unexpected errors happened, please contact administrator.';
      }
    } finally {
      if (msg === '') {
        if (400 <= res.status && res.status < 500) {
          msg = `${res.status} Client Error: ${res.statusText} for url: ${res.url}`;
        } else if (500 <= res.status && res.status < 600) {
          // Server error
          msg = `${res.status} Server Error: ${res.statusText} for url: ${res.url}`;
        }
      }
    }

    if (msg !== '') {
      // send status code also for checking
      throw Error(msg, { cause: res.status });
    }
  }

  static extractResponse<T>(resJson: unknown): ApiResponse<T> {
    return {
      status: get(resJson, 'status', undefined),
      data: get(resJson, 'data', undefined),
      message: get(resJson, 'message', undefined),
      total_rows: get(resJson, 'total_rows', undefined),
      return_rows: get(resJson, 'return_rows', undefined),
    };
  }

  // return user profile
  public static async getUserProfile(): Promise<ApiResponse<AccountsUser>> {
    const url = `${Uris.AccountsApi.User.Profile}`;
    const res = await fetch(url, { method: 'GET', credentials: 'include', cache: 'no-cache' });
    const resJson = await AccountsAPI.checkFetchResponse(res);
    return AccountsAPI.extractResponse<AccountsUser>(resJson);
  }

  public static async getSubscriptionPlans() {
    const url = `${Uris.AccountsApi.SubscriptionPlan.GetSubscriptionPlans}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await AccountsAPI.checkFetchResponse(res);
    return AccountsAPI.extractResponse<SubscriptionPlan[]>(resJson);
  }

  public static async getCreditPlans() {
    const url = `${Uris.AccountsApi.CreditPlan.GetCreditPlans}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await AccountsAPI.checkFetchResponse(res);
    return AccountsAPI.extractResponse<CreditPlan[]>(resJson);
  }

  public static async createSubscriptionCheckout(plan: SubscriptionPlan, successUrl?: string, cancelUrl?: string) {
    const url = `${Uris.AccountsApi.SubscriptionPlan.CreateSubscriptionCheckout}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        price_id: plan.stripe_price_id,
        success_url: successUrl || window.location.href,
        cancel_url: cancelUrl || window.location.href,
      }),
    });
    const resJson = await AccountsAPI.checkFetchResponse(res);
    return AccountsAPI.extractResponse<{ url: string }>(resJson);
  }

  public static async createCreditCheckout(plan: CreditPlan) {
    const url = `${Uris.AccountsApi.CreditPlan.CreateCreditCheckout}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        price_id: plan.stripe_price_id,
        success_url: window.location.href,
        cancel_url: window.location.href,
      }),
    });
    const resJson = await AccountsAPI.checkFetchResponse(res);
    return AccountsAPI.extractResponse<{ url: string }>(resJson);
  }

  public static async getCredits() {
    const url = `${Uris.AccountsApi.Credit.GetCredits}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await AccountsAPI.checkFetchResponse(res);
    return AccountsAPI.extractResponse<Credit[]>(resJson);
  }

  public static async getCreditTransactions(paginationOptions?: PaginationOptions) {
    const queryParams: string[] = [];
    if (paginationOptions?.rowsPerPage) {
      queryParams.push(`rows=${paginationOptions.rowsPerPage}`);
    }
    if (paginationOptions?.rowsPerPage) {
      queryParams.push(`page=${paginationOptions.page + 1}`);
    }
    const url = `${Uris.AccountsApi.Credit.GetCreditTransactions}${
      queryParams.length ? `?${queryParams.join('&')}` : ''
    }`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await AccountsAPI.checkFetchResponse(res);
    return AccountsAPI.extractResponse<CreditTransaction[]>(resJson);
  }
}

export default AccountsAPI;
