import {
  TICK_LENGTH,
  BAR_WIDTH,
  HALF_DIMENSION,
  START_ANGLE_DEG,
  BAR_ANGLE_DEG,
  DIMENSION,
  TICK_BAR_PADDING,
} from 'components/Guage/constants';
import styles from 'components/Guage/Guage.module.scss';
import { toRgb } from 'common/colors/toColor';
import { Color } from 'common/types/Color';

type BarProps = { colors: Color[] };

const CONIC_ROTATE = -45;
const CONIC_COLOR_SPAN = 75; // 3/4 of the full circle
const CONIC_START_ANGLE = 12.5; // The rest of color span / 2

const createConicGradient = (colors: Color[]) => {
  const step = CONIC_COLOR_SPAN / (colors.length - 1);
  let stops = colors.map<[Color, number]>((color, i) => [color, CONIC_START_ANGLE + step * i]);
  // NOTE: Conic gradient is always a circle. Adding buffer at both side to cater the end capping.
  stops = [[colors[0], 0], ...stops, [colors[colors.length - 1], 100]];
  const stopStr = stops.map(([color, percentage]) => `${toRgb(color)} ${percentage}%`).join(',');
  return `conic-gradient(from ${CONIC_ROTATE}deg, ${stopStr})`;
};

export const Bar = ({ colors }: BarProps) => {
  const borderToRadius = TICK_LENGTH + TICK_BAR_PADDING + BAR_WIDTH / 2;
  const radius = HALF_DIMENSION - borderToRadius;
  const backgroundImage = createConicGradient(colors);

  return (
    // Transform the bar first to simplify calculation
    <g transform={`rotate(${START_ANGLE_DEG} ${HALF_DIMENSION} ${HALF_DIMENSION})`}>
      <mask id='bar-shape'>
        <path
          d={`M ${HALF_DIMENSION},${borderToRadius} A ${radius} ${radius} ${BAR_ANGLE_DEG} 1 1 ${borderToRadius},${HALF_DIMENSION}`}
          fill='none'
          stroke='white'
          strokeWidth={BAR_WIDTH}
          strokeLinecap='round'
        />
      </mask>
      <foreignObject x='0' y='0' width={DIMENSION} height={DIMENSION} mask='url(#bar-shape)'>
        <div className={styles.bar} style={{ backgroundImage }} />
      </foreignObject>
    </g>
  );
};
