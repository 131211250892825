import {
  AddOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Avatar, IconButton, OutlinedInput, Stack, Typography } from '@mui/material';
import { Collection, CollectionTwitterUser } from 'common/types/Extension/Collection';
import { FC, useCallback, useContext, useState } from 'react';

import { AddUserToCollectionPopover } from 'components/InfluencerMatcher/collection/AddUserToCollectionPopover';
import { DeleteUserFromCollection } from 'components/InfluencerMatcher/collection/DeleteUserFromCollection';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import classNames from 'classnames';
import classes from './SingleCollection.module.scss';
import { useMessage } from 'components/message/useMessage';

interface SingleCollectionProps {
  collections: Collection[];
  collection: Collection;
  onTwitterViewClicked?: (userId: string) => void;
}

export const SingleCollection: FC<SingleCollectionProps> = ({ collections, collection, onTwitterViewClicked }) => {
  const { showMessage } = useMessage();
  const { refetchCollections$ } = useContext(ExtensionContext);
  const [addAnchor, setAddAnchor] = useState<HTMLElement | undefined>(undefined);
  const [selectedTwitterUser, setSelectedTwitterUser] = useState<CollectionTwitterUser | undefined>(undefined);
  const [deleteUserFromCollectionOpened, setDeleteUserFromCollectionOpened] = useState<boolean>(false);
  const [editName, setEditName] = useState<string | undefined>(undefined);

  const updateCollectionName = useCallback(
    async (collection: Collection) => {
      const newCollection: Collection = { ...collection, name: editName || '' };
      await ExtensionAPI.updateCollection(newCollection)
        .then(({ status }) => {
          if (status === 'success') {
            refetchCollections$.next(true);
            setEditName(undefined);
            return;
          }
          showMessage('Pin collection failed');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Pin collection failed', 'error');
        });
    },
    [refetchCollections$, editName, showMessage],
  );

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack spacing={1}>
        <Stack direction='row' alignItems='center' spacing={1}>
          {typeof editName === 'string' ? (
            <>
              <OutlinedInput size='medium' value={editName} onChange={(e) => setEditName(e.target.value)} />
              <IconButton onClick={() => setEditName(undefined)}>
                <CloseOutlined color='error' />
              </IconButton>
              <IconButton
                onClick={() => {
                  updateCollectionName(collection);
                }}
              >
                <CheckOutlined color='success' />
              </IconButton>
            </>
          ) : (
            <>
              <Typography variant='h6'>{collection?.name}</Typography>
              <IconButton onClick={() => setEditName(collection.name)}>
                <EditOutlined />
              </IconButton>
            </>
          )}
        </Stack>
        <Stack direction='row' spacing={1}>
          <Typography variant='body1' className={classes.highlight}>
            {collection?.twitter_users.length}
          </Typography>
          <Typography variant='body1'>Influencers</Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        {collection?.twitter_users.map((twitterUser) => (
          <Stack
            key={twitterUser.id}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            className={classes.twitterUser}
          >
            <Stack direction='row' spacing={1} alignItems='center'>
              <Avatar
                alt='TwitterUserAvatar'
                className={classes.avatar}
                src={twitterUser.profile_image_url?.replace('_normal.', '_400x400.')}
              />
              <Stack>
                <Typography variant='body1'>{twitterUser.display_name}</Typography>
                <Typography variant='label1' className={classes.username}>
                  @{twitterUser.username}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction='row'
              spacing={1}
              className={classNames(classes.actions, twitterUser.id === selectedTwitterUser?.id && classes.selected)}
            >
              <IconButton
                className={classes.button}
                onClick={() => {
                  setSelectedTwitterUser(twitterUser);
                  setDeleteUserFromCollectionOpened(true);
                }}
              >
                <DeleteOutlineOutlined />
              </IconButton>
              <IconButton
                className={classes.button}
                onClick={() => {
                  onTwitterViewClicked?.(twitterUser.id);
                }}
              >
                <VisibilityOutlined />
              </IconButton>
              <IconButton
                className={classes.button}
                onClick={(event) => {
                  setSelectedTwitterUser(twitterUser);
                  setAddAnchor(event.currentTarget);
                }}
              >
                <AddOutlined />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </Stack>

      {addAnchor && selectedTwitterUser ? (
        <AddUserToCollectionPopover
          anchor={addAnchor}
          twitterUsers={[selectedTwitterUser]}
          collections={collections}
          onSuccess={() => {
            setAddAnchor(undefined);
            setSelectedTwitterUser(undefined);
            refetchCollections$.next(true);
          }}
          onCancel={() => {
            setAddAnchor(undefined);
            setSelectedTwitterUser(undefined);
          }}
        />
      ) : null}

      {deleteUserFromCollectionOpened && selectedTwitterUser ? (
        <DeleteUserFromCollection
          collection={collection}
          twitterUser={selectedTwitterUser}
          onSuccess={() => {
            setSelectedTwitterUser(undefined);
            setDeleteUserFromCollectionOpened(false);
            refetchCollections$.next(true);
          }}
          onCancel={() => {
            setDeleteUserFromCollectionOpened(false);
          }}
        />
      ) : null}
    </Stack>
  );
};
