import { Button, Stack, Typography } from '@mui/material';

import { CommonChip } from 'components/chip/CommonChip';
import { DeleteIcon } from 'components/icons/DeleteIcon';
import { FC } from 'react';
import classes from './EmptyAuth.module.scss';

interface EmptyAuthProps {
  onBtnCancelClicked?: () => void;
}

export const EmptyAuth: FC<EmptyAuthProps> = ({ onBtnCancelClicked }) => {
  return (
    <Stack className={classes.root}>
      <Stack spacing={3} className={classes.content} alignItems='center'>
        <CommonChip>
          <DeleteIcon fontSize='large' />
        </CommonChip>
        <Stack spacing={1} className={classes.info}>
          <Typography variant='h6'>No Google Drive account found</Typography>
          <Typography variant='body1'>
            To unlock our google sheet feature, simply connect your Google Drive account with us.
          </Typography>
          <Typography variant='body1'>
            1. Connect your Google Drive account
            <br />
            2. Export sheet to Google Drive
          </Typography>
        </Stack>
      </Stack>
      <Stack direction='row' className={classes.actions} justifyContent='center' spacing={1}>
        <Button variant='outlined' onClick={onBtnCancelClicked}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
