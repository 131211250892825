import { Button, Dialog, DialogProps, DialogTitle, Stack, Typography } from '@mui/material';
import { TutorialCompleteAction, TutorialCompleteActionBtn } from 'common/types/Tutorial';

import { FC } from 'react';
import classes from './TutorialCompleteDialog.module.scss';

interface TutorialCompleteDialogProps extends DialogProps {
  percentage?: number;
  completeAction: TutorialCompleteAction;
  onActionBtnClick?: (actionBtn: TutorialCompleteActionBtn) => void;
  onSkip?: () => void;
}

export const TutorialCompleteDialog: FC<TutorialCompleteDialogProps> = ({
  open,
  percentage,
  completeAction,
  onActionBtnClick,
  onSkip,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      className={classes.dialog}
      PaperProps={{ sx: { backgroundColor: 'white', borderRadius: '8px' } }}
      {...props}
    >
      {typeof percentage === 'number' ? (
        <Stack className={classes.progressBar}>
          <Stack className={classes.progress} style={{ width: `${percentage * 100}%` }}></Stack>
        </Stack>
      ) : null}
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <Typography className={classes.skip} onClick={onSkip}>
            Skip
          </Typography>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            {completeAction.img ? <img alt='cover' className={classes.img} src={completeAction.img}></img> : null}
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center' color='#1c1b1f'>
                {completeAction.title}
              </Typography>
              <Typography variant='body1' textAlign='center' color='#49454f'>
                {completeAction.text}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          {completeAction.actionBtns?.map((actionBtn, idx) => (
            <Button
              key={`action-btn-${idx}`}
              variant={actionBtn.variant || 'contained'}
              onClick={() => onActionBtnClick?.(actionBtn)}
            >
              {actionBtn.text}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Dialog>
  );
};
