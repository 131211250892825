import {
  ARROW_SIZE,
  BAR_ANGLE_DEG,
  BAR_WIDTH,
  HALF_DIMENSION,
  START_ANGLE_DEG,
  TICK_LENGTH,
  TICK_BAR_PADDING,
  ARROW_BAR_PADDING,
} from 'components/Guage/constants';
import styles from 'components/Guage/Guage.module.scss';

type ArrowProps = { progress: number; color: string };

export const Arrow = ({ progress, color }: ArrowProps) => {
  const angle = START_ANGLE_DEG + BAR_ANGLE_DEG * (progress * 0.01);
  const arrowPositionY = TICK_LENGTH + TICK_BAR_PADDING + BAR_WIDTH + ARROW_BAR_PADDING;
  const halfSize = ARROW_SIZE / 2;

  return (
    // The SVG "transform" attribute does not works well for transition, using "styles.transform" instead
    <g className={styles.arrow} fill={color} style={{ transform: `rotate(${angle}deg)` }}>
      {/* Position arrow to the 50% point to simplify computation */}
      <g transform={`translate(${HALF_DIMENSION} ${arrowPositionY})`}>
        <path d={`M 0,0 L ${-halfSize},${ARROW_SIZE} L ${halfSize},${ARROW_SIZE} Z`} />
      </g>
    </g>
  );
};
