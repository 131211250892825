import {
  CommonTable,
  CommonTableColumn,
  DefaultPaginationOptions,
  PaginationOptions,
} from 'components/table/CommonTable';
import { FC, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import AccountsAPI from 'common/AccountsAPI';
import { CreditTransaction } from 'common/types/Accounts/CreditTransaction';
import classes from './CreditTransactionList.module.scss';
import { datetimeFormatter } from 'common/formatters';
import { useAsync } from 'react-use';

interface CreditTransactionListProps extends HTMLAttributes<HTMLElement> {}

export const CreditTransactionList: FC<CreditTransactionListProps> = ({ ...rest }) => {
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>(DefaultPaginationOptions);
  const { loading, value: creditsRes } = useAsync(async () => {
    return await AccountsAPI.getCreditTransactions(paginationOptions);
  }, [paginationOptions]);

  const onPageChange = useCallback((paginationOptions: PaginationOptions) => {
    setPaginationOptions(paginationOptions);
  }, []);

  const columnDefs: CommonTableColumn<CreditTransaction>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'created_at',
      label: 'Date',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>;
      },
    },
    {
      id: 'description',
      label: 'Reason',
      render: (value) => {
        return <Typography variant='body1'>{value || '-'}</Typography>;
      },
    },
    {
      id: 'amount',
      label: 'Credit amount',
      render: (value, _, d) => {
        return (
          <Typography variant='body1' className={(value || 0) >= 0 ? classes.plus : classes.minus}>
            {(value || 0) >= 0 ? '+' : ''}
            {value}
          </Typography>
        );
      },
    },
  ];

  const tableHeader = useMemo(() => {
    return (
      <Stack direction='row' justifyContent='space-between' className={classes.tableHeader}>
        <Typography variant='h6'>Credit balance history</Typography>
      </Stack>
    );
  }, []);

  return (
    <CommonTable
      {...rest}
      dataPagination
      loading={loading}
      rowsPerPageOptions={[10, 20, 50]}
      pageNum={paginationOptions?.page}
      totalDataCnt={creditsRes?.total_rows || 0}
      data={creditsRes?.data || []}
      columns={columnDefs}
      header={tableHeader}
      onPageChange={onPageChange}
    />
  );
};
