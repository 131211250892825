import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';

import ClientAPI from 'common/ClientAPI';
import { FC } from 'react';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classes from './HomePage.module.scss';
import { useAsync } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'common/hooks/useTracking';

const features = [
  {
    title: 'Wallet Selector',
    description: 'Customize your target cohorts through intuitive option settings.',
    image: Uris.Public.Image.Root.WalletSelector,
    link: Uris.Pages.WalletSelector.Index,
  },
  {
    title: 'Ads Audience',
    description: 'Leverage Cohort-based Ads Audience to drive high-performance campaigns.',
    image: Uris.Public.Image.Root.AdsAudience,
    link: Uris.Pages.AdsAudience.Index,
  },
  {
    title: 'Influencer Matcher',
    description: 'Maximize KOL recommendations tailored to your target cohorts.',
    image: Uris.Public.Image.Root.InfluencerMatcher,
    link: Uris.Pages.InfluencerMatcher.Index,
  },
];

export const HomePage: FC = () => {
  const navigate = useNavigate();
  const { track } = useTracking();

  const { loading, value: changelog } = useAsync(async () => {
    return (await ClientAPI.getChangelogs()).data;
  }, []);

  return (
    <Stack className={classes.root}>
      <Stack className={classes.top}>
        <Stack className={classes.content}>
          <Typography variant='h2'>All-in-One Marketing Tool</Typography>
          <Typography variant='h2'>in the Web3 World</Typography>
        </Stack>
      </Stack>
      <Stack className={classes.bottom}>
        <Stack className={classes.container} spacing={4}>
          <Typography variant='h3'>Precise Target with Growing3</Typography>
          <Stack direction='row' spacing={3} justifyContent='space-between'>
            {features.map((feature) => (
              <Card className={classes.card} key={feature.title}>
                <CardContent className={classes.content}>
                  <Stack spacing={2}>
                    <Typography variant='h6'>{feature.title}</Typography>
                    <Typography variant='body1'>{feature.description}</Typography>
                  </Stack>
                </CardContent>
                <CardActions className={classes.actions}>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      track('click', { event_props: { link_id: feature.title } });
                      navigate(feature.link);
                    }}
                  >
                    Explore more
                  </Button>
                </CardActions>
                <Stack className={classes.media}>
                  <img alt={`img-${feature.title}`} className={classes.img} src={feature.image} />
                </Stack>
              </Card>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classes.changelog}>
        <Stack className={classes.container} direction='row' justifyContent='space-between' spacing={1}>
          <Stack className={classes.details} spacing={2}>
            {loading ? (
              <Stack justifyContent='center' alignItems='center' className={classes.spinner}>
                <Spinner />
              </Stack>
            ) : !changelog ? (
              <Typography variant='h5'>No Changelogs</Typography>
            ) : (
              <>
                <Typography variant='h5'>{changelog?.title}</Typography>
                <Stack className={classes.items}>
                  {changelog?.items.map((item, idx) => (
                    <Stack key={idx} direction='row' spacing={1}>
                      <Typography>{idx + 1}.</Typography>
                      <Typography className={classes.item}>
                        <span style={{ fontWeight: 'bold' }}>{item.subtitle}</span> <span>{item.text}</span>
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
                <Stack direction='row' spacing={1}>
                  <Button variant='outlined' onClick={() => window.open(Uris.External.Changelogs, '_blank')}>
                    Product Updates
                  </Button>
                  <Button variant='outlined' onClick={() => window.open(Uris.External.Documentation, '_blank')}>
                    Documentation
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
          <img alt={`img-changelog`} className={classes.img} src={Uris.Public.Image.Root.Changelog} />
        </Stack>
      </Stack>
      <Stack className={classes.white}></Stack>
    </Stack>
  );
};
