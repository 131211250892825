export enum ChainType {
  ETH = 'ethereum',
  SOL = 'solana',
  POL = 'polygon',
}

export const DefaultChainType: ChainType = ChainType.ETH;

export const ChainTypeNameMap = new Map<string, string>([
  [ChainType.ETH, 'ETH'],
  [ChainType.SOL, 'SOL'],
  [ChainType.POL, 'POL'],
]);
