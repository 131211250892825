import { FiberManualRecord } from '@mui/icons-material';
import { JobStatus } from 'common/types/JobStatus';
import { Typography } from '@mui/material';

export const renderJobStatus = (status?: JobStatus) => {
  switch (status) {
    case JobStatus.COMPLETED:
      return (
        <>
          <FiberManualRecord fontSize='small' color='success' />
          <Typography variant='body1'>Ready</Typography>
        </>
      );
    case JobStatus.FAILED:
      return (
        <>
          <FiberManualRecord fontSize='small' color='error' />
          <Typography variant='body1'>Failed</Typography>
        </>
      );
    case JobStatus.QUEUEING:
    case JobStatus.RUNNING:
    default:
      return (
        <>
          <FiberManualRecord fontSize='small' color='warning' />
          <Typography variant='body1'>Syncing</Typography>
        </>
      );
  }
};
