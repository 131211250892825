import { Button, Stack, Typography } from '@mui/material';

import { CommonChip } from 'components/chip/CommonChip';
import { DeleteIcon } from 'components/icons/DeleteIcon';
import { FC } from 'react';
import { IntegrationAuth } from 'common/types/IntegrationAuth';
import classes from './DeleteConfirm.module.scss';

interface DeleteConfirmProps {
  auth: IntegrationAuth;
  onBtnCancelClicked?: () => void;
  onBtnDeleteClicked?: (auth: IntegrationAuth) => void;
}

export const DeleteConfirm: FC<DeleteConfirmProps> = ({ auth, onBtnCancelClicked, onBtnDeleteClicked }) => {
  return (
    <Stack className={classes.root}>
      <Stack spacing={3} className={classes.content} alignItems='center'>
        <CommonChip>
          <DeleteIcon fontSize='large' />
        </CommonChip>
        <Stack spacing={1} className={classes.info}>
          <Typography variant='h6'>Remove account {auth.name}</Typography>
          <Typography variant='body1'>
            If you remove the account, you will need to re-authenticate again next time.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction='row' className={classes.actions} justifyContent='center' spacing={1}>
        <Button variant='outlined' onClick={onBtnCancelClicked}>
          Cancel
        </Button>
        <Button variant='contained' onClick={() => onBtnDeleteClicked?.(auth)}>
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};
