import Switch, { SwitchProps } from '@mui/material/Switch';
import { colorNeutral50, colorPrimary40 } from 'common/params';

import { styled } from '@mui/material/styles';

// https://mui.com/material-ui/react-switch/#CustomizedSwitches.tsx
export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(() => ({
  width: 52,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 8,
    color: colorNeutral50,
    transitionDuration: '300ms',
    '> input': {
      width: '400%',
    },
    '&.Mui-checked': {
      margin: 4,
      transform: 'translateX(20px)',
      color: '#fff',
      '& .MuiSwitch-thumb': {
        width: 24,
        height: 24,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: colorPrimary40,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: colorNeutral50,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    border: `2px solid ${colorNeutral50}`,
    backgroundColor: 'transparent',
    opacity: 1,
    transition: 'background-color: 500ms',
  },
}));
