import { AddOutlined, KeyboardArrowLeftOutlined, OutboundOutlined } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { TwitterUser } from 'common/types/Extension/TwitterUser';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './DrawerHeader.module.scss';

interface DrawerHeaderProps {
  twitterUser?: TwitterUser;
  text?: string;
  dark?: boolean;
  onBack?: () => void;
  onAddTwitterUser?: (twitterUser: TwitterUser) => void;
}

export const DrawerHeader: FC<DrawerHeaderProps> = ({ twitterUser, text, dark, onBack, onAddTwitterUser }) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      className={classNames(classes.root, dark && classes.dark)}
      justifyContent='space-between'
    >
      <Stack direction='row' alignItems='center'>
        <IconButton size='small' onClick={onBack} className={classes.backBtn}>
          <KeyboardArrowLeftOutlined />
        </IconButton>
        <Typography variant='body1'>{text}</Typography>
      </Stack>
      {twitterUser ? (
        <Stack direction='row' spacing={2} alignItems='center'>
          <Button variant='contained' startIcon={<AddOutlined />} onClick={() => onAddTwitterUser?.(twitterUser)}>
            Add to List
          </Button>
          <Button
            variant='contained'
            startIcon={<OutboundOutlined />}
            onClick={() => window.open(`${Uris.External.Twitter}/${twitterUser.username}`, '_blank')}
          >
            See Twitter Profile
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
};
