import { numberFormatter } from 'common/formatters';

export enum ExpressionOperation {
  GREATER = 'gt',
  LESS = 'lt',
  BETWEEN = 'between',
  TOP_N = 'topN',
}

export const ExpressionOperationNameMap = new Map<string, string>([
  [ExpressionOperation.GREATER, '> Greater than'],
  [ExpressionOperation.LESS, '< Less than'],
  [ExpressionOperation.BETWEEN, 'Between'],
  [ExpressionOperation.TOP_N, 'Top N'],
]);

type GreaterThan = { operation: ExpressionOperation.GREATER; value_1: number };
type LessThan = { operation: ExpressionOperation.LESS; value_1: number };
type Between = { operation: ExpressionOperation.BETWEEN; value_1: number; value_2: number };
type TopN = { operation: ExpressionOperation.TOP_N; value_1: number };

export type Expression = GreaterThan | LessThan | Between | TopN;

export const DefaultExpressionData: Expression = {
  operation: ExpressionOperation.GREATER,
  value_1: 0,
};

export const expressionToString = (expression: Expression) => {
  const { operation } = expression;

  switch (operation) {
    case ExpressionOperation.GREATER:
      return `≥ ${numberFormatter.format(expression.value_1)} USD`;
    case ExpressionOperation.LESS:
      return `< ${numberFormatter.format(expression.value_1)} USD`;
    case ExpressionOperation.BETWEEN:
      return `Between ${numberFormatter.format(expression.value_1)} USD to ${numberFormatter.format(
        expression.value_2,
      )} USD`;
    case ExpressionOperation.TOP_N:
      return `Top ${numberFormatter.format(expression.value_1)} % USD`;
    default:
      // unsupported type -> throw error to remind user/develoepr
      throw Error('Unsupport value compare data');
  }
};
