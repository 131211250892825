import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, EmailOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import classes from './SubscribeFailedDialog.module.scss';

interface SubscribeFailedDialogProps extends DialogProps {
  error: string;
  onBtnCancelClicked?: () => void;
}

export const SubscribeFailedDialog: FC<SubscribeFailedDialogProps> = ({
  open,
  error,
  onBtnCancelClicked,
  ...props
}) => {
  const onBtnEmailUsClicked = useCallback(() => {
    window.open('mailto: info@growing3.ai', '_blank');
  }, []);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(_, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      maxWidth='xl'
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <EmailOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Plan changes can only be made after 30 days
              </Typography>
              <Typography variant='body1' textAlign='center'>
                For special requests, email us at <span className={classes.highlight}>info@growing3.ai</span>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='contained' onClick={onBtnEmailUsClicked}>
            Email us
          </Button>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
