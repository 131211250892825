import { CohortFilter, DefaultCohortFilterData } from 'common/types/CohortFilter';
import { DefaultSchedulerData, Scheduler } from 'common/types/Scheduler';

import { JobStatus } from './JobStatus';
import { validateAddress } from 'common/utils';

export enum CohortProjectType {
  NFT = 'nft',
  GAME = 'game',
  DEFI = 'defi',
}

export interface Cohort {
  name: string;
  description: string;
  scheduler: Scheduler;
  created_from: string;

  filter: CohortFilter;
  project_type: CohortProjectType;
  growing3_categories?: number[];
  customized_categories?: number[];

  id?: number;
  total_balance?: number;
  wallet_count?: number;
  last_run_at?: number;
  status?: JobStatus;
  created_at?: number;
  updated_at?: number;
}

export const DefaultCohortData: Cohort = {
  name: '',
  description: '',
  scheduler: DefaultSchedulerData,
  created_from: 'Wallet Picker',
  project_type: CohortProjectType.DEFI,

  filter: DefaultCohortFilterData,
};

export const isCohortValid = (cohort: Cohort) => {
  const isAssetPortofolioValid = (cohort: Cohort) => {
    // not enabled -> valid
    if (!cohort.filter.operands.asset_portfolio.enabled) return true;

    // enabled but no data -> invalid
    if (!cohort.filter.operands.asset_portfolio.operands.length) return false;

    // detail check -> tokens should larger than zero
    return cohort.filter.operands.asset_portfolio.operands.reduce(
      (valid, operand) => valid && (operand.all_tokens || operand.tokens.length > 0),
      true,
    );
  };
  const isDefiEngagementValid = (cohort: Cohort) => {
    // not enabled -> valid
    if (!cohort.filter.operands.defi_engagement.enabled) return true;

    // enabled but no data -> invalid
    if (!cohort.filter.operands.defi_engagement.operands.length) return false;

    // detail check
    return true;
  };
  const isContractInteractionValid = (cohort: Cohort) => {
    // not enabled -> valid
    if (!cohort.filter.operands.contract_interaction.enabled) return true;

    // enabled but no data -> invalid
    if (!cohort.filter.operands.contract_interaction.operands.length) return false;

    // detail check
    return cohort.filter.operands.contract_interaction.operands.reduce(
      (valid, operand) => valid && !!operand.contract_addr && validateAddress(operand.contract_addr),
      true,
    );
  };
  const isAllDisabled = (cohort: Cohort) => {
    return (
      !cohort.filter.operands.asset_portfolio.enabled &&
      !cohort.filter.operands.defi_engagement.enabled &&
      !cohort.filter.operands.contract_interaction.enabled
    );
  };
  return (
    isAssetPortofolioValid(cohort) &&
    isDefiEngagementValid(cohort) &&
    isContractInteractionValid(cohort) &&
    !isAllDisabled(cohort)
  );
};
