import { useCallback, useContext, useEffect, useState } from 'react';

import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { useMessage } from 'components/message/useMessage';

export const useLanguages = () => {
  const { showMessage } = useMessage();
  const { languages$ } = useContext(ExtensionContext);
  const [languages, setLanguages] = useState<string[] | undefined>(languages$.getValue());
  const [loading, setLoading] = useState<boolean>(true);

  const fetchLanguages = useCallback(async () => {
    setLoading(true);
    await ExtensionAPI.getTwitterUserLanguages()
      .then(({ data }) => {
        languages$.next(data);
        setLanguages(data);
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, languages$]);

  useEffect(() => {
    if (languages) {
      setLoading(false);
      return;
    }
    fetchLanguages();
  }, [languages, fetchLanguages]);

  return { loading, value: languages };
};
