import { Link, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { Uris } from 'Uris';
import classes from './UserDisabledPage.module.scss';
import { colorPrimary10 } from 'common/params';

export const UserDisabledPage: FC = () => {
  return (
    <Stack direction='row' className={classes.root} textAlign='center' justifyContent='center'>
      <Stack className={classes.block}>
        <Stack className={classes.container} spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <img alt='logo' className={classes.logo} src={Uris.Public.Logo.NoSub.Dark} />
          </Stack>
          <Typography variant='h4' color={colorPrimary10}>
            Please contact Growing3 to enabled your account.
          </Typography>
          <Typography variant='body1' className={classes.note}>
            <Link
              variant='body1'
              className={classes.contact}
              onClick={() => window.open(Uris.External.BookDemo, '_blank')}
            >
              Contact us
            </Link>
          </Typography>
          <Typography variant='body2' className={classes.note}>
            <Link variant='body2' href={Uris.External.TermsOfService} target='_blank'>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link variant='body2' href={Uris.External.PrivacyPolicy} target='_blank'>
              Privacy Policy
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
