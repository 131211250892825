import { ArrowForwardOutlined, CheckCircleOutline } from '@mui/icons-material';
import { Button, Divider, IconButton, Popover, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Tutorial, tutorialGroupInfos, tutorials } from 'common/types/Tutorial';

import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './TutorialGetStarted.module.scss';

interface TutorialGetStartedProps {
  completedTutorials: Tutorial[];
  onTutorialStarted?: (tutorial: Tutorial) => void;
}

const renderPercentage = (data?: number) => {
  if (typeof data !== 'number') return data;
  return `${Math.round(data * 10000) / 100} %`;
};

export const TutorialGetStarted: FC<TutorialGetStartedProps> = ({ completedTutorials, onTutorialStarted }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined);
  const btnRef = useRef(null);

  const tutorialGroups = useMemo(() => {
    const tutorialGroups: Map<string, Tutorial[]> = new Map<string, Tutorial[]>();
    for (const t of tutorials) {
      if (!t.groupId) continue;
      if (tutorialGroups.has(t.groupId)) tutorialGroups.get(t.groupId)?.push(t);
      else tutorialGroups.set(t.groupId, [t]);
    }
    return tutorialGroups;
  }, []);

  const allTutorials = useMemo(() => {
    return tutorials.filter((t) => !!t.groupId);
  }, []);

  const pendingTutorials = useMemo(() => {
    return allTutorials.filter(
      (t) => completedTutorials.findIndex((completedTutorial) => completedTutorial.key === t.key) === -1,
    );
  }, [allTutorials, completedTutorials]);

  const percentage = useMemo(() => {
    return allTutorials.length ? (allTutorials.length - pendingTutorials.length) / allTutorials.length : undefined;
  }, [allTutorials, pendingTutorials]);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  return (
    <Stack ref={btnRef} className={classes.root}>
      <Button
        variant='contained'
        startIcon={<CheckCircleOutline />}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        Get Started
      </Button>
      <Stack className={classes.badge} alignItems='center' justifyContent='center'>
        <Typography>{pendingTutorials.length}</Typography>
      </Stack>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '.MuiPopover-paper': {
            backgroundColor: 'white',
            transform: 'translateY(-32px) !important',
          },
        }}
      >
        <Stack className={classes.panel} spacing={2}>
          <Stack spacing={1}>
            {pendingTutorials.length ? (
              <Typography>Let's start with the basics</Typography>
            ) : (
              <>
                <img
                  className={classes.allComplete}
                  alt='all-tasks-complete'
                  src={Uris.Public.Image.Tutorial.AllTasksComplete}
                />
                <Typography>You have completed all the tasks.</Typography>
              </>
            )}
            <Typography variant='body2'>Complete our onboarding tasks to unlock all features.</Typography>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Typography className={classes.percentage}>{renderPercentage(percentage)}</Typography>
              <Stack className={classes.progressBar}>
                <Stack
                  className={classes.progress}
                  style={{ width: `${(typeof percentage === 'number' ? percentage : 0) * 100}%` }}
                ></Stack>
              </Stack>
            </Stack>
          </Stack>
          {Array.from(tutorialGroups.entries()).map((tutorialGroup) => {
            const tutorialGroupInfo = tutorialGroupInfos.find(
              (tutorialGroupInfo) => tutorialGroupInfo.id === tutorialGroup[0],
            );
            return (
              <Stack spacing={2} key={tutorialGroup[0]}>
                <Divider />
                <Stack direction='row' alignItems='center' spacing={1}>
                  <img alt='task-img' src={tutorialGroupInfo?.img} />
                  <Typography>{tutorialGroupInfo?.name}</Typography>
                </Stack>
                <Stack spacing={1.5}>
                  {tutorialGroup[1].map((tutorial, idx) => {
                    const tutorialCompleted = completedTutorials.findIndex((t) => t.key === tutorial.key) > -1;
                    const tutorialDisabled =
                      !tutorialCompleted &&
                      idx > 0 &&
                      completedTutorials.findIndex((t) => t.key === tutorialGroup[1][idx - 1].key) === -1;
                    return (
                      <Stack
                        className={classNames(classes.tutorial, tutorialDisabled && classes.disabled)}
                        key={tutorial.key}
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={1}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          {tutorialCompleted ? (
                            <Stack className={classes.check} alignItems='center' justifyContent='center'>
                              <CheckCircleOutline className={classes.svg} />
                            </Stack>
                          ) : (
                            <Stack className={classes.badge} alignItems='center' justifyContent='center'>
                              <Typography>{idx + 1}</Typography>
                            </Stack>
                          )}
                          <Typography>{tutorial.name}</Typography>
                        </Stack>
                        <IconButton
                          disabled={tutorialDisabled}
                          onClick={() => {
                            setAnchorEl(undefined);
                            onTutorialStarted?.(tutorial);
                          }}
                        >
                          <ArrowForwardOutlined />
                        </IconButton>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Popover>
    </Stack>
  );
};
