import { FC } from 'react';
import { Stack } from '@mui/system';
import { Uris } from 'Uris';
import classes from './Logo.module.scss';
import { useNavigate } from 'react-router';

interface LogoProps {
  dark?: boolean; // dark = true 時使用深色的 logo
}

export const Logo: FC<LogoProps> = ({ dark }) => {
  const navigate = useNavigate();
  return (
    <Stack className={classes.logo} onClick={() => navigate(Uris.Pages.Root)}>
      <img alt='logo' src={dark ? Uris.Public.Logo.Dark : Uris.Public.Logo.Light} className={classes.img} />
    </Stack>
  );
};
