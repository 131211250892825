import { useEffect, useState } from 'react';

import { useAccountsUserProfile } from './Accounts/useAccountsUserProfile';
import { useAllFeatureLimits } from 'common/hooks/useAllFeatureLimits';

export const useUserFeatureLimits = (featureName: string, subModule?: string) => {
  const { value: accountsUser } = useAccountsUserProfile();
  const { value: featureLimits } = useAllFeatureLimits();
  const [userLimit, setUserLimit] = useState<number>(0);

  useEffect(() => {
    if (!accountsUser || !featureLimits?.length) {
      setUserLimit(0);
      return;
    }

    let limit = 0;
    featureLimits
      .filter((featureLimit) => {
        return (
          featureLimit.user_tier === accountsUser.tier &&
          featureLimit.feature_name === featureName &&
          (typeof subModule === 'string' ? featureLimit.sub_module === subModule : !featureLimit.sub_module)
          // 因為 subMobile 在 featureLimit 裡面如果沒有的話會是 null
          // 然後在 typescript 裡面 undefined !== null
          // 所以這邊會特別判斷他傳進來有 string 的時候才去比對字串
        );
      })
      .forEach((featureLimit) => {
        limit = featureLimit.max_amount;
      });
    setUserLimit(limit);
  }, [accountsUser, featureLimits, featureName, subModule]);

  return { value: userLimit };
};
