import { FC, useContext, useMemo, useState } from 'react';
import { InfoOutlined, LanguageOutlined, PlaceOutlined, Star } from '@mui/icons-material';
import { LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import {
  formatPercentileRank,
  getPercentileColor,
  getPercentileColorHex,
  numberToAbbreviation,
  numberToPercentage,
  numberToSecondFraction,
} from 'common/utils';

import { ExtensionContext } from 'contexts/ExtensionContext';
import { FallenLeavesIcon } from 'components/icons/FallenLeavesIcon';
import { GhostIcon } from 'components/icons/GhostIcon';
import { LabelChip } from 'components/chip/LabelChip';
import { TermCloud } from 'components/InfluencerMatcher/twitter/TermCloud';
import { TopicTreeMap } from './TopicTreeMap';
import { TwitterUserMetrics } from 'common/types/Extension/TwitterUserMetrics';
import classNames from 'classnames';
import classes from './UserMetrics.module.scss';
import { getLangFromIso } from 'common/languages';
import { jsonToTermsFrequency } from 'common/types/Extension/TermFrequency';

interface UserMetricsProps {
  userMetrics: TwitterUserMetrics;
}

enum MetricsMode {
  SUMMARY = 'Summary',
  METRICS = 'Metrics',
  CONTENT_AFFINITY = 'Content Affinity',
}

const renderInfluencerScore = (totalUsers?: number, metrics?: TwitterUserMetrics) => {
  if (typeof metrics?.influence_percentile !== 'number')
    return (
      <Stack height={80} spacing={2} alignItems='center' justifyContent='center'>
        <FallenLeavesIcon style={{ width: '32px', height: '32px' }} />
        <Typography variant='body1'>This influencer has no score data.</Typography>
      </Stack>
    );
  const color = getPercentileColor(metrics.influence_percentile);
  const colorHex = getPercentileColorHex(metrics.influence_percentile);
  return (
    <Stack spacing={1.5}>
      <Stack direction='row' spacing={1}>
        <Typography variant='label1'>Influence Score</Typography>
        <Tooltip
          arrow
          placement='top'
          title='Influence Score on X.com uses the PageRank algorithm and retweet data to measure impact. High-score retweets boost your score, reflecting the ripple effect of influence.'
        >
          <InfoOutlined fontSize='small' />
        </Tooltip>
      </Stack>
      <Stack direction='row' spacing={3} justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <Star fontSize='small' />
          <Typography variant='h6'>
            {typeof metrics.influence_score === 'number' ? numberToAbbreviation(metrics.influence_score) : '**'}
          </Typography>
        </Stack>
        <Stack spacing={3} direction='row' alignItems='center'>
          <Stack spacing={0.5} style={{ width: '300px' }}>
            <Typography variant='label1' color={colorHex}>
              {typeof metrics.influence_percentile === 'number'
                ? formatPercentileRank(metrics.influence_percentile)
                : '**'}{' '}
              Percentile
            </Typography>
            <LinearProgress
              variant='determinate'
              color={color}
              value={(metrics.influence_percentile || 0) * 100}
              sx={{
                borderRadius: '2px',
              }}
            />
          </Stack>
          <Stack direction='row' spacing={0.5} justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' color={colorHex}>
              {metrics.influence_rank}
            </Typography>
            <Typography>/{totalUsers}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const renderMetric = (title: string, tip: string, value?: number, valueTier?: number, isRatio?: boolean) => {
  const color = getPercentileColor(valueTier);
  const colorHex = getPercentileColorHex(valueTier);
  return (
    <Stack className={classes.metric}>
      <Stack direction='row' spacing={1}>
        <Typography variant='label1'>{title}</Typography>
        <Tooltip arrow placement='top' title={tip}>
          <InfoOutlined fontSize='small' />
        </Tooltip>
      </Stack>
      <Typography variant='h6'>
        {typeof value === 'number' ? (isRatio ? numberToPercentage(value) : numberToSecondFraction(value)) : '***'}
      </Typography>
      <Stack spacing={0.5}>
        <Typography variant='label1' color={colorHex}>
          {typeof valueTier === 'number' ? formatPercentileRank(valueTier) : '**'} Percentile
        </Typography>
        <LinearProgress
          variant='determinate'
          color={color}
          value={(valueTier || 0) * 100}
          sx={{
            borderRadius: '2px',
            width: '120px',
          }}
        />
      </Stack>
    </Stack>
  );
};

export const UserMetrics: FC<UserMetricsProps> = ({ userMetrics }) => {
  const { metricsRange$ } = useContext(ExtensionContext);
  const [metricsMode, setMetricsMode] = useState<MetricsMode>(MetricsMode.SUMMARY);

  const termsFrequency = useMemo(() => {
    return jsonToTermsFrequency(userMetrics.terms_frequency);
  }, [userMetrics.terms_frequency]);

  const topics = useMemo(() => {
    return userMetrics.topics || [];
  }, [userMetrics.topics]);

  const metricsContent = useMemo(() => {
    switch (metricsMode) {
      case MetricsMode.SUMMARY:
        return (
          <>
            <Stack className={classes.content}>
              {userMetrics.summary ? (
                <Typography>{userMetrics.summary}</Typography>
              ) : (
                <Stack height={168} spacing={2} alignItems='center' justifyContent='center'>
                  <GhostIcon />
                  <Typography variant='body1'>The data is under processing</Typography>
                </Stack>
              )}
            </Stack>
            {(userMetrics.languages?.length || 0) > 0 || !!userMetrics.location ? (
              <Stack className={classes.content} spacing={1.5}>
                {userMetrics.languages?.length ? (
                  <Stack direction='row' alignItems='center' spacing={3}>
                    <LanguageOutlined color='primary' />
                    <Stack direction='row' alignItems='center' spacing={1}>
                      {userMetrics.languages.map((language, idx) => (
                        <LabelChip key={idx}>{getLangFromIso(language)}</LabelChip>
                      ))}
                    </Stack>
                  </Stack>
                ) : null}
                {userMetrics.location ? (
                  <Stack direction='row' alignItems='center' spacing={3}>
                    <PlaceOutlined color='primary' />
                    <LabelChip>{userMetrics.location}</LabelChip>
                  </Stack>
                ) : null}
              </Stack>
            ) : null}
          </>
        );
      case MetricsMode.METRICS:
        if (userMetrics.original_tweets_ratio === 0) {
          return (
            <Stack className={classes.content}>
              <Stack height={168} spacing={2} alignItems='center' justifyContent='center'>
                <FallenLeavesIcon style={{ width: '32px', height: '32px' }} />
                <Typography variant='body1'>This influencer has posted less content in the past 90 days.</Typography>
              </Stack>
            </Stack>
          );
        }
        return (
          <>
            <Stack className={classes.content} spacing={3}>
              {renderInfluencerScore(metricsRange$.getValue()?.total_users, userMetrics)}
            </Stack>
            <Stack className={classes.content} spacing={3}>
              <Stack direction='row' justifyContent='space-between'>
                {renderMetric(
                  'ETR',
                  'Average engagements(retweet+reply+like) / total followers',
                  userMetrics.etr,
                  userMetrics.etr_tier,
                  true,
                )}
                {renderMetric(
                  'VTR',
                  'Average tweet impressions / total followers',
                  userMetrics.vtr,
                  userMetrics.vtr_tier,
                  true,
                )}
                {renderMetric(
                  'Avg. Tweet Impression',
                  'Average impressions in the past 90 days.',
                  userMetrics.avg_tweet_impr,
                  userMetrics.avg_tweet_impr_tier,
                  false,
                )}
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                {renderMetric(
                  'Campaign Term Ratio',
                  'Campaign tweets (tweets related to campaign keywords like airdrop, giveaway or RTs) count / original tweet counts.',
                  userMetrics.campaign_term_ratio,
                  userMetrics.campaign_term_ratio_tier,
                  true,
                )}
              </Stack>
            </Stack>
          </>
        );
      case MetricsMode.CONTENT_AFFINITY:
        if (!termsFrequency.length && !topics.length)
          return (
            <Stack className={classes.content} height={200} spacing={2} alignItems='center' justifyContent='center'>
              <GhostIcon />
              <Typography variant='body1'>The data is under processing</Typography>
            </Stack>
          );
        return (
          <>
            {topics.length ? (
              <Stack className={classes.content} spacing={3}>
                <Typography variant='label1'>Top topics</Typography>
                <TopicTreeMap topics={topics} />
              </Stack>
            ) : null}
            {termsFrequency.length ? (
              termsFrequency.length >= 10 && userMetrics.original_tweets_ratio !== 0 ? (
                <Stack className={classes.content} spacing={3}>
                  <Typography variant='label1'>Word clouds</Typography>
                  <TermCloud termsFrequency={termsFrequency} />
                </Stack>
              ) : (
                <Stack className={classes.content} spacing={3}>
                  <Typography variant='label1'>Word clouds</Typography>
                  <Stack height={168} spacing={2} alignItems='center' justifyContent='center'>
                    <FallenLeavesIcon style={{ width: '32px', height: '32px' }} />
                    <Typography variant='body1'>
                      This influencer has posted less content in the past 90 days.
                    </Typography>
                  </Stack>
                </Stack>
              )
            ) : null}
          </>
        );
    }
  }, [metricsMode, userMetrics, termsFrequency, topics, metricsRange$]);

  const metricsModes = useMemo(() => {
    return Object.values(MetricsMode);
  }, []);

  return (
    <Stack spacing={2} className={classNames(classes.root)}>
      <Stack direction='row' spacing={0.25}>
        {metricsModes.map((value, idx) => {
          return (
            <Stack
              key={value}
              className={classNames(
                classes.tab,
                value === metricsMode && classes.selected,
                idx === 0 && classes.first,
                idx === metricsModes.length - 1 && classes.last,
              )}
              alignItems='center'
              onClick={() => {
                setMetricsMode(value as MetricsMode);
              }}
            >
              <Typography variant='label1'>{value}</Typography>
            </Stack>
          );
        })}
      </Stack>

      {metricsContent}
    </Stack>
  );
};
