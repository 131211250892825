import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Audience } from 'common/types/Audience';
import ClientAPI from 'common/ClientAPI';
import { Cohort } from 'common/types/Cohort';
import { CohortDeleteDialog } from 'components/Cohort/dialog/CohortDeleteDialog';
import { CohortDeleteFailedDialog } from 'components/Cohort/dialog/CohortDeleteFailedDialog';
import { CohortList } from 'pages/WalletSelector/Cohort/CohortList';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classes from 'pages/WalletSelector/WalletSelector.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router';
import { useTracking } from 'common/hooks/useTracking';

export const WalletSelector = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const [cohorts, setCohorts] = useState<Cohort[]>([]);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [cohortAudiences, setCohortAudiences] = useState<Audience[]>([]);
  const [deletedCohort, setDeletedCohort] = useState<Cohort | undefined>(undefined);

  const { loading, error, value } = useAsync(async () => {
    return (await ClientAPI.getCohorts()).data;
  }, [refetch]);

  useEffect(() => {
    if (!value) return;
    setCohorts(value);
  }, [value]);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const onCohortDelete = useCallback(async (cohort: Cohort) => {
    setDeletedCohort(cohort);
  }, []);

  const onCohortDuplicate = useCallback(
    (cohort: Cohort) => {
      // navigate to create cohort page and pass cohort data via state
      track('form_start', { sub_event: 'cohort_add_started' });
      navigate(Uris.Pages.WalletSelector.CreateCohort, {
        state: { cohort: cohorts.find((c) => c.id === cohort.id) },
      });
    },
    [track, cohorts, navigate],
  );

  return (
    <Stack className={classes.root}>
      <Stack className={classes.top}>
        <Stack className={classes.content}>
          <Typography variant='h2'>Wallet Selector</Typography>
          <Typography>Customize your target cohorts through intuitive option settings.</Typography>
        </Stack>
      </Stack>
      <Stack className={classes.bottom} spacing={3}>
        <Stack direction='row' className={classes.title}>
          <Typography variant='h3'>Cohort Lists</Typography>
          <Button
            id='btn-create-cohort'
            variant='contained'
            startIcon={<Add />}
            onClick={() => {
              track('form_start', { sub_event: 'cohort_add_started' });
              navigate(Uris.Pages.WalletSelector.CreateCohort);
            }}
          >
            New Cohort
          </Button>
        </Stack>
        {loading ? (
          <Spinner />
        ) : cohorts.length ? (
          <CohortList
            className={classes.table}
            cohorts={cohorts}
            onDuplicate={onCohortDuplicate}
            onDelete={onCohortDelete}
          />
        ) : (
          <Stack className={classes.init} spacing={3}>
            <Typography variant='h3'>Take the first step towards better ad targeting</Typography>
            <Button
              className={classes.btn}
              variant='contained'
              onClick={() => {
                track('form_start', { sub_event: 'cohort_add_started' });
                navigate(Uris.Pages.WalletSelector.CreateCohort);
              }}
            >
              Getting Started
            </Button>
          </Stack>
        )}
      </Stack>

      {/* delete cohort -> show confirm dialog */}
      {deletedCohort ? (
        <CohortDeleteDialog
          open
          cohort={deletedCohort}
          onDeleteFailed={(audiences) => {
            setDeletedCohort(undefined);
            setCohortAudiences(audiences);
          }}
          onDelete={() => {
            setDeletedCohort(undefined);
            setRefetch((old) => !old);
          }}
          onCancel={() => {
            setDeletedCohort(undefined);
          }}
        />
      ) : null}

      {/* delete cohort failed -> show binded audiences */}
      {cohortAudiences.length ? (
        <CohortDeleteFailedDialog
          open
          audiences={cohortAudiences}
          onCancel={() => {
            setCohortAudiences([]);
          }}
        />
      ) : null}
    </Stack>
  );
};
