import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CampaignLinkBase, CampaignLinkChannel } from 'common/types/CampaignLink';
import { CloseOutlined, ContentCopyOutlined, EditOutlined } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { colorPrimary40, colorSecondary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import classes from './CampaignLinkEditDialog.module.scss';

interface CampaignLinkEditDialogProps extends DialogProps {
  mode: 'create' | 'modify';
  campaignLink: CampaignLinkBase;
  onSave?: (mode: 'create' | 'modify', campaignLink: CampaignLinkBase) => void;
  onCancel?: () => void;
}

export const CampaignLinkEditDialog: FC<CampaignLinkEditDialogProps> = ({
  open,
  mode,
  campaignLink,
  onSave,
  onCancel,
  ...props
}) => {
  const [editCampaignLink, setEditCampaignLink] = useState<CampaignLinkBase>(campaignLink);
  const [saveBtnClicked, setSaveBtnClicked] = useState<boolean>(false);

  const campaignLinkValid = useMemo(() => {
    return editCampaignLink.campaign && editCampaignLink.channel;
  }, [editCampaignLink]);

  const onBtnSaveClicked = useCallback(() => {
    setSaveBtnClicked(true);
    if (!campaignLinkValid) return;
    onSave?.(mode, editCampaignLink);
  }, [mode, campaignLinkValid, editCampaignLink, onSave]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const content = useMemo(() => {
    switch (editCampaignLink.channel) {
      case CampaignLinkChannel.TWITTER_ADS:
        return (
          <Stack className={classes.input}>
            <FormControl>
              <TextField label='Audience' disabled value={editCampaignLink.medium}></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Ad groups selected'
                disabled
                value={
                  editCampaignLink.ad_groups
                    ? Object.values(editCampaignLink.ad_groups)
                        .map((value) => value.ad_group_name)
                        .join(', ')
                    : ''
                }
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Name'
                error={saveBtnClicked && !editCampaignLink.name}
                value={editCampaignLink.name}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, name: e.target.value }))}
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Original URL'
                error={saveBtnClicked && !editCampaignLink.original_url}
                value={editCampaignLink.original_url}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, original_url: e.target.value }))}
              ></TextField>
            </FormControl>
          </Stack>
        );
      case CampaignLinkChannel.CUSTOMIZED:
        return (
          <Stack className={classes.input}>
            <FormControl>
              <TextField
                label='Original URL'
                error={saveBtnClicked && !editCampaignLink.original_url}
                value={editCampaignLink.original_url}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, original_url: e.target.value }))}
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Name'
                error={saveBtnClicked && !editCampaignLink.name}
                value={editCampaignLink.name}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, name: e.target.value }))}
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Campaign'
                error={saveBtnClicked && !editCampaignLink.campaign}
                value={editCampaignLink.campaign}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, campaign: e.target.value }))}
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Source'
                error={saveBtnClicked && !editCampaignLink.source}
                value={editCampaignLink.source}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, source: e.target.value }))}
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Medium'
                error={saveBtnClicked && !editCampaignLink.medium}
                value={editCampaignLink.medium}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, medium: e.target.value }))}
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Term'
                error={saveBtnClicked && !editCampaignLink.term}
                value={editCampaignLink.term || ''}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, term: e.target.value }))}
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Content'
                error={saveBtnClicked && !editCampaignLink.content}
                value={editCampaignLink.content || ''}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => setEditCampaignLink((old) => ({ ...old, content: e.target.value }))}
              ></TextField>
            </FormControl>
          </Stack>
        );
      default:
        return null;
    }
  }, [saveBtnClicked, editCampaignLink]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              {mode === 'create' ? <ContentCopyOutlined /> : mode === 'modify' ? <EditOutlined /> : null}
            </CommonChip>
            <Stack direction='row' spacing={1}>
              <Typography variant='h6' textAlign='center'>
                {mode === 'create' ? 'Copy of' : ''}
              </Typography>
              <Typography variant='h6' color={mode === 'create' ? colorSecondary40 : 'auto'}>
                "{campaignLink.name}"
              </Typography>
            </Stack>
          </Stack>
          {content}
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onBtnSaveClicked}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
