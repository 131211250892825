import { FC, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { colorPrimary10, colorPrimary40 } from 'common/params';

import ClientAPI from 'common/ClientAPI';
import { OutboundOutlined } from '@mui/icons-material';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';

interface TweetEmbedProps extends HTMLAttributes<HTMLDivElement> {
  tweetId: string;
  username: string;
  options?: object;
  protocol?: string;
  className?: string;
  onTweetLoadSuccess?: (twitterWidgetElement: HTMLElement) => any;
  onTweetLoadError?: (err: Error) => any;
}
const callbacks: (() => void)[] = [];

// this component is modified from the link below
// https://github.com/capaj/react-tweet-embed/blob/master/src/tweet-embed.tsx

export const TweetEmbed: FC<TweetEmbedProps> = ({
  tweetId,
  username,
  protocol,
  className,
  options,
  onTweetLoadError,
  onTweetLoadSuccess,
  ...restProps
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const tweetIdref = useRef<string>();

  // insert script to load widget.js
  // after the script load completed, execute cached callbacks
  const addScript = useCallback((src: string, cb: () => any) => {
    if (callbacks.length === 0) {
      callbacks.push(cb);
      const s = document.createElement('script');
      s.setAttribute('src', src);
      s.addEventListener('load', () => callbacks.forEach((cb) => cb()), false);
      document.body.appendChild(s);
    } else {
      callbacks.push(cb);
    }
  }, []);

  const loadTweetForProps = useCallback(() => {
    if (tweetId === tweetIdref.current) return;
    tweetIdref.current = tweetId;
    const renderTweet = () => {
      const twttr = (window as any)['twttr'];
      twttr.ready().then(async ({ widgets }: any) => {
        // Clear previously rendered tweet before rendering the updated tweet id
        if (divRef.current) {
          divRef.current.innerHTML = '';
        }

        let available = false;
        await ClientAPI.checkTweetAvailable(username, tweetId).then(({ data }) => {
          if (data) available = true;
        });

        if (!available) {
          setLoading(false);
          setError(true);
          return;
        }

        widgets
          .createTweet(tweetId, divRef.current, options)
          .then((twitterWidgetElement: any) => {
            onTweetLoadSuccess && onTweetLoadSuccess(twitterWidgetElement);
          })
          .catch((err: Error) => {
            setError(true);
            onTweetLoadError && onTweetLoadError(err);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    };

    const twttr = (window as any)['twttr'];
    if (!(twttr && twttr.ready)) {
      const isLocal = window.location.protocol.indexOf('file') >= 0;
      const p = isLocal ? protocol : '';

      addScript(`${p}//platform.x.com/widgets.js`, renderTweet);
    } else {
      renderTweet();
    }
  }, [addScript, tweetId, username, protocol, options, onTweetLoadSuccess, onTweetLoadError]);

  useEffect(() => {
    loadTweetForProps();
  }, [loadTweetForProps]);

  return (
    <Stack className={className} {...restProps}>
      {loading ? (
        <Stack style={{ minHeight: '200px' }} justifyContent='center'>
          <Spinner text='Loading tweet...' />
        </Stack>
      ) : null}
      {error ? (
        <Stack
          style={{
            width: '100%',
            padding: '24px',
            backgroundColor: 'white',
            borderRadius: '12px',
            marginBottom: '12px',
          }}
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={1.5}
        >
          <Typography style={{ color: colorPrimary10 }}>This tweet might not exist anymore</Typography>
          <OutboundOutlined
            fontSize='small'
            style={{ color: colorPrimary40, cursor: 'pointer' }}
            onClick={() => window.open(`${Uris.External.Twitter}/${username}/status/${tweetId}`)}
          />
        </Stack>
      ) : null}
      <Stack ref={divRef} style={{ display: error ? 'none' : 'flex' }}></Stack>
    </Stack>
  );
};
