import { InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { colorDisabled } from 'common/params';
import { label1 } from 'theme/typography';

interface PercentageValueInputProps {
  percentage?: string;
  disabled?: boolean;
  onChange?: (percentage: string) => void;
}

// for percentage value usage in filter
export const PercentageValueInput: FC<PercentageValueInputProps> = ({ percentage, disabled, onChange }) => {
  return (
    <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
      <Typography variant='label1' style={{ color: disabled ? colorDisabled : 'inherit' }}>
        Top
      </Typography>
      <OutlinedInput
        sx={{ width: '90px', height: '32px', borderRadius: '8px', ...label1 }}
        disabled={disabled}
        endAdornment={<InputAdornment position='start'>%</InputAdornment>}
        value={percentage}
        onChange={(e) => onChange?.(e.target.value)}
      ></OutlinedInput>
      <Typography variant='label1' style={{ color: disabled ? colorDisabled : 'inherit' }}>
        Amount Holders
      </Typography>
    </Stack>
  );
};
