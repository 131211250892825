import { AccountCircleOutlined, ArrowBackOutlined } from '@mui/icons-material';
import { Audience, AudienceTarget } from 'common/types/Audience';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';

import { InfluencerMatcherIcon } from 'components/icons/InfluencerMatcherIcon';
import classNames from 'classnames';
import classes from './AudienceApproachEdit.module.scss';
import { colorPrimary10 } from 'common/params';

interface AudienceApproachEditProps {
  audience: Audience;
  creating?: boolean;
  onAudienceApproachEdit?: (audience: Audience) => void;
  onBack?: () => void;
  onCreate?: () => void;
}

export const AudienceApproachEdit: FC<AudienceApproachEditProps> = ({
  audience,
  creating,
  onAudienceApproachEdit,
  onBack,
  onCreate,
}) => {
  const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(false);

  const audienceValid = useMemo(() => {
    return !!audience.target;
  }, [audience]);

  const onNextBtnClicked = useCallback(() => {
    setNextBtnClicked(true);
    if (!audienceValid) return;
    onCreate?.();
  }, [audienceValid, onCreate]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classNames(classes.container, classes.top)} spacing={2}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Chip color='primary' label={<Typography variant='h6'>2</Typography>} className={classes.step} />
          <Stack spacing={1} className={classes.title}>
            <Typography variant='h4' color={colorPrimary10}>
              Targeting Approach
            </Typography>
            <Typography variant='body1'>
              Map wallet addresses from selected cohorts to Twitter accounts for uploading as a Customized List Audience
              in Twitter Ads.
            </Typography>
            <Stack direction='row-reverse' spacing={2}>
              <Button
                variant='contained'
                disabled={creating || (nextBtnClicked && !audienceValid)}
                onClick={onNextBtnClicked}
              >
                Create
              </Button>
              <Button variant='outlined' onClick={onBack} startIcon={<ArrowBackOutlined />}>
                Back
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' className={classNames(classes.container, classes.bottom)} spacing={4}>
        <Stack
          id='area-ads-audience-create-customer-list'
          className={classNames(classes.card, audience.target === AudienceTarget.CUSTOMER_LIST && classes.selected)}
          onClick={() => onAudienceApproachEdit?.({ ...audience, target: AudienceTarget.CUSTOMER_LIST })}
        >
          <Stack spacing={2} alignItems='center'>
            <Chip
              label={<AccountCircleOutlined className={classNames(classes.svg, classes.account)} />}
              className={classes.icon}
            />
            <Typography variant='h6'>Customer List</Typography>
            <Typography variant='body1'>
              Convert selected wallet cohorts into a Twitter Customized List Audience.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          id='area-ads-audience-create-influencer-batch'
          className={classNames(classes.card, audience.target === AudienceTarget.INFLUENCER_BATCH && classes.selected)}
          onClick={() => onAudienceApproachEdit?.({ ...audience, target: AudienceTarget.INFLUENCER_BATCH })}
        >
          <Stack spacing={2} alignItems='center'>
            <Chip
              label={<InfluencerMatcherIcon className={classNames(classes.svg, classes.influencer)} />}
              className={classes.icon}
            />
            <Typography variant='h6'>Influencer Batch</Typography>
            <Typography variant='body1'>
              Get influencer recommendations based on chosen wallet cohorts to target their followers on Twitter,
              expanding reach.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
