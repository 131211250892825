import { FC, useEffect, useMemo, useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';

interface TopValueInputProps {
  top?: number;
  disabled?: boolean;
  onChange?: (top?: number) => void;
}

export const TopValueInput: FC<TopValueInputProps> = ({ top, disabled, onChange }) => {
  const [editTop, setEditTop] = useState<string | undefined>(top?.toString());

  useEffect(() => {
    setEditTop(top?.toString());
  }, [top]);

  const topError = useMemo(() => {
    const top = parseFloat(editTop || '');
    return typeof top === 'number' && (top > 100 || top < 0);
  }, [editTop]);

  return (
    <Stack direction='row' spacing={3} alignItems='center' justifyContent='flex-start'>
      <Typography variant='label1'>Top</Typography>
      <FormControl>
        <InputLabel>Top</InputLabel>
        <OutlinedInput
          label='Top'
          placeholder='Top'
          type='number'
          error={topError}
          disabled={disabled}
          value={editTop?.toString() || ''}
          onChange={(e) => {
            setEditTop(e.target.value);
          }}
          onBlur={() => {
            const top = parseFloat(editTop || '');
            const finalTop = Number.isNaN(top) ? undefined : top > 100 ? 100 : top < 0 ? 0 : top;
            onChange?.(finalTop);
            setEditTop((old) => (finalTop === 100 ? '100' : finalTop === 0 ? '0' : old));
          }}
        ></OutlinedInput>
      </FormControl>
      <Typography variant='label1'>%</Typography>
    </Stack>
  );
};
