import { Button, Paper, ThemeProvider } from '@mui/material';
import { datetimeFormatter, numberFormatter } from 'common/formatters';

import { Color } from 'common/types/Color';
import { FC } from 'react';
import { Gauge } from 'components/Guage/Guage';
import { Spinner } from 'components/common/Spinner';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import { darkTheme } from 'theme/ThemeProvider';
import { getColorProgress } from 'common/colors/getColorProgress';
import styles from './PredictionPanel.module.scss';

/** Color used in guage bar to indicate the coverage ratio, ordering from 0% to 100% */
const GUAGE_COLORS: Color[] = [
  [244, 91, 91], // Red
  [244, 194, 91], // Orange
  [235, 239, 56], // Yellow
  [177, 244, 91], // Green
];
const GUAGE_SIZE = 200;

type PredictionPanelProps = {
  direction?: 'vertical' | 'horizontal';
  resyncing?: boolean;
  selectedCount?: number;
  totalCount?: number;
  lastUpdated?: number;
  onPredict?: () => void;
};

export const PredictionPanel: FC<PredictionPanelProps> = ({
  direction = 'horizontal',
  resyncing,
  selectedCount,
  totalCount,
  lastUpdated,
  onPredict,
}) => {
  // if total count is zero -> progress should be zero
  const progress =
    typeof selectedCount === 'number' && totalCount
      ? selectedCount >= totalCount
        ? 100
        : Math.round((selectedCount / totalCount) * 1000) / 10
      : 0; // round to first float point

  return (
    // Apply dark them for theme consistency
    <ThemeProvider theme={darkTheme}>
      <Paper id='area-create-cohort-wallet-predict' elevation={0} className={styles.panel}>
        {direction === 'horizontal' ? (
          <Stack direction='row' spacing={4} justifyContent='space-between'>
            <Stack spacing={2}>
              <Typography variant='h5'>Cohort Wallet Count</Typography>
              <Typography variant='body1'>
                <Typography variant='h5' component='span' color={getColorProgress(GUAGE_COLORS, progress || 0)}>
                  {typeof selectedCount === 'number' ? numberFormatter.format(selectedCount) : '-'}
                </Typography>
                /{typeof totalCount === 'number' ? numberFormatter.format(totalCount) : '-'}
              </Typography>
              <Typography variant='body1'>Selected/All ETH wallets active past 60d</Typography>
              <Stack direction='row' spacing={2}>
                <Typography variant='body1'>Last updated</Typography>
                <Typography variant='body1'>
                  {typeof lastUpdated === 'number' ? datetimeFormatter.format(lastUpdated * 1000) : '-'}
                </Typography>
              </Stack>
              {onPredict ? (
                <Stack direction='row'>
                  <Button
                    variant='contained'
                    startIcon={resyncing ? <Spinner size={24} /> : null}
                    disabled={resyncing}
                    onClick={onPredict}
                  >
                    Update
                  </Button>
                </Stack>
              ) : null}
            </Stack>
            <Gauge
              size={GUAGE_SIZE}
              progress={progress}
              colors={GUAGE_COLORS}
              description={
                <Typography textAlign='center' variant='body1'>
                  Selected/All ETH wallets active past 60d
                </Typography>
              }
            />
          </Stack>
        ) : (
          <Stack spacing={4} alignItems='center'>
            <Gauge
              size={GUAGE_SIZE}
              progress={progress}
              colors={GUAGE_COLORS}
              description={
                <Typography textAlign='center' variant='body1'>
                  Selected/All ETH wallets active past 60d
                </Typography>
              }
            />
            <Stack spacing={2}>
              <Typography variant='h5'>Cohort Wallet Count</Typography>
              <Typography variant='body1'>
                <Typography variant='h5' component='span' color={getColorProgress(GUAGE_COLORS, progress || 0)}>
                  {typeof selectedCount === 'number' ? numberFormatter.format(selectedCount) : '-'}
                </Typography>
                /{typeof totalCount === 'number' ? numberFormatter.format(totalCount) : '-'}
              </Typography>
              <Typography variant='body1'>Selected/All ETH wallets active past 60d</Typography>
              <Stack direction='row' spacing={2}>
                <Typography variant='body1'>Last updated</Typography>
                <Typography variant='body1'>
                  {typeof lastUpdated === 'number' ? datetimeFormatter.format(lastUpdated * 1000) : '-'}
                </Typography>
              </Stack>
              {onPredict ? (
                <Stack direction='row' justifyContent='center'>
                  <Button
                    variant='contained'
                    startIcon={resyncing ? <Spinner size={24} /> : null}
                    disabled={resyncing}
                    onClick={onPredict}
                  >
                    Update
                  </Button>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        )}
      </Paper>
    </ThemeProvider>
  );
};
