import { Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { IOSSwitch } from 'components/switch/IOSSwitch';
import { VerifiedIcon } from 'components/icons/VerifiedIcon';
import classes from './VerifiedFilter.module.scss';

interface VerifiedFilterCollapseProps {
  data?: boolean;
  onChange?: (data: boolean) => void;
}

export const VerifiedFilterCollapse: FC<VerifiedFilterCollapseProps> = ({ data, onChange }) => {
  return (
    <Stack className={classes.root}>
      <Stack direction='row' className={classes.title} justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <VerifiedIcon className={classes.verified} />
          <Typography>Verified</Typography>
        </Stack>
        <IOSSwitch
          checked={data || false}
          onChange={(e) => {
            onChange?.(e.target.checked);
          }}
        />
      </Stack>
    </Stack>
  );
};
