import { FC, HTMLAttributes } from 'react';

import AccountsContextProvider from './AccountsContext';
import DataContextProvider from 'contexts/DataContext';
import { DataInitializer } from 'contexts/DataInitializer';
import ExtensionContextProvider from 'contexts/ExtensionContext';
import TrackingContextProvider from 'contexts/TrackingContext';
import TutorialContextProvider from 'contexts/TutorialContext';

export const ContextProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  return (
    <DataContextProvider>
      <ExtensionContextProvider>
        <AccountsContextProvider>
          <TrackingContextProvider>
            <TutorialContextProvider>
              <DataInitializer>{children}</DataInitializer>
            </TutorialContextProvider>
          </TrackingContextProvider>
        </AccountsContextProvider>
      </ExtensionContextProvider>
    </DataContextProvider>
  );
};
