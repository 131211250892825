import {
  AddOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ArticleOutlined,
  CloseOutlined,
  InfoOutlined,
  OpenInNewOutlined,
  SendOutlined,
} from '@mui/icons-material';
import { Button, Checkbox, MenuItem, MenuList, Popover, Select, Stack, Tooltip, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import {
  DefaultInfluencerPostLink,
  InfluencerCampaign,
  InfluencerPostLink,
  InfluencerThread,
  InfluencerThreadStage,
  InfluencerThreadStageNameMap,
  InfluencerThreadStatus,
  InfluencerThreadStatusNameMap,
} from 'common/types/Extension/InfluencerCampaign';
import { FC, HTMLAttributes, useEffect, useMemo, useState } from 'react';

import { AddPostLinkDialog } from '../Edit/Dialog/AddPostLinkDialog';
import { DeletePostLinkDialog } from '../Edit/Dialog/DeletePostLinkDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { InProgressIcon } from 'components/icons/InProgressIcon';
import { LabelChip } from 'components/chip/LabelChip';
import { TwitterUserInfo } from 'components/Twitter/TwitterUserInfo';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './InfluencerThreadList.module.scss';
import { colorPrimary40 } from 'common/params';
import { minutesToString } from 'common/utils';
import { useAsync } from 'react-use';

interface InfluencerThreadListProps extends HTMLAttributes<HTMLElement> {
  campaign: InfluencerCampaign;
  stage: InfluencerThreadStage;
  status: InfluencerThreadStatus;
  influencerThreads: InfluencerThread[];
  onInfluencerThreadsStageChangeClicked?: (threads: InfluencerThread[]) => void;
  onInfluencerThreadsMessageClicked?: (threads: InfluencerThread[], status: InfluencerThreadStatus) => void;
}

export const InfluencerThreadList: FC<InfluencerThreadListProps> = ({
  campaign,
  stage,
  status,
  influencerThreads,
  onInfluencerThreadsStageChangeClicked,
  onInfluencerThreadsMessageClicked,
  ...rest
}) => {
  const [selectedThreads, setSelectedThreads] = useState<InfluencerThread[]>([]);
  const [anchorElement, setAnchor] = useState<HTMLElement>();

  const [refetchPostLinks, setRefetchPostLinks] = useState<boolean>(false);
  const [addedPostLink, setAddedPostLink] = useState<InfluencerPostLink | undefined>(undefined);
  const [deletedPostLink, setDeletedPostLink] = useState<InfluencerPostLink | undefined>(undefined);

  const [showThreads, setShowThreads] = useState<InfluencerThread[]>([]);

  const { value: campaignPostLinks } = useAsync(async () => {
    if (typeof campaign.id !== 'number') return [];
    return (await ExtensionAPI.getInfluencerCampaignPostLinks(campaign.id)).data;
  }, [refetchPostLinks]);

  useEffect(() => {
    setSelectedThreads([]);
  }, [influencerThreads]);

  const isAllSelected = useMemo(() => {
    if (!showThreads.length) return false;
    return showThreads.every(
      (availableThread) => selectedThreads.findIndex((selectedThread) => selectedThread.id === availableThread.id) > -1,
    );
  }, [showThreads, selectedThreads]);

  const isPartialSelected = useMemo(() => {
    return (
      !isAllSelected &&
      showThreads.some(
        (availableThread) =>
          selectedThreads.findIndex((selectedThread) => selectedThread.id === availableThread.id) > -1,
      )
    );
  }, [isAllSelected, showThreads, selectedThreads]);

  const showPostLinks = useMemo(() => {
    return stage === InfluencerThreadStage.PUBLISHED && status === InfluencerThreadStatus.SENT;
  }, [stage, status]);

  const columnDefs: CommonTableColumn<InfluencerThread>[] = [
    {
      id: 'id',
      label: '#',
      startAdornment: (
        <Checkbox
          checked={isAllSelected}
          indeterminate={isPartialSelected}
          disabled={!influencerThreads.length}
          onChange={(e) => {
            if (!e.target.checked) {
              // de-select current show available threads
              setSelectedThreads((old) => [
                ...old.filter(
                  (selectedThread) =>
                    showThreads.findIndex((influencerThread) => influencerThread.id === selectedThread.id) === -1,
                ),
              ]);
            } else {
              // select all current show available threads
              setSelectedThreads((old) => [
                ...old,
                ...showThreads.filter(
                  (influencerThread) =>
                    old.findIndex((selectedThread) => selectedThread.id === influencerThread.id) === -1,
                ),
              ]);
            }
          }}
        ></Checkbox>
      ),
      render: (value, _, data) => {
        return (
          <Checkbox
            checked={selectedThreads.findIndex((selectedThread) => selectedThread.id === value) > -1}
            onChange={(e) => {
              if (!data) return;
              if (e.target.checked) {
                setSelectedThreads((old) => {
                  return [...old, data];
                });
              } else {
                setSelectedThreads((old) => {
                  return [...old.filter((selectedThread) => selectedThread.id !== value)];
                });
              }
            }}
          />
        );
      },
    },
    {
      id: 'display_name',
      label: 'Name',
      sortable: true,
      valueGetter: (data) => {
        return data?.receiver?.display_name;
      },
      render: (_, __, data) => {
        if (!data?.receiver) return <></>;
        return (
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            justifyContent='space-between'
            className={classes.profile}
          >
            <TwitterUserInfo user={data.receiver} />
            <OpenInNewOutlined
              fontSize='small'
              className={classes.link}
              color='primary'
              onClick={() =>
                window.open(`${Uris.External.Twitter}/messages/${data?.receiver_id}-${data?.sender_id}`, '_blank')
              }
            />
          </Stack>
        );
      },
    },
    {
      id: 'status',
      label: 'Status',
      render: (value, _, data) => {
        if (value === InfluencerThreadStatus.FAILED)
          return (
            <Tooltip title={data?.error_msg} placement='top' arrow>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography color='error'>{InfluencerThreadStatusNameMap.get(value)}</Typography>
                <InfoOutlined color='error' fontSize='small' />
              </Stack>
            </Tooltip>
          );
        return <Typography color='secondary'>{InfluencerThreadStatusNameMap.get(value)}</Typography>;
      },
    },
    {
      id: 'idx',
      label: 'Stage',
      render: (_, __, data) => {
        return (
          <Stack className={classNames(classes.actionBtn, showPostLinks && classes.showPostLinks)}>
            <Stack direction='row'>
              <Select
                className={classes.select}
                disabled={status === InfluencerThreadStatus.SENDING || status === InfluencerThreadStatus.FAILED}
                value={data?.stage}
                onChange={(e) =>
                  data &&
                  onInfluencerThreadsStageChangeClicked?.([{ ...data, stage: e.target.value as InfluencerThreadStage }])
                }
              >
                {Object.entries(InfluencerThreadStage).map((entry) => (
                  <MenuItem key={entry[1]} value={entry[1]}>
                    {InfluencerThreadStageNameMap.get(entry[1])}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  if (showPostLinks) {
    columnDefs.push({
      id: 'receiver_id',
      label: 'Delivered Posts',
      render: (value) => {
        return (
          <Stack direction='row' className={classes.postLinksColumn} spacing={2}>
            <Stack direction='row' className={classes.postLinks} spacing={1}>
              {(campaignPostLinks || [])
                .filter((postLink) => postLink.twitter_user_id === value)
                .map((postLink) => (
                  <LabelChip
                    startIcon={<ArticleOutlined />}
                    color={colorPrimary40}
                    key={postLink.id}
                    onCancel={() => setDeletedPostLink(postLink)}
                  >
                    {postLink.name}
                  </LabelChip>
                ))}
            </Stack>
            <Button
              startIcon={<AddOutlined />}
              onClick={() =>
                setAddedPostLink({
                  ...DefaultInfluencerPostLink,
                  influencer_campaign_id: campaign.id || 0,
                  twitter_user_id: value,
                })
              }
            >
              Add Post
            </Button>
          </Stack>
        );
      },
    });
  }

  const tableHeader = useMemo(() => {
    const btns = () => {
      switch (status) {
        case InfluencerThreadStatus.INIT:
          return (
            <>
              <Button
                variant='outlined'
                className={classes.changeStage}
                disabled={!selectedThreads.length}
                endIcon={anchorElement != null ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
                onClick={(e) => setAnchor(e.currentTarget)}
              >
                Change State
              </Button>
              <Button
                className={classes.msgBtn}
                startIcon={<SendOutlined />}
                disabled={!selectedThreads.length}
                variant='contained'
                onClick={() => {
                  if (!selectedThreads.length) return;
                  onInfluencerThreadsMessageClicked?.(selectedThreads, status);
                }}
              >
                Message
              </Button>
            </>
          );
        case InfluencerThreadStatus.SENDING:
          return (
            <Stack direction='row' spacing={1}>
              <Typography>
                {minutesToString(influencerThreads.length ? influencerThreads.length * 1.5 + 10 : 0)} left
              </Typography>
              <Stack style={{ color: colorPrimary40 }}>
                <InProgressIcon />
              </Stack>
            </Stack>
          );
        case InfluencerThreadStatus.SENT:
          return (
            <>
              <Button
                variant='outlined'
                className={classes.changeStage}
                disabled={!selectedThreads.length}
                endIcon={anchorElement != null ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
                onClick={(e) => setAnchor(e.currentTarget)}
              >
                Change State
              </Button>
              <Button
                className={classes.msgBtn}
                startIcon={<SendOutlined />}
                disabled={!selectedThreads.length}
                variant='contained'
                onClick={() => {
                  if (!selectedThreads.length) return;
                  onInfluencerThreadsMessageClicked?.(selectedThreads, status);
                }}
              >
                Message
              </Button>
            </>
          );
        case InfluencerThreadStatus.FAILED:
          return (
            <>
              <Button
                className={classes.msgBtn}
                startIcon={<SendOutlined />}
                disabled={!selectedThreads.length}
                variant='contained'
                onClick={() => {
                  if (!selectedThreads.length) return;
                  onInfluencerThreadsMessageClicked?.(selectedThreads, status);
                }}
              >
                Resend
              </Button>
            </>
          );
      }
    };
    return (
      <Stack direction='row' alignItems='center' className={classes.tableHeader} justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Typography>{selectedThreads.length} influencers selected</Typography>
          <Button variant='outlined' startIcon={<CloseOutlined />} onClick={() => setSelectedThreads([])}>
            Deselect All
          </Button>
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2}>
          {btns()}

          <Popover
            open={anchorElement != null}
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              '.MuiPaper-root': {
                width: '160px',
              },
            }}
            onClose={() => {
              setAnchor(undefined);
            }}
          >
            <MenuList>
              {Array.from(InfluencerThreadStageNameMap.entries())
                .filter((entry) => entry[0] !== stage)
                .map((entry) => (
                  <MenuItem
                    key={entry[0]}
                    value={entry[0]}
                    onClick={() => {
                      if (!selectedThreads.length) return;
                      onInfluencerThreadsStageChangeClicked?.(
                        selectedThreads.map((thread) => ({ ...thread, stage: entry[0] })),
                      );
                    }}
                  >
                    {entry[1]}
                  </MenuItem>
                ))}
            </MenuList>
          </Popover>
        </Stack>
      </Stack>
    );
  }, [
    anchorElement,
    influencerThreads,
    stage,
    status,
    selectedThreads,
    onInfluencerThreadsStageChangeClicked,
    onInfluencerThreadsMessageClicked,
  ]);

  return (
    <>
      <CommonTable
        {...rest}
        data={influencerThreads}
        columns={columnDefs}
        classes={classes}
        header={tableHeader}
        onShowDataChange={(data) => setShowThreads(data)}
      />

      {addedPostLink ? (
        <AddPostLinkDialog
          open
          postLink={addedPostLink}
          onAdd={() => {
            setAddedPostLink(undefined);
            setRefetchPostLinks((old) => !old);
          }}
          onCancel={() => setAddedPostLink(undefined)}
        />
      ) : null}

      {deletedPostLink ? (
        <DeletePostLinkDialog
          open
          postLink={deletedPostLink}
          onDelete={() => {
            setDeletedPostLink(undefined);
            setRefetchPostLinks((old) => !old);
          }}
          onCancel={() => setDeletedPostLink(undefined)}
        />
      ) : null}
    </>
  );
};
