import { Chip, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { LogicalOperator, LogicalOperatorNameMap } from 'common/types/LogicalOperator';
import {
  border,
  borderDisabled,
  borderRadius,
  chipInputHeight,
  colorDisabled,
  colorNeutralVariant50,
} from 'common/params';

import { AndIcon } from 'components/icons/AndIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { OrIcon } from 'components/icons/OrIcon';

interface LogicalOperatorSelectProps {
  data?: LogicalOperator;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (data: LogicalOperator) => void;
}

const LogicalOperatorIconMap = new Map<string, JSX.Element>([
  [LogicalOperator.OR, <OrIcon />],
  [LogicalOperator.AND, <AndIcon />],
]);

const options = [
  {
    value: LogicalOperator.OR,
    icon: LogicalOperatorIconMap.get(LogicalOperator.OR),
    label: LogicalOperatorNameMap.get(LogicalOperator.OR),
  },
  {
    value: LogicalOperator.AND,
    icon: LogicalOperatorIconMap.get(LogicalOperator.AND),
    label: LogicalOperatorNameMap.get(LogicalOperator.AND),
  },
];

export const LogicalOperatorSelect: FC<LogicalOperatorSelectProps> = ({ data, disabled, readonly, onChange }) => {
  const onLogicOperatorChange = useCallback(
    (logicOperator: LogicalOperator) => {
      onChange?.(logicOperator);
    },
    [onChange],
  );

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      {readonly ? (
        <Chip
          sx={{
            border: disabled ? borderDisabled : border,
            color: disabled ? colorDisabled : colorNeutralVariant50,
            borderRadius: borderRadius,
            backgroundColor: 'transparent',
          }}
          icon={
            <Stack style={{ color: disabled ? colorDisabled : colorNeutralVariant50 }}>
              {LogicalOperatorIconMap.get(data || '')}
            </Stack>
          }
          label={<Typography variant='label1'>{LogicalOperatorNameMap.get(data || '')}</Typography>}
        />
      ) : (
        <Select
          value={data || ''}
          disabled={disabled}
          onChange={(e) => onLogicOperatorChange(e.target.value as LogicalOperator)}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            width: '100px',
            height: chipInputHeight,
            borderRadius: borderRadius,
          }}
          renderValue={(v) => {
            return (
              <Stack direction='row' alignItems='center'>
                {LogicalOperatorIconMap.get(v)}
                <Typography variant='label1'>{LogicalOperatorNameMap.get(v)}</Typography>
              </Stack>
            );
          }}
        >
          {options.map(({ value, icon, label }) => (
            <MenuItem value={value} key={value}>
              <Stack direction='row' alignItems='center'>
                {icon}
                <Typography variant='body1'>{label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      )}
    </Stack>
  );
};
