import {
  Breadcrumbs,
  Button,
  Dialog,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  OutlinedInput,
  Paper,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InsightsOutlined,
  ListOutlined,
  MonetizationOnOutlined,
  RefreshOutlined,
  TagOutlined,
} from '@mui/icons-material/';
import { DefaultPaginationOptions, PaginationOptions } from 'components/table/CommonTable';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { body1, h1 } from 'theme/typography';
import { colorError40, colorError80, colorNeutral40, colorPrimary30 } from 'common/params';
import { datetimeFormatter, numberFormatter } from 'common/formatters';
import { useNavigate, useParams } from 'react-router-dom';

import { BooleanIcon } from 'components/icons/BooleanIcon';
import ClientAPI from 'common/ClientAPI';
import { Cohort } from 'common/types/Cohort';
import { CohortInsight } from 'pages/WalletSelector/Cohort/CohortInsight';
import { CohortWalletInfo } from 'common/types/WalletInfo';
import { Color } from 'common/types/Color';
import { DataContext } from 'contexts/DataContext';
import { FilterDetails } from 'components/filter/FilterDetails';
import { Gauge } from 'components/Guage/Guage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NotFoundPage } from 'pages/Common/NotFoundPage';
import { SchedulerSelect } from 'components/select/SchedulerSelect';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import { WalletIcon } from 'components/icons/WalletIcon';
import { WalletList } from 'pages/WalletSelector/Wallet/WalletList';
import classNames from 'classnames';
import classes from './CohortDetail.module.scss';
import { darkTheme } from 'theme/ThemeProvider';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

/** Color used in guage bar to indicate the coverage ratio, ordering from 0% to 100% */
const GUAGE_COLORS: Color[] = [
  [244, 91, 91], // Red
  [244, 194, 91], // Orange
  [235, 239, 56], // Yellow
  [177, 244, 91], // Green
];
const GUAGE_SIZE = 200;

interface DetailTab {
  icon: React.ReactNode; // icon for the tab
  label: string; // display label for the tab
}

const tabs: DetailTab[] = [
  { label: 'Filter Details', icon: <BooleanIcon /> },
  { label: 'Wallet Lists', icon: <ListOutlined /> },
  { label: 'Insight', icon: <InsightsOutlined /> },
];

export const CohortDetail: FC<any> = (props) => {
  const { cohortId } = useParams();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const navigate = useNavigate();
  const [edit, setEdit] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [cohort, setCohort] = useState<Cohort | undefined>(undefined);
  const [wallets, setWallets] = useState<CohortWalletInfo[]>([]);
  const [totalWalletCnt, setTotalWalletCnt] = useState<number>(0);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>(DefaultPaginationOptions);
  const [resyncDialogOpened, setResyncDialogOpened] = useState<boolean>(false);
  const [updateCohortDialogOpened, setUpdateCohortDialogOpened] = useState<boolean>(false);
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const { activeWalletCount$ } = useContext(DataContext);
  const [resyncing, setResyncing] = useState<boolean>(false);

  const {
    loading: cohortLoading,
    error: cohortError,
    value: cohortValue,
  } = useAsync(async () => {
    const id = Number.parseInt(cohortId || '');
    if (Number.isNaN(id)) return undefined;
    return (await ClientAPI.getCohort(id)).data;
  }, [refetch]);

  const {
    loading: walletsLoading,
    error: walletsError,
    value: walletsValue,
  } = useAsync(async () => {
    const id = Number.parseInt(cohortId || '');
    if (Number.isNaN(id)) return undefined;
    return await ClientAPI.getWalletList(id, paginationOptions);
  }, [paginationOptions, refetch]);

  const {
    loading: growing3CategoriesLoading,
    error: growing3CategoriesError,
    value: growing3Categories,
  } = useAsync(async () => {
    return (await ClientAPI.getGrowing3Categories()).data;
  }, []);

  const {
    loading: customizedCategoriesLoading,
    error: customizedCategoriesError,
    value: customizedCategories,
  } = useAsync(async () => {
    return (await ClientAPI.getCustomizedCategories()).data;
  }, []);

  const updateCohort = useCallback(
    async (cohort: Cohort) => {
      await ClientAPI.updateCohort(cohort)
        .then(({ status, message }) => {
          if (status === 'success') {
            setEdit(false);
            setRefetch((old) => !old);
            return;
          }
          showMessage(`Update Cohort failed, ${message}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    },
    [showMessage],
  );

  useEffect(() => {
    if (!cohortValue) return;
    setCohort(cohortValue);
  }, [cohortValue]);

  useEffect(() => {
    cohortError && showMessage(cohortError?.message || 'Unknown Error', 'error');
  }, [cohortError, showMessage]);

  useEffect(() => {
    if (!walletsValue) return;
    setTotalWalletCnt(walletsValue.total_rows || 0);
    setWallets(walletsValue.data || []);
  }, [walletsValue]);

  useEffect(() => {
    walletsError && showMessage(walletsError?.message || 'Unknown Error', 'error');
  }, [walletsError, showMessage]);

  useEffect(() => {
    growing3CategoriesError &&
      growing3CategoriesError &&
      showMessage(growing3CategoriesError?.message || 'Unknown Error', 'error');
  }, [growing3CategoriesError, showMessage]);

  useEffect(() => {
    customizedCategoriesError &&
      customizedCategoriesError &&
      showMessage(customizedCategoriesError?.message || 'Unknown Error', 'error');
  }, [customizedCategoriesError, showMessage]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }, []);

  const onResyncDialogClose = useCallback(() => {
    setResyncDialogOpened(false);
  }, []);

  const onResyncDialogConfirm = useCallback(async () => {
    if (typeof cohort?.id !== 'number') return;
    setResyncing(true);
    await ClientAPI.createCohortJob(cohort?.id)
      .then(({ status, message }) => {
        if (status === 'success') {
          navigate(Uris.Pages.WalletSelector.Index);
          track('click', {
            sub_event: 'cohort_size_resynced',
            custom_props: { cohort_id: cohort.id, cohort_name: cohort.name },
          });
          return;
        }
        showMessage(`Resync Cohort failed, ${message}`, 'error');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setResyncing(false);
      });
  }, [cohort, navigate, showMessage, track]);

  const onUpdateCohortDialogClose = useCallback(() => {
    setUpdateCohortDialogOpened(false);
  }, []);

  const onWalletTablePageChange = useCallback((paginationOptions: PaginationOptions) => {
    setPaginationOptions(paginationOptions);
  }, []);

  const onDownloadClicked = useCallback(async () => {
    if (!cohort?.id) return;
    setDownloadDisabled(true);
    await ClientAPI.getCohortWalletDownloadLink(cohort.id)
      .then(({ status, data }) => {
        if (status === 'success' && data) {
          const link = document.createElement('a');
          link.href = data;
          link.click();
          track('click', {
            sub_event: 'cohort_list_downloaded',
            custom_props: { cohort_id: cohort.id, cohort_name: cohort.name },
          });
          return;
        }
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Download Error', 'error');
      })
      .finally(() => {
        setDownloadDisabled(false);
      });
  }, [cohort, showMessage, track]);

  const isCohortCategoriesValid = useMemo(() => {
    if (!cohort) return true;
    if (growing3CategoriesLoading || customizedCategoriesLoading) return true;
    const growing3CategoriesValid = cohort.growing3_categories?.reduce(
      (valid, categoryId) =>
        valid && growing3Categories?.find((category) => category.id === categoryId)?.disabled === false,
      true,
    );
    const customizedCategoriesValid = cohort.customized_categories?.reduce(
      (valid, categoryId) =>
        valid && customizedCategories?.find((category) => category.id === categoryId)?.disabled === false,
      true,
    );
    return growing3CategoriesValid && customizedCategoriesValid;
  }, [cohort, growing3CategoriesLoading, customizedCategoriesLoading, growing3Categories, customizedCategories]);

  const mainContent = useMemo(() => {
    if (!cohort || !cohort.id) return null;
    switch (tab) {
      case 0: // Filter Details
        return <FilterDetails cohort={cohort} />;
      case 1: // Wallet Lists
        return (
          <WalletList
            className={classes.table}
            cohortId={cohort.id}
            cohortName={cohort.name}
            wallets={wallets}
            totalWalletCnt={totalWalletCnt}
            loading={walletsLoading}
            downloadDisabled={downloadDisabled}
            onPageChange={onWalletTablePageChange}
            onDownloadClicked={onDownloadClicked}
          ></WalletList>
        );
      case 2: // Insight
        return (
          <Stack className={classes.comingSoon}>
            <CohortInsight cohortId={cohort.id} />
          </Stack>
        );
      default:
        return <></>;
    }
  }, [
    tab,
    cohort,
    walletsLoading,
    wallets,
    totalWalletCnt,
    downloadDisabled,
    onWalletTablePageChange,
    onDownloadClicked,
  ]);

  if (cohortLoading) return <Spinner />;
  if (!cohort) return <NotFoundPage />;

  return (
    <Stack className={classes.root}>
      {/* top black area */}
      <ThemeProvider theme={darkTheme}>
        <Paper className={classes.blackArea}>
          {/* breadcrumbs */}
          <Breadcrumbs
            separator={<NavigateNextIcon style={{ color: colorNeutral40 }} />}
            className={classes.breadcrumbs}
            aria-label='breadcrumb'
          >
            <Link
              variant='subtitle2'
              underline='none'
              color={colorNeutral40}
              className={classes.link}
              onClick={() => navigate(Uris.Pages.WalletSelector.Index)}
            >
              Wallet Selector
            </Link>
            <Typography color={colorPrimary30} variant='subtitle2'>
              {cohort.name}
            </Typography>
          </Breadcrumbs>
          <Stack direction='row' spacing={4} className={classes.container}>
            <Stack direction='row' spacing={4} className={classes.content}>
              {/* info content */}
              <Stack direction='column' spacing={1} className={classes.info}>
                {edit ? (
                  <>
                    <OutlinedInput
                      multiline
                      className={classes.name}
                      sx={{
                        ...h1,
                      }}
                      value={cohort.name}
                      onChange={(e) => setCohort((old) => (old ? { ...old, name: e.target.value } : old))}
                    />
                    <OutlinedInput
                      multiline
                      className={classes.description}
                      sx={{
                        ...body1,
                      }}
                      value={cohort.description}
                      onChange={(e) =>
                        // maximum -> 250 characters
                        setCohort((old) => (old ? { ...old, description: e.target.value.substring(0, 249) } : old))
                      }
                    />
                  </>
                ) : (
                  <>
                    <Typography variant='h1' className={classes.name}>
                      {cohort.name}
                    </Typography>
                    <Typography variant='body1'>{cohort.description}</Typography>
                  </>
                )}
                {/* <Typography variant='body1'>{cohort.description}</Typography> */}
                <Stack direction='row' spacing={2} alignItems='center'>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='body1'>Update frequency:</Typography>
                    <SchedulerSelect
                      dark
                      readonly
                      data={cohort.scheduler}
                      onChange={(scheduler) => setCohort((old) => (old ? { ...old, scheduler } : old))}
                    />
                  </Stack>
                  <FormControl disabled={edit}>
                    <Stack direction='row' spacing={1}>
                      <FormLabel>
                        <Typography variant='body1'>Updated at</Typography>
                      </FormLabel>
                      <FormLabel>
                        <Typography variant='body1'>
                          {typeof cohort.last_run_at === 'number'
                            ? datetimeFormatter.format(cohort.last_run_at * 1000)
                            : ''}
                        </Typography>
                      </FormLabel>
                    </Stack>
                  </FormControl>
                </Stack>
                <FormControl disabled={edit}>
                  <Stack direction='row' spacing={1}>
                    <FormLabel>
                      <Typography variant='body1'>Created at</Typography>
                    </FormLabel>
                    <FormLabel>
                      <Typography variant='body1'>
                        {typeof cohort.created_at === 'number'
                          ? datetimeFormatter.format(cohort.created_at * 1000)
                          : ''}
                      </Typography>
                    </FormLabel>
                  </Stack>
                </FormControl>
                <Stack direction='row' spacing={1}>
                  <Stack className={classNames(classes.box, edit && classes.disabled)} spacing={3}>
                    <Stack className={classes.chip}>
                      <MonetizationOnOutlined fontSize='large' className={classes.icon} />
                    </Stack>
                    <Stack>
                      <Stack direction='row' spacing={1} alignItems='flex-end'>
                        <Typography variant='h6' className={classes.count}>
                          {numberFormatter.format(cohort.total_balance || 0)}
                        </Typography>
                        <Typography variant='subtitle1' className={classes.count}>
                          USD
                        </Typography>
                      </Stack>
                      <Typography variant='label1'>Selected wallet balance on ETH Chain</Typography>
                    </Stack>
                  </Stack>
                  <Stack className={classNames(classes.box, edit && classes.disabled)} spacing={3}>
                    <Stack className={classes.chip}>
                      <WalletIcon fontSize='large' className={classes.icon} />
                    </Stack>
                    <Stack>
                      <Stack direction='row' spacing={1} alignItems='flex-end'>
                        <Typography variant='h6' className={classes.count}>
                          {numberFormatter.format(cohort.wallet_count || 0)}
                        </Typography>
                        <Typography variant='subtitle1'>/{numberFormatter.format(activeWalletCount$.value)}</Typography>
                      </Stack>
                      <Typography variant='label1'>Selected/All ETH wallets active past 60d</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {/* edit button */}
              <Stack direction='column' spacing={2}>
                {edit ? (
                  <>
                    <IconButton className={classes.actionBtn} onClick={() => updateCohort(cohort)}>
                      <CheckOutlined className={classes.icon} />
                    </IconButton>
                    <IconButton
                      className={classes.actionBtn}
                      onClick={() => {
                        setCohort(cohortValue);
                        setEdit(false);
                      }}
                    >
                      <CloseOutlined className={classes.icon} />
                    </IconButton>
                  </>
                ) : (
                  <IconButton className={classes.actionBtn} onClick={() => setEdit(true)}>
                    <EditOutlined className={classes.icon} />
                  </IconButton>
                )}
              </Stack>
              {/* gauge chart */}
              <Stack className={classNames(classes.chart, edit && classes.disabled)}>
                <Stack
                  direction='column'
                  spacing={2}
                  className={classes.container}
                  alignItems='center'
                  justifyContent='center'
                >
                  <Gauge
                    size={GUAGE_SIZE}
                    progress={
                      activeWalletCount$.value
                        ? (cohort.wallet_count || 0) >= activeWalletCount$.value
                          ? 100
                          : Math.round(((cohort.wallet_count || 0) * 1000) / activeWalletCount$.value) / 10
                        : 0
                    }
                    colors={GUAGE_COLORS}
                    description={
                      <Typography textAlign='center' variant='body2'>
                        of wallet count selected on ETH chain
                      </Typography>
                    }
                  />
                  <Stack direction='row' justifyContent='center'>
                    <Button
                      className={classes.updateBtn}
                      variant='contained'
                      color='primary'
                      disabled={edit}
                      startIcon={<RefreshOutlined />}
                      onClick={() => {
                        if (!isCohortCategoriesValid) {
                          setUpdateCohortDialogOpened(true);
                        } else {
                          setResyncDialogOpened(true);
                        }
                      }}
                    >
                      Update
                    </Button>
                  </Stack>
                  {!isCohortCategoriesValid ? (
                    <Typography color={colorError80}>
                      Several categories within this cohort have been removed.{' '}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </ThemeProvider>
      {/* bottom detail area */}
      <Stack direction='column' spacing={4} alignItems='center' className={classes.detail}>
        <Tabs value={tab} onChange={handleTabChange} centered className={classes.tabs}>
          {tabs.map((t) => (
            <Tab
              key={t.label}
              className={classes.tab}
              disabled={edit}
              label={
                <Stack direction='row' alignItems='center' spacing={1}>
                  {t.icon}
                  <Typography variant='subtitle2'>{t.label}</Typography>
                </Stack>
              }
            />
          ))}
        </Tabs>
        {mainContent}
      </Stack>
      {/* resync confirm dialog */}
      <Dialog
        className={classes.dialog}
        open={resyncDialogOpened}
        onClose={(evt, reason) => {
          reason !== 'backdropClick' && onResyncDialogClose();
        }}
        PaperProps={{ sx: { backgroundColor: 'transparent' } }}
      >
        <Stack className={classes.container}>
          <Stack direction='row-reverse' className={classes.header}>
            <IconButton onClick={onResyncDialogClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Stack spacing={3} className={classes.content} alignItems='center'>
            <Stack className={classes.chip}>
              <RefreshOutlined fontSize='large' className={classes.icon} />
            </Stack>
            <Stack spacing={2}>
              <Typography variant='h6'>Please confirm if you would like to Re-Sync.</Typography>
              <Typography variant='body1'>
                Once submitted, the cohort will reset its calculation based on new wallet counts, and you will be unable
                to view the results during the synchronization period.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='center' spacing={2} className={classes.footer}>
            <Button variant='outlined' onClick={onResyncDialogClose}>
              Cancel
            </Button>
            <Button variant='contained' disabled={resyncing} onClick={onResyncDialogConfirm}>
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      {/* update cohort dialog */}
      <Dialog
        className={classes.dialog}
        open={updateCohortDialogOpened}
        onClose={(evt, reason) => {
          reason !== 'backdropClick' && onUpdateCohortDialogClose();
        }}
        PaperProps={{ sx: { backgroundColor: 'transparent' } }}
      >
        <Stack className={classes.container}>
          <Stack direction='row-reverse' className={classes.header}>
            <IconButton onClick={onUpdateCohortDialogClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Stack spacing={3} className={classes.content} alignItems='center'>
            <Stack className={classes.chip}>
              <TagOutlined fontSize='large' className={classes.icon} />
            </Stack>
            <Stack spacing={2}>
              <Typography variant='h6' color={colorError40}>
                Update Cohort Categories
              </Typography>
              <Typography variant='body1' color={colorError40}>
                Several categories within this cohort have been removed. Create a new cohort with the existing
                categories.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='center' spacing={2} className={classes.footer}>
            <Button variant='outlined' onClick={onUpdateCohortDialogClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                if (!cohort) return;
                track('form_start', { sub_event: 'cohort_add_started' });
                // navigate to create cohort page and pass cohort data via state
                navigate(Uris.Pages.WalletSelector.CreateCohort, {
                  state: { cohort: cohort },
                });
              }}
            >
              Create Cohort
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  );
};
