export enum CampaignLinkChannel {
  TWITTER_ADS = 'twitter_ads',
  CUSTOMIZED = 'customized',
}

export interface CampaignLinkBase {
  channel: CampaignLinkChannel;
  name: string;
  campaign: string;
  source: string;
  medium: string;
  original_url: string;
  content?: string;
  term?: string;
  audience_id?: number;
  ad_groups?: Record<string, Record<string, string>>;
}

export interface CampaignLink extends CampaignLinkBase {
  id: number;
  created_at?: number;
  updated_at?: number;
  user_id: string;
}

export const DefaultCampaignLinkBase: CampaignLinkBase = {
  channel: CampaignLinkChannel.TWITTER_ADS,
  name: '',
  campaign: '',
  source: '',
  medium: '',
  original_url: '',
};

export const generateCampaignLinkUrl = (campaignLink: CampaignLinkBase) => {
  const url = new URL(campaignLink.original_url);
  const params = new URLSearchParams(url.search);
  params.set('utm_campaign', campaignLink.campaign);
  params.set('utm_source', campaignLink.source);
  params.set('utm_medium', campaignLink.medium);
  if (campaignLink.term) params.set('utm_term', campaignLink.term);
  if (campaignLink.content) params.set('utm_content', campaignLink.content);
  const finalUrl = `${url.origin}${url.pathname}?${params.toString()}`;
  return encodeURI(finalUrl);
};
