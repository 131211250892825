import { DefiProject, TargetDefiProject } from 'common/types/DefiProject';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { InputAdornment, ListSubheader, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { borderRadius, chipInputHeight, colorSurface4 } from 'common/params';

import { DataContext } from 'contexts/DataContext';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { dedupArray } from 'common/utils';
import { useObservable } from 'react-use';

interface DefiProjectSelectProps {
  data?: TargetDefiProject;
  disabled?: boolean;
  onChange?: (data: TargetDefiProject) => void;
}

const extractActionsByProjectName = (defiProjects: DefiProject[], projectName: string) => {
  return dedupArray(
    defiProjects
      .filter((defiProject) => defiProject.name === projectName)
      .reduce((pre, cur) => pre.concat(cur.actions), [] as string[]),
  );
};

export const DefiProjectSelect: FC<DefiProjectSelectProps> = ({ data, disabled, onChange }) => {
  const { defiProjects$ } = useContext(DataContext);
  const defiProjects = useObservable(defiProjects$, []);
  const [action, setAction] = useState<string>(data?.action || '');
  const [searchStr, setSearchStr] = useState<string>('');
  const [projectName, setProjectName] = useState<string>(data?.name || '');

  const projectNames = useMemo(() => {
    return dedupArray(
      defiProjects
        .filter((defiProject) => {
          return defiProject.name.toUpperCase().startsWith(searchStr.toUpperCase());
        })
        .map((defiProject) => defiProject.name),
    );
  }, [defiProjects, searchStr]);

  const projectNameGroups = useMemo(() => {
    const groups: { name: string; projects: string[] }[] = [];
    projectNames.forEach((projectName) => {
      const dashIndex = projectName.indexOf('-');
      let project = projectName;
      if (dashIndex > -1) {
        project = projectName.substring(0, dashIndex - 1).trim();
      }
      const idx = groups.findIndex((group) => group.name === project);
      if (idx > -1) {
        groups[idx].projects.push(projectName);
      } else {
        groups.push({ name: project, projects: [projectName] });
      }
    });
    return groups;
  }, [projectNames]);

  const actions = useMemo(() => {
    if (!defiProjects.length) return [];
    return extractActionsByProjectName(defiProjects, projectName);
  }, [defiProjects, projectName]);

  const handleProjectNameChange = useCallback(
    (projectName: string) => {
      setProjectName(projectName);
      const actions = extractActionsByProjectName(defiProjects, projectName);
      if (!actions.length) return;
      setAction(actions[0]);
      onChange?.({ name: projectName, action: actions[0] });
    },
    [defiProjects, onChange],
  );

  const handleActionChange = useCallback(
    (action: string) => {
      setAction(action);
      onChange?.({ name: projectName, action });
    },
    [projectName, onChange],
  );

  return (
    <Stack direction='row' spacing={1}>
      <Select
        value={projectName}
        onChange={(e) => handleProjectNameChange(e.target.value as string)}
        disabled={disabled}
        sx={{
          width: '120px',
          height: chipInputHeight,
          borderRadius: borderRadius,
        }}
        MenuProps={{
          disableAutoFocusItem: true,
          sx: {
            height: '50vh',
          },
        }}
        renderValue={(v) => {
          return <Typography variant='label1'>{projectName}</Typography>;
        }}
      >
        <Stack>
          <OutlinedInput
            startAdornment={
              <InputAdornment position='start'>
                <SearchOutlinedIcon />
              </InputAdornment>
            }
            placeholder='Search'
            sx={{ width: '100%', '& fieldset': { borderColor: 'transparent' } }}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value.toUpperCase())}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Stack>
        {projectNameGroups.map((projectNameGroup) => {
          if (projectNameGroup.projects.length > 1) {
            // due to MenuItem should be direct child of Select and element map key issues
            // here we use jsxElements to prepare the elements to show
            const jsxElements = [];
            jsxElements.push(
              <ListSubheader style={{ backgroundColor: colorSurface4 }}>
                <Typography variant='body1' style={{ padding: '6px 0px' }}>
                  {projectNameGroup.name}
                </Typography>
              </ListSubheader>,
            );
            projectNameGroup.projects.forEach((project) =>
              jsxElements.push(
                <MenuItem key={project} value={project} onClick={() => handleProjectNameChange(project)}>
                  <Typography variant='body1' style={{ marginLeft: '16px' }}>
                    {project}
                  </Typography>
                </MenuItem>,
              ),
            );
            return jsxElements;
          }
          return (
            <MenuItem key={projectNameGroup.projects[0]} value={projectNameGroup.projects[0]}>
              <Typography variant='body1'>{projectNameGroup.projects[0]}</Typography>
            </MenuItem>
          );
        })}
      </Select>
      <Select
        value={action}
        onChange={(e) => handleActionChange(e.target.value as string)}
        disabled={disabled}
        sx={{
          width: '120px',
          height: chipInputHeight,
          borderRadius: borderRadius,
        }}
        renderValue={(v) => {
          return <Typography variant='label1'>{action}</Typography>;
        }}
      >
        {actions.map((action) => (
          <MenuItem key={action} value={action}>
            <Typography variant='body1'>{action}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
