import { FC, useCallback } from 'react';

import { AddessInteraction } from 'common/types/AddressInteraction';
import { AddressSelect } from 'components/select/AddressSelect';
import { CohortContractFilterOperand } from 'common/types/CohortFilter';
import { LastDays } from 'common/types/LastDays';
import { LastDaysSelect } from 'components/select/LastDaysSelect';
import { Stack } from '@mui/material';

export interface ContractInteractionOperandProps {
  data: CohortContractFilterOperand;
  disabled?: boolean;
  onChange?: (data: CohortContractFilterOperand) => void;
}

export const ContractInteractionOperand: FC<ContractInteractionOperandProps> = ({ data, disabled, onChange }) => {
  const onLastDaysChange = useCallback(
    (last_days: LastDays) => {
      onChange?.({ ...data, last_days });
    },
    [data, onChange],
  );
  const onAddressChange = useCallback(
    (address_interaction: AddessInteraction) => {
      onChange?.({ ...data, ...address_interaction });
    },
    [data, onChange],
  );
  return (
    <Stack direction='column' spacing={1}>
      <LastDaysSelect data={data.last_days} disabled={disabled} onChange={onLastDaysChange} />
      <AddressSelect
        data={{ contract_addr: data.contract_addr, has_interaction: data.has_interaction }}
        disabled={disabled}
        onChange={onAddressChange}
      />
    </Stack>
  );
};
