import { JobStatus } from './JobStatus';

export enum AudienceTarget {
  CUSTOMER_LIST = 'customer_list',
  INFLUENCER_BATCH = 'influencer_batch',
}

export const AudienceTargetNameMap = new Map<string, string>([
  [AudienceTarget.CUSTOMER_LIST, 'Customer List'],
  [AudienceTarget.INFLUENCER_BATCH, 'Influencer Batch'],
]);

export interface Audience {
  name: string;
  description: string;
  cohorts: { id: number; name: string }[];
  target: AudienceTarget;
  status: JobStatus;

  id?: number;
  created_at?: number;
  updated_at?: number;
}

export const DefaultAudienceData: Audience = {
  name: '',
  description: '',
  cohorts: [],
  target: AudienceTarget.CUSTOMER_LIST,
  status: JobStatus.QUEUEING,
};
