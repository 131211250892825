import { SvgIcon, SvgIconProps } from '@mui/material';

export const VerifiedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 23 22'>
    <path
      fill='currentColor'
      d='M20.9 11a3.5 3.5 0 0 0-2-3 3.5 3.5 0 0 0-.8-3.6 3.5 3.5 0 0 0-3.5-.8 3.5 3.5 0 0 0-3.1-2 3.5 3.5 0 0 0-3 2 3.5 3.5 0 0 0-3.6.8A3.5 3.5 0 0 0 4 7.9a3.5 3.5 0 0 0-2 3.1 3.5 3.5 0 0 0 2 3 3.5 3.5 0 0 0 .8 3.6 3.5 3.5 0 0 0 3.5.8 3.5 3.5 0 0 0 3.1 2 3.5 3.5 0 0 0 3-2A3.3 3.3 0 0 0 19 14a3.5 3.5 0 0 0 2-3.1Zm-10.7 3.8-3.5-3.4L8 10.1l2.1 2 4.4-4.7 1.3 1.2-5.6 6.3Z'
    />
  </SvgIcon>
);
