import { SvgIcon, SvgIconProps } from '@mui/material';

export const DeleteIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 44 44'>
    <path
      fillRule='evenodd'
      d='M27.3 6v1.8h9v3.5h-1.9v23.1c0 2-1.6 3.6-3.5 3.6H13c-2 0-3.5-1.6-3.5-3.6v-23H7.8V7.7h8.9V6h10.6ZM13.1 34.4H31v-23H13v23ZM16.7 15h3.5v16h-3.5v-16Zm10.6 0h-3.5v16h3.5v-16Z'
      clipRule='evenodd'
    />
  </SvgIcon>
);
