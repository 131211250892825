import { SvgIcon, SvgIconProps } from '@mui/material';

export const TelegramIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 16 17'>
    <g clipPath='url(#a)'>
      <path fill='url(#b)' d='M8 .5a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z' />
      <path
        fill='#fff'
        d='M3.6 8.4a389 389 0 0 1 7.7-3h.3l.1.2V6c-.1 1.3-.6 4.4-.9 5.8-.1.6-.3.8-.5.8-.5 0-.9-.3-1.3-.6l-1.8-1.2c-.8-.5-.3-.8.2-1.3l2.2-2.1V7h-.2A82.6 82.6 0 0 0 5 9.7L4 9.4c-.5-.2-1-.3-.9-.5 0-.2.2-.3.6-.5Z'
      />
    </g>
    <defs>
      <linearGradient id='b' x1='800' x2='800' y1='.5' y2='1600.5' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#2AABEE' />
        <stop offset='1' stopColor='#229ED9' />
      </linearGradient>
      <clipPath id='a'>
        <path fill='#fff' d='M0 .5h16v16H0z' />
      </clipPath>
    </defs>
  </SvgIcon>
);
