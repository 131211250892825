import { Button, Stack, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn, PaginationOptions } from 'components/table/CommonTable';
import { FC, HTMLAttributes, useMemo } from 'react';
import { FiberManualRecord, UploadOutlined } from '@mui/icons-material';
import { RecommendationLevel, RecommendationMap, TwitterAccount } from 'common/types/TwitterAccount';

import classes from './InfluencerList.module.scss';
import { colorNeutralVariant80 } from 'common/params';
import { numberFormatter } from 'common/formatters';

interface InfluencerListProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
  twitterAccounts: TwitterAccount[];
  totalTwitterAccountCnt: number;
  downloadDisabled?: boolean;
  onPageChange?: (paginationOptions: PaginationOptions) => void;
  onDownloadClicked?: () => void;
  onAudiencePublish?: () => void;
}

export const InfluencerList: FC<InfluencerListProps> = ({
  loading,
  twitterAccounts,
  totalTwitterAccountCnt,
  downloadDisabled,
  onPageChange,
  onDownloadClicked,
  onAudiencePublish,
  ...rest
}) => {
  const columnDefs: CommonTableColumn<TwitterAccount>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'username',
      label: 'Name',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Stack>
            <Typography variant='subtitle2'>{data?.display_name}</Typography>
            <Typography variant='body2' color='secondary'>
              {value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'description',
      label: 'Description',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'tags',
      label: 'Category',
      sortable: true,
      render: (values?: string[]) => {
        if (typeof values !== 'object') return '-';
        return <Typography variant='body1'>{values.join(', ')}</Typography>;
      },
    },
    {
      id: 'total_followers',
      label: 'Follower Counts',
      textAlign: 'right',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{numberFormatter.format(value)}</Typography>;
      },
    },
    {
      id: 'percentile',
      label: 'Recommend degree',
      sortable: true,
      render: (value?: number) => {
        if (typeof value !== 'number') return '-';
        const content =
          value >= (RecommendationMap.get(RecommendationLevel.HIGH) || 0) ? (
            <>
              <FiberManualRecord fontSize='small' color='error' />
              <Typography variant='body1'>{RecommendationLevel.HIGH}</Typography>
            </>
          ) : value >= (RecommendationMap.get(RecommendationLevel.MEDIUM) || 0) ? (
            <>
              <FiberManualRecord fontSize='small' color='warning' />
              <Typography variant='body1'>{RecommendationLevel.MEDIUM}</Typography>
            </>
          ) : (
            <>
              <FiberManualRecord fontSize='small' style={{ color: colorNeutralVariant80 }} />
              <Typography variant='body1'>{RecommendationLevel.LOW}</Typography>
            </>
          );
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            {content}
          </Stack>
        );
      },
    },
  ];

  const tableHeader = useMemo(() => {
    return (
      <Stack className={classes.tableHeader}>
        <Button
          id={twitterAccounts.length ? 'btn-ads-audience-publish' : undefined}
          variant='contained'
          startIcon={<UploadOutlined />}
          disabled={!twitterAccounts.length}
          onClick={onAudiencePublish}
        >
          <Typography variant='label1'>Publish</Typography>
        </Button>
      </Stack>
    );
  }, [twitterAccounts, onAudiencePublish]);

  return (
    <Stack spacing={4}>
      <Typography {...rest} variant='h3'>
        Twitter Influencer List
      </Typography>
      <CommonTable
        {...rest}
        dataPagination
        loading={loading}
        data={twitterAccounts}
        totalDataCnt={totalTwitterAccountCnt}
        columns={columnDefs}
        classes={classes}
        header={tableHeader}
        onPageChange={onPageChange}
      />
    </Stack>
  );
};
