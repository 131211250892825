import { Button, Drawer, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { DefaultInfluencerSearchParams, InfluencerSearchParams } from 'common/types/Extension/InfluencerFilter';
import { FC, useEffect, useState } from 'react';

import { AverageTweetImpressionFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/AverageTweetImpressionFilter';
import { CampaignTermRatioFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/CampaignTermRatioFilter';
import { CategoriesFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/CategoriesFilter';
import { EngagementRateFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/EngagementRateFilter';
import { FollowersFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/FollowersFilter';
import { InfluencerScoreFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/InfluencerScoreFilter';
import { KeyboardArrowLeftOutlined } from '@mui/icons-material';
import { LanguagesFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/LanguagesFilter';
import { LocationsFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/LocationsFilter';
import { MetricsRange } from 'common/types/Extension/TwitterUserSearchResult';
import { OriginalTweetsRatioFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/OriginalTweetsRatioFilter';
import { VerifiedFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/VerifiedFilter';
import { ViewThroughRateFilterCollapse } from 'components/InfluencerMatcher/filter/collapse/ViewThroughRateFilter';
import classes from './InfluencerFilterDrawer.module.scss';
import { colorSurface1 } from 'common/params';

interface InfluencerFilterDrawerProps {
  showIsProject?: boolean;
  showTop?: boolean;
  metricsRange?: MetricsRange;
  categories?: string[];
  languages?: string[];
  locations?: string[];
  searchParams: InfluencerSearchParams;
  onApply?: (searchParams: InfluencerSearchParams) => void;
  onClose?: () => void;
}

export const InfluencerFilterDrawer: FC<InfluencerFilterDrawerProps> = ({
  showIsProject,
  showTop,
  metricsRange,
  categories,
  languages,
  locations,
  searchParams,
  onApply,
  onClose,
}) => {
  const [editSearchParams, setEditSearchParams] = useState<InfluencerSearchParams>(searchParams);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    // in order to make this drawer open more smoothly
    // we set open to true after 1 msec when component is mounted
    const timeout = setTimeout(() => setOpen(true), 1);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '700px',
          minWidth: '700px',
          height: '100%',
          backgroundColor: colorSurface1,
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: '24px',
          padding: '12px',
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Stack className={classes.root} spacing={2}>
        <Stack direction='row' className={classes.header} alignItems='center' spacing={3}>
          <KeyboardArrowLeftOutlined className={classes.backBtn} onClick={() => onClose?.()} />
          <Typography variant='h6' className={classes.title}>
            Filter
          </Typography>
        </Stack>
        <Stack spacing={1} className={classes.filters}>
          {showIsProject ? (
            <Stack spacing={1.5} style={{ marginBottom: '12px' }}>
              <Typography>Influencer Type</Typography>
              <RadioGroup
                row
                style={{ justifyContent: 'space-between', paddingRight: '60px' }}
                value={
                  editSearchParams.is_project === undefined
                    ? 'all'
                    : editSearchParams.is_project
                    ? 'project'
                    : 'influencer'
                }
                onChange={(e) => {
                  switch (e.target.value) {
                    case 'all':
                      setEditSearchParams((old) => ({ ...old, is_project: undefined }));
                      break;
                    case 'project':
                      setEditSearchParams((old) => ({ ...old, is_project: true }));
                      break;
                    case 'influencer':
                      setEditSearchParams((old) => ({ ...old, is_project: false }));
                      break;
                  }
                }}
              >
                <FormControlLabel value='all' control={<Radio />} label='All' />
                <FormControlLabel value='project' control={<Radio />} label='Project' />
                <FormControlLabel value='influencer' control={<Radio />} label='Influencer' />
              </RadioGroup>
            </Stack>
          ) : undefined}
          <InfluencerScoreFilterCollapse
            showTop={showTop}
            data={editSearchParams.influence_score}
            metricsRange={metricsRange}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, influence_score: data }))}
          />
          <FollowersFilterCollapse
            data={editSearchParams.followers}
            metricsRange={metricsRange}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, followers: data }))}
          />
          <VerifiedFilterCollapse
            data={editSearchParams.verified_only}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, verified_only: data ? data : undefined }))}
          />
          <EngagementRateFilterCollapse
            showTop={showTop}
            data={editSearchParams.engagement_rate}
            metricsRange={metricsRange}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, engagement_rate: data }))}
          />
          <ViewThroughRateFilterCollapse
            showTop={showTop}
            data={editSearchParams.view_through_rate}
            metricsRange={metricsRange}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, view_through_rate: data }))}
          />
          <CampaignTermRatioFilterCollapse
            data={editSearchParams.campaign_term_ratio}
            metricsRange={metricsRange}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, campaign_term_ratio: data }))}
          />
          <OriginalTweetsRatioFilterCollapse
            data={editSearchParams.original_tweets_ratio}
            metricsRange={metricsRange}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, original_tweets_ratio: data }))}
          />
          <AverageTweetImpressionFilterCollapse
            showTop={showTop}
            data={editSearchParams.average_tweet_impression}
            metricsRange={metricsRange}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, average_tweet_impression: data }))}
          />
          <CategoriesFilterCollapse
            data={editSearchParams.categories}
            categories={categories}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, categories: data }))}
          />
          <LanguagesFilterCollapse
            data={editSearchParams.languages}
            languages={languages}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, languages: data }))}
          />
          <LocationsFilterCollapse
            data={editSearchParams.locations}
            locations={locations}
            onChange={(data) => setEditSearchParams((old) => ({ ...old, locations: data }))}
          />
        </Stack>
        <Stack direction='row-reverse' spacing={2}>
          <Button variant='contained' onClick={() => onApply?.(editSearchParams)}>
            Apply
          </Button>
          <Button variant='outlined' onClick={() => setEditSearchParams(DefaultInfluencerSearchParams)}>
            Clear Filter
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};
