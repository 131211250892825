import { Avatar, Divider, Stack, Tooltip, Typography } from '@mui/material';

import { FC } from 'react';
import classes from './UserInfo.module.scss';
import { colorSecondary30 } from 'common/params';
import { useUserProfile } from 'common/hooks/useUserProfile';

export const UserInfo: FC = () => {
  const { value: user } = useUserProfile();

  return (
    <Stack className={classes.root}>
      <Stack className={classes.container} spacing={5} alignItems='center'>
        <Stack spacing={8} className={classes.content}>
          <Tooltip title={user?.name} placement='top'>
            <Typography variant='h3' className={classes.text}>
              👋 Welcome, {user?.name}
            </Typography>
          </Tooltip>
          <Stack spacing={1}>
            <Stack direction='row' className={classes.item} alignItems='center'>
              <Typography variant='body1' className={classes.title}>
                Avatar
              </Typography>
              <Avatar alt='avatar' className={classes.avatar} src={user?.image_url} />
            </Stack>
            <Divider />
          </Stack>
          <Stack spacing={1}>
            <Stack direction='row' className={classes.item}>
              <Typography variant='body1' className={classes.title}>
                Name
              </Typography>
              <Tooltip title={user?.name} placement='top'>
                <Typography variant='body1' color={colorSecondary30} className={classes.text}>
                  {user?.name}
                </Typography>
              </Tooltip>
            </Stack>
            <Divider />
          </Stack>
          <Stack spacing={1}>
            <Stack direction='row' className={classes.item}>
              <Typography variant='body1' className={classes.title}>
                Wallet Address
              </Typography>
              <Tooltip
                componentsProps={{ popper: { sx: { '.MuiTooltip-tooltip': { maxWidth: '336px' } } } }}
                title={user?.wallets?.map((wallet) => wallet.wallet_address)?.join('\n')}
                placement='top'
              >
                <Stack className={classes.wrapper}>
                  {(user?.wallets || []).map((wallet, idx) => (
                    <Typography className={classes.text} key={`address-${idx}`}>
                      {wallet.wallet_address}
                    </Typography>
                  ))}
                </Stack>
              </Tooltip>
            </Stack>
            <Divider />
          </Stack>
          <Stack spacing={1}>
            <Stack direction='row' className={classes.item}>
              <Typography variant='body1' className={classes.title}>
                Email
              </Typography>
              <Typography variant='body1'>{user?.email}</Typography>
            </Stack>
            <Divider />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
