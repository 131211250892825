import { Button, Checkbox, FormControl, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { LevelCount, RecommendationLevel } from 'common/types/TwitterAccount';

import { CommonChip } from 'components/chip/CommonChip';
import { ThumbUpOutlined } from '@mui/icons-material';
import classes from './SelectLevels.module.scss';

interface SelectLevelsProps {
  levels?: RecommendationLevel[];
  levelCounts?: LevelCount[];
  onBtnCancelClicked?: () => void;
  onBtnNextClicked?: (levels: RecommendationLevel[]) => void;
}

export const SelectLevels: FC<SelectLevelsProps> = ({ levels, levelCounts, onBtnCancelClicked, onBtnNextClicked }) => {
  const [selectedLevels, setSelectedLevels] = useState<RecommendationLevel[]>(levels || []);

  const onLevelClicked = useCallback((level: RecommendationLevel) => {
    setSelectedLevels((old) => {
      if (old.findIndex((v) => v === level) > -1) {
        return [...old.filter((v) => v !== level)];
      } else {
        return [...old, level];
      }
    });
  }, []);

  return (
    <Stack className={classes.root} spacing={1}>
      <Stack spacing={3} className={classes.content} alignItems='center'>
        <Stack className={classes.title} alignItems='center' spacing={2}>
          <CommonChip>
            <ThumbUpOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            <Typography variant='h6'>Publish audience list according to our recommendation system</Typography>
            <Typography variant='body1'>Select levels below to publish.</Typography>
          </Stack>
        </Stack>
        <FormControl className={classes.items}>
          <MenuList>
            {Object.values(RecommendationLevel).map((value) => (
              <MenuItem divider key={value}>
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  className={classes.item}
                  onClick={() => onLevelClicked(value)}
                >
                  <CommonChip>
                    <Typography className={classes.icon}>{value.substring(0, 1)}</Typography>
                  </CommonChip>
                  <Typography className={classes.text}>{value}</Typography>
                  <Typography>{(levelCounts || []).find((levelCount) => levelCount.level === value)?.count}</Typography>
                  <Checkbox checked={selectedLevels.findIndex((level) => level === value) > -1}></Checkbox>
                </Stack>
              </MenuItem>
            ))}
          </MenuList>
        </FormControl>
      </Stack>
      <Stack direction='row-reverse' spacing={1} className={classes.actions}>
        <Button variant='contained' onClick={() => selectedLevels.length && onBtnNextClicked?.(selectedLevels)}>
          Next
        </Button>
        <Button variant='outlined' onClick={onBtnCancelClicked}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
