import { FC, HTMLAttributes, createContext, useState } from 'react';

import { AccountsUser } from 'common/types/Accounts/AccountsUser';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionPlan } from 'common/types/Accounts/SubscriptionPlan';
import { cloneDeep } from 'lodash';
import { useLifecycles } from 'react-use';

export interface AccountsContextProps {
  user$: BehaviorSubject<AccountsUser | undefined>;
  refetchUser$: BehaviorSubject<boolean>;
  subscriptionPlans$: BehaviorSubject<SubscriptionPlan[] | undefined>;
}
export const AccountsContextDefault: AccountsContextProps = {
  user$: new BehaviorSubject<AccountsUser | undefined>(undefined),
  refetchUser$: new BehaviorSubject<boolean>(true),
  subscriptionPlans$: new BehaviorSubject<SubscriptionPlan[] | undefined>(undefined),
};

export const AccountsContext = createContext<AccountsContextProps>(AccountsContextDefault);

const AccountsContextProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  /** State */
  const [context, setContext] = useState<AccountsContextProps>(AccountsContextDefault);

  /** Lifecycle */
  useLifecycles(
    () => setContext(cloneDeep(AccountsContextDefault)),
    () => {},
  );

  /** Render */
  return <AccountsContext.Provider value={context}>{children}</AccountsContext.Provider>;
};

export default AccountsContextProvider;
