import { FC, HTMLAttributes, createContext, useState } from 'react';
import { Tutorial, tutorials } from 'common/types/Tutorial';

import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { useLifecycles } from 'react-use';

export interface TutorialContextProps {
  component$: BehaviorSubject<Element | undefined>;
  tutorials$: BehaviorSubject<Tutorial[]>;
  tutorial$: BehaviorSubject<Tutorial | undefined>;
  completedTutorials$: BehaviorSubject<Tutorial[]>;
  refetchCompletedTutorials$: BehaviorSubject<boolean>;
  landingCompleted$: BehaviorSubject<boolean>;
}
export const TutorialContextDefault: TutorialContextProps = {
  component$: new BehaviorSubject<Element | undefined>(undefined),
  tutorials$: new BehaviorSubject<Tutorial[]>(tutorials),
  tutorial$: new BehaviorSubject<Tutorial | undefined>(undefined),
  completedTutorials$: new BehaviorSubject<Tutorial[]>([]),
  refetchCompletedTutorials$: new BehaviorSubject<boolean>(true),
  landingCompleted$: new BehaviorSubject<boolean>(true),
};

export const TutorialContext = createContext<TutorialContextProps>(TutorialContextDefault);

const TutorialContextProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  /** State */
  const [context, setContext] = useState<TutorialContextProps>(TutorialContextDefault);
  const [init, setInit] = useState<boolean>(false);

  /** Lifecycle */
  useLifecycles(
    () => {
      setContext(cloneDeep(TutorialContextDefault));
      setInit(true);
    },
    () => {},
  );

  /** Render */
  return <TutorialContext.Provider value={context}>{init ? children : null}</TutorialContext.Provider>;
};

export default TutorialContextProvider;
