import { AdGroup, TwitterAdGroupSelect } from 'components/select/TwitterAdGroupSelect';
import {
  AddLinkOutlined,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  DeleteOutline,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Button,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, HTMLAttributes, useMemo, useState } from 'react';
import { colorPrimary10, colorPrimary40 } from 'common/params';
import { dedupArray, validateUrl } from 'common/utils';

import { Audience } from 'common/types/Audience';
import { CampaignLinkBase } from 'common/types/CampaignLink';
import { CommonChip } from 'components/chip/CommonChip';
import { Spinner } from 'components/common/Spinner';
import { TwitterAdGroupPublishRecord } from 'common/types/TwitterPublishRecord';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './TwitterAdsCampaignInfo.module.scss';

enum TwitterAdsCampaignInfoStep {
  SELECT_AUDIENCE,
  GENERATE_LINKS,
}

interface TwitterAdsCampaignInfoProps extends HTMLAttributes<HTMLElement> {
  creating?: boolean;
  campaignLink: CampaignLinkBase;
  twitterAdGroupPublishRecords: TwitterAdGroupPublishRecord[];
  audiences: Audience[];
  onCreate?: (campaignLinks: CampaignLinkBase[]) => void;
  onBack?: () => void;
}

export const TwitterAdsCampaignInfo: FC<TwitterAdsCampaignInfoProps> = ({
  creating,
  campaignLink,
  twitterAdGroupPublishRecords,
  audiences,
  onCreate,
  onBack,
}) => {
  const [step, setStep] = useState<number>(TwitterAdsCampaignInfoStep.SELECT_AUDIENCE);
  const [selectedAdGroups, setSelectedAdGroups] = useState<AdGroup[]>([]);
  const [links, setLinks] = useState<{ originalUrl: string; name: string }[]>([{ originalUrl: '', name: '' }]);
  const [selectedAudienceId, setSelectedAudienceId] = useState<string>('');
  const [createBtnClicked, setCreateBtnClicked] = useState<boolean>(false);

  const urlValid = useMemo(() => {
    return links
      .map((link) => {
        return validateUrl(link.originalUrl);
      })
      .reduce((prev, curr) => prev && curr, true);
  }, [links]);

  const audienceIds = useMemo(() => {
    return dedupArray(
      twitterAdGroupPublishRecords.map((twitterAdGroupPublishRecord) =>
        twitterAdGroupPublishRecord.audience_id.toString(),
      ),
    );
  }, [twitterAdGroupPublishRecords]);

  const adGroups = useMemo(() => {
    const adGroups: AdGroup[] = [];
    const adGroupIdSet = new Set();
    twitterAdGroupPublishRecords.forEach((twitterAdGroupPublishRecord) => {
      if (twitterAdGroupPublishRecord.audience_id.toString() !== selectedAudienceId) return;
      Object.entries(twitterAdGroupPublishRecord.ad_groups).forEach(([id, data]) => {
        if (adGroupIdSet.has(id)) return;
        adGroups.push({
          adGroupId: id,
          adGroupName: data.ad_group_name,
          campaignId: data.campaign_id,
          campaignName: data.campaign_name,
        });
        adGroupIdSet.add(id);
      });
    });
    return adGroups;
  }, [selectedAudienceId, twitterAdGroupPublishRecords]);

  const title = useMemo(() => {
    switch (step) {
      case TwitterAdsCampaignInfoStep.SELECT_AUDIENCE:
        return 'Select your Twitter Ad Campaign Audience';
      case TwitterAdsCampaignInfoStep.GENERATE_LINKS:
        return 'Use Campaign Link';
      default:
        return '';
    }
  }, [step]);

  const subTitle = useMemo(() => {
    switch (step) {
      case TwitterAdsCampaignInfoStep.SELECT_AUDIENCE:
        return 'Choose which audience and Ad groups your campaign is targeting. ';
      case TwitterAdsCampaignInfoStep.GENERATE_LINKS:
        return 'Create multiple links to track different Ad creatives.';
      default:
        return '';
    }
  }, [step]);

  const additionalInfo = useMemo(() => {
    switch (step) {
      case TwitterAdsCampaignInfoStep.SELECT_AUDIENCE:
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            <InfoOutlined />
            <Stack direction='row' spacing={1}>
              <Typography>
                Please note that you should create and publish Audience to Twitter Ad group in advance via{' '}
                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => window.open(Uris.Pages.AdsAudience.Index)}
                >
                  the Ads Audience tool.
                </span>
              </Typography>
            </Stack>
          </Stack>
        );
      case TwitterAdsCampaignInfoStep.GENERATE_LINKS:
        return null;
      default:
        return null;
    }
  }, [step]);

  const buttons = useMemo(() => {
    switch (step) {
      case TwitterAdsCampaignInfoStep.SELECT_AUDIENCE:
        return (
          <>
            <Button
              variant='contained'
              disabled={creating || !selectedAdGroups.length}
              onClick={() => {
                setStep(TwitterAdsCampaignInfoStep.GENERATE_LINKS);
              }}
              endIcon={<ArrowForwardOutlined />}
            >
              Next
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                setCreateBtnClicked(false);
                onBack?.();
              }}
              startIcon={<ArrowBackOutlined />}
            >
              Back
            </Button>
          </>
        );
      case TwitterAdsCampaignInfoStep.GENERATE_LINKS:
        return (
          <>
            <Button
              variant='contained'
              disabled={creating || (createBtnClicked && !urlValid) || !links.length}
              onClick={() => {
                setCreateBtnClicked(true);
                if (!urlValid) return;

                const adGroups: Record<string, Record<string, string>> = {};
                selectedAdGroups.forEach(
                  (adGroup) =>
                    (adGroups[adGroup.adGroupId] = {
                      ad_group_name: adGroup.adGroupName,
                      campaign_id: adGroup.campaignId,
                      campaign_name: adGroup.adGroupName,
                    }),
                );
                onCreate?.(
                  links.map((link) => ({
                    ...campaignLink,
                    ad_groups: adGroups,
                    audience_id: Number.parseInt(selectedAudienceId),
                    original_url: link.originalUrl,
                    name: link.name,
                  })),
                );
              }}
              startIcon={creating ? <Spinner size={24} /> : null}
            >
              Create
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                setStep(TwitterAdsCampaignInfoStep.SELECT_AUDIENCE);
              }}
              startIcon={<ArrowBackOutlined />}
            >
              Back
            </Button>
          </>
        );
      default:
        return null;
    }
  }, [
    step,
    campaignLink,
    creating,
    createBtnClicked,
    urlValid,
    links,
    selectedAdGroups,
    selectedAudienceId,
    onBack,
    onCreate,
  ]);

  const mainContent = useMemo(() => {
    switch (step) {
      case TwitterAdsCampaignInfoStep.SELECT_AUDIENCE:
        return (
          <>
            <FormControl>
              <InputLabel>Audience</InputLabel>
              <Select
                value={selectedAudienceId}
                label='Audience'
                renderValue={(audienceId) => {
                  return (
                    <Typography>
                      {audiences.find((audience) => audience.id?.toString() === audienceId)?.name}
                    </Typography>
                  );
                }}
                onChange={(e) => setSelectedAudienceId(e.target.value)}
              >
                {audienceIds.map((audienceId, idx) => (
                  <MenuItem key={audienceId} divider={idx !== audienceIds.length - 1} value={audienceId}>
                    <Typography variant='body1'>
                      {audiences.find((audience) => audience.id?.toString() === audienceId)?.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TwitterAdGroupSelect
              adGroups={adGroups}
              selectedAdGroups={selectedAdGroups}
              onAdGroupsChanged={(adGroups) => setSelectedAdGroups(adGroups)}
            />
          </>
        );
      case TwitterAdsCampaignInfoStep.GENERATE_LINKS:
        return (
          <>
            {links?.map((link, idx) => (
              <Stack direction='row' key={`link-${idx}`} spacing={3} alignItems='center' className={classes.link}>
                <CommonChip backgroundColor='transparent' color={colorPrimary40}>
                  <Typography variant='body1'>{idx + 1}</Typography>
                </CommonChip>
                <FormControl className={classes.flex}>
                  <TextField
                    label='Original URL'
                    error={createBtnClicked && !validateUrl(link.originalUrl)}
                    className={classes.textField}
                    value={link.originalUrl}
                    inputProps={{ maxLength: 2048 }}
                    onChange={(e) =>
                      setLinks((old) => {
                        const newValue = [...old];
                        if (idx >= newValue.length) return newValue;
                        newValue[idx].originalUrl = e.target.value;
                        return newValue;
                      })
                    }
                  ></TextField>
                </FormControl>
                <FormControl className={classes.flex}>
                  <TextField
                    label='Name'
                    className={classes.textField}
                    value={link.name}
                    inputProps={{ maxLength: 256 }}
                    onChange={(e) =>
                      setLinks((old) => {
                        const newValue = [...old];
                        if (idx >= newValue.length) return newValue;
                        newValue[idx].name = e.target.value;
                        return newValue;
                      })
                    }
                  ></TextField>
                </FormControl>
                <IconButton
                  onClick={() =>
                    setLinks((old) => {
                      return [...old.slice(0, idx), ...old.slice(idx + 1)];
                    })
                  }
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
            ))}
            <Divider />
            <Button
              variant='outlined'
              startIcon={<AddLinkOutlined />}
              onClick={() =>
                setLinks((old) => {
                  const newValue = [...old, { originalUrl: '', name: '' }];
                  return newValue;
                })
              }
            >
              Add New URL
            </Button>
          </>
        );
      default:
        return null;
    }
  }, [step, adGroups, audienceIds, audiences, createBtnClicked, links, selectedAdGroups, selectedAudienceId]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classNames(classes.container, classes.top)} spacing={2}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Chip color='primary' label={<Typography variant='h6'>2-{step + 1}</Typography>} className={classes.step} />
          <Stack spacing={2} className={classes.title}>
            <Typography variant='h4' color={colorPrimary10}>
              {title}
            </Typography>
            <Typography variant='body1'>{subTitle}</Typography>
            {additionalInfo}
            <Stack direction='row-reverse' spacing={2}>
              {buttons}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classNames(classes.container, classes.bottom)} spacing={2}>
        {mainContent}
      </Stack>
    </Stack>
  );
};
