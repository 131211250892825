import { Button, Stack, Typography } from '@mui/material';
import { CloseOutlined, FilterListOutlined } from '@mui/icons-material';
import {
  DefaultInfluencerSearchParams,
  InfluencerSearchParams,
  getValueFilterString,
} from 'common/types/Extension/InfluencerFilter';
import { FC, useState } from 'react';
import { numberToAbbreviation, numberToPercentage } from 'common/utils';

import { InfluencerFilterDrawer } from 'pages/InfluencerMatcher/Drawer/InfluencerFilterDrawer';
import { MetricsRange } from 'common/types/Extension/TwitterUserSearchResult';
import classes from './InfluencerFilter.module.scss';
import { getLangFromIso } from 'common/languages';

interface InfluencerFilterProps {
  showIsProject?: boolean;
  showTop?: boolean;
  metricsRange?: MetricsRange;
  categories?: string[];
  languages?: string[];
  locations?: string[];
  searchParams: InfluencerSearchParams;
  onSearchParamsChanged?: (searchParams: InfluencerSearchParams) => void;
}

export const InfluencerFilter: FC<InfluencerFilterProps> = ({
  showIsProject,
  showTop,
  metricsRange,
  categories,
  languages,
  locations,
  searchParams,
  onSearchParamsChanged,
}) => {
  const [influencerFilterOpened, setInfluencerFilterOpened] = useState<boolean>(false);

  return (
    <Stack direction='row' alignItems='center' className={classes.root}>
      <Button
        id='btn-search-influencers-filter'
        startIcon={<FilterListOutlined />}
        variant='outlined'
        onClick={() => setInfluencerFilterOpened(true)}
      >
        Filters
      </Button>
      {searchParams.influence_score ? (
        <Button
          className={classes.filter}
          endIcon={
            <CloseOutlined onClick={() => onSearchParamsChanged?.({ ...searchParams, influence_score: undefined })} />
          }
        >
          <Typography variant='label1'>
            Influence Score {getValueFilterString(searchParams.influence_score, numberToPercentage)}
          </Typography>
        </Button>
      ) : null}
      {searchParams.followers ? (
        <Button
          className={classes.filter}
          endIcon={<CloseOutlined onClick={() => onSearchParamsChanged?.({ ...searchParams, followers: undefined })} />}
        >
          <Typography variant='label1'>
            Followers {getValueFilterString(searchParams.followers, numberToAbbreviation)}
          </Typography>
        </Button>
      ) : null}
      {searchParams.verified_only ? (
        <Button
          className={classes.filter}
          endIcon={
            <CloseOutlined onClick={() => onSearchParamsChanged?.({ ...searchParams, verified_only: undefined })} />
          }
        >
          <Typography variant='label1'>Verfied Only</Typography>
        </Button>
      ) : null}
      {searchParams.engagement_rate ? (
        <Button
          className={classes.filter}
          endIcon={
            <CloseOutlined onClick={() => onSearchParamsChanged?.({ ...searchParams, engagement_rate: undefined })} />
          }
        >
          <Typography variant='label1'>
            ETR {getValueFilterString(searchParams.engagement_rate, numberToPercentage)}
          </Typography>
        </Button>
      ) : null}
      {searchParams.view_through_rate ? (
        <Button
          className={classes.filter}
          endIcon={
            <CloseOutlined onClick={() => onSearchParamsChanged?.({ ...searchParams, view_through_rate: undefined })} />
          }
        >
          <Typography variant='label1'>
            VTR {getValueFilterString(searchParams.view_through_rate, numberToPercentage)}
          </Typography>
        </Button>
      ) : null}
      {searchParams.campaign_term_ratio ? (
        <Button
          className={classes.filter}
          endIcon={
            <CloseOutlined
              onClick={() => onSearchParamsChanged?.({ ...searchParams, campaign_term_ratio: undefined })}
            />
          }
        >
          <Typography variant='label1'>
            Campaign Term Ratio {getValueFilterString(searchParams.campaign_term_ratio, numberToPercentage)}
          </Typography>
        </Button>
      ) : null}
      {searchParams.original_tweets_ratio ? (
        <Button
          className={classes.filter}
          endIcon={
            <CloseOutlined
              onClick={() => onSearchParamsChanged?.({ ...searchParams, original_tweets_ratio: undefined })}
            />
          }
        >
          <Typography variant='label1'>
            Original Tweets Ratio {getValueFilterString(searchParams.original_tweets_ratio, numberToPercentage)}
          </Typography>
        </Button>
      ) : null}
      {searchParams.average_tweet_impression ? (
        <Button
          className={classes.filter}
          endIcon={
            <CloseOutlined
              onClick={() => onSearchParamsChanged?.({ ...searchParams, average_tweet_impression: undefined })}
            />
          }
        >
          <Typography variant='label1'>
            Avg. Tweet Impression {getValueFilterString(searchParams.average_tweet_impression, numberToPercentage)}
          </Typography>
        </Button>
      ) : null}
      {searchParams.categories?.length
        ? searchParams.categories.map((category) => (
            <Button
              key={category}
              className={classes.filter}
              endIcon={
                <CloseOutlined
                  onClick={() =>
                    onSearchParamsChanged?.({
                      ...searchParams,
                      categories:
                        searchParams.categories?.length === 1
                          ? undefined
                          : searchParams.categories?.filter((c) => c !== category),
                    })
                  }
                />
              }
            >
              <Typography variant='label1'>{category}</Typography>
            </Button>
          ))
        : null}
      {searchParams.languages?.length
        ? searchParams.languages.map((language) => (
            <Button
              key={language}
              className={classes.filter}
              endIcon={
                <CloseOutlined
                  onClick={() =>
                    onSearchParamsChanged?.({
                      ...searchParams,
                      languages:
                        searchParams.languages?.length === 1
                          ? undefined
                          : searchParams.languages?.filter((l) => l !== language),
                    })
                  }
                />
              }
            >
              <Typography variant='label1'>{getLangFromIso(language)}</Typography>
            </Button>
          ))
        : null}
      {searchParams.locations?.length
        ? searchParams.locations.map((location) => (
            <Button
              key={location}
              className={classes.filter}
              endIcon={
                <CloseOutlined
                  onClick={() =>
                    onSearchParamsChanged?.({
                      ...searchParams,
                      locations:
                        searchParams.locations?.length === 1
                          ? undefined
                          : searchParams.locations?.filter((l) => l !== location),
                    })
                  }
                />
              }
            >
              <Typography variant='label1'>{location}</Typography>
            </Button>
          ))
        : null}
      <Button
        className={classes.clear}
        onClick={() => {
          onSearchParamsChanged?.({ ...DefaultInfluencerSearchParams, is_project: searchParams.is_project });
        }}
      >
        <Typography variant='label1'>Clear Filter</Typography>
      </Button>

      {influencerFilterOpened ? (
        <InfluencerFilterDrawer
          showIsProject={showIsProject}
          showTop={showTop}
          metricsRange={metricsRange}
          categories={categories}
          languages={languages}
          locations={locations}
          searchParams={searchParams}
          onApply={(searchParams) => {
            onSearchParamsChanged?.(searchParams);
            setInfluencerFilterOpened(false);
          }}
          onClose={() => {
            setInfluencerFilterOpened(false);
          }}
        />
      ) : null}
    </Stack>
  );
};
