import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { DirectionsRunOutlined, MoreVertOutlined, RefreshOutlined } from '@mui/icons-material';
import { FC, useMemo, useState } from 'react';
import { IconButton, Tooltip as MuiTooltip, Stack, Typography } from '@mui/material';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { GhostIcon } from 'components/icons/GhostIcon';
import { Spinner } from 'components/common/Spinner';
import classes from './CohortWalletActiveDaysChart.module.scss';
import { numberFormatter } from 'common/formatters';
import { numberToSecondFraction } from 'common/utils';
import { useAsync } from 'react-use';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface CohortWalletActiveDaysChartProps {
  cohortId: number;
}

export const CohortWalletActiveDaysChart: FC<CohortWalletActiveDaysChartProps> = ({ cohortId }) => {
  const [refetch, setRefetch] = useState<boolean>(false);
  const {
    loading,
    error,
    value: metrics,
  } = useAsync(async () => {
    return (await ClientAPI.getCohortAddressMetrics(cohortId, 'active_days_in_60d')).data;
  }, [refetch]);

  const {
    loading: avgLoading,
    error: avgError,
    value: avg,
  } = useAsync(async () => {
    return (await ClientAPI.getCohortAddressMetrics(cohortId, 'avg_active_days_in_60d')).data;
  }, [refetch]);

  const { labels, data } = useMemo(() => {
    const labels = (metrics || []).map((m) => m.label);
    const data = (metrics || []).map((m) => m.value);
    return { labels, data };
  }, [metrics]);

  const dataSum = useMemo(() => {
    return data.reduce((prev, curr) => prev + curr, 0);
  }, [data]);

  const chartContent = useMemo(() => {
    if (loading) return <Spinner />;
    if (error)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <RefreshOutlined className={classes.refresh} onClick={() => setRefetch((old) => !old)} />
          <Typography>Refresh to load the chart</Typography>
        </Stack>
      );
    if (!data.length)
      return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
          <GhostIcon />
          <Typography>Update cohort to load the chart</Typography>
        </Stack>
      );
    return (
      <Bar
        width='100%'
        height='100%'
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: '#add0e5',
              borderWidth: 1,
              hoverBackgroundColor: '#3f75a8',
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Wallet Active Days',
            },
            legend: {
              display: false,
            },
            datalabels: {
              align: 'end',
              anchor: 'end',
              formatter: (value) => {
                if (!value) return '';
                return value;
              },
              font: {
                size: 8,
              },
            },
            tooltip: {
              displayColors: false,
              bodyFont: { weight: 'bold' },
              callbacks: {
                title: () => '',
                label: (context) => {
                  let label = context.dataset.label || '';

                  if (context.parsed.y !== null) {
                    return `Total: ${numberFormatter.format(context.parsed.y)} wallets`;
                  }
                  return label;
                },
                footer: (tooltipItems) => {
                  let sum = 0;
                  tooltipItems.forEach((tooltipItem) => {
                    sum += tooltipItem.parsed.y;
                  });

                  return `Percentage: ${Math.round((sum * 10000) / dataSum) / 100}%`;
                },
              },
            },
          },
        }}
      />
    );
  }, [labels, data, dataSum, loading, error]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classes.title} spacing={3}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={3} alignItems='center'>
            <CommonChip>
              <DirectionsRunOutlined fontSize='large' />
            </CommonChip>
            <Stack>
              <Typography variant='h6'>Wallet Activity Days</Typography>
              <Typography>The average amount of time that wallets were active within the past 60 days.</Typography>
            </Stack>
          </Stack>
          <IconButton>
            <MoreVertOutlined />
          </IconButton>
        </Stack>
        <Stack direction='row' spacing={1}>
          {avgLoading || avgError || !avg?.length ? null : (
            <Stack className={classes.chip}>
              <MuiTooltip arrow title={`${numberToSecondFraction(avg[0].value)} days`}>
                <Typography variant='label1' className={classes.text}>
                  Average: {numberToSecondFraction(avg[0].value)} days
                </Typography>
              </MuiTooltip>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack className={classes.chart} alignItems='center' justifyContent='center'>
        {chartContent}
      </Stack>
    </Stack>
  );
};
