import { Dialog, DialogProps, DialogTitle, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { Uris } from 'Uris';
import classes from './TutorialRewardDialog.module.scss';

interface TutorialRewardDialogProps extends DialogProps {
  onClose?: () => void;
}

export const TutorialRewardDialog: FC<TutorialRewardDialogProps> = ({ open, onClose, ...props }) => {
  return (
    <Dialog
      open={open}
      className={classes.dialog}
      PaperProps={{ sx: { backgroundColor: 'white', borderRadius: '8px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <Typography className={classes.skip} onClick={onClose}>
            Skip
          </Typography>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <img alt='cover' className={classes.img} src={Uris.Public.Image.Tutorial.Reward}></img>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center' color='#1c1b1f'>
                The reward will be sent to your email in 3 days!
              </Typography>
              <Typography variant='body1' textAlign='center' color='#49454f'>
                Please check your inbox to claim it. If you have any questions, contact your Account Manager or report
                them in the Telegram Channel.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};
