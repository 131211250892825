import { Divider, Stack, Typography } from '@mui/material';
import {
  assetFilterOperandToString,
  contractFilterOperandToString,
  defiFilterOperandToString,
} from 'common/types/CohortFilter';

import { Cohort } from 'common/types/Cohort';
import { ContractInteractionIcon } from 'components/icons/ContractInteractionIcon';
import { FC } from 'react';
import { LogicalOperatorNameMap } from 'common/types/LogicalOperator';
import { LogicalOperatorSelect } from 'components/select/LogicalOperatorSelect';
import { WalletIcon } from 'components/icons/WalletIcon';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import classes from './FilterDetails.module.scss';

interface FilterDetailsProps {
  cohort: Cohort;
}

export const FilterDetails: FC<FilterDetailsProps> = ({ cohort }) => {
  return (
    <Stack className={classes.root}>
      {/* Wallet Asset Portfolio */}
      {cohort.filter.operands.asset_portfolio.enabled && cohort.filter.operands.asset_portfolio.operands.length > 0 ? (
        <>
          <Stack className={classes.container} direction='row' alignItems='center' spacing={3}>
            <Stack className={classes.chip}>
              <WalletIcon fontSize='large' className={classes.icon} />
            </Stack>
            <Stack className={classes.content}>
              <Typography variant='h6'>Wallet Asset Portfolio</Typography>
              {cohort.filter.operands.asset_portfolio.operands.map((operand, idx) => {
                return (
                  <Stack
                    direction='row'
                    key={`wallet-asset-operand-${idx}`}
                    justifyContent='space-between'
                    className={classes.operand}
                  >
                    <Typography>{assetFilterOperandToString(operand)}</Typography>
                    <Typography>
                      {idx < cohort.filter.operands.asset_portfolio.operands.length - 1
                        ? ` ${LogicalOperatorNameMap.get(cohort.filter.operands.asset_portfolio.op)}`
                        : ''}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </>
      ) : null}
      {/* Defi Contract */}
      {cohort.filter.operands.defi_engagement.enabled && cohort.filter.operands.defi_engagement.operands.length > 0 ? (
        <>
          {/* should check whether previous data has value then show divider */}
          {cohort.filter.operands.asset_portfolio.enabled &&
          cohort.filter.operands.asset_portfolio.operands.length > 0 ? (
            <div className={classes.logicalOperator}>
              <Divider sx={{ '.MuiDivider-wrapper': { padding: 0 } }}>
                <LogicalOperatorSelect data={cohort.filter.op} readonly />
              </Divider>
            </div>
          ) : null}
          <Stack className={classes.container} direction='row' alignItems='center' spacing={3}>
            <Stack className={classes.chip}>
              <ZoomOutMapOutlinedIcon fontSize='large' className={classes.icon} />
            </Stack>
            <Stack className={classes.content}>
              <Typography variant='h6'>Defi Contract</Typography>
              {cohort.filter.operands.defi_engagement.operands.map((operand, idx) => {
                return (
                  <Stack
                    direction='row'
                    key={`defi-engagement-operand-${idx}`}
                    justifyContent='space-between'
                    className={classes.operand}
                  >
                    <Typography>{defiFilterOperandToString(operand)}</Typography>
                    <Typography>
                      {idx < cohort.filter.operands.defi_engagement.operands.length - 1
                        ? ` ${LogicalOperatorNameMap.get(cohort.filter.operands.defi_engagement.op)}`
                        : ''}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </>
      ) : null}
      {/* Contract Interaction */}
      {cohort.filter.operands.contract_interaction.enabled &&
      cohort.filter.operands.contract_interaction.operands.length > 0 ? (
        <>
          {/* should check whether previous data has value then show divider */}
          {(cohort.filter.operands.asset_portfolio.enabled &&
            cohort.filter.operands.asset_portfolio.operands.length > 0) ||
          (cohort.filter.operands.defi_engagement.enabled &&
            cohort.filter.operands.defi_engagement.operands.length > 0) ? (
            <div className={classes.logicalOperator}>
              <Divider sx={{ '.MuiDivider-wrapper': { padding: 0 } }}>
                <LogicalOperatorSelect data={cohort.filter.op} readonly />
              </Divider>
            </div>
          ) : null}
          <Stack className={classes.container} direction='row' alignItems='center' spacing={3}>
            <Stack className={classes.chip}>
              <ContractInteractionIcon fontSize='large' className={classes.icon} />
            </Stack>
            <Stack className={classes.content}>
              <Typography variant='h6'>Contract Interaction</Typography>
              {cohort.filter.operands.contract_interaction.operands.map((operand, idx) => {
                return (
                  <Stack
                    direction='row'
                    key={`contract-interaction-operand-${idx}`}
                    justifyContent='space-between'
                    className={classes.operand}
                  >
                    <Typography>{contractFilterOperandToString(operand)}</Typography>
                    <Typography>
                      {idx < cohort.filter.operands.contract_interaction.operands.length - 1
                        ? ` ${LogicalOperatorNameMap.get(cohort.filter.operands.contract_interaction.op)}`
                        : ''}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </>
      ) : null}
      {(!cohort.filter.operands.asset_portfolio.enabled || !cohort.filter.operands.asset_portfolio.operands.length) &&
      (!cohort.filter.operands.defi_engagement.enabled || !cohort.filter.operands.defi_engagement.operands.length) &&
      (!cohort.filter.operands.contract_interaction.enabled ||
        !cohort.filter.operands.contract_interaction.operands.length) ? (
        <Typography>No filter applied</Typography>
      ) : null}
    </Stack>
  );
};
