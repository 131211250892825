import { ChainTypeNameMap, DefaultChainType } from 'common/types/ChainType';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { spacing, spacingX2 } from 'common/params';

import { DataContext } from 'contexts/DataContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { dedupArray } from 'common/utils';
import { priceFormatter } from 'common/formatters';
import { useObservable } from 'react-use';

enum TokenFastFilter {
  ALL = 'all',
  TOP_10 = 'top_10',
}

const TokenFastFilterNameMap = new Map<string, string>([
  [TokenFastFilter.ALL, 'All'],
  [TokenFastFilter.TOP_10, 'Top 10'],
]);

interface AssetTypeSelectProps {
  chain?: string;
  tokens?: string[];
  allTokens?: boolean;
  disabled?: boolean;
  onChange?: (chain: string, tokens: string[], allTokens?: boolean) => void;
}

export const AssetTypeSelect: FC<AssetTypeSelectProps> = ({ chain, tokens, allTokens, disabled, onChange }) => {
  const { walletAssets$ } = useContext(DataContext);
  const walletAssets = useObservable(walletAssets$, []);
  const [searchStr, setSearchStr] = useState<string>('');
  const [selectedChain, setSelectedChain] = useState<string>(chain || DefaultChainType);
  // here we use a local state to perform select behavior
  // make sure every time we call onChange in props will also setSelectedToken
  // to make them consistency
  const [selectedTokens, setSelectedTokens] = useState<string[]>(tokens || []);
  const [tokenFastFilter, setTokenFastFilter] = useState<TokenFastFilter>(TokenFastFilter.ALL);

  const chains = useMemo(() => {
    return dedupArray(walletAssets.map((walletAsset) => walletAsset.chain));
  }, [walletAssets]);

  const chainAssets = useMemo(() => {
    return walletAssets.filter((walletAsset) => {
      if (walletAsset.chain !== selectedChain) return false;
      return true;
    });
  }, [selectedChain, walletAssets]);

  const assets = useMemo(() => {
    const fastFilteredAssets = chainAssets.filter((_, idx) => {
      if (tokenFastFilter === TokenFastFilter.TOP_10) return idx < 10;
      return true;
    });
    return fastFilteredAssets.filter((walletAsset) => {
      if (!searchStr) return walletAsset.symbol.toUpperCase();
      return walletAsset.symbol.toUpperCase().startsWith(searchStr.toUpperCase());
    });
  }, [chainAssets, tokenFastFilter, searchStr]);

  useEffect(() => {
    if (!allTokens) return;
    setSelectedTokens(assets.map((asset) => asset.contract_addr));
  }, [allTokens, assets]);

  const handleAssetChainChange = useCallback(
    (chain: string) => {
      setSelectedChain(chain);
      const newTokens = walletAssets$.value.filter((asset) => asset.chain === chain).map((asset) => asset.id);

      setSelectedTokens(newTokens);
      onChange?.(chain, newTokens, true);
    },
    [onChange, walletAssets$],
  );

  const handleAssetTypeChange = useCallback(
    (value: string | string[]) => {
      const allTokenShown = assets.length === chainAssets.length;

      // check whether all is the last element in value which means user click all
      if (typeof value === 'object' && value[value.length - 1] === 'all') {
        /**
         * user click all
         * allTokenShown => assets len == chainAssets len
         *   true: currently all token are shown
         *   false: currently not all token are shown
         * selectAll => assets all in value
         *   true: currently all assets are selected, user want to de-select all
         *   false: currently not all assets are selected, user want to select all
         *
         * 1. allTokenShown = true
         *    a. selectAll = true
         *       -> empty asset [] with all_token = false
         *    b. selectAll = false -> select all
         *       -> empty asset [] with all_token = true
         * 2. allTokenShown = false
         *    a. selectAll = true
         *       -> empty asset [] with all_token = false
         *    b. selectAll = false -> select all
         *       -> all assets [a,b,c...] with all_token = false
         */
        const selectAll = assets.reduce(
          (prev, curr) => prev && value.findIndex((v) => v === curr.contract_addr) > -1,
          true,
        );
        const allTokens = allTokenShown && !selectAll;
        const newTokens = allTokenShown || selectAll ? [] : assets.map((asset) => asset.contract_addr);
        onChange?.(selectedChain, newTokens, allTokens);
        setSelectedTokens(newTokens);
        return;
      }

      // when clicking fast filter from all to top10 with no selected tokens
      // this function will be called with value = [undefined]
      // in this case, newTokens should be []
      // so we handle it by checking isEmptyValue additionally
      const isEmptyValue = typeof value === 'object' && value.length === 1 && value[0] === undefined;
      const selectedTokens = typeof value === 'string' ? value.split(',') : isEmptyValue ? [] : value;

      // all tokens are shown and both len are equal -> select all
      const allTokens = allTokenShown && selectedTokens.length === assets.length;
      const newTokens = allTokens ? [] : selectedTokens;

      onChange?.(selectedChain, newTokens, allTokens);
      setSelectedTokens(newTokens);
    },
    [selectedChain, chainAssets, assets, onChange],
  );

  const isAllSelected = useMemo(() => {
    return !!assets.length && selectedTokens.length === assets.length;
  }, [selectedTokens, assets]);

  const isPartialSelected = useMemo(() => {
    return !!selectedTokens && selectedTokens.length > 0 && selectedTokens.length < assets.length;
  }, [selectedTokens, assets]);

  return (
    <Stack direction='row' spacing={1}>
      <Select
        value={selectedChain}
        onChange={(e) => handleAssetChainChange(e.target.value as string)}
        disabled={disabled}
        sx={{
          width: '120px',
          height: '32px',
          borderRadius: '8px',
        }}
        renderValue={(v) => {
          return <Typography variant='label1'>{ChainTypeNameMap.get(selectedChain)}</Typography>;
        }}
      >
        {chains.map((chain) => (
          <MenuItem key={chain} value={chain} disabled={chain !== 'ethereum'}>
            <Typography variant='body1'>{ChainTypeNameMap.get(chain)}</Typography>
          </MenuItem>
        ))}
      </Select>
      <Select
        multiple
        value={selectedTokens}
        onChange={(e) => handleAssetTypeChange(e.target.value)}
        disabled={disabled}
        IconComponent={KeyboardArrowDownIcon}
        error={!allTokens && !selectedTokens?.length}
        sx={{
          width: '240px',
          height: '32px',
          borderRadius: '8px',
        }}
        MenuProps={{
          disableAutoFocusItem: true,
          sx: {
            height: '50vh',
          },
        }}
        renderValue={(selected) => {
          if (allTokens) {
            return <Typography variant='label1'>All Tokens</Typography>;
          }
          // convert selected value to corresponding symbol
          const symbols = selected
            .map((d) => walletAssets.find((asset) => asset.contract_addr === d)?.symbol || '')
            .filter((d) => !!d);
          return (
            <Stack direction='row' spacing={1} style={{ marginTop: '1px' }}>
              {symbols.length > 2 ? (
                <Typography variant='label1'>
                  {symbols.slice(0, 2).join(', ')}
                  {` +${selected.length} more`}
                </Typography>
              ) : (
                <Typography variant='label1'>{symbols.join(', ')}</Typography>
              )}
            </Stack>
          );
        }}
      >
        <Stack>
          <FormControl style={{ margin: '0px' }}>
            <RadioGroup
              row
              style={{ justifyContent: 'space-around', paddingLeft: '8px' }}
              value={tokenFastFilter}
              onChange={(e) => setTokenFastFilter(e.target.value as TokenFastFilter)}
            >
              {Object.values(TokenFastFilter).map((v) => {
                return <FormControlLabel key={v} value={v} control={<Radio />} label={TokenFastFilterNameMap.get(v)} />;
              })}
            </RadioGroup>
          </FormControl>
          <OutlinedInput
            startAdornment={
              <InputAdornment position='start'>
                <SearchOutlinedIcon />
              </InputAdornment>
            }
            placeholder='Search'
            sx={{ width: '100%', '& fieldset': { borderColor: 'transparent' } }}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value.toUpperCase())}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Stack>
        <MenuItem
          value='all'
          style={{
            paddingLeft: spacing,
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12',
          }}
        >
          <Checkbox checked={isAllSelected} indeterminate={isPartialSelected} />
          <Typography variant='body1'>Select All</Typography>
        </MenuItem>
        {assets.map((asset) => (
          <MenuItem key={asset.id} value={asset.contract_addr} style={{ paddingLeft: spacingX2 }}>
            <Stack direction='row' style={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center'>
                <Checkbox checked={selectedTokens && selectedTokens.indexOf(asset.contract_addr) > -1} />
                <Typography variant='body1'>{asset.symbol}</Typography>
              </Stack>
              <Typography variant='body1'>${priceFormatter.format(Math.round(asset.price * 100) / 100)}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
