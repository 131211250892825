import { Audience, AudienceTarget, AudienceTargetNameMap } from 'common/types/Audience';
import { Button, IconButton, MenuItem, MenuList, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { ContentCopyOutlined, DeleteOutlineOutlined, InfoOutlined, MoreVertOutlined } from '@mui/icons-material';
import { FC, HTMLAttributes, useState } from 'react';

import { JobStatus } from 'common/types/JobStatus';
import { Uris } from 'Uris';
import classes from './AudienceList.module.scss';
import { renderJobStatus } from 'components/JobStatus/renderJobStatus';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'common/hooks/useTracking';

interface AudienceListProps extends HTMLAttributes<HTMLElement> {
  audiences: Audience[];
  onPublish?: (audience: Audience) => void; // keep this temporary, currently not used
  onDelete?: (audience: Audience) => void;
  onDuplicate?: (audience: Audience) => void;
}

export const AudienceList: FC<AudienceListProps> = ({ audiences, onPublish, onDelete, onDuplicate, ...rest }) => {
  const navigate = useNavigate();
  const { track } = useTracking();
  const [anchorElement, setAnchor] = useState<HTMLElement>();
  const [selectedAudience, setSelectedAudience] = useState<Audience | undefined>(undefined);

  const columnDefs: CommonTableColumn<Audience>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Stack
            className={data?.status === JobStatus.COMPLETED ? classes.pointer : ''}
            onClick={() => {
              if (!data || data?.status !== JobStatus.COMPLETED) return;
              track('click', {
                sub_event: 'audience_viewed',
                custom_props: {
                  audience_id: data.id,
                  audience_name: data.name,
                },
              });
              navigate(Uris.Pages.AdsAudience.AudienceDetail.replace(':audienceId', data.id?.toString() || ''));
            }}
          >
            <Typography variant='subtitle2'>{value}</Typography>
            <Typography variant='body2' color='secondary'>
              no. {data?.id || ''}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'target',
      label: 'Source',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{AudienceTargetNameMap.get(value)}</Typography>;
      },
    },
    {
      id: 'cohorts',
      label: 'Sync Cohort',
      sortable: true,
      render: (values: { id: number; name: string }[]) => {
        return (values || []).map((value) => (
          <Typography key={value.id} variant='body1' className={classes.cohort}>
            {value.name}
          </Typography>
        ));
      },
    },
    {
      id: 'status',
      label: 'Sync Status',
      sortable: true,
      render: (value, idx, data) => {
        return (
          <Stack id={`ads-audience-sync-status-${idx}`} direction='row' alignItems='center' spacing={1}>
            {renderJobStatus(value)}
            {value === JobStatus.QUEUEING || value === JobStatus.RUNNING ? (
              <Tooltip
                title={
                  data?.target === AudienceTarget.CUSTOMER_LIST
                    ? 'This process may take between 30 minutes to 1 hour. Please return later to check the results.'
                    : 'This process may take between 1 to 3 hours. Please return later to check the results.'
                }
              >
                <InfoOutlined fontSize='small' />
              </Tooltip>
            ) : null}
          </Stack>
        );
      },
    },
    {
      id: 'id',
      label: 'Action',
      render: (value, idx, data) => {
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            <Button
              id={data?.status === JobStatus.COMPLETED ? `btn-ads-audience-view-${idx}` : undefined}
              variant='contained'
              disabled={data?.status !== JobStatus.COMPLETED}
              onClick={() => navigate(Uris.Pages.AdsAudience.AudienceDetail.replace(':audienceId', value.toString()))}
            >
              View
            </Button>
            <IconButton
              onClick={(event) => {
                setAnchor(event.currentTarget);
                setSelectedAudience(data);
              }}
              className={classes.moreButton}
            >
              <MoreVertOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <CommonTable
        {...rest}
        selected={audiences.findIndex((audience) => audience.id === selectedAudience?.id)}
        data={audiences}
        columns={columnDefs}
        classes={classes}
      />
      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchor(undefined);
          setSelectedAudience(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              if (typeof selectedAudience?.id !== 'number') return;
              setAnchor(undefined);
              onDuplicate?.(selectedAudience);
              setSelectedAudience(undefined);
            }}
          >
            <Stack direction='row' spacing={1}>
              <ContentCopyOutlined />
              <Typography>Duplicate</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (typeof selectedAudience?.id !== 'number') return;
              setAnchor(undefined);
              onDelete?.(selectedAudience);
              setSelectedAudience(undefined);
            }}
          >
            <Stack direction='row' spacing={1} color='error'>
              <DeleteOutlineOutlined color='error' />
              <Typography color='error'>Delete</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
