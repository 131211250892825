import { Button, Chip, FormControl, Stack, TextField, Typography } from '@mui/material';
import { FC, HTMLAttributes, useMemo, useState } from 'react';

import { ArrowBackOutlined } from '@mui/icons-material';
import { CampaignLinkBase } from 'common/types/CampaignLink';
import { Spinner } from 'components/common/Spinner';
import classNames from 'classnames';
import classes from './CustomizedCampaignInfo.module.scss';
import { colorPrimary10 } from 'common/params';
import { validateUrl } from 'common/utils';

interface CustomizedCampaignInfoProps extends HTMLAttributes<HTMLElement> {
  creating?: boolean;
  campaignLink: CampaignLinkBase;
  onCreate?: (campaignLink: CampaignLinkBase) => void;
  onBack?: () => void;
}

export const CustomizedCampaignInfo: FC<CustomizedCampaignInfoProps> = ({
  creating,
  campaignLink,
  onCreate,
  onBack,
}) => {
  const [editCampaignLink, setEditCampaignLink] = useState<CampaignLinkBase>(campaignLink);
  const [createBtnClicked, setCreateBtnClicked] = useState<boolean>(false);

  const urlValid = useMemo(() => {
    return validateUrl(editCampaignLink.original_url);
  }, [editCampaignLink]);

  const campaignLinkValid = useMemo(() => {
    return (
      urlValid &&
      editCampaignLink.name &&
      editCampaignLink.campaign &&
      editCampaignLink.source &&
      editCampaignLink.medium
    );
  }, [urlValid, editCampaignLink]);

  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classNames(classes.container, classes.top)} spacing={2}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Chip color='primary' label={<Typography variant='h6'>2</Typography>} className={classes.step} />
          <Stack spacing={2} className={classes.title}>
            <Typography variant='h4' color={colorPrimary10}>
              Campaign Information
            </Typography>
            <Typography variant='body1'>
              Fill in your campaign information in the fields below, click the Create URL button and copy the generated
              URL for use in your campaign.
            </Typography>
            <Stack direction='row-reverse' spacing={2}>
              <Button
                variant='contained'
                disabled={creating || (createBtnClicked && !campaignLinkValid)}
                onClick={() => {
                  setCreateBtnClicked(true);
                  if (!campaignLinkValid) return;
                  onCreate?.(editCampaignLink);
                }}
                startIcon={creating ? <Spinner size={24} /> : null}
              >
                Create
              </Button>
              <Button variant='outlined' onClick={onBack} startIcon={<ArrowBackOutlined />}>
                Back
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classNames(classes.container, classes.bottom)} spacing={2}>
        <FormControl>
          <TextField
            label='Original URL *'
            error={createBtnClicked && !urlValid}
            value={editCampaignLink.original_url}
            inputProps={{ maxLength: 2048 }}
            onChange={(e) => setEditCampaignLink((old) => ({ ...old, original_url: e.target.value }))}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label='Name *'
            error={createBtnClicked && !editCampaignLink.name}
            value={editCampaignLink.name}
            inputProps={{ maxLength: 256 }}
            onChange={(e) => setEditCampaignLink((old) => ({ ...old, name: e.target.value }))}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label='Campaign *'
            error={createBtnClicked && !editCampaignLink.campaign}
            value={editCampaignLink.campaign}
            inputProps={{ maxLength: 256 }}
            onChange={(e) => setEditCampaignLink((old) => ({ ...old, campaign: e.target.value }))}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label='Source *'
            error={createBtnClicked && !editCampaignLink.source}
            value={editCampaignLink.source}
            inputProps={{ maxLength: 256 }}
            onChange={(e) => setEditCampaignLink((old) => ({ ...old, source: e.target.value }))}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label='Medium *'
            error={createBtnClicked && !editCampaignLink.medium}
            value={editCampaignLink.medium}
            inputProps={{ maxLength: 256 }}
            onChange={(e) => setEditCampaignLink((old) => ({ ...old, medium: e.target.value }))}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label='Term'
            value={editCampaignLink.term || ''}
            inputProps={{ maxLength: 256 }}
            onChange={(e) => setEditCampaignLink((old) => ({ ...old, term: e.target.value }))}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label='Content'
            value={editCampaignLink.content || ''}
            inputProps={{ maxLength: 256 }}
            onChange={(e) => setEditCampaignLink((old) => ({ ...old, content: e.target.value }))}
          ></TextField>
        </FormControl>
      </Stack>
    </Stack>
  );
};
