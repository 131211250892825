import { Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { IntegrationAuth, IntegrationAuthProvider } from 'common/types/IntegrationAuth';

import { AddOutlined } from '@mui/icons-material';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { HumanIcon } from 'components/icons/HumanIcon';
import classNames from 'classnames';
import classes from './ManageAuth.module.scss';
import { setCookie } from 'common/utils';
import { useMessage } from 'components/message/useMessage';

interface ManageAuthProps {
  urlParam?: string;
  auths: IntegrationAuth[];
  onBtnBackClicked?: () => void;
  onBtnDeleteClicked?: (auth: IntegrationAuth) => void;
}

export const ManageAuth: FC<ManageAuthProps> = ({ urlParam, auths, onBtnBackClicked, onBtnDeleteClicked }) => {
  const { showMessage } = useMessage();

  const [selectedAuthId, setSelectedAuthId] = useState<string>('');

  const onBtnAddClicked = useCallback(async () => {
    // no default auth -> redirect user to twitter authorization page
    await ClientAPI.integrationOauth2(IntegrationAuthProvider.GOOGLE_DRIVE)
      .then(({ data: oauth_url }) => {
        if (!oauth_url) return;
        setCookie(
          'redirect_url',
          `${window.location.origin}${window.location.pathname}${urlParam ? '?' + urlParam : ''}`,
        );
        window.location.href = oauth_url;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      });
  }, [urlParam, showMessage]);

  return (
    <Stack className={classes.root}>
      <Stack className={classes.content}>
        <Stack className={classes.title} alignItems='center' spacing={2}>
          <CommonChip>
            <HumanIcon fontSize='large' />
          </CommonChip>
          <Stack spacing={1} className={classes.info}>
            <Typography variant='h6'>Account Management</Typography>
            <Typography variant='body1'>You can remove or add a new account here.</Typography>
          </Stack>
        </Stack>
        <FormControl className={classes.items}>
          <RadioGroup value={selectedAuthId} onChange={(e) => setSelectedAuthId(e.target.value)}>
            {auths.map((auth, idx) => (
              <Stack key={idx}>
                {idx > 0 ? <Divider /> : null}
                <FormControlLabel
                  labelPlacement='start'
                  value={auth.id}
                  control={<Radio />}
                  sx={{
                    marginLeft: '0px',
                    '.MuiFormControlLabel-label': {
                      flex: '1 1',
                    },
                  }}
                  className={classNames(classes.item, selectedAuthId === auth.id && classes.selected)}
                  label={
                    <Stack direction='row' alignItems='center' spacing={2} className={classes.option}>
                      <HumanIcon fontSize='large' />
                      <Stack>
                        <Typography textAlign='left' className={classes.text}>
                          {auth.name.trim()}
                        </Typography>
                        <Typography textAlign='left' variant='label1'>
                          user_id: {auth.user_id}
                        </Typography>
                      </Stack>
                    </Stack>
                  }
                />
              </Stack>
            ))}
          </RadioGroup>
        </FormControl>
        <Stack direction='row-reverse' className={classes.actions} spacing={1}>
          <Button variant='contained' startIcon={<AddOutlined />} onClick={onBtnAddClicked}>
            Add New
          </Button>
          <Button
            variant='outlined'
            disabled={!selectedAuthId}
            onClick={() => {
              const selectedAuth = auths.find((auth) => auth.id === selectedAuthId);
              selectedAuth && onBtnDeleteClicked?.(selectedAuth);
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction='row-reverse' spacing={1} className={classes.actions}>
        <Button variant='outlined' onClick={onBtnBackClicked}>
          Go Back
        </Button>
      </Stack>
    </Stack>
  );
};
