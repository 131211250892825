import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { FiberManualRecord, InfoOutlined } from '@mui/icons-material';
import { PercentileLevel, PercentileNameMap } from 'common/types/Extension/InfluencerFilter';

import { colorNeutralVariant30 } from 'common/params';

interface PercentilesValueInputProps {
  percentiles?: PercentileLevel[];
  disabled?: boolean;
  onChange?: (percentiles?: PercentileLevel[]) => void;
}

export const PercentilesValueInput: FC<PercentilesValueInputProps> = ({ percentiles, disabled, onChange }) => {
  const [editPercentiles, setEditPercentiles] = useState<PercentileLevel[] | undefined>(percentiles);

  useEffect(() => {
    setEditPercentiles(percentiles);
  }, [percentiles]);

  const getColor = useCallback((level: PercentileLevel) => {
    switch (level) {
      case PercentileLevel.BOTTOM:
        return 'error';
      case PercentileLevel.MIDDLE:
        return 'warning';
      case PercentileLevel.TOP:
        return 'success';
      default:
        return 'success';
    }
  }, []);

  const getTooltipText = useCallback((level: PercentileLevel) => {
    switch (level) {
      case PercentileLevel.TOP:
        return 'PR 70th and above';
      case PercentileLevel.MIDDLE:
        return 'PR 30th to 70th';
      case PercentileLevel.BOTTOM:
        return 'PR 30th and below';
      default:
        return '';
    }
  }, []);

  return (
    <Stack spacing={4}>
      <Stack direction='row' alignItems='center' spacing={1.5}>
        <Typography variant='label1'>Percentile ranges</Typography>
        <Tooltip
          arrow
          placement='top'
          title={
            <Typography variant='label2'>
              The percentile shows how a metric ranks within their tier. A higher percentile means a higher ranking.
            </Typography>
          }
        >
          <InfoOutlined fontSize='small' />
        </Tooltip>
      </Stack>
      <Stack direction='row' spacing={3} alignItems='center' justifyContent='flex-start'>
        {Object.values(PercentileLevel).map((level) => {
          const isSelected = (editPercentiles || [])?.findIndex((percentile) => level === percentile) > -1;
          return (
            <Tooltip arrow placement='top' title={<Typography variant='label2'>{getTooltipText(level)}</Typography>}>
              <Button
                variant='outlined'
                sx={{
                  border: `1px solid ${colorNeutralVariant30}`,
                  color: `#1c1b1f`,
                  backgroundColor: isSelected ? `#add0e5` : 'unset',
                  '&:hover': {
                    backgroundColor: isSelected ? '#99b9cc' : `#add0e5`,
                  },
                }}
                startIcon={<FiberManualRecord fontSize='small' color={getColor(level)} />}
                onClick={() => {
                  const oldArr = editPercentiles || [];
                  if (oldArr.findIndex((v) => v === level) > -1) {
                    // de-select
                    // no elements after de-select -> mark it as undefined
                    const newArr = oldArr.length <= 1 ? undefined : oldArr.filter((v) => v !== level);
                    onChange?.(newArr);
                    setEditPercentiles(newArr);
                    return;
                  }
                  const newArr = [...oldArr, level].sort(
                    (a, b) =>
                      Object.values(PercentileLevel).findIndex((l) => l === a) -
                      Object.values(PercentileLevel).findIndex((l) => l === b),
                  );
                  onChange?.(newArr);
                  setEditPercentiles(newArr);
                }}
              >
                {PercentileNameMap.get(level)}
              </Button>
            </Tooltip>
          );
        })}
      </Stack>
    </Stack>
  );
};
