import { SvgIcon, SvgIconProps } from '@mui/material';

export const AscendingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 24 24'>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M4 17h12M4 12h9M4 7h6m8 6V5m0 0 3 3m-3-3-3 3'
    />
  </SvgIcon>
);
