import { AccessTimeOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { Chip, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Scheduler, SchedulerNameMap } from 'common/types/Scheduler';
import {
  border,
  borderRadius,
  chipInputHeight,
  colorNeutralVariant50,
  colorNeutralVariant80,
  colorPrimary80,
} from 'common/params';

import { FC } from 'react';

interface SchedulerSelectProps {
  data?: Scheduler;
  dark?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (scheduler: Scheduler) => void;
}

const options = [
  {
    value: Scheduler.ONCE,
    label: SchedulerNameMap.get(Scheduler.ONCE),
  },
  {
    value: Scheduler.DAILY,
    label: SchedulerNameMap.get(Scheduler.DAILY),
  },
  {
    value: Scheduler.WEEKLY,
    label: SchedulerNameMap.get(Scheduler.WEEKLY),
  },
];

export const SchedulerSelect: FC<SchedulerSelectProps> = ({ data, dark, readonly, disabled, onChange }) => {
  return (
    <Stack>
      {readonly ? (
        <Chip
          sx={{
            border: border,
            color: colorNeutralVariant50,
            borderRadius: borderRadius,
            backgroundColor: 'transparent',
          }}
          icon={<AccessTimeOutlined style={{ width: '16px', color: colorPrimary80 }} />}
          label={
            <Typography variant='label1' color={colorNeutralVariant80}>
              {SchedulerNameMap.get(data || '')}
            </Typography>
          }
        />
      ) : (
        <Select
          value={data}
          onChange={(e) => {
            onChange?.(e.target.value as Scheduler);
          }}
          IconComponent={KeyboardArrowDown}
          disabled={disabled}
          sx={{
            width: '120px',
            height: chipInputHeight,
            borderRadius: borderRadius,
            border: border,
            '.MuiSelect-icon': {
              color: colorNeutralVariant80,
            },
          }}
          renderValue={(v) => {
            return (
              <Stack direction='row' alignItems='center' spacing={1}>
                <AccessTimeOutlined style={{ width: '16px', color: colorPrimary80 }} />
                <Typography variant='label1' color={colorNeutralVariant80}>
                  {SchedulerNameMap.get(v)}
                </Typography>
              </Stack>
            );
          }}
        >
          {options.map(({ value, label }) => (
            <MenuItem value={value} key={value}>
              <Typography variant='body1'>{label}</Typography>
            </MenuItem>
          ))}
        </Select>
      )}
    </Stack>
  );
};
