import { Button, Chip, Stack, TextField, Typography } from '@mui/material';
import { CampaignLinkBase, generateCampaignLinkUrl } from 'common/types/CampaignLink';
import { FC, HTMLAttributes } from 'react';
import { colorPrimary10, colorPrimary40 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import { ContentCopyOutlined } from '@mui/icons-material';
import { FormControl } from '@mui/base';
import classNames from 'classnames';
import classes from './TwitterAdsGeneratedLink.module.scss';
import copy from 'copy-to-clipboard';
import { useMessage } from 'components/message/useMessage';

interface TwitterAdsGeneratedLinkProps extends HTMLAttributes<HTMLElement> {
  campaignLinks: CampaignLinkBase[];
  onComplete?: () => void;
  onBack?: () => void;
}

export const TwitterAdsGeneratedLink: FC<TwitterAdsGeneratedLinkProps> = ({ campaignLinks, onComplete, onBack }) => {
  const { showMessage } = useMessage();
  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classNames(classes.container, classes.top)} spacing={2}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Chip color='primary' label={<Typography variant='h6'>3</Typography>} className={classes.step} />
          <Stack spacing={2} className={classes.title}>
            <Typography variant='h4' color={colorPrimary10}>
              Use Campaign Link
            </Typography>
            <Typography variant='body1'>
              Share this generated URL in any promotional channels you want to be associated with this custom campaign.
            </Typography>
            <Stack direction='row-reverse' spacing={2}>
              <Button
                variant='contained'
                onClick={() => {
                  onComplete?.();
                }}
              >
                Complete
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classNames(classes.container, classes.bottom)} spacing={2}>
        {campaignLinks.map((campaignLink, idx) => (
          <Stack
            direction='row'
            key={`result-${idx}`}
            alignItems='center'
            justifyContent='space-between'
            spacing={3}
            className={classes.link}
          >
            <CommonChip backgroundColor='transparent' color={colorPrimary40}>
              <Typography variant='body1'>{idx + 1}</Typography>
            </CommonChip>
            <FormControl className={classes.flex}>
              <TextField
                label='Original URL'
                className={classes.textField}
                value={campaignLink.original_url}
                disabled
              ></TextField>
            </FormControl>
            <FormControl className={classes.flex}>
              <TextField label='Name' className={classes.textField} value={campaignLink.name} disabled></TextField>
            </FormControl>
            <Button
              variant='contained'
              startIcon={<ContentCopyOutlined />}
              onClick={() => {
                copy(generateCampaignLinkUrl(campaignLink));
                showMessage('Link is copied', 'success');
              }}
            >
              Copy
            </Button>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
