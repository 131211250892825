import { AddOutlined, FileUploadOutlined } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Collection } from 'common/types/Extension/Collection';
import { CollectionCreateLimitDialog } from 'components/FeatureLimit/CollectionCreateLimitDialog';
import { CollectionDetail } from 'components/collection/CollectionDetail';
import { CollectionList } from 'pages/InfluencerMatcher/Manage/CollectionList';
import { CopyUserToCollectionDialog } from 'components/InfluencerMatcher/collection/CopyUserToCollectionDialog';
import { CreateCollection } from 'components/InfluencerMatcher/collection/CreateCollection';
import { DataContext } from 'contexts/DataContext';
import { DeleteCollection } from 'components/InfluencerMatcher/collection/DeleteCollection';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { FileManagementTab } from 'pages/User/UserFileManagement';
import { InfluencerUploadLimitDialog } from 'components/FeatureLimit/InfluencerUploadLimitDialog';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './ManageCollection.module.scss';
import copy from 'copy-to-clipboard';
import { useMessage } from 'components/message/useMessage';
import { useObservable } from 'react-use';
import { useTracking } from 'common/hooks/useTracking';
import { useUserFeatureLimits } from 'common/hooks/useUserFeatureLimits';

interface ManageCollectionProps {
  collectionId?: number;
}

export const ManageCollection: FC<ManageCollectionProps> = ({ collectionId }) => {
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const { collections$, refetchCollections$ } = useContext(ExtensionContext);
  const collections = useObservable(collections$);
  const [selectedCollection, setSelectedCollection] = useState<Collection | undefined>(undefined);
  const [createCollectionOpened, setCreateCollectionOpened] = useState<boolean>(false);
  const [deleteCollectionOpened, setDeleteCollectionOpened] = useState<boolean>(false);
  const [viewCollectionDetailOpened, setViewCollectionDetailOpened] = useState<boolean>(false);
  const [collectionCreateLimitDialogOpened, setCollectionCreateLimitDialogOpened] = useState<boolean>(false);
  const [influencerUploadLimitDialogOpened, setInfluencerUploadLimitDialogOpened] = useState<boolean>(false);
  const [copiedCollection, setCopiedCollection] = useState<Collection | undefined>(undefined);
  const viewCollectionIdRef = useRef<number | undefined>(collectionId);

  const [shared, setShared] = useState<boolean>(false);

  const { value: createCollectionLimit } = useUserFeatureLimits('collection', 'create');
  const { value: uploadInfluencerLimit } = useUserFeatureLimits('customized_influencer_job');

  useEffect(() => {
    if (!shared) return;
    const timeout = setTimeout(() => {
      setShared(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [shared]);

  useEffect(() => {
    if (!viewCollectionDetailOpened) return;
    setSelectedCollection((old) => collections?.find((collection) => collection.id === old?.id));
  }, [viewCollectionDetailOpened, collections]);

  useEffect(() => {
    setSelectedCollection((old) => (old ? collections?.find((collection) => collection.id === old.id) : undefined));
  }, [collections]);

  useEffect(() => {
    if (typeof viewCollectionIdRef.current !== 'number') return;
    if (collections === undefined) return;
    setSelectedCollection(collections?.find((collection) => collection.id === viewCollectionIdRef.current));
    setViewCollectionDetailOpened(true);
    viewCollectionIdRef.current = undefined;
  }, [collections]);

  const onCollectionViewClicked = useCallback((collection: Collection) => {
    setSelectedCollection(collection);
    setViewCollectionDetailOpened(true);
  }, []);

  const onCollectionPinClicked = useCallback(
    async (collection: Collection) => {
      const newCollection: Collection = {
        ...collection,
        pinned: !collection.pinned,
      };
      await ExtensionAPI.updateCollection(newCollection)
        .then(({ status }) => {
          if (status === 'success') {
            refetchCollections$.next(true);
            return;
          }
          showMessage('Pin collection failed', 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Pin collection failed', 'error');
        });
    },
    [showMessage, refetchCollections$],
  );

  const onCollectionDuplicateClicked = useCallback(
    async (collection: Collection) => {
      const newCollection: Collection = { ...collection };
      newCollection.id = 0;
      newCollection.name = `${newCollection.name} (Copy)`;
      await ExtensionAPI.createCollection(newCollection)
        .then(({ status }) => {
          if (status === 'success') {
            refetchCollections$.next(true);
            return;
          }
          showMessage('Duplicate collection failed', 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Duplicate collection failed', 'error');
        });
    },
    [showMessage, refetchCollections$],
  );

  const onCollectionCopyToClicked = useCallback(async (collection: Collection) => {
    setCopiedCollection(collection);
  }, []);

  const onCollectionDeleteClicked = useCallback((collection: Collection) => {
    setSelectedCollection(collection);
    setDeleteCollectionOpened(true);
  }, []);

  const onCollectionView = useCallback(
    (collectionId?: number) => {
      const collection = collections?.find((collection) => collection.id === collectionId);
      // if new collection -> need to wait until new collections refetch then set
      if (collection) {
        setSelectedCollection(collections?.find((collection) => collection.id === collectionId));
      } else {
        viewCollectionIdRef.current = collectionId;
      }
    },
    [collections],
  );

  const onCollectionShareClicked = useCallback(
    async (collection: Collection) => {
      const copyShareLink = (collectionPublicId: string) => {
        copy(
          window.location.origin +
            Uris.Pages.Public.Collection.replace(':collectionPublicId', collectionPublicId) +
            '?openExternalBrowser=1',
        );
        setShared(true);
      };
      if (collection.public_id) {
        copyShareLink(collection.public_id);
        track('click', {
          sub_event: 'share_link_copied',
          custom_props: { list_id: collection.id, influencer_number: collection.twitter_users.length },
        });
        return;
      }
      await ExtensionAPI.shareCollection(collection)
        .then(({ status, data }) => {
          if (status === 'success' && data?.public_id) {
            refetchCollections$.next(true);
            copyShareLink(data?.public_id);
            track('click', {
              sub_event: 'share_link_copied',
              custom_props: { list_id: collection.id, influencer_number: collection.twitter_users.length },
            });
            return;
          }
          showMessage('Share collection failed', 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Share collection failed', 'error');
        });
    },
    [showMessage, refetchCollections$, track],
  );

  if (viewCollectionDetailOpened && selectedCollection)
    return (
      <CollectionDetail
        shared={shared}
        collection={selectedCollection}
        onBackClicked={() => {
          setViewCollectionDetailOpened(false);
          setSelectedCollection(undefined);
        }}
        onShareClicked={onCollectionShareClicked}
        onCollectionView={onCollectionView}
      />
    );

  return (
    <Stack className={classes.root} spacing={6}>
      <Stack direction='row' justifyContent='space-between' spacing={1}>
        <Typography variant='h3'>My List</Typography>
        <Stack direction='row' spacing={3}>
          <Button
            variant='outlined'
            startIcon={<FileUploadOutlined />}
            onClick={() => {
              if (uploadInfluencerLimit === 0) {
                setInfluencerUploadLimitDialogOpened(true);
                return;
              }
              window.open(`${Uris.Pages.User.FileManagement}?tab=${FileManagementTab.Influencer}`, '_blank');
            }}
          >
            Upload Influencers
          </Button>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography>
              <span
                className={classNames(
                  classes.highlight,
                  (collections?.length || 0) >= createCollectionLimit && createCollectionLimit >= 0 && classes.error,
                )}
              >
                {collections?.length || 0}
              </span>
              /{createCollectionLimit >= 0 ? createCollectionLimit : 'unlimit'} lists
            </Typography>
            <Button
              variant='contained'
              startIcon={<AddOutlined />}
              onClick={() => {
                if ((collections?.length || 0) >= createCollectionLimit && createCollectionLimit >= 0) {
                  setCollectionCreateLimitDialogOpened(true);
                  return;
                }
                setCreateCollectionOpened(true);
              }}
            >
              New List
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classes.table} alignItems='center'>
        {(collections || []).length ? (
          <CollectionList
            collections={collections || []}
            onCollectionShareClicked={onCollectionShareClicked}
            onCollectionViewClicked={onCollectionViewClicked}
            onCollectionPinClicked={onCollectionPinClicked}
            onCollectionDuplicateClicked={onCollectionDuplicateClicked}
            onCollectionCopyToClicked={onCollectionCopyToClicked}
            onCollectionDeleteClicked={onCollectionDeleteClicked}
          />
        ) : (
          <Stack spacing={3} className={classes.empty} alignItems='center'>
            <img alt='empty' src={Uris.Public.Image.InfluencerMatcher.List} className={classes.img} />
            <Typography variant='h4'>Start building your list.</Typography>
            <Typography variant='h6'>
              Before you can add any influencers. We'll first need to create a list in here!
            </Typography>
          </Stack>
        )}
      </Stack>

      {createCollectionOpened ? (
        <CreateCollection
          onSuccess={() => {
            setCreateCollectionOpened(false);
            refetchCollections$.next(true);
          }}
          onCancel={() => {
            setCreateCollectionOpened(false);
          }}
        />
      ) : null}

      {deleteCollectionOpened && selectedCollection ? (
        <DeleteCollection
          collection={selectedCollection}
          onSuccess={() => {
            setDeleteCollectionOpened(false);
            setSelectedCollection(undefined);
            refetchCollections$.next(true);
          }}
          onCancel={() => {
            setDeleteCollectionOpened(false);
            setSelectedCollection(undefined);
          }}
        />
      ) : null}

      {copiedCollection ? (
        <CopyUserToCollectionDialog
          source='List table'
          collection={copiedCollection}
          collections={collections || []}
          onSuccess={(collectionId) => {
            setCopiedCollection(undefined);

            refetchCollections$.next(true);
            const collection = collections?.find((collection) => collection.id === collectionId);
            // if new collection -> need to wait until new collections refetch then set
            if (collection) {
              setSelectedCollection(collections?.find((collection) => collection.id === collectionId));
            } else {
              viewCollectionIdRef.current = collectionId;
            }
            setViewCollectionDetailOpened(true);
          }}
          onCancel={() => {
            setCopiedCollection(undefined);
          }}
        />
      ) : null}

      {collectionCreateLimitDialogOpened ? (
        <CollectionCreateLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setCollectionCreateLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setCollectionCreateLimitDialogOpened(false)}
        />
      ) : null}

      {influencerUploadLimitDialogOpened ? (
        <InfluencerUploadLimitDialog
          open
          onBtnUpgradeClicked={() => {
            setInfluencerUploadLimitDialogOpened(false);
            openSubscriptionDialog$.next(true);
          }}
          onBtnCloseClicked={() => setInfluencerUploadLimitDialogOpened(false)}
        />
      ) : null}
    </Stack>
  );
};
