import { FC, ReactNode } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';

import { CloseOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import classes from './LabelChip.module.scss';

interface LabelChipProps {
  color?: string;
  backgroundColor?: string;
  startIcon?: ReactNode;
  children?: ReactNode;
  tooltip?: string;
  onClick?: () => void;
  onCancel?: () => void;
}

export const LabelChip: FC<LabelChipProps> = ({
  backgroundColor,
  color,
  startIcon,
  children,
  tooltip,
  onClick,
  onCancel,
}) => {
  return (
    <Tooltip title={tooltip} arrow placement='top'>
      <Stack
        direction='row'
        className={classNames(classes.chip, onClick && classes.link)}
        style={{ backgroundColor: backgroundColor || 'auto', color: color || 'auto', borderColor: color || 'auto' }}
        spacing={1}
        onClick={onClick}
      >
        {startIcon ? startIcon : null}
        <Typography variant='label1'>{children}</Typography>
        {onCancel ? <CloseOutlined className={classes.closeBtn} fontSize='small' onClick={onCancel} /> : null}
      </Stack>
    </Tooltip>
  );
};
