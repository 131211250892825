import { Button, Dialog, DialogProps, DialogTitle, FormControl, IconButton, Stack, TextField } from '@mui/material';
import { CloseOutlined, TagOutlined } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { CustomizedCategory } from 'common/types/Category';
import classes from './CustomizedCategoryEditDialog.module.scss';
import { useMessage } from 'components/message/useMessage';

interface CustomizedCategoryEditDialogProps extends DialogProps {
  category: CustomizedCategory;
  onSave?: () => void;
  onCancel?: () => void;
}

export const CustomizedCategoryEditDialog: FC<CustomizedCategoryEditDialogProps> = ({
  open,
  category,
  onSave,
  onCancel,
  ...props
}) => {
  const { showMessage } = useMessage();
  const [name, setName] = useState<string>(category.name);
  const [saveBtnClicked, setSaveBtnClicked] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(category.description || '');

  const categoryValid = useMemo(() => {
    return name;
  }, [name]);

  const updateCategory = useCallback(async () => {
    if (!categoryValid) return;
    await ClientAPI.updateCustomizeCategory(category.id, name, description ? description : undefined)
      .then(({ status, data, message }) => {
        if (status === 'success' && data) {
          onSave?.();
          return;
        }
        showMessage(`Save customized category failed, ${message}`);
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      });
  }, [categoryValid, name, description, category.id, showMessage, onSave]);

  const onBtnSaveClicked = useCallback(() => {
    setSaveBtnClicked(true);
    if (!categoryValid) return;
    updateCategory();
  }, [categoryValid, updateCategory]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={5} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <TagOutlined fontSize='large' />
            </CommonChip>
          </Stack>
          <Stack spacing={2}>
            <FormControl>
              <TextField
                label='Category Name'
                error={saveBtnClicked && !name}
                sx={{ borderColor: 'red' }}
                value={name}
                inputProps={{ maxLength: 40 }}
                onChange={(e) => setName(e.target.value)}
                helperText="This is the name you'll see in the notifications, so use a descriptive name."
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Note'
                value={description}
                inputProps={{ maxLength: 250 }}
                onChange={(e) => setDescription(e.target.value)}
                helperText='Please make a value less than 250 characters.'
              ></TextField>
            </FormControl>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onBtnSaveClicked}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
