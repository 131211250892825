import { Button, Stack, Typography } from '@mui/material';
import { TwitterAdsAccount, TwitterAuth } from 'common/types/TwitterAds';

import { CheckOutlined } from '@mui/icons-material';
import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import classes from './SetSuccess.module.scss';

export interface AdGroupSetSuccess {
  auth: TwitterAuth;
  accountId: string;
}

interface SetSuccessProps {
  auth: TwitterAuth;
  account: TwitterAdsAccount;
  onBtnCloseClicked?: () => void;
  onBtnGettingStartedClicked?: () => void;
}

export const SetSuccess: FC<SetSuccessProps> = ({ account, onBtnCloseClicked, onBtnGettingStartedClicked }) => {
  return (
    <Stack className={classes.root} spacing={1} alignItems='center'>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <CheckOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            <Typography variant='h6'>{account.name} has been set as your default ad account</Typography>
            <Typography variant='body1'>
              You can now publish your audience list to your Twitter Ad groups under {account.name}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
        <Button variant='outlined' onClick={onBtnCloseClicked}>
          Close
        </Button>
        <Button variant='contained' onClick={onBtnGettingStartedClicked}>
          Getting Started
        </Button>
      </Stack>
    </Stack>
  );
};
