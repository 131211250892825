import { Button, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, PauseOutlined, PlayArrowOutlined } from '@mui/icons-material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { FC, HTMLAttributes } from 'react';

import { AttributionSource } from 'common/types/AttributionSource';
import { datetimeFormatter } from 'common/formatters';
import { renderJobStatus } from 'components/JobStatus/renderJobStatus';

interface AttributionSourceListProps extends HTMLAttributes<HTMLElement> {
  attributionSources: AttributionSource[];
  onEdit?: (sourceId: number, data: AttributionSource) => void;
  onDelete?: (sourceId: number) => void;
}

export const AttributionSourceList: FC<AttributionSourceListProps> = ({
  attributionSources,
  onEdit,
  onDelete,
  ...rest
}) => {
  const columnDefs: CommonTableColumn<AttributionSource>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'property_name',
      label: 'Property name',
      sortable: true,
      render: (value, _, data) => {
        return (
          <Stack>
            <Typography variant='subtitle2'>{value}</Typography>
            <Typography variant='body2' color='secondary'>
              {data?.property_id}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'organization_name',
      label: 'Analytics account',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value || '-'}</Typography>;
      },
    },
    {
      id: 'status',
      label: 'Status',
      sortable: true,
      render: (value) => {
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            {renderJobStatus(value)}
          </Stack>
        );
      },
    },
    {
      id: 'updated_at',
      label: 'Last update',
      sortable: true,
      render: (value) => {
        if (!value) return '-';
        return <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>;
      },
    },
    {
      id: 'disabled',
      label: 'Action',
      sortable: true,
      render: (value, _, data) => {
        if (!data) return null;
        return (
          <Stack direction='row' justifyContent='space-between'>
            {value ? (
              <IconButton onClick={() => onEdit?.(data.id, { ...data, disabled: false })}>
                <PlayArrowOutlined />
              </IconButton>
            ) : (
              <IconButton onClick={() => onEdit?.(data.id, { ...data, disabled: true })}>
                <PauseOutlined />
              </IconButton>
            )}
            <Button variant='outlined' startIcon={<CloseOutlined />} onClick={() => onDelete?.(data.id)}>
              Remove
            </Button>
          </Stack>
        );
      },
    },
  ];

  return <CommonTable {...rest} data={attributionSources} columns={columnDefs} />;
};
