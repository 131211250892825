import { Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { DataObjectOutlined } from '@mui/icons-material';
import classes from './InsertPlaceholderDialog.module.scss';

interface InsertPlaceholderDialogProps {
  onSave?: (placeholder: string, fallback?: string) => void;
  onCancel?: () => void;
}

enum PlaceHolder {
  USERNAME = 'username',
  DISPLAY_NAME = 'display_name',
}

const PlaceHolderMap = new Map<PlaceHolder, string>([
  [PlaceHolder.USERNAME, 'Username'],
  [PlaceHolder.DISPLAY_NAME, 'Display name'],
]);

export const InsertPlaceholderDialog: FC<InsertPlaceholderDialogProps> = ({ onSave, onCancel }) => {
  const [placeholder, setPlaceholder] = useState<PlaceHolder>(PlaceHolder.USERNAME);
  // const [fallback, setFallback] = useState<string>('');

  return (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={3} alignItems='center' className={classes.dialog}>
          <CommonChip>
            <DataObjectOutlined fontSize='large' />
          </CommonChip>
          <Typography variant='h6' className={classes.title}>
            Name Placeholder
          </Typography>
          <Typography className={classes.subtitle}>
            You can insert name placeholders in the message content. When the DM is sent, the system will automatically
            capture the influencer's username or display name and insert it.
          </Typography>
          <FormControl className={classes.input}>
            <InputLabel>Placeholder</InputLabel>
            <Select
              label='Placeholder'
              value={placeholder}
              onChange={(e) => setPlaceholder(e.target.value as PlaceHolder)}
            >
              {Array.from(PlaceHolderMap.entries()).map((entry) => (
                <MenuItem key={entry[0]} value={entry[0]}>
                  {entry[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl className={classes.input}>
            <InputLabel>Fallback text (Optional)</InputLabel>
            <OutlinedInput
              size='medium'
              label='Fallback text (Optional)'
              value={fallback}
              onChange={(e) => setFallback(e.target.value)}
            />
          </FormControl> */}
        </Stack>
      }
      footer={
        <Stack direction='row-reverse' spacing={1} className={classes.actions}>
          <Button
            variant='contained'
            onClick={() => {
              onSave?.(placeholder, '');
            }}
          >
            Add
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              onCancel?.();
            }}
          >
            Cancel
          </Button>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  );
};
