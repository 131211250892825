import { Button, Stack, Typography } from '@mui/material';

import { CheckOutlined } from '@mui/icons-material';
import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import { Uris } from 'Uris';
import classes from './PublishAudienceResult.module.scss';

interface PublishAudienceResultProps {
  accountId: string;
  onBtnBackClicked?: () => void;
}

export const PublishAudienceResult: FC<PublishAudienceResultProps> = ({ accountId, onBtnBackClicked }) => {
  return (
    <Stack className={classes.root} spacing={1}>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <CheckOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            <Typography variant='h6'>Successfully published</Typography>
            <Typography variant='body1'>
              View the Audience Manager on{' '}
              <span
                className={classes.link}
                onClick={() => window.open(`${Uris.External.TwitterAds}/audiences/${accountId}/audience_manager`)}
              >
                X(Twitter)
              </span>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
        <Button variant='contained' onClick={onBtnBackClicked}>
          Back to list
        </Button>
      </Stack>
    </Stack>
  );
};
