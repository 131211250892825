import { AddOutlined, InfoOutlined, SearchOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DefaultInfluencerThread, InfluencerThread } from 'common/types/Extension/InfluencerCampaign';
import { FC, useEffect, useMemo, useState } from 'react';

import { Collection } from 'common/types/Extension/Collection';
import { CommonDialog } from 'components/dialog/CommonDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { TwitterUserInfo } from 'components/Twitter/TwitterUserInfo';
import classNames from 'classnames';
import classes from './AddThreadFromListDialog.module.scss';
import { useAsync } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

interface AddThreadFromListDialogProps {
  includedThreads: InfluencerThread[];
  excludedThreads: InfluencerThread[];
  onAdd?: (threads: InfluencerThread[]) => void;
  onCancel?: () => void;
}

export const AddThreadFromListDialog: FC<AddThreadFromListDialogProps> = ({
  includedThreads,
  excludedThreads,
  onAdd,
  onCancel,
}) => {
  const [threads, setThreads] = useState<InfluencerThread[]>(includedThreads);
  const [searchString, setSearchString] = useState<string>('');
  const [selectedCollection, setSelectedCollection] = useState<Collection | undefined>(undefined);

  const { loading, value: collections } = useAsync(async () => {
    return (await ExtensionAPI.getCollections()).data;
  }, []);

  useEffect(() => {
    if (!collections?.length) return;
    setSelectedCollection((old) => (old ? old : collections[0]));
  }, [collections]);

  const filteredTwitterUsers = useMemo(() => {
    if (!selectedCollection) return [];

    return selectedCollection.twitter_users
      .filter((twitter_user) => excludedThreads.every((thread) => twitter_user.id !== thread.receiver_id))
      .filter((twitter_user) => {
        if (!searchString) return true;
        return twitter_user.username?.includes(searchString) || twitter_user.display_name?.includes(searchString);
      });
  }, [searchString, selectedCollection, excludedThreads]);

  const isAllSelected = useMemo(() => {
    return filteredTwitterUsers.every(
      (twitterUser) => threads.findIndex((thread) => thread.receiver_id === twitterUser.id) > -1,
    );
  }, [filteredTwitterUsers, threads]);

  return (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={3} alignItems='center' className={classes.dialog}>
          <Typography variant='h6' className={classes.title}>
            Select influencers from the lists
          </Typography>
          <FormControl className={classes.input}>
            <InputLabel>Search Influencers</InputLabel>
            <Select
              label='Search Influencers'
              value={selectedCollection?.id || ''}
              onChange={(e) =>
                setSelectedCollection(collections?.find((collection) => collection.id === e.target.value))
              }
            >
              {(collections || []).map((collection) => (
                <MenuItem key={collection.id} value={collection.id}>
                  {collection.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.input}>
            <InputLabel>Search Influencers</InputLabel>
            <OutlinedInput
              startAdornment={<SearchOutlined />}
              size='medium'
              label='Search Influencers'
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </FormControl>
          {filteredTwitterUsers.length ? (
            <>
              <Stack
                className={classNames(classes.searchResults, excludedThreads.length && classes.smallSize)}
                spacing={2}
              >
                {filteredTwitterUsers.length
                  ? filteredTwitterUsers.map((user) => {
                      const added = threads.findIndex((thread) => thread.receiver_id === user.id) > -1;
                      return (
                        <Stack spacing={2} key={user.id}>
                          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                            <TwitterUserInfo user={user} />
                            <Button
                              className={classes.addBtn}
                              variant={added ? 'contained' : 'outlined'}
                              onClick={() => {
                                if (added)
                                  setThreads((old) => [...old.filter((thread) => thread.receiver_id !== user.id)]);
                                else
                                  setThreads((old) => [
                                    ...old,
                                    {
                                      ...DefaultInfluencerThread,
                                      id: uuidv4(),
                                      receiver_id: user.id,

                                      receiver: {
                                        id: user.id,
                                        username: user.username,
                                        display_name: user.display_name,
                                        profile_image_url: user.profile_image_url,
                                        verified: user.verified,
                                        verified_type: user.verified_type,
                                        follower_count: user.follower_count,
                                        following_count: user.following_count,
                                      },
                                    },
                                  ]);
                              }}
                            >
                              {added ? 'Added' : 'Add Profile'}
                            </Button>
                          </Stack>
                          <Divider />
                        </Stack>
                      );
                    })
                  : null}
              </Stack>
              {excludedThreads.length ? (
                <Stack spacing={2} className={classes.hint}>
                  <Stack direction='row' alignItems='flex-start' spacing={1}>
                    <InfoOutlined />
                    <Typography style={{ maxWidth: '410px' }}>Contacted influencers won't be shown above</Typography>
                  </Stack>
                  <Divider />
                </Stack>
              ) : null}
              <Stack direction='row-reverse' spacing={1} className={classes.btns}>
                <Button
                  variant='outlined'
                  disabled={!filteredTwitterUsers.length || isAllSelected}
                  startIcon={<AddOutlined />}
                  onClick={() =>
                    setThreads((old) => [
                      ...old,
                      ...filteredTwitterUsers
                        .filter((user) => old.findIndex((thread) => thread.receiver_id === user.id) === -1)
                        .map((user) => ({
                          ...DefaultInfluencerThread,
                          id: uuidv4(),
                          receiver_id: user.id,

                          receiver: {
                            id: user.id,
                            username: user.username,
                            display_name: user.display_name,
                            profile_image_url: user.profile_image_url,
                            verified: user.verified,
                            verified_type: user.verified_type,
                            follower_count: user.follower_count,
                            following_count: user.following_count,
                          },
                        })),
                    ])
                  }
                >
                  Add All
                </Button>
                <Button
                  disabled={!filteredTwitterUsers.length || !isAllSelected}
                  onClick={() => {
                    setThreads((old) => [
                      ...old.filter(
                        (thread) => filteredTwitterUsers.findIndex((user) => user.id === thread.receiver_id) === -1,
                      ),
                    ]);
                  }}
                >
                  Remove All
                </Button>
              </Stack>
            </>
          ) : !loading ? (
            <Stack direction='row' alignItems='flex-start' spacing={1} className={classes.hint}>
              <InfoOutlined />
              <Typography style={{ maxWidth: '410px' }}>
                All influencers in this list have already been assigned in a previous message.
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      }
      footer={
        <Stack direction='row-reverse' spacing={1} className={classes.actions}>
          <Button
            variant='contained'
            disabled={!threads.length}
            onClick={() => {
              onAdd?.(threads);
            }}
          >
            Save
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              onCancel?.();
            }}
          >
            Cancel
          </Button>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  );
};
