import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { colorNeutralVariant20, colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import ExtensionAPI from 'common/ExtensionAPI';
import { InfluencerCampaign } from 'common/types/Extension/InfluencerCampaign';
import classes from './ArchiveCampaignDialog.module.scss';
import { useMessage } from 'components/message/useMessage';

interface ArchiveCampaignDialogProps extends DialogProps {
  campaign: InfluencerCampaign;
  onArchive?: () => void;
  onCancel?: () => void;
}

export const ArchiveCampaignDialog: FC<ArchiveCampaignDialogProps> = ({
  open,
  campaign,
  onArchive,
  onCancel,
  ...props
}) => {
  const { showMessage } = useMessage();

  const archiveCampaign = useCallback(async () => {
    if (typeof campaign.id !== 'number') return;
    await ExtensionAPI.archiveInfluencerCampaign(campaign.id)
      .then(({ status, data }) => {
        if (status === 'success' && data) {
          onArchive?.();
          return;
        }
        showMessage(`Archive campaign failed: ${status}`, 'error');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      });
  }, [campaign.id, showMessage, onArchive]);

  const onBtnArchiveClicked = useCallback(() => {
    archiveCampaign();
  }, [archiveCampaign]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <DeleteOutlineOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Are you sure you want to archive the campaign?
              </Typography>
              <Typography variant='body1' textAlign='center' color={colorNeutralVariant20}>
                Once archived, it will not be accessible unless you request retrieval through a support ticket.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='contained' onClick={onBtnArchiveClicked}>
            Archive
          </Button>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
