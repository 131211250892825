import { SvgIcon, SvgIconProps } from '@mui/material';
import { useEffect, useState } from 'react';

export const InProgressIcon = (props: SvgIconProps) => {
  const [rotation, setRotation] = useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setRotation((old) => (old + 1) % 8);
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <SvgIcon {...props} viewBox='0 0 25 24' style={{ transform: `rotate(${45 * rotation}deg)` }}>
      <path
        d='M12.5 6V3M12.5 21V19M19.5 12H21.5M3.5 12H6.5M18.157 6.343L18.864 5.636M6.136 18.364L7.55 16.95M17.45 16.95L18.864 18.364M6.136 5.636L8.257 7.757'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
};
