import { Breadcrumbs, IconButton, Link, Paper, Stack, Tab, Tabs, ThemeProvider, Typography } from '@mui/material';
import {
  ContentCopyOutlined,
  DirectionsRunOutlined,
  MonetizationOnOutlined,
  ScheduleOutlined,
  TrendingUpOutlined,
} from '@mui/icons-material/';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { colorNeutral40, colorPrimary30 } from 'common/params';
import { dateFormatter, datetimeFormatter, numberFormatter } from 'common/formatters';
import { useNavigate, useParams } from 'react-router-dom';

import { BabyIcon } from 'components/icons/BabyIcon';
import { ChainIcon } from 'components/icons/ChainIcon';
import ClientAPI from 'common/ClientAPI';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NotFoundPage } from 'pages/Common/NotFoundPage';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import { WalletTokenAssetChart } from 'components/AddressMetrics/WalletTokenAssetChart';
import { WalletTotalAssetChart } from 'components/AddressMetrics/WalletTotalAssetChart';
import { WalletTrendChart } from 'components/AddressMetrics/WalletTrendChart';
import classes from './WalletDetail.module.scss';
import copy from 'copy-to-clipboard';
import { darkTheme } from 'theme/ThemeProvider';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

interface DetailTab {
  icon: React.ReactNode; // icon for the tab
  label: string; // display label for the tab
}

const tabs: DetailTab[] = [
  { label: 'Trend', icon: <TrendingUpOutlined /> },
  { label: 'Token Allocation', icon: <MonetizationOnOutlined /> },
  { label: 'Blockchain Distribution', icon: <ChainIcon /> },
];

export const WalletDetail: FC<any> = () => {
  const navigate = useNavigate();
  const { address } = useParams();
  const { showMessage } = useMessage();
  const [tab, setTab] = useState<number>(0);

  const {
    loading,
    error,
    value: wallet,
  } = useAsync(async () => {
    return (await ClientAPI.getWallet(address || '')).data;
  }, [address]);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }, []);

  const mainContent = useMemo(() => {
    if (!wallet) return null;
    switch (tab) {
      case 0: // Wallet history asset trend
        return <WalletTrendChart address={wallet.account} />;
      case 1: // Wallet token allocation
        return <WalletTokenAssetChart address={wallet.account} />;
      case 2: // Wallet block chain asset distribution
        return <WalletTotalAssetChart address={wallet.account} />;
      default:
        return <></>;
    }
  }, [tab, wallet]);

  if (loading) return <Spinner />;
  if (!wallet) return <NotFoundPage />;

  return (
    <Stack className={classes.root}>
      {/* top black area */}
      <ThemeProvider theme={darkTheme}>
        <Paper className={classes.blackArea}>
          {/* breadcrumbs */}
          <Breadcrumbs
            separator={<NavigateNextIcon style={{ color: colorNeutral40 }} />}
            className={classes.breadcrumbs}
            aria-label='breadcrumb'
          >
            <Link
              variant='subtitle2'
              underline='none'
              color={colorNeutral40}
              className={classes.link}
              onClick={() => navigate(Uris.Pages.WalletSelector.Index)}
            >
              Wallet Selector
            </Link>
            <Typography color={colorPrimary30} variant='subtitle2'>
              {address}
            </Typography>
          </Breadcrumbs>
          <Stack direction='column' spacing={3} className={classes.container}>
            <Stack spacing={1}>
              <Typography variant='h1' className={classes.name}>
                {wallet.name ||
                  `${wallet.account.substring(0, 5)}...${wallet.account.substring(wallet.account.length - 5)}`}
              </Typography>
              <Stack direction='row' spacing={1.5}>
                <Typography variant='body1'>{wallet.account}</Typography>
                <IconButton style={{ width: '24px', height: '24px' }} onClick={() => copy(wallet.account)}>
                  <ContentCopyOutlined />
                </IconButton>
              </Stack>
            </Stack>
            <Stack spacing={1.5}>
              <Stack direction='row' spacing={1}>
                <BabyIcon />
                <Typography variant='body1'>Wallet age: Since </Typography>
                <Typography variant='body1'>
                  {typeof wallet.registration_time === 'number'
                    ? dateFormatter.format(new Date(wallet.registration_time * 1000))
                    : '-/-/-'}
                </Typography>
                <Typography variant='body1'>
                  {typeof wallet.registration_time === 'number'
                    ? `(${Math.floor((new Date().getTime() - wallet.registration_time * 1000) / (86400 * 1000))} days)`
                    : ''}
                </Typography>
              </Stack>
              <Stack direction='row' spacing={1}>
                <DirectionsRunOutlined />
                <Typography variant='body1'>Wallet active since:</Typography>
                <Typography variant='body1'>
                  {typeof wallet.last_trading_time === 'number'
                    ? dateFormatter.format(new Date(wallet.last_trading_time * 1000))
                    : '-/-/-'}
                </Typography>
              </Stack>
              <Stack direction='row' spacing={1}>
                <ScheduleOutlined />
                <Typography variant='body1'>Data synced at:</Typography>
                <Typography variant='body1'>
                  {typeof wallet.synced_at === 'number'
                    ? datetimeFormatter.format(new Date(wallet.synced_at * 1000))
                    : '-/-/-'}
                </Typography>
              </Stack>
            </Stack>
            <Stack className={classes.box} spacing={3}>
              <Stack className={classes.chip}>
                <MonetizationOnOutlined fontSize='large' className={classes.icon} />
              </Stack>
              <Stack>
                <Stack direction='row' spacing={1} alignItems='flex-end'>
                  <Typography variant='h6' className={classes.count}>
                    {typeof wallet.total_asset_usd === 'number' ? numberFormatter.format(wallet.total_asset_usd) : '-'}
                  </Typography>
                  <Typography variant='subtitle1' className={classes.count}>
                    USD
                  </Typography>
                </Stack>
                <Typography variant='label1'>Selected wallet balance on ETH Chain</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </ThemeProvider>

      {/* bottom detail area */}
      <Stack direction='column' spacing={4} alignItems='center' className={classes.detail}>
        <Tabs value={tab} onChange={handleTabChange} centered className={classes.tabs}>
          {tabs.map((t) => (
            <Tab
              key={t.label}
              className={classes.tab}
              label={
                <Stack direction='row' alignItems='center' spacing={1}>
                  {t.icon}
                  <Typography variant='subtitle2'>{t.label}</Typography>
                </Stack>
              }
            />
          ))}
        </Tabs>
        {mainContent}
      </Stack>
    </Stack>
  );
};
