import {
  CssBaseline,
  PaletteColorOptions,
  ThemeOptions,
  ThemeProvider as ThemeProviderV5,
  createTheme as createThemeV5,
  responsiveFontSizes,
} from '@mui/material';
import { FC, HTMLAttributes } from 'react';
import { darkComponents, lightComponents } from 'theme/components';
import { darkPalett, lightPalett } from 'theme/palette';

import { typography } from 'theme/typography';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    ...lightPalett,
  },
  typography: { ...typography },
  components: {
    ...lightComponents,
  },
};

export const darkThemeOptions: ThemeOptions = {
  palette: {
    ...darkPalett,
  },
  typography: { ...typography },
  components: {
    ...darkComponents,
  },
};

/** Theme */
export const lightTheme = responsiveFontSizes(createThemeV5(lightThemeOptions), {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
  factor: 2,
});
export const darkTheme = responsiveFontSizes(createThemeV5(darkThemeOptions), {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
  factor: 2,
});

export const ThemeProvider: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  /** Render */
  return (
    <ThemeProviderV5 theme={lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProviderV5>
  );
};

// Type augmentation for custom theming variants
declare module '@mui/material/styles' {
  interface TypographyVariants {
    label1: React.CSSProperties;
    label2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label1?: React.CSSProperties;
    label2?: React.CSSProperties;
  }

  interface PaletteOptions {
    teritary?: PaletteColorOptions;
    neutral?: PaletteColorOptions;
    neutralVariant?: PaletteColorOptions;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    teritary: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label1: true;
    label2: true;
  }
}
