import { FC, HTMLAttributes, createContext, useState } from 'react';

import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { getGAClientId } from 'common/utils';
import { useLifecycles } from 'react-use';

interface PagePath {
  prev: string;
  curr: string;
}

export interface TrackingContextProps {
  sessionId$: BehaviorSubject<number>;
  engageTime$: BehaviorSubject<Date>;
  clientId$: BehaviorSubject<string>;
  pagePath$: BehaviorSubject<PagePath>;
}
export const TrackingContextDefault: TrackingContextProps = {
  sessionId$: new BehaviorSubject<number>(Math.floor(new Date().getTime() / 1000)),
  engageTime$: new BehaviorSubject<Date>(new Date()),
  clientId$: new BehaviorSubject<string>(getGAClientId() || crypto.randomUUID()),
  pagePath$: new BehaviorSubject<PagePath>({ curr: '', prev: '' }),
};

export const TrackingContext = createContext<TrackingContextProps>(TrackingContextDefault);

const TrackingContextProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  /** State */
  const [context, setContext] = useState<TrackingContextProps>(TrackingContextDefault);

  /** Lifecycle */
  useLifecycles(
    () => setContext(cloneDeep(TrackingContextDefault)),
    () => {},
  );

  /** Render */
  return <TrackingContext.Provider value={context}>{children}</TrackingContext.Provider>;
};

export default TrackingContextProvider;
