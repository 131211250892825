import { Divider, FormControl, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';

interface MinMaxValueInputProps {
  min?: number;
  max?: number;
  minLimit?: number;
  maxLimit?: number;
  percentage?: boolean;
  roundLimit?: boolean;
  disabled?: boolean;
  onChange?: (min?: number, max?: number) => void;
}

export const MinMaxValueInput: FC<MinMaxValueInputProps> = ({
  min,
  max,
  minLimit,
  maxLimit,
  percentage,
  roundLimit,
  disabled,
  onChange,
}) => {
  const [editMin, setEditMin] = useState<string | undefined>(
    percentage ? (typeof min === 'number' ? (min * 100).toString() : undefined) : min?.toString(),
  );
  const [editMax, setEditMax] = useState<string | undefined>(
    percentage ? (typeof max === 'number' ? (max * 100).toString() : undefined) : max?.toString(),
  );

  useEffect(() => {
    setEditMin(percentage ? (typeof min === 'number' ? (min * 100).toString() : undefined) : min?.toString());
  }, [percentage, min]);

  useEffect(() => {
    setEditMax(percentage ? (typeof max === 'number' ? (max * 100).toString() : undefined) : max?.toString());
  }, [percentage, max]);

  const minError = useMemo(() => {
    const min = parseFloat(editMin || '');
    return typeof min === 'number' && typeof maxLimit === 'number' && min > maxLimit * (percentage ? 100 : 1);
  }, [percentage, editMin, maxLimit]);

  const maxError = useMemo(() => {
    const max = parseFloat(editMax || '');
    return typeof max === 'number' && typeof minLimit === 'number' && max < minLimit * (percentage ? 100 : 1);
  }, [percentage, editMax, minLimit]);

  return (
    <Stack direction='row' spacing={3} alignItems='center' justifyContent='flex-start'>
      <FormControl>
        <InputLabel>MIN</InputLabel>
        <OutlinedInput
          label='MIN'
          placeholder={
            typeof minLimit === 'number'
              ? (roundLimit
                  ? Math.ceil(minLimit * (percentage ? 100 : 1))
                  : minLimit * (percentage ? 100 : 1)
                ).toString()
              : 'MIN'
          }
          type='number'
          disabled={disabled}
          error={minError}
          value={editMin?.toString() || ''}
          onChange={(e) => {
            setEditMin(e.target.value);
          }}
          onBlur={() => {
            let min = parseFloat(editMin || '');
            let max = typeof editMax === 'string' ? parseFloat(editMax) : undefined;
            if (percentage) {
              if (typeof min === 'number' && !Number.isNaN(min)) min = min / 100;
              if (typeof max === 'number' && !Number.isNaN(max)) max = max / 100;
            }
            onChange?.(Number.isNaN(min) ? undefined : min, Number.isNaN(max) ? undefined : max);
          }}
        ></OutlinedInput>
      </FormControl>
      {percentage ? <Typography variant='label1'>%</Typography> : null}
      <Stack style={{ width: '16px' }}>
        <Divider />
      </Stack>
      <FormControl>
        <InputLabel>MAX</InputLabel>
        <OutlinedInput
          label='MAX'
          placeholder={
            typeof maxLimit === 'number'
              ? (roundLimit
                  ? Math.floor(maxLimit * (percentage ? 100 : 1))
                  : maxLimit * (percentage ? 100 : 1)
                ).toString()
              : 'MAX'
          }
          type='number'
          disabled={disabled}
          error={maxError}
          value={editMax?.toString() || ''}
          onChange={(e) => {
            setEditMax(e.target.value);
          }}
          onBlur={() => {
            let min = typeof editMin === 'string' ? parseFloat(editMin) : undefined;
            let max = parseFloat(editMax || '');
            if (percentage) {
              if (typeof min === 'number' && !Number.isNaN(min)) min = min / 100;
              if (typeof max === 'number' && !Number.isNaN(max)) max = max / 100;
            }
            onChange?.(Number.isNaN(min) ? undefined : min, Number.isNaN(max) ? undefined : max);
          }}
        ></OutlinedInput>
      </FormControl>
      {percentage ? <Typography variant='label1'>%</Typography> : null}
    </Stack>
  );
};
