import { InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { colorDisabled } from 'common/params';
import { label1 } from 'theme/typography';

interface TargetValueInputProps {
  target?: string;
  disabled?: boolean;
  onChange?: (target: string) => void;
}

// for target (greater/less) value usage in filter
export const TargetValueInput: FC<TargetValueInputProps> = ({ target, disabled, onChange }) => {
  return (
    <Stack direction='row' alignItems='center'>
      <OutlinedInput
        sx={{ width: '180px', height: '32px', borderRadius: '8px', ...label1 }}
        disabled={disabled}
        startAdornment={
          <InputAdornment position='start'>
            <Typography style={{ color: disabled ? colorDisabled : 'inherit' }}>$</Typography>
          </InputAdornment>
        }
        value={target}
        onChange={(e) => onChange?.(e.target.value)}
      ></OutlinedInput>
    </Stack>
  );
};
