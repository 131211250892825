import { Button, Chip, Stack, Typography } from '@mui/material';
import { CampaignLinkBase, generateCampaignLinkUrl } from 'common/types/CampaignLink';
import { ContentCopyOutlined, LinkOutlined } from '@mui/icons-material';
import { FC, HTMLAttributes } from 'react';

import { CommonChip } from 'components/chip/CommonChip';
import classNames from 'classnames';
import classes from './CustomizedGeneratedLink.module.scss';
import { colorPrimary10 } from 'common/params';
import copy from 'copy-to-clipboard';
import { useMessage } from 'components/message/useMessage';

interface CustomizedGeneratedLinkProps extends HTMLAttributes<HTMLElement> {
  campaignLinks: CampaignLinkBase[];
  onComplete?: () => void;
  onBack?: () => void;
}

export const CustomizedGeneratedLink: FC<CustomizedGeneratedLinkProps> = ({ campaignLinks, onComplete, onBack }) => {
  const { showMessage } = useMessage();
  return (
    <Stack className={classes.root} alignItems='center'>
      <Stack className={classNames(classes.container, classes.top)} spacing={2}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Chip color='primary' label={<Typography variant='h6'>3</Typography>} className={classes.step} />
          <Stack spacing={2} className={classes.title}>
            <Typography variant='h4' color={colorPrimary10}>
              Use Campaign Link
            </Typography>
            <Typography variant='body1'>
              Put the generated link URL in the promotional contents to track the source of visitors and understand
              which campaign perform well.
            </Typography>
            <Stack direction='row-reverse' spacing={2}>
              <Button
                variant='contained'
                onClick={() => {
                  onComplete?.();
                }}
              >
                Complete
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classNames(classes.container, classes.bottom)} spacing={2}>
        {campaignLinks.map((campaignLink, idx) => (
          <Stack direction='row' key={`result-${idx}`} className={classes.link} spacing={3} alignItems='center'>
            <Stack>
              <CommonChip>
                <LinkOutlined />
              </CommonChip>
            </Stack>
            <Typography className={classes.url}>{generateCampaignLinkUrl(campaignLink)}</Typography>
            <Stack>
              <Button
                variant='contained'
                startIcon={<ContentCopyOutlined />}
                onClick={() => {
                  copy(generateCampaignLinkUrl(campaignLink));
                  showMessage('Link is copied', 'success');
                }}
              >
                Copy
              </Button>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
