import { Color } from 'common/types/Color';
import { toRgb } from 'common/colors/toColor';

const MAX = 100;
const MIN = 0;

/**
 * Obtain the current color based on colors stops and the current progress.
 */
export const getColorProgress = (colors: Color[], progress: number) => {
  // clip progress between 0 - 100
  const finalProgress = Math.min(Math.max(progress, 0), 100);

  const unit = (MAX - MIN) / (colors.length - 1);
  const startIdx = Math.floor(finalProgress / unit);
  const [startColor, endColor] = [colors[startIdx], colors[Math.min(startIdx + 1, colors.length - 1)]];
  const progressInStop = (finalProgress % unit) / unit;
  const r = startColor[0] + progressInStop * (endColor[0] - startColor[0]);
  const g = startColor[1] + progressInStop * (endColor[1] - startColor[1]);
  const b = startColor[2] + progressInStop * (endColor[2] - startColor[2]);
  return toRgb([r, g, b]);
};
