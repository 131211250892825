import { SvgIcon, SvgIconProps } from '@mui/material';

export const GoogleAnalyticsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 70 80'>
    <path
      d='M50.4541 9.54544V69.5454C50.4541 76.2642 55.0848 80 59.9996 80C64.545 80 69.545 76.8181 69.545 69.5454V9.99998C69.545 3.84942 64.9996 0 59.9996 0C54.9996 0 50.4541 4.24715 50.4541 9.54544Z'
      fill='#F8AB00'
    />
    <path
      d='M25.4541 40V69.5455C25.4541 76.2642 30.0848 80 34.9996 80C39.545 80 44.545 76.8182 44.545 69.5455V40.4546C44.545 34.304 39.9996 30.4546 34.9996 30.4546C29.9996 30.4546 25.4541 34.7017 25.4541 40Z'
      fill='#E37300'
    />
    <path
      d='M19.545 70.4546C19.545 75.7245 15.2694 80.0001 9.99955 80.0001C4.72967 80.0001 0.454102 75.7245 0.454102 70.4546C0.454102 65.1847 4.72967 60.9092 9.99955 60.9092C15.2694 60.9092 19.545 65.1847 19.545 70.4546Z'
      fill='#E37300'
    />
  </SvgIcon>
);
