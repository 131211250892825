import { Button, Stack, Typography } from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  InfluencerCampaign,
  InfluencerThread,
  InfluencerThreadStage,
  InfluencerThreadStageNameMap,
  InfluencerThreadStatus,
  InfluencerThreadStatusNameMap,
} from 'common/types/Extension/InfluencerCampaign';
import { colorNeutralVariant50, colorPrimary40, colorSurface1 } from 'common/params';

import { AddOutlined } from '@mui/icons-material';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { InProgressIcon } from 'components/icons/InProgressIcon';
import { InfluencerThreadList } from './InfluencerThreadList';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './CampaignThreadsDetail.module.scss';
import { useNavigate } from 'react-router-dom';

interface CampaignThreadsDetailProps {
  campaign: InfluencerCampaign;
  onInfluencerThreadsStageChangeClicked?: (threads: InfluencerThread[]) => void;
  onInfluencerThreadsStatusChangeClicked?: (threads: InfluencerThread[]) => void;
}

export const CampaignThreadsDetail: FC<CampaignThreadsDetailProps> = ({
  campaign,
  onInfluencerThreadsStageChangeClicked,
  onInfluencerThreadsStatusChangeClicked,
}) => {
  const navigate = useNavigate();
  const { selectedThreadStage$, selectedThreadStatus$ } = useContext(ExtensionContext);
  const [selectedStage, setSelectedStage] = useState<InfluencerThreadStage>(
    selectedThreadStage$.getValue() || InfluencerThreadStage.INVITED,
  );
  const [selectedStatus, setSelectedStatus] = useState<InfluencerThreadStatus>(
    selectedThreadStatus$.getValue() || InfluencerThreadStatus.INIT,
  );

  useEffect(() => {
    selectedThreadStage$.next(selectedStage);
  }, [selectedStage, selectedThreadStage$]);

  useEffect(() => {
    selectedThreadStatus$.next(selectedStatus);
  }, [selectedStatus, selectedThreadStatus$]);

  const filteredStageThreads = useMemo(() => {
    return (campaign?.threads || []).filter((thread) => !selectedStage || thread.stage === selectedStage);
  }, [selectedStage, campaign?.threads]);

  const filteredStatusThreads = useMemo(() => {
    return filteredStageThreads.filter((thread) => !selectedStatus || thread.status === selectedStatus);
  }, [selectedStatus, filteredStageThreads]);

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          {Array.from(InfluencerThreadStageNameMap.entries()).map((entry) => (
            <Button
              key={entry[0]}
              className={classes.filterBtn}
              sx={{
                '&.MuiButton-contained': {
                  backgroundColor: colorSurface1,
                  color: colorPrimary40,
                  border: `1px solid ${colorNeutralVariant50}`,
                },
              }}
              variant={selectedStage === entry[0] ? 'contained' : 'outlined'}
              onClick={() => setSelectedStage(entry[0])}
            >
              {entry[1]} - {(campaign?.threads || []).filter((thread) => thread.stage === entry[0]).length}
            </Button>
          ))}
        </Stack>
        <Button
          variant='contained'
          startIcon={<AddOutlined />}
          onClick={() =>
            navigate(
              Uris.Pages.InfluencerMatcher.EditInfluencerCampaign.replace(
                ':campaignId',
                campaign?.id?.toString() || '',
              ),
            )
          }
        >
          Add Influencers
        </Button>
      </Stack>
      <Stack direction='row'>
        <Stack direction='row' spacing={1.5} className={classes.tabs}>
          {Array.from(InfluencerThreadStatusNameMap.entries()).map((entry) => (
            <Stack
              direction='row'
              spacing={1}
              key={entry[0]}
              className={classNames(classes.tab, selectedStatus === entry[0] && classes.selected)}
              onClick={() => setSelectedStatus(entry[0])}
            >
              {entry[0] === InfluencerThreadStatus.SENDING &&
              filteredStageThreads.filter((thread) => thread.status === entry[0]).length ? (
                <InProgressIcon className={classes.progress} />
              ) : null}
              <Typography>
                {entry[1]} - {filteredStageThreads.filter((thread) => thread.status === entry[0]).length}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack className={classes.table} alignItems='center'>
        <InfluencerThreadList
          campaign={campaign}
          stage={selectedStage}
          status={selectedStatus}
          influencerThreads={filteredStatusThreads}
          onInfluencerThreadsStageChangeClicked={onInfluencerThreadsStageChangeClicked}
          onInfluencerThreadsMessageClicked={(threads, status) => {
            switch (status) {
              case InfluencerThreadStatus.SENDING:
                return;
              case InfluencerThreadStatus.FAILED:
                onInfluencerThreadsStatusChangeClicked?.(
                  threads.map((thread) => ({ ...thread, status: InfluencerThreadStatus.SENDING })),
                );
                return;
              default:
                navigate(
                  Uris.Pages.InfluencerMatcher.CampaignSendMessage.replace(
                    ':campaignId',
                    campaign?.id?.toString() || '',
                  ),
                  {
                    state: { threads },
                  },
                );
                return;
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
