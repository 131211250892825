import { useBeforeUnload, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';

const userLang = navigator.language;

export const useLeavePageConfirm = (
  enabled: boolean = true,
  message = userLang === 'zh-TW'
    ? '要離開網站嗎？\n系統可能不會儲存你所做的變更'
    : 'Leave site?\nChanges you made may not be saved.',
) => {
  const blocker = useBlocker(
    useCallback(() => (typeof message === 'string' && enabled ? !window.confirm(message) : false), [message, enabled]),
  );
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (!enabled) return;
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker, enabled]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (!enabled) return;
        event.preventDefault();
        event.returnValue = '';
      },
      [enabled],
    ),
    { capture: enabled },
  );
};
