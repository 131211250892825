import { Button, Drawer, DrawerProps, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, TagOutlined } from '@mui/icons-material';
import { CustomizedCategory, Growing3Category } from 'common/types/Category';
import { FC, useCallback, useEffect, useState } from 'react';

import { CategoriesSelectDialog } from 'pages/WalletSelector/CohortV2/Edit/Dialog/CategoriesSelectDialog';
import { Cohort } from 'common/types/Cohort';
import { FileManagementTab } from 'pages/User/UserFileManagement';
import { Growing3Icon } from 'components/icons/Growing3Icon';
import { JobStatus } from 'common/types/JobStatus';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './EditCategory.module.scss';
import { colorPrimary10 } from 'common/params';
import { renderCategory } from 'components/Category/renderCategory';

interface EditCategoryProps extends DrawerProps {
  cohort: Cohort;
  growing3Categories: Growing3Category[];
  customizedCategories: CustomizedCategory[];
  onNext?: (growing3Categories: number[], customizedCategories: number[]) => void;
  onClose?: () => void;
}

export const EditCategory: FC<EditCategoryProps> = ({
  open,
  cohort,
  growing3Categories,
  customizedCategories,
  onNext,
  onClose,
}) => {
  const [selectedGrowing3Categories, setSelectedGrowing3Categories] = useState<number[]>(
    cohort.growing3_categories || [],
  );
  const [selectedCustomizedCategories, setSelectedCustomizedCategories] = useState<number[]>(
    cohort.customized_categories || [],
  );
  const [growing3DialogOpened, setGrowing3DialogOpened] = useState<boolean>(false);
  const [customizedDialogOpened, setCustomizedDialogOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!open) return;
    setSelectedGrowing3Categories(cohort.growing3_categories || []);
    setSelectedCustomizedCategories(cohort.customized_categories || []);
  }, [open, cohort]);

  const onGrowing3CategoryClicked = useCallback((id: number) => {
    setSelectedGrowing3Categories((old) => {
      if (old.findIndex((v) => v === id) > -1) {
        return [...old.filter((c) => c !== id)];
      }
      return [...old, id];
    });
  }, []);

  const onCustomizedCategoryClicked = useCallback((id: number) => {
    setSelectedCustomizedCategories((old) => {
      if (old.findIndex((v) => v === id) > -1) {
        return [...old.filter((c) => c !== id)];
      }
      return [...old, id];
    });
  }, []);

  const deleteGrowing3Category = useCallback((id: number) => {
    setSelectedGrowing3Categories((old) => old.filter((c) => c !== id));
  }, []);

  const deleteCustomizedCategory = useCallback((id: number) => {
    setSelectedCustomizedCategories((old) => old.filter((c) => c !== id));
  }, []);

  const resetCategory = useCallback(() => {
    setSelectedGrowing3Categories([]);
    setSelectedCustomizedCategories([]);
  }, []);

  return (
    <Drawer
      open={open}
      anchor='right'
      PaperProps={{ sx: { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' } }}
    >
      <Stack spacing={3} className={classes.root}>
        <Stack direction='row-reverse' className={classes.btnClose}>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Stack>
        <img alt='category' src={Uris.Public.Image.WalletSelector.CreateCohort.Category} className={classes.img} />
        <Stack alignItems='center'>
          <Typography variant='h5'>Choose the categories you'd like to filter below</Typography>
        </Stack>
        <Stack direction='row' flexWrap='wrap' justifyContent='center'>
          {selectedGrowing3Categories.length || selectedCustomizedCategories.length ? (
            <>
              {selectedGrowing3Categories.map((categoryId) => {
                const category = growing3Categories.find((category) => category.id === categoryId);
                if (!category) return null;
                return (
                  <Stack
                    className={classNames(classes.category, category.disabled && classes.disabled)}
                    key={category.id}
                    direction='row'
                  >
                    {renderCategory(category)}
                    <CloseOutlined className={classes.btnDelete} onClick={() => deleteGrowing3Category(category.id)} />
                  </Stack>
                );
              })}
              {selectedCustomizedCategories.map((categoryId) => {
                const category = customizedCategories.find((category) => category.id === categoryId);
                if (!category) return null;
                return (
                  <Stack
                    className={classNames(classes.category, category.disabled && classes.disabled)}
                    key={category.id}
                    direction='row'
                  >
                    {renderCategory(category)}
                    <CloseOutlined
                      className={classes.btnDelete}
                      onClick={() => deleteCustomizedCategory(category.id)}
                    />
                  </Stack>
                );
              })}
            </>
          ) : (
            <Stack className={classes.category} direction='row'>
              <Typography variant='label1'>All Wallet Addresses</Typography>
            </Stack>
          )}
        </Stack>
        <Stack spacing={2} direction='row' justifyContent='center'>
          <Button variant='outlined' onClick={resetCategory}>
            Reset
          </Button>
          <Button
            id='btn-create-cohort-category-next'
            variant='contained'
            onClick={() => onNext?.(selectedGrowing3Categories, selectedCustomizedCategories)}
          >
            Next
          </Button>
        </Stack>
        <Stack className={classes.pool} spacing={2}>
          <Typography variant='h5' color={colorPrimary10}>
            Category Pool
          </Typography>
          <Typography variant='h6' color={colorPrimary10}>
            Growing3 Category
          </Typography>
          <Stack direction='row' flexWrap='wrap' alignItems='center' spacing={1}>
            {(growing3Categories || [])
              .filter(
                (category) =>
                  !category.project_types.length ||
                  category.project_types.findIndex((project_type) => project_type === cohort.project_type) > -1,
              )
              .filter((category) => category.status === JobStatus.COMPLETED && category.disabled === false)
              .map((category, idx) => (
                <Stack
                  id={idx === 0 ? 'btn-create-cohort-growing3-category' : undefined}
                  className={classNames(
                    classes.category,
                    classes.clickable,
                    selectedGrowing3Categories.findIndex((c) => c === category.id) > -1 && classes.selected,
                  )}
                  key={category.id}
                  onClick={() => onGrowing3CategoryClicked(category.id)}
                >
                  {renderCategory(category)}
                </Stack>
              ))}
            <Button variant='outlined' onClick={() => setGrowing3DialogOpened(true)}>
              View More
            </Button>
          </Stack>
          <Typography variant='h6' color={colorPrimary10}>
            Customized Category
          </Typography>
          <Stack direction='row' flexWrap='wrap' alignItems='center' spacing={1}>
            {(customizedCategories || [])
              .filter((category) => category.status === JobStatus.COMPLETED && category.disabled === false)
              .map((category) => (
                <Stack
                  className={classNames(
                    classes.category,
                    classes.clickable,
                    selectedCustomizedCategories.findIndex((c) => c === category.id) > -1 && classes.selected,
                  )}
                  key={category.id}
                  onClick={() => onCustomizedCategoryClicked(category.id)}
                >
                  {renderCategory(category)}
                </Stack>
              ))}
            <Button
              id='btn-create-cohort-customized-category-view-more'
              variant='outlined'
              onClick={() => setCustomizedDialogOpened(true)}
            >
              View More
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {growing3DialogOpened ? (
        <CategoriesSelectDialog
          open
          icon={<Growing3Icon fontSize='large' />}
          cohort={cohort}
          categoryIds={selectedGrowing3Categories}
          cateogories={growing3Categories}
          title="Growing3's Categories"
          onCancel={() => setGrowing3DialogOpened(false)}
          onSave={(categoryIds: number[]) => {
            setGrowing3DialogOpened(false);
            setSelectedGrowing3Categories(categoryIds);
          }}
        />
      ) : null}
      {customizedDialogOpened ? (
        <CategoriesSelectDialog
          open
          icon={<TagOutlined fontSize='large' />}
          cohort={cohort}
          categoryIds={selectedCustomizedCategories}
          cateogories={customizedCategories}
          title="Customized's Categories"
          subtitle='Recruit your own categories in this cohort'
          onAdd={() => {
            window.open(`${Uris.Pages.User.FileManagement}?tab=${FileManagementTab.Category}`, '_blank');
          }}
          onCancel={() => setCustomizedDialogOpened(false)}
          onSave={(categoryIds: number[]) => {
            setCustomizedDialogOpened(false);
            setSelectedCustomizedCategories(categoryIds);
          }}
        />
      ) : null}
    </Drawer>
  );
};
