import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { InfluencerMessage, InfluencerThread, InfluencerThreadStatus } from 'common/types/Extension/InfluencerCampaign';

import { AdsAudienceIcon } from 'components/icons/AdsAudienceIcon';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { SearchOutlined } from '@mui/icons-material';
import { TwitterUserInfo } from 'components/Twitter/TwitterUserInfo';
import classNames from 'classnames';
import classes from './SelectThreadDialog.module.scss';
import { numberToAbbreviation } from 'common/utils';

interface SelectThreadDialogProps {
  threads: InfluencerThread[];
  message: InfluencerMessage;
  onAdd?: (threads: InfluencerThread[]) => void;
  onCancel?: () => void;
}

enum ThreadFilter {
  NANO = 'nano_influencer',
  MICRO = 'micro_influencer',
  MACRO = 'macro_influencer',
  MEGA = 'mega_influencer',
  CELEBRITY = 'celebrity',
}

const ThreadFilterMap = new Map<ThreadFilter, { label: string; description: string; min?: number; max?: number }>([
  [ThreadFilter.NANO, { label: 'Nano Influencer', description: '< 10K', max: 10000 }],
  [ThreadFilter.MICRO, { label: 'Micro Influencer', description: '10K ~ 100K', min: 10000, max: 100000 }],
  [ThreadFilter.MACRO, { label: 'Macro Influencer', description: '100K ~ 500K', min: 100000, max: 500000 }],
  [ThreadFilter.MEGA, { label: 'Mega Influencer', description: '500K ~ 1M', min: 500000, max: 1000000 }],
  [ThreadFilter.CELEBRITY, { label: 'Celebrity', description: '1 M+', min: 1000000 }],
]);

export const SelectThreadDialog: FC<SelectThreadDialogProps> = ({ threads, message, onAdd, onCancel }) => {
  const [selectedThreads, setSelectedThreads] = useState<InfluencerThread[]>(
    threads.filter((thread) => thread.influencer_message_id === message.id),
  );
  const [searchString, setSearchString] = useState<string>('');
  const [threadFilter, setThreadFilter] = useState<ThreadFilter | undefined>(undefined);

  const isThreadSelected = useCallback(
    (thread: InfluencerThread) => {
      return selectedThreads.findIndex((selectedThread) => selectedThread.id === thread.id) > -1;
    },
    [selectedThreads],
  );

  const isThreadDisabled = useCallback((thread: InfluencerThread, message: InfluencerMessage) => {
    // if thread has link to a message id and is not equal to input message -> disabled
    return !!thread.influencer_message_id && thread.influencer_message_id !== message.id;
  }, []);

  const filteredThreads = useMemo(() => {
    return threads
      .filter((thread) => {
        if (!threadFilter) return true;
        const filter = ThreadFilterMap.get(threadFilter);
        if (!filter) return true;
        if (typeof thread.receiver?.follower_count !== 'number') return false;
        if (typeof filter.max === 'number' && thread.receiver.follower_count >= filter.max) return false;
        if (typeof filter.min === 'number' && thread.receiver.follower_count < filter.min) return false;
        return true;
      })
      .filter((thread) => {
        if (!searchString) return true;
        return (
          thread.receiver?.display_name?.toLowerCase().includes(searchString.toLowerCase()) ||
          thread.receiver?.username?.toLowerCase().includes(searchString.toLowerCase())
        );
      });
  }, [threads, searchString, threadFilter]);

  const noneDisabledThreads = useMemo(() => {
    return filteredThreads.filter((thread) => !isThreadDisabled(thread, message));
  }, [message, filteredThreads, isThreadDisabled]);

  const isAllSelected = useMemo(() => {
    return (
      !!noneDisabledThreads.length &&
      noneDisabledThreads.every(
        (thread) => selectedThreads.findIndex((selectedThread) => selectedThread.id === thread.id) > -1,
      )
    );
  }, [noneDisabledThreads, selectedThreads]);

  const isPartialSelected = useMemo(() => {
    return (
      !isAllSelected &&
      noneDisabledThreads.some(
        (thread) => selectedThreads.findIndex((selectedThread) => selectedThread.id === thread.id) > -1,
      )
    );
  }, [isAllSelected, noneDisabledThreads, selectedThreads]);

  return (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={3} alignItems='center' className={classes.dialog}>
          <CommonChip>
            <AdsAudienceIcon fontSize='large' />
          </CommonChip>
          <Typography variant='h6' className={classes.title}>
            Select influencers
          </Typography>
          <FormControl className={classes.input}>
            <InputLabel>Influencer Type</InputLabel>
            <Select
              label='Influencer Type'
              value={threadFilter || ''}
              onChange={(e) => {
                setThreadFilter(e.target.value as ThreadFilter);
              }}
            >
              {Array.from(ThreadFilterMap.entries()).map((entry) => (
                <MenuItem key={entry[0]} value={entry[0]}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    className={classes.menuItem}
                  >
                    <Typography className={classes.mainText}>{entry[1].label}</Typography>
                    <Typography variant='label2' className={classes.subText}>
                      {entry[1].description}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.input}>
            <InputLabel>Search Influencers</InputLabel>
            <OutlinedInput
              startAdornment={<SearchOutlined />}
              size='medium'
              label='Search Influencers'
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </FormControl>
          <Stack className={classes.searchResults} spacing={2}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={isPartialSelected}
                  disabled={!noneDisabledThreads.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedThreads((old) => [
                        ...old,
                        ...filteredThreads
                          .filter((thread) => !isThreadDisabled(thread, message))
                          .filter((thread) => old.every((t) => t.id !== thread.id)),
                      ]);
                    } else {
                      setSelectedThreads((old) => [
                        ...old.filter((t) => filteredThreads.every((thread) => thread.id !== t.id)),
                      ]);
                    }
                  }}
                />
                <Typography>Select All</Typography>
              </Stack>
              <Typography>Followers</Typography>
            </Stack>
            {filteredThreads
              .filter((thread) => thread.receiver)
              .map((thread) => {
                const selected = isThreadSelected(thread);
                const disabled = isThreadDisabled(thread, message);
                return (
                  <Stack
                    key={thread.id}
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    className={classNames(classes.user, disabled && classes.disabled)}
                    onClick={() => {
                      if (disabled) return;
                      if (!selected) setSelectedThreads((old) => [...old, thread]);
                      else setSelectedThreads((old) => [...old.filter((t) => t.id !== thread.id)]);
                    }}
                  >
                    <Stack direction='row' spacing={1}>
                      <Checkbox checked={selected} disabled={disabled} />
                      <TwitterUserInfo user={thread.receiver!} />
                    </Stack>
                    <Typography>{numberToAbbreviation(thread.receiver?.follower_count || 0)}</Typography>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>
      }
      footer={
        <Stack direction='row' justifyContent='space-between' alignItems='center' className={classes.actions}>
          <Typography>
            {selectedThreads.length}/{threads.length} selected
          </Typography>
          <Stack direction='row' spacing={1}>
            <Button
              variant='outlined'
              onClick={() => {
                onCancel?.();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                onAdd?.(
                  threads.map((thread) => {
                    const disabled = isThreadDisabled(thread, message);
                    if (disabled) return { ...thread };
                    const selected = isThreadSelected(thread);
                    return {
                      ...thread,
                      influencer_message_id: selected ? message.id : undefined,
                      status: selected ? InfluencerThreadStatus.SENDING : InfluencerThreadStatus.INIT,
                    };
                  }),
                );
              }}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  );
};
