import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, ScheduleSendOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { InfluencerCampaign, calculateEstimateTime } from 'common/types/Extension/InfluencerCampaign';
import { colorNeutralVariant20, colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import classes from './TimeEstimateDialog.module.scss';
import { minutesToString } from 'common/utils';

interface TimeEstimateDialogProps extends DialogProps {
  campaign: InfluencerCampaign;
  onBack?: () => void;
}

export const TimeEstimateDialog: FC<TimeEstimateDialogProps> = ({ open, campaign, onBack, ...props }) => {
  const onBtnBackClicked = useCallback(() => {
    onBack?.();
  }, [onBack]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnBackClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnBackClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack id='area-create-campaign-view-status' alignItems='center' spacing={2}>
            <CommonChip>
              <ScheduleSendOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Your Message is on Its Way
              </Typography>
              <Typography variant='body1' textAlign='center' color={colorNeutralVariant20}>
                It may take up to {minutesToString(calculateEstimateTime(campaign))} for the system to process sending.
                Please refresh the window to update the data.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='contained' onClick={onBtnBackClicked}>
            Back to list
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
