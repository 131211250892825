export enum IntegrationAuthProvider {
  GA = 'ga',
  TWITTER_ADS = 'twitter_ads',
  GOOGLE_DRIVE = 'google_drive',
}

export interface IntegrationAuth {
  id: string;
  name: string;
  login_id: string;
  provider: IntegrationAuthProvider;
  protocal: string;
  user_id: string;
  created_at?: number;
  updated_at?: number;
}
