import { useCallback, useContext, useEffect, useState } from 'react';

import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { MetricsRange } from 'common/types/Extension/TwitterUserSearchResult';
import { useMessage } from 'components/message/useMessage';

export const useMetricsRange = () => {
  const { showMessage } = useMessage();
  const { metricsRange$ } = useContext(ExtensionContext);
  const [metricsRange, setMetricsRange] = useState<MetricsRange | undefined>(metricsRange$.getValue());
  const [loading, setLoading] = useState<boolean>(true);

  const fetchMetricsRange = useCallback(async () => {
    setLoading(true);
    await ExtensionAPI.getTwitterUserMetricsRange()
      .then(({ data }) => {
        metricsRange$.next(data);
        setMetricsRange(data);
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, metricsRange$]);

  useEffect(() => {
    if (metricsRange) {
      setLoading(false);
      return;
    }
    fetchMetricsRange();
  }, [metricsRange, fetchMetricsRange]);

  return { loading, value: metricsRange };
};
