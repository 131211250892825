import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import { InfluencerThread } from 'common/types/Extension/InfluencerCampaign';
import classes from './RemoveThreadsDialog.module.scss';

interface RemoveThreadsDialogProps extends DialogProps {
  threads: InfluencerThread[];
  onRemove?: () => void;
  onCancel?: () => void;
}

export const RemoveThreadsDialog: FC<RemoveThreadsDialogProps> = ({ open, threads, onRemove, onCancel, ...props }) => {
  const onBtnRemoveClicked = useCallback(() => {
    onRemove?.();
  }, [onRemove]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <DeleteOutlineOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1} className={classes.detail}>
              <Typography variant='h6' textAlign='center'>
                Remove influencers
              </Typography>
              <Typography variant='body1' textAlign='center' className={classes.text}>
                <span style={{ color: colorPrimary40 }}>
                  {threads
                    .slice(0, 3)
                    .map((thread) => thread.receiver?.username)
                    .join(', ') + (threads.length > 3 ? `... and ${threads.length - 3} more ` : ' ')}
                </span>
                will be removed from the list.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onBtnRemoveClicked}>
            Remove
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
