import { BAR_ANGLE_DEG, HALF_DIMENSION, TICK_COLOR, TICK_COUNT, TICK_LENGTH } from 'components/Guage/constants';

export const Ticks = () => {
  const ticks = [];
  const end = (TICK_COUNT - 1) / 2;
  const start = -end;
  const tickUnit = BAR_ANGLE_DEG / (TICK_COUNT - 1);

  for (let i = start; i <= end; i++) {
    ticks.push(
      <line
        key={i}
        x1={HALF_DIMENSION}
        x2={HALF_DIMENSION}
        y1='0'
        y2={TICK_LENGTH}
        strokeLinecap='round'
        transform={`rotate(${tickUnit * i} ${HALF_DIMENSION} ${HALF_DIMENSION})`}
      />,
    );
  }
  return <g stroke={TICK_COLOR}>{ticks}</g>;
};
