import {
  DefaultExpressionData,
  Expression,
  ExpressionOperation,
  ExpressionOperationNameMap,
} from 'common/types/Expression';
import { FC, useCallback, useMemo } from 'react';
import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { borderRadius, chipInputHeight } from 'common/params';

import { BetweenValueInput } from 'components/input/BetweenValueInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PercentageValueInput } from 'components/input/PercentageValueInput';
import { TargetValueInput } from 'components/input/TargetValueInput';

interface ExpressionSelectProps {
  data?: Expression;
  disabled?: boolean;
  onChange?: (data: Expression) => void;
}

const options = [
  {
    value: ExpressionOperation.GREATER,
    label: ExpressionOperationNameMap.get(ExpressionOperation.GREATER),
  },
  {
    value: ExpressionOperation.LESS,
    label: ExpressionOperationNameMap.get(ExpressionOperation.LESS),
  },
  {
    value: ExpressionOperation.BETWEEN,
    label: ExpressionOperationNameMap.get(ExpressionOperation.BETWEEN),
  },
  {
    value: ExpressionOperation.TOP_N,
    label: ExpressionOperationNameMap.get(ExpressionOperation.TOP_N),
  },
];

export const ExpressionSelect: FC<ExpressionSelectProps> = ({ data, disabled, onChange }) => {
  const onMethodChange = useCallback(
    (operation: ExpressionOperation) => {
      switch (operation) {
        case ExpressionOperation.GREATER:
          onChange?.({ operation, value_1: 0 });
          break;
        case ExpressionOperation.LESS:
          onChange?.({ operation, value_1: 5000 });
          break;
        case ExpressionOperation.BETWEEN:
          onChange?.({ operation, value_1: 10, value_2: 1000 });
          break;
        case ExpressionOperation.TOP_N:
          onChange?.({ operation, value_1: 10 });
          break;
      }
    },
    [onChange],
  );

  const valueComponents = useMemo(() => {
    switch (data?.operation) {
      case ExpressionOperation.GREATER:
      case ExpressionOperation.LESS:
        return (
          <TargetValueInput
            target={data.value_1.toString()}
            disabled={disabled}
            onChange={(value_1) => {
              const t = parseInt(value_1);
              if (Number.isNaN(t)) return;
              onChange?.({ ...data, value_1: t });
            }}
          />
        );
      case ExpressionOperation.BETWEEN:
        return (
          <BetweenValueInput
            lower={data.value_1.toString()}
            upper={data.value_2.toString()}
            disabled={disabled}
            onChange={(value_1, value_2) => {
              const l = parseInt(value_1);
              const u = parseInt(value_2);
              if (Number.isNaN(l) || Number.isNaN(u)) return;
              onChange?.({ ...data, value_1: l, value_2: u });
            }}
          />
        );
      case ExpressionOperation.TOP_N:
        return (
          <PercentageValueInput
            percentage={data.value_1.toString()}
            disabled={disabled}
            onChange={(value_1) => {
              const p = parseInt(value_1);
              if (Number.isNaN(p)) return;
              onChange?.({ ...data, value_1: p });
            }}
          />
        );
    }
  }, [data, disabled, onChange]);

  return (
    <Stack spacing={1}>
      <Select
        value={(data || DefaultExpressionData).operation}
        onChange={(e) => onMethodChange(e.target.value as ExpressionOperation)}
        IconComponent={KeyboardArrowDownIcon}
        disabled={disabled}
        sx={{
          width: '160px',
          height: chipInputHeight,
          borderRadius: borderRadius,
        }}
        renderValue={(v) => {
          return <Typography variant='label1'>{ExpressionOperationNameMap.get(v)}</Typography>;
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            <Typography variant='body1'>{label}</Typography>
          </MenuItem>
        ))}
      </Select>

      {valueComponents}
    </Stack>
  );
};
