export interface TermFrequency {
  text: string;
  value: number;
}

export const jsonToTermsFrequency = (json?: Record<string, number>) => {
  const arr: TermFrequency[] = [];
  if (typeof json === "undefined") return arr;
  for (const key in json) {
    arr.push({
      text: key,
      value: json[key],
    });
  }
  return arr;
};
