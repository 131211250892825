import { Collapse, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { OriginalTweetsRatioFilter, getValueFilterString } from 'common/types/Extension/InfluencerFilter';

import { InfoOutlined } from '@mui/icons-material';
import { MetricsRange } from 'common/types/Extension/TwitterUserSearchResult';
import { MinMaxValueInput } from 'components/input/MinMaxValueInput';
import classes from './OriginalTweetsRatioFilter.module.scss';
import { numberToPercentage } from 'common/utils';

interface OriginalTweetsRatioFilterCollapseProps {
  data?: OriginalTweetsRatioFilter;
  metricsRange?: MetricsRange;
  onChange?: (data?: OriginalTweetsRatioFilter) => void;
}

export const OriginalTweetsRatioFilterCollapse: FC<OriginalTweetsRatioFilterCollapseProps> = ({
  data,
  metricsRange,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack className={classes.root}>
      <Stack
        direction='row'
        className={classes.title}
        justifyContent='space-between'
        onClick={() => setOpen((old) => !old)}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <Typography>Original Tweets Ratio</Typography>
            <Tooltip title='original tweet counts / (original + retweet counts)' placement='top'>
              <InfoOutlined fontSize='small' />
            </Tooltip>
          </Stack>
          <Typography className={classes.value} variant='label1'>
            {getValueFilterString(data, numberToPercentage)}
          </Typography>
        </Stack>
        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Stack>
      <Collapse in={open} timeout='auto'>
        <Stack spacing={3}>
          <Divider className={classes.divider} />
          <Stack spacing={3} className={classes.input}>
            <MinMaxValueInput
              percentage
              roundLimit
              min={data?.min}
              max={data?.max}
              minLimit={metricsRange?.original_tweets_ratio_min}
              maxLimit={metricsRange?.original_tweets_ratio_max}
              onChange={(min, max) => {
                if (min === undefined && max === undefined) {
                  onChange?.(undefined);
                  return;
                }
                onChange?.({ ...data, min: min, max: max, top: undefined });
              }}
            />
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
