import {
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';

import { SearchOutlined } from '@mui/icons-material';
import { colorNeutralVariant30 } from 'common/params';

export interface AdGroup {
  adGroupId: string;
  adGroupName: string;
  campaignId: string;
  campaignName: string;
}

interface TwitterAdGroupSelectProps {
  adGroups: AdGroup[];
  selectedAdGroups: AdGroup[];
  onAdGroupsChanged?: (adGroups: AdGroup[]) => void;
}

export const TwitterAdGroupSelect: FC<TwitterAdGroupSelectProps> = ({
  adGroups,
  selectedAdGroups,
  onAdGroupsChanged,
}) => {
  const [searchStr, setSearchStr] = useState<string>('');

  const handleAdGroupsChange = useCallback(
    (value: string | string[]) => {
      const selectedAdGroups: AdGroup[] = [];
      if (typeof value === 'string') {
        value.split(',').forEach((value) => {
          const adGroup = adGroups.find((adGroup) => adGroup.adGroupId === value);
          adGroup && selectedAdGroups.push(adGroup);
        });
      } else {
        value.forEach((value) => {
          const adGroup = adGroups.find((adGroup) => adGroup.adGroupId === value);
          adGroup && selectedAdGroups.push(adGroup);
        });
      }
      onAdGroupsChanged?.(selectedAdGroups);
    },
    [adGroups, onAdGroupsChanged],
  );

  const filteredAdGroups = useMemo(() => {
    if (!searchStr) return adGroups;
    const str = searchStr.toUpperCase();
    return adGroups?.filter((adGroup) => {
      return (
        adGroup.adGroupName.toUpperCase().indexOf(str) > -1 ||
        (adGroup.campaignName && adGroup.campaignName.toUpperCase().indexOf(str) > -1)
      );
    });
  }, [searchStr, adGroups]);

  return (
    <FormControl>
      <InputLabel>Ad groups selected</InputLabel>
      <Select
        multiple
        value={selectedAdGroups.map((adGroup) => adGroup.adGroupId)}
        MenuProps={{
          disableAutoFocusItem: true,
          sx: {
            height: '50vh',
            width: '520px',
          },
        }}
        label='Ad groups selected'
        renderValue={(adGroupIds) => {
          const string = adGroupIds
            .map((adGroupId) => adGroups?.find((adGroup) => adGroup.adGroupId === adGroupId)?.adGroupName)
            .join(', ');
          return <Typography>{string}</Typography>;
        }}
        onChange={(e) => handleAdGroupsChange(e.target.value)}
      >
        <OutlinedInput
          startAdornment={
            <InputAdornment position='start'>
              <SearchOutlined />
            </InputAdornment>
          }
          placeholder='Search'
          sx={{ width: '100%', '& fieldset': { borderColor: 'transparent' } }}
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value.toUpperCase())}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Divider />
        {filteredAdGroups?.length ? (
          (filteredAdGroups || []).map((adGroup, idx) => (
            <MenuItem
              key={adGroup.adGroupId}
              divider={idx !== (filteredAdGroups || []).length - 1}
              value={adGroup.adGroupId}
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                spacing={2}
                style={{ width: '100%' }}
              >
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Checkbox
                    checked={selectedAdGroups.map((adGroup) => adGroup.adGroupId).indexOf(adGroup.adGroupId) > -1}
                  />
                  <Stack>
                    <Typography variant='body1'>{adGroup.adGroupName}</Typography>
                    <Typography variant='body2' color={colorNeutralVariant30}>
                      ID: {adGroup.adGroupId}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography variant='label2' color={colorNeutralVariant30}>
                  {adGroup.campaignName}
                </Typography>
              </Stack>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            <Typography variant='body1'>No Result</Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
