import { FC, useMemo } from 'react';

import { TermFrequency } from 'common/types/Extension/TermFrequency';
import { Text } from '@visx/text';
import { Wordcloud } from '@visx/wordcloud';
import classes from './TermCloud.module.scss';
import { scaleLog } from '@visx/scale';

interface TermCloudProps {
  termsFrequency: TermFrequency[];
}

interface Color {
  r: number;
  g: number;
  b: number;
}
const darkest: Color = { r: 13, g: 70, b: 161 }; // colorPrimary20
const lightest: Color = { r: 66, g: 165, b: 245 }; // colorPrimary70

const maxFontSize = 84;
const minFontSize = 12;

const maxFontWeight = 600;
const minFontWeight = 200;

const interpolateValue = (v1: number, maxV1: number, minV1: number, maxV2: number, minV2: number) => {
  return minV2 + ((v1 - minV1) / (maxV1 - minV1)) * (maxV2 - minV2);
};

export const TermCloud: FC<TermCloudProps> = ({ termsFrequency }) => {
  const filteredTermsFrequency = useMemo(() => {
    // we need to filter the value which is equal to zero
    // otherwise the calculated weight will be NaN in library
    return termsFrequency.filter((termFrequency) => termFrequency.value !== 0);
  }, [termsFrequency]);

  const fontScale = scaleLog({
    domain: [
      Math.min(...filteredTermsFrequency.map((w) => w.value)),
      Math.max(...filteredTermsFrequency.map((w) => w.value)),
    ],
    range: [minFontSize, maxFontSize],
  });
  const fontSizeSetter = (data: TermFrequency) => fontScale(data.value);

  const fontWeight = scaleLog({
    domain: [
      Math.min(...filteredTermsFrequency.map((w) => w.value)),
      Math.max(...filteredTermsFrequency.map((w) => w.value)),
    ],
    range: [minFontWeight, maxFontWeight],
  });
  const fontWeightSetter = (data: TermFrequency) => fontWeight(data.value);

  const fontColorGetter = (size: number) => {
    const r = Math.round(interpolateValue(size, maxFontSize, minFontSize, darkest.r, lightest.r));
    const g = Math.round(interpolateValue(size, maxFontSize, minFontSize, darkest.g, lightest.g));
    const b = Math.round(interpolateValue(size, maxFontSize, minFontSize, darkest.b, lightest.b));

    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <Wordcloud
      words={filteredTermsFrequency}
      width={580}
      height={270}
      fontSize={fontSizeSetter}
      fontWeight={fontWeightSetter}
      font='Poppins'
      padding={4}
      spiral='rectangular'
      rotate={0}
      random={() => 0.5}
    >
      {(cloudWords) =>
        cloudWords.map((w) => (
          <Text
            className={classes.text}
            key={w.text}
            fill={fontColorGetter(w.size || 0)}
            textAnchor={'middle'}
            transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
            fontSize={w.size}
            fontWeight={w.weight}
            fontFamily={w.font}
          >
            {w.text}
          </Text>
        ))
      }
    </Wordcloud>
  );
};
