import { Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { CommonChip } from 'components/chip/CommonChip';
import { HumanIcon } from 'components/icons/HumanIcon';
import { TwitterAdsAccount } from 'common/types/TwitterAds';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './SelectAccount.module.scss';

interface SelectAccountProps {
  accounts: TwitterAdsAccount[];
  onBtnBackClicked?: () => void;
  onBtnNextClicked?: (accountId: string) => void;
}

export const SelectAccount: FC<SelectAccountProps> = ({ accounts, onBtnBackClicked, onBtnNextClicked }) => {
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(
    accounts.length ? accounts[0].id : undefined,
  );

  const content = useMemo(() => {
    // has accounts -> show options for user to select
    if (accounts.length)
      return (
        <>
          <Stack spacing={3} className={classes.content}>
            <Stack className={classes.title} alignItems='center' spacing={2}>
              <CommonChip>
                <HumanIcon fontSize='large' />
              </CommonChip>
              <Stack spacing={1}>
                <Typography variant='h6'>Select an ads account</Typography>
                <Typography variant='body1'>This address is associated with the following categories.</Typography>
              </Stack>
            </Stack>
            <FormControl className={classes.items}>
              <RadioGroup value={selectedAccountId} onChange={(e) => setSelectedAccountId(e.target.value)}>
                {accounts.map((account, idx) => (
                  <Stack key={idx}>
                    {idx > 0 ? <Divider /> : null}
                    <FormControlLabel
                      labelPlacement='start'
                      value={account.id}
                      control={<Radio />}
                      sx={{
                        marginLeft: '0px',
                        '.MuiFormControlLabel-label': {
                          flex: '1 1',
                        },
                      }}
                      className={classNames(classes.item, selectedAccountId === account.id && classes.selected)}
                      label={
                        <Stack direction='row' alignItems='center' spacing={2} className={classes.option}>
                          <HumanIcon fontSize='large' />
                          <Stack>
                            <Typography textAlign='left' className={classes.text}>
                              {account.name.trim()}
                            </Typography>
                            <Typography textAlign='left' variant='label1'>
                              ID: {account.id}
                            </Typography>
                          </Stack>
                        </Stack>
                      }
                    />
                  </Stack>
                ))}
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack direction='row-reverse' spacing={1} className={classes.actions}>
            <Button
              variant='contained'
              disabled={!selectedAccountId}
              onClick={() => selectedAccountId && onBtnNextClicked?.(selectedAccountId)}
            >
              Next
            </Button>
            <Button variant='outlined' onClick={onBtnBackClicked}>
              Back
            </Button>
          </Stack>
        </>
      );

    // no account -> show warning message to inform user to create account first
    return (
      <>
        <Stack spacing={3} className={classes.content}>
          <Stack className={classes.title} alignItems='center' spacing={2}>
            <CommonChip>
              <HumanIcon fontSize='large' />
            </CommonChip>
            <Stack spacing={1} className={classes.info}>
              <Typography variant='h6'>No ad accounts found</Typography>
              <Typography variant='body1'>
                Before publishing your audience list to the ad group, make sure to:{' '}
              </Typography>
              <Typography variant='body1'>
                1. Create your Twitter Ads account
                <br />
                2. Set up the campaign
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row-reverse' spacing={1} className={classes.actions}>
          <Button variant='contained' onClick={() => window.open(Uris.External.TwitterAds, '_blank')}>
            Go to Twitter
          </Button>
          <Button variant='outlined' onClick={onBtnBackClicked}>
            Cancel
          </Button>
        </Stack>
      </>
    );
  }, [selectedAccountId, accounts, onBtnNextClicked, onBtnBackClicked]);

  return (
    <Stack className={classes.root} spacing={1}>
      {content}
    </Stack>
  );
};
