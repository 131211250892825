export interface TwitterAccount {
  user_id: string;
  username: string;
  display_name?: string;
  description?: string;
  tags?: string[];
  total_followers: number;
  account_created_at: number;
  percentile?: number;
  run_at: number;
}

export interface LevelCount {
  level: string;
  count: number;
}

export enum RecommendationLevel {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export const RecommendationMap = new Map<RecommendationLevel, number>([
  [RecommendationLevel.HIGH, 60],
  [RecommendationLevel.MEDIUM, 30],
  [RecommendationLevel.LOW, 0],
]);
