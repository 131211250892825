import { FC, HTMLAttributes, createContext, useState } from 'react';

import { BehaviorSubject } from 'rxjs';
import { DefiProject } from 'common/types/DefiProject';
import { FeatureLimit } from 'common/types/FeatureLimit';
import { User } from 'common/types/User';
import { WalletAsset } from 'common/types/WalletAsset';
import { cloneDeep } from 'lodash';
import { useLifecycles } from 'react-use';

export interface DataContextProps {
  user$: BehaviorSubject<User | undefined>;
  isBlock$: BehaviorSubject<boolean>;
  openSubscriptionDialog$: BehaviorSubject<boolean>;
  openCreditDialog$: BehaviorSubject<boolean>;
  activeWalletCount$: BehaviorSubject<number>;
  walletAssets$: BehaviorSubject<WalletAsset[]>;
  defiProjects$: BehaviorSubject<DefiProject[]>;
  featureLimits$: BehaviorSubject<FeatureLimit[] | undefined>;
}
export const DataContextDefault: DataContextProps = {
  user$: new BehaviorSubject<User | undefined>(undefined),
  isBlock$: new BehaviorSubject<boolean>(false),
  openSubscriptionDialog$: new BehaviorSubject<boolean>(false),
  openCreditDialog$: new BehaviorSubject<boolean>(false),
  activeWalletCount$: new BehaviorSubject<number>(0),
  walletAssets$: new BehaviorSubject<WalletAsset[]>([]),
  defiProjects$: new BehaviorSubject<DefiProject[]>([]),
  featureLimits$: new BehaviorSubject<FeatureLimit[] | undefined>(undefined),
};

export const DataContext = createContext<DataContextProps>(DataContextDefault);

const DataContextProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  /** State */
  const [context, setContext] = useState<DataContextProps>(DataContextDefault);

  /** Lifecycle */
  useLifecycles(
    () => setContext(cloneDeep(DataContextDefault)),
    () => {},
  );

  /** Render */
  return <DataContext.Provider value={context}>{children}</DataContext.Provider>;
};

export default DataContextProvider;
