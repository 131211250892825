import { Button, Stack, Tooltip, Typography } from '@mui/material';

import { CheckOutlined } from '@mui/icons-material';
import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import classNames from 'classnames';
import classes from './PublishAdGroupResult.module.scss';

export interface AdGroupPublishResult {
  accountId: string;
  adGroupId: string;
  adGroupName: string;
  campaignId: string;
  campaignName: string;
  url: string;
  error?: string;
}

interface PublishAdGroupResultProps {
  results: AdGroupPublishResult[];
  onBtnBackClicked?: () => void;
}

export const PublishAdGroupResult: FC<PublishAdGroupResultProps> = ({ results, onBtnBackClicked }) => {
  return (
    <Stack className={classes.root} spacing={1}>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <CheckOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            <Typography variant='h6'>
              Influencer list have been successfully published to {results.length} Ad groups
            </Typography>
            <Typography variant='body1'>
              Please note: It might take a couple of minutes to upload. You can check the details of your Twitter ad
              group by clicking the following link.
            </Typography>
          </Stack>
        </Stack>
        <Stack className={classes.result} direction='row'>
          {results.map((result) =>
            result.error ? (
              <Tooltip title={result.error} placement='top'>
                <Stack
                  key={result.adGroupId}
                  className={classNames(classes.link, !!result.error && classes.error)}
                  onClick={() => window.open(result.url, '_blank')}
                >
                  {result.adGroupName}
                </Stack>
              </Tooltip>
            ) : (
              <Stack
                key={result.adGroupId}
                className={classNames(classes.link, !!result.error && classes.error)}
                onClick={() => window.open(result.url, '_blank')}
              >
                {result.adGroupName}
              </Stack>
            ),
          )}
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
        <Button variant='contained' onClick={onBtnBackClicked}>
          Back to list
        </Button>
      </Stack>
    </Stack>
  );
};
