import { FC, useEffect, useState } from 'react';

import { CollectionViewDesktop } from 'pages/Public/CollectionViewDesktop';
import { CollectionViewMobile } from 'pages/Public/CollectionViewMobile';
import ExtensionAPI from 'common/ExtensionAPI';
import { NotFoundPage } from 'pages/Common/NotFoundPage';
import { Spinner } from 'components/common/Spinner';
import { mobileWidth } from 'common/params';
import { useAsync } from 'react-use';
import { useCollections } from 'common/hooks/Extension/useCollections';
import { useParams } from 'react-router-dom';

export const CollectionView: FC = () => {
  const { collectionPublicId } = useParams();
  const [mobileMode, setMobileMode] = useState<boolean>(window.innerWidth <= mobileWidth);

  useCollections(true);

  const { loading, value: collection } = useAsync(async () => {
    if (!collectionPublicId) return undefined;
    return (await ExtensionAPI.getPublicCollection(collectionPublicId)).data;
  }, []);

  useEffect(() => {
    const onResize = () => {
      setMobileMode(window.innerWidth <= mobileWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  if (loading) return <Spinner />;
  if (!collection) return <NotFoundPage />;

  return mobileMode ? (
    <CollectionViewMobile collection={collection} />
  ) : (
    <CollectionViewDesktop collection={collection} />
  );
};
