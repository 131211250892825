import { SvgIcon, SvgIconProps } from '@mui/material';

export const SendPlaneIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 24 24'>
    <g clipPath='url(#a)'>
      <path
        fill='currentColor'
        d='M20.2 3.5A1 1 0 0 1 21.4 5l-6 15.5a1 1 0 0 1-1.6.3l-3.4-3.2-1.2 1.2a1 1 0 0 1-1.7-.7v-3.3l-5.2-5A1 1 0 0 1 2.8 8l17.4-4.5Zm-2 4.3-8 6.6 4 3.8 4-10.4Zm-1.6-1.3L5 9.5l3.7 3.6 7.9-6.6Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </SvgIcon>
);
