import { Button, FormControl, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { ArrowForwardOutlined } from '@mui/icons-material';
import { InfluencerCampaign } from 'common/types/Extension/InfluencerCampaign';
import classes from './CampaignInfoEdit.module.scss';

interface CampaignInfoEditProps {
  campaign: InfluencerCampaign;
  onNextBtnClicked?: () => void;
  onCampaignInfoEdit?: (campaign: InfluencerCampaign) => void;
}

export const CampaignInfoEdit: FC<CampaignInfoEditProps> = ({ campaign, onNextBtnClicked, onCampaignInfoEdit }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>Campaign Information</Typography>
        <Button
          variant='contained'
          startIcon={<ArrowForwardOutlined />}
          onClick={() => {
            setClicked(true);
            if (!campaign.name) return;
            onNextBtnClicked?.();
          }}
        >
          Next
        </Button>
      </Stack>
      <FormControl>
        <InputLabel error={clicked && !campaign.name}>Campaign name</InputLabel>
        <OutlinedInput
          label='Campaign name'
          className={classes.input}
          error={clicked && !campaign.name}
          value={campaign.name}
          onChange={(e) => onCampaignInfoEdit?.({ ...campaign, name: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>Note</InputLabel>
        <OutlinedInput
          label='Note'
          className={classes.input}
          value={campaign.description || ''}
          onChange={(e) => onCampaignInfoEdit?.({ ...campaign, description: e.target.value })}
        />
      </FormControl>
    </Stack>
  );
};
