import { Stack, Tooltip, Typography } from '@mui/material';

import { FC } from 'react';
import { VerifiedIcon } from 'components/icons/VerifiedIcon';
import classNames from 'classnames';
import classes from './TwitterUserInfo.module.scss';

interface TwitterUser {
  username?: string;
  display_name?: string;
  profile_image_url?: string;
  verified?: boolean;
  verified_type?: string;
}

interface TwitterUserInfoProps {
  user: TwitterUser;
}

export const TwitterUserInfo: FC<TwitterUserInfoProps> = ({ user }) => {
  return (
    <Stack direction='row' className={classes.root} alignItems='center' spacing={1.5}>
      <img alt='img' src={user.profile_image_url} className={classes.img} />
      <Stack>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Tooltip title={user.display_name} placement='top' arrow>
            <Typography variant='subtitle2' className={classes.text}>
              {user.display_name}
            </Typography>
          </Tooltip>
          {user.verified ? (
            <VerifiedIcon
              fontSize='small'
              className={classNames(
                classes.verified,
                user.verified_type === 'Business' && classes.business,
                user.verified_type === 'Government' && classes.government,
              )}
            />
          ) : null}
        </Stack>
        <Tooltip title={`@${user.username}`} placement='top' arrow>
          <Typography variant='body2' className={classes.text}>
            @{user.username}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
