import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, PriorityHighOutlined } from '@mui/icons-material';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import { Uris } from 'Uris';
import classes from './BlockDialog.module.scss';

interface BlockDialogProps extends DialogProps {
  onDialogClose?: () => void;
}

export const BlockDialog: FC<BlockDialogProps> = ({ onDialogClose, ...props }) => {
  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onDialogClose?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onDialogClose}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <PriorityHighOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={2} className={classes.fields}>
            <Typography variant='h6' textAlign='center'>
              Unusual Activity Detected
            </Typography>
            <Typography textAlign='center'>
              Unusual activity detected. Your account is temporarily suspended from accessing data. If you believe this
              is a mistake, please contact us at info@growing3.ai or join our Telegram group.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
        <Button variant='contained' onClick={() => window.open('mailto: support@growing3.ai', '_blank')}>
          Contact us
        </Button>
        <Button variant='outlined' onClick={() => window.open(Uris.External.Telegram, '_blank')}>
          Telegram Group
        </Button>
      </Stack>
    </Dialog>
  );
};
