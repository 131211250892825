import { useCallback, useContext, useEffect, useState } from 'react';

import ClientAPI from 'common/ClientAPI';
import { DataContext } from 'contexts/DataContext';
import { FeatureLimit } from 'common/types/FeatureLimit';
import { useMessage } from 'components/message/useMessage';

export const useFeatureLimits = () => {
  const { showMessage } = useMessage();
  const { featureLimits$ } = useContext(DataContext);
  const [featureLimits, setFeatureLimits] = useState<FeatureLimit[] | undefined>(featureLimits$.getValue());
  const [loading, setLoading] = useState<boolean>(true);

  const fetchFeatureLimits = useCallback(async () => {
    setLoading(true);
    await ClientAPI.getFeatureLimits()
      .then(({ data }) => {
        featureLimits$.next(data);
        setFeatureLimits(data);
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, featureLimits$]);

  useEffect(() => {
    if (featureLimits) {
      setLoading(false);
      return;
    }
    fetchFeatureLimits();
  }, [featureLimits, fetchFeatureLimits]);

  return { loading, value: featureLimits };
};
