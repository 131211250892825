import { ArrowBackOutlined, ArrowForwardOutlined, CloseOutlined, FileUploadOutlined } from '@mui/icons-material';
import { Button, Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { AddBulkInfluencersDialog } from 'components/InfluencerCampaign/Edit/Dialog/AddBulkInfluencersDialog';
import { AddThreadFromListDialog } from 'components/InfluencerCampaign/Edit/Dialog/AddThreadFromListDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionDrawer } from 'pages/InfluencerMatcher/Drawer/ExtensionDrawer';
import { InfluencerThread } from 'common/types/Extension/InfluencerCampaign';
import { RemoveThreadsDialog } from 'components/InfluencerCampaign/Edit/Dialog/RemoveThreadsDialog';
import { TwitterUserInfo } from 'components/Twitter/TwitterUserInfo';
import { TwitterUserSentHistory } from 'common/types/Extension/TwitterUserSearchResult';
import classes from './CampaignThreadListEdit.module.scss';
import { datetimeFormatter } from 'common/formatters';
import { numberToAbbreviation } from 'common/utils';
import { useMessage } from 'components/message/useMessage';

interface CampaignThreadListEditProps {
  draft?: boolean;
  saving?: boolean;
  threads: InfluencerThread[];
  excludedThreads: InfluencerThread[];
  onNextBtnClicked?: () => void;
  onBackBtnClicked?: () => void;
  onSaveBtnClicked?: () => void;
  onCampaignThreadListEdit?: (threads: InfluencerThread[]) => void;
}

export const CampaignThreadListEdit: FC<CampaignThreadListEditProps> = ({
  draft,
  saving,
  threads,
  excludedThreads,
  onNextBtnClicked,
  onBackBtnClicked,
  onSaveBtnClicked,
  onCampaignThreadListEdit,
}) => {
  const { showMessage } = useMessage();
  const [selectedThreads, setSelectedThreads] = useState<InfluencerThread[]>([]);
  const [addFromListDialogOpened, setAddFromListDialogOpened] = useState<boolean>(false);
  const [addBulkInfluencersDialogOpened, setAddBulkInfluencersDialogOpened] = useState<boolean>(false);

  const [twitterUserId, setTwitterUserId] = useState<string | undefined>(undefined);
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);

  const [removeThreads, setRemoveThreads] = useState<InfluencerThread[]>([]);
  const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);

  const [showThreads, setShowThreads] = useState<InfluencerThread[]>([]);

  const [cachedTwitterUsers, setCachedTwitterUsers] = useState<TwitterUserSentHistory[]>([]);

  const searchTwitterUsers = useCallback(
    async (threads: InfluencerThread[]) => {
      await ExtensionAPI.searchTwitterUserSentHistory({ user_ids: threads.map((thread) => thread.receiver_id) })
        .then(({ status, data }) => {
          if (status === 'success') {
            if (!data?.length) return;
            setCachedTwitterUsers((old) => [...old, ...data]);
            return;
          }
          showMessage(`Search twitter users failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    },
    [showMessage],
  );

  useEffect(() => {
    const newThreads = threads.filter((thread) => cachedTwitterUsers.every((user) => user.id !== thread.receiver_id));
    if (!newThreads.length) return;

    // update cached
    searchTwitterUsers(newThreads);
  }, [threads, cachedTwitterUsers, searchTwitterUsers]);

  const isAllSelected = useMemo(() => {
    if (!showThreads.length) return false;
    return showThreads.every(
      (showThread) => selectedThreads.findIndex((selectedThread) => selectedThread.id === showThread.id) > -1,
    );
  }, [showThreads, selectedThreads]);

  const isPartialSelected = useMemo(() => {
    return (
      !isAllSelected &&
      showThreads.some(
        (showThread) => selectedThreads.findIndex((selectedThread) => selectedThread.id === showThread.id) > -1,
      )
    );
  }, [isAllSelected, showThreads, selectedThreads]);

  const columnDefs: CommonTableColumn<InfluencerThread>[] = [
    {
      id: 'receiver_id',
      label: '#',
      startAdornment: (
        <Checkbox
          checked={isAllSelected}
          indeterminate={isPartialSelected}
          disabled={!showThreads.length}
          onChange={(e) => {
            if (!e.target.checked) {
              // de-select current show threads
              setSelectedThreads((old) => [
                ...old.filter((thread) => showThreads.findIndex((showThread) => showThread.id === thread.id) === -1),
              ]);
            } else {
              // select all current show threads
              setSelectedThreads((old) => [
                ...old,
                ...showThreads.filter((showThread) => old.findIndex((thread) => thread.id === showThread.id) === -1),
              ]);
            }
          }}
        ></Checkbox>
      ),
      render: (value, _, data) => {
        return (
          <Checkbox
            checked={selectedThreads.findIndex((selectedThread) => selectedThread.receiver_id === value) > -1}
            onChange={(e) => {
              if (!data) return;
              if (e.target.checked) {
                setSelectedThreads((old) => {
                  return [...old, data];
                });
              } else {
                setSelectedThreads((old) => {
                  return [...old.filter((selectedThread) => selectedThread.receiver_id !== value)];
                });
              }
            }}
          />
        );
      },
    },
    {
      id: 'display_name',
      label: 'Name',
      sortable: true,
      valueGetter: (data) => {
        return data?.receiver?.display_name;
      },
      render: (_, __, data) => {
        if (!data?.receiver) return <></>;
        return <TwitterUserInfo user={data.receiver} />;
      },
    },
    {
      id: 'followers',
      label: 'Followers',
      sortable: true,
      valueGetter: (data) => {
        return cachedTwitterUsers.find((user) => user.id === data?.receiver_id)?.follower_count;
      },
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.text}>
            {typeof value === 'number' ? numberToAbbreviation(value) : value}
          </Typography>
        );
      },
    },
    {
      id: 'last_sent_times',
      label: 'Last sent time',
      sortable: true,
      valueGetter: (data) => {
        return cachedTwitterUsers.find((user) => user.id === data?.receiver_id)?.last_sent_times;
      },
      render: (value: number[]) => {
        return (
          <Typography variant='body1' className={classes.text}>
            {value?.length ? datetimeFormatter.format(value[0] * 1000) : 'No Sent History'}
          </Typography>
        );
      },
    },
  ];

  const tableHeader = useMemo(() => {
    return (
      <Stack direction='row' alignItems='center' className={classes.tableHeader} justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Typography>{selectedThreads.length} influencers selected</Typography>
          <Button variant='outlined' startIcon={<CloseOutlined />} onClick={() => setSelectedThreads([])}>
            Deselect All
          </Button>
        </Stack>
        <Stack direction='row'>
          <Button
            variant='contained'
            disabled={!selectedThreads.length}
            onClick={() => {
              setRemoveThreads(selectedThreads);
              setRemoveDialogOpened(true);
            }}
          >
            Remove
          </Button>
        </Stack>
      </Stack>
    );
  }, [selectedThreads]);

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>Add Influencers</Typography>
        <Stack direction='row' spacing={1}>
          {/* show save btn only in draft mode */}
          {draft ? (
            <Button variant='outlined' disabled={saving} onClick={onSaveBtnClicked}>
              Save and exit
            </Button>
          ) : null}
          {!draft && onBackBtnClicked ? (
            <Button variant='outlined' disabled={saving} startIcon={<ArrowBackOutlined />} onClick={onBackBtnClicked}>
              Back
            </Button>
          ) : null}
          <Tooltip title={saving ? 'Saving' : !threads.length ? 'Should add at least one user' : null}>
            <span>
              <Button
                variant='contained'
                disabled={saving || !threads.length}
                startIcon={<ArrowForwardOutlined />}
                onClick={onNextBtnClicked}
              >
                Next
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1}>
        <Button
          id='btn-campaign-add-threads-from-list'
          variant='outlined'
          startIcon={<FileUploadOutlined />}
          onClick={() => setAddFromListDialogOpened(true)}
        >
          Add from the lists
        </Button>
        <Button
          id='btn-campaign-add-threads-from-bulk'
          variant='outlined'
          startIcon={<FileUploadOutlined />}
          onClick={() => setAddBulkInfluencersDialogOpened(true)}
        >
          Add Bulk influencers
        </Button>
      </Stack>

      <CommonTable
        data={threads}
        totalDataCnt={threads.length}
        columns={columnDefs}
        classes={classes}
        header={tableHeader}
        onShowDataChange={(data) => setShowThreads(data)}
      />

      {addFromListDialogOpened ? (
        <AddThreadFromListDialog
          includedThreads={threads}
          excludedThreads={excludedThreads}
          onAdd={(threads) => {
            onCampaignThreadListEdit?.(threads);
            setAddFromListDialogOpened(false);
          }}
          onCancel={() => setAddFromListDialogOpened(false)}
        />
      ) : null}

      {addBulkInfluencersDialogOpened ? (
        <AddBulkInfluencersDialog
          includedThreads={threads}
          excludedThreads={excludedThreads}
          onAdd={(newThreads) => {
            const mergedThreads = [...threads, ...newThreads].filter(
              (v1, i, a) => a.findIndex((v2) => v2.receiver_id === v1.receiver_id) === i,
            );
            onCampaignThreadListEdit?.(mergedThreads);
            setAddBulkInfluencersDialogOpened(false);
          }}
          onCancel={() => setAddBulkInfluencersDialogOpened(false)}
        />
      ) : null}

      {drawerOpened ? (
        <ExtensionDrawer
          twitterUserId={twitterUserId}
          onClose={() => {
            setDrawerOpened(false);
            setTwitterUserId(undefined);
          }}
        />
      ) : null}

      {removeDialogOpened && removeThreads.length ? (
        <RemoveThreadsDialog
          open
          threads={removeThreads}
          onRemove={() => {
            onCampaignThreadListEdit?.(
              threads.filter((thread) =>
                removeThreads.every((removeThread) => thread.receiver_id !== removeThread.receiver_id),
              ),
            );
            setSelectedThreads((old) => [
              ...old.filter((selectedThread) =>
                removeThreads.every((removeThread) => selectedThread.receiver_id !== removeThread.receiver_id),
              ),
            ]);
            setRemoveThreads([]);
            setRemoveDialogOpened(false);
          }}
          onCancel={() => {
            setRemoveThreads([]);
            setRemoveDialogOpened(false);
          }}
        />
      ) : null}
    </Stack>
  );
};
