import { CohortWalletActiveDaysChart } from 'components/AddressMetrics/CohortWalletActiveDaysChart';
import { CohortWalletBalanceChart } from 'components/AddressMetrics/CohortWalletBalanceChart';
import { CohortWalletRegisterDaysChart } from 'components/AddressMetrics/CohortWalletRegisterDaysChart';
import { CohortWalletTransactionChart } from 'components/AddressMetrics/CohortWalletTransactionChart';
import { FC } from 'react';
import { Stack } from '@mui/material';
import classes from './CohortInsight.module.scss';

interface CohortInsightProps {
  cohortId: number;
}

export const CohortInsight: FC<CohortInsightProps> = ({ cohortId }) => {
  return (
    <Stack className={classes.root} spacing={2} alignItems='center'>
      <Stack direction='row' spacing={2} alignItems='center'>
        <CohortWalletBalanceChart cohortId={cohortId} />
        <CohortWalletRegisterDaysChart cohortId={cohortId} />
      </Stack>
      <Stack direction='row' spacing={2}>
        <CohortWalletActiveDaysChart cohortId={cohortId} />
        <CohortWalletTransactionChart cohortId={cohortId} />
      </Stack>
    </Stack>
  );
};
