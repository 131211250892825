import { SvgIcon, SvgIconProps } from '@mui/material';

export const HumanIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12 2.5a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm6.4 14.8c-1.5-1.7-5-2.3-6.4-2.3-1.5 0-5 .6-6.4 2.3a8 8 0 1 1 12.7 0ZM8.5 10c0-2 1.6-3.5 3.5-3.5 2 0 3.5 1.6 3.5 3.5 0 2-1.6 3.5-3.5 3.5-2 0-3.5-1.6-3.5-3.5Z'
      clipRule='evenodd'
    />
  </SvgIcon>
);
