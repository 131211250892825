import { Stack, Typography } from '@mui/material';

import { FC } from 'react';

export const FontDemo: FC = () => {
  return (
    <Stack>
      <Typography variant='h1'>H1 - Poppins 57/64 . 0</Typography>
      <Typography variant='h2'>H2 - Poppins 45/52 . 0 </Typography>
      <Typography variant='h3'>H3 - Poppins 36/44 . 0 </Typography>
      <Typography variant='h4'>H4 - Poppins 32/40 . 0 </Typography>
      <Typography variant='h5'>H5 - Poppins 28/36 . 0 </Typography>
      <Typography variant='h6'>H6 - Poppins 24/32 . 0</Typography>
      <Typography variant='subtitle1'>Subtitle 1 - Poppins Medium 16/24 . +0.15 </Typography>
      <Typography variant='subtitle2'>Subtitle 2 - Poppins Medium 14/20 . +0.1</Typography>
      <Typography variant='label1'>Label 1 - Roboto Medium 14/20 . +0.1 </Typography>
      <Typography variant='label2'>Label 2 - Roboto Medium 11/16 . +0.5</Typography>
      <Typography variant='body1'>Body 1 - Roboto 16/24 . +0.5</Typography>
      <Typography variant='body2'>Body 2 - Roboto 12/16 . +0.4</Typography>
    </Stack>
  );
};
