import { AssignmentOutlined, CloseOutlined, CloudUploadOutlined, UploadFileOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';
import { downloadAsCsv, fileSizeToString } from 'common/utils';

import { AxiosProgressEvent } from 'axios';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { CustomizedCategory } from 'common/types/Category';
import classes from './CustomizedCategoryUploadDialog.module.scss';
import { useMessage } from 'components/message/useMessage';

interface CustomizedCategoryUploadDialogProps extends DialogProps {
  onCreate?: (category: CustomizedCategory) => void;
  onCancel?: () => void;
}

export const CustomizedCategoryUploadDialog: FC<CustomizedCategoryUploadDialogProps> = ({
  open,
  onCreate,
  onCancel,
  ...props
}) => {
  const { showMessage } = useMessage();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [createBtnClicked, setCreateBtnClicked] = useState<boolean>(false);
  const [controller, setController] = useState<AbortController | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [progress, setProgress] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (open) {
      setController(new AbortController());
      return () => {
        setController(undefined);
      };
    }
  }, [open]);

  const categoryValid = useMemo(() => {
    return name && file;
  }, [name, file]);

  const handleSelectFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    setFile(e.target.files[0]);
  }, []);

  const handleProgress = useCallback((e: AxiosProgressEvent) => {
    console.log(e.progress);
    setProgress(e.progress);
  }, []);

  const createCategory = useCallback(async () => {
    if (!file) return;
    const formData = new FormData();
    formData.set('file', file);
    formData.set('name', name);
    if (description) formData.set('description', description);
    await ClientAPI.createCustomizeCategory(formData, controller, (e: AxiosProgressEvent) => handleProgress(e))
      .then(({ status, data, message }) => {
        if (status === 'success' && data) {
          onCreate?.(data);
          return;
        }
        showMessage(`Create customized category failed, ${message}`);
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      });
  }, [file, name, description, controller, handleProgress, showMessage, onCreate]);

  const downloadTemplate = useCallback(() => {
    downloadAsCsv([{ address: '0x0000000000000000000000000000000000000001' }], `customized_categories_template.csv`);
  }, []);

  const onBtnCreateClicked = useCallback(() => {
    setCreateBtnClicked(true);
    if (!categoryValid) return;
    createCategory();
  }, [categoryValid, createCategory]);

  const onBtnCancelClicked = useCallback(() => {
    controller?.abort();
    onCancel?.();
  }, [controller, onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <UploadFileOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Upload a CSV file
              </Typography>
              <Stack direction='row' alignItems='center'>
                <Typography className={classes.description} variant='body1'>
                  Please make sure that each line contains only one address, without any punctuation marks in between.
                </Typography>
                <Button className={classes.template} startIcon={<AssignmentOutlined />} onClick={downloadTemplate}>
                  Template
                </Button>
              </Stack>
            </Stack>
            {file ? (
              <Stack className={classes.upload}>
                <Stack direction='row' justifyContent='space-between' className={classes.text}>
                  <Typography>{file.name}</Typography>
                  <Typography>{fileSizeToString(file.size)}</Typography>
                </Stack>
                {typeof progress === 'number' ? (
                  <div className={classes.progress} style={{ width: `${progress * 100}%` }} />
                ) : null}
              </Stack>
            ) : (
              <Stack spacing={1}>
                <Button component='label' startIcon={<CloudUploadOutlined />}>
                  Upload File
                  <input type='file' hidden accept='text/csv' onChange={handleSelectFile} />
                </Button>
                {createBtnClicked && !file ? (
                  <Typography color='error'>At least one file must be uploaded</Typography>
                ) : null}
              </Stack>
            )}
          </Stack>
          <Stack spacing={2}>
            <FormControl>
              <TextField
                label='Category Name'
                error={createBtnClicked && !name}
                sx={{ borderColor: 'red' }}
                value={name}
                inputProps={{ maxLength: 40 }}
                onChange={(e) => setName(e.target.value)}
                helperText="This is the name you'll see in the notifications, so use a descriptive name."
              ></TextField>
            </FormControl>
            <FormControl>
              <TextField
                label='Note'
                value={description}
                inputProps={{ maxLength: 250 }}
                onChange={(e) => setDescription(e.target.value)}
                helperText='Please make a value less than 250 characters.'
              ></TextField>
            </FormControl>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onBtnCreateClicked}>
            Create
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
