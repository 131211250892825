import { ContextProvider } from 'contexts/ContextProvider';
import { MainPage } from 'pages/MainPage';
import { MessageProvider } from 'components/message/MessageProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { TrackingProvider } from 'components/tracking/TrackingProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MessageProvider>
      <ContextProvider>
        <TrackingProvider>
          <MainPage />
        </TrackingProvider>
      </ContextProvider>
    </MessageProvider>
  </React.StrictMode>,
);
