import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { CloseOutlined, ManageAccountsOutlined } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { Scheduler, SchedulerNameMap } from 'common/types/Scheduler';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { Cohort } from 'common/types/Cohort';
import { CommonChip } from 'components/chip/CommonChip';
import classes from './CohortInfoEditDialog.module.scss';

interface CohortInfoEditDialogProps extends DialogProps {
  cohort: Cohort;
  onSave?: (cohort: Cohort) => void;
  onCancel?: () => void;
}

export const CohortInfoEditDialog: FC<CohortInfoEditDialogProps> = ({ cohort, onSave, onCancel, ...props }) => {
  const [editCohort, setEditCohort] = useState<Cohort>(cohort);
  const [saveBtnClicked, setSaveBtnClicked] = useState<boolean>(false);

  const cohortValid = useMemo(() => {
    return editCohort.name;
  }, [editCohort]);

  const onSaveBtnClicked = useCallback(() => {
    setSaveBtnClicked(true);
    if (!cohortValid) return;
    onSave?.(editCohort);
  }, [cohortValid, editCohort, onSave]);

  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onCancel?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onCancel}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <ManageAccountsOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={2}>
              <FormControl>
                <TextField
                  label='Cohort Name'
                  error={saveBtnClicked && !editCohort.name}
                  sx={{ borderColor: 'red' }}
                  value={editCohort.name}
                  inputProps={{ maxLength: 40 }}
                  onChange={(e) => setEditCohort((old) => ({ ...old, name: e.target.value }))}
                  helperText="This is the name you'll see in the notifications, so use a descriptive name."
                ></TextField>
              </FormControl>
              <FormControl>
                <TextField
                  label='Note'
                  value={editCohort.description}
                  inputProps={{ maxLength: 250 }}
                  onChange={(e) => setEditCohort((old) => ({ ...old, description: e.target.value }))}
                  helperText='Please make a value less than 250 characters.'
                ></TextField>
              </FormControl>
              <FormControl>
                <InputLabel>Update Frequency</InputLabel>
                <Select
                  label='Update Frequency'
                  disabled // FIXME: remove this line when schedular is ready=
                  value={editCohort.scheduler}
                  onChange={(e) =>
                    e.target.value && setEditCohort((old) => ({ ...old, scheduler: e.target.value as Scheduler }))
                  }
                >
                  <MenuItem value={Scheduler.ONCE}>{SchedulerNameMap.get(Scheduler.ONCE)}</MenuItem>
                  <MenuItem value={Scheduler.DAILY}>{SchedulerNameMap.get(Scheduler.DAILY)}</MenuItem>
                  <MenuItem value={Scheduler.WEEKLY}>{SchedulerNameMap.get(Scheduler.WEEKLY)}</MenuItem>
                </Select>
                <FormHelperText>
                  The result of this cohort will be regularly updated according to the frequency you schedule.
                </FormHelperText>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row-reverse' spacing={1} className={classes.actions}>
          <Button variant='contained' disabled={saveBtnClicked && !cohortValid} onClick={onSaveBtnClicked}>
            Save
          </Button>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
