import { FC, HTMLAttributes, createContext, useState } from 'react';
import {
  InfluencerCampaign,
  InfluencerCampaignStatus,
  InfluencerThreadStage,
  InfluencerThreadStatus,
} from 'common/types/Extension/InfluencerCampaign';
import { MetricsRange, TwitterUserSearchResult } from 'common/types/Extension/TwitterUserSearchResult';

import { BehaviorSubject } from 'rxjs';
import { Collection } from 'common/types/Extension/Collection';
import { FeatureLimit } from 'common/types/FeatureLimit';
import { User } from 'common/types/Extension/User';
import { cloneDeep } from 'lodash';
import { useLifecycles } from 'react-use';

export interface ExtensionContextProps {
  user$: BehaviorSubject<User | undefined>;
  nonProjectCategories$: BehaviorSubject<string[] | undefined>;
  projectCategories$: BehaviorSubject<string[] | undefined>;
  languages$: BehaviorSubject<string[] | undefined>;
  locations$: BehaviorSubject<string[] | undefined>;
  metricsRange$: BehaviorSubject<MetricsRange | undefined>;
  featureLimits$: BehaviorSubject<FeatureLimit[] | undefined>;
  collections$: BehaviorSubject<Collection[] | undefined>;
  refetchCollections$: BehaviorSubject<boolean>;
  searchedTwitterUsers$: BehaviorSubject<TwitterUserSearchResult[]>;

  selectedTab$: BehaviorSubject<string>;
  selectedCampaign$: BehaviorSubject<InfluencerCampaign | undefined>;
  selectedCampaignStatus$: BehaviorSubject<InfluencerCampaignStatus | undefined>;
  selectedThreadStage$: BehaviorSubject<InfluencerThreadStage | undefined>;
  selectedThreadStatus$: BehaviorSubject<InfluencerThreadStatus | undefined>;
}
export const ExtensionContextDefault: ExtensionContextProps = {
  user$: new BehaviorSubject<User | undefined>(undefined),
  nonProjectCategories$: new BehaviorSubject<string[] | undefined>(undefined),
  projectCategories$: new BehaviorSubject<string[] | undefined>(undefined),
  languages$: new BehaviorSubject<string[] | undefined>(undefined),
  locations$: new BehaviorSubject<string[] | undefined>(undefined),
  featureLimits$: new BehaviorSubject<FeatureLimit[] | undefined>(undefined),
  metricsRange$: new BehaviorSubject<MetricsRange | undefined>(undefined),
  collections$: new BehaviorSubject<Collection[] | undefined>(undefined),
  refetchCollections$: new BehaviorSubject<boolean>(true),
  searchedTwitterUsers$: new BehaviorSubject<TwitterUserSearchResult[]>([]),

  selectedTab$: new BehaviorSubject<string>(''),
  selectedCampaign$: new BehaviorSubject<InfluencerCampaign | undefined>(undefined),
  selectedCampaignStatus$: new BehaviorSubject<InfluencerCampaignStatus | undefined>(undefined),
  selectedThreadStage$: new BehaviorSubject<InfluencerThreadStage | undefined>(undefined),
  selectedThreadStatus$: new BehaviorSubject<InfluencerThreadStatus | undefined>(undefined),
};

export const ExtensionContext = createContext<ExtensionContextProps>(ExtensionContextDefault);

const ExtensionContextProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  /** State */
  const [context, setContext] = useState<ExtensionContextProps>(ExtensionContextDefault);

  /** Lifecycle */
  useLifecycles(
    () => setContext(cloneDeep(ExtensionContextDefault)),
    () => {},
  );

  /** Render */
  return <ExtensionContext.Provider value={context}>{children}</ExtensionContext.Provider>;
};

export default ExtensionContextProvider;
