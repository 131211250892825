import { DefaultPaginationOptions, PaginationOptions } from 'components/table/CommonTable';
import { FeatureLimit, FeatureUsage } from 'common/types/FeatureLimit';
import { InfluencerCampaign, InfluencerPostLink, TwitterCookie } from 'common/types/Extension/InfluencerCampaign';
import {
  MetricsRange,
  TwitterUserSearchResult,
  TwitterUserSentHistory,
} from 'common/types/Extension/TwitterUserSearchResult';
import { TwitterUser, TwitterUserCheck } from 'common/types/Extension/TwitterUser';

import { Collection } from 'common/types/Extension/Collection';
import { InfluencerSearchParams } from 'common/types/Extension/InfluencerFilter';
import { TwitterUserMetrics } from 'common/types/Extension/TwitterUserMetrics';
import { Uris } from 'Uris';
import { User } from 'common/types/User';
import { get } from 'lodash';
import pako from 'pako';

interface ApiResponse<T> {
  status?: string;
  data?: T;
  message?: string;
  total_rows?: number;
  return_rows?: number;
}

class ExtensionAPI {
  static async checkFetchResponse(res: Response): Promise<unknown | void> {
    let msg = '';
    try {
      const resJson = await res.json();
      const resJsonDetail = get(resJson, 'detail');
      if (resJsonDetail) msg = resJsonDetail;
      else {
        return resJson;
      }
    } catch (err) {
      if (err instanceof SyntaxError) {
        msg = 'Unexpected errors happened, please contact administrator.';
      }
    } finally {
      if (msg === '') {
        if (400 <= res.status && res.status < 500) {
          msg = `${res.status} Client Error: ${res.statusText} for url: ${res.url}`;
        } else if (500 <= res.status && res.status < 600) {
          // Server error
          msg = `${res.status} Server Error: ${res.statusText} for url: ${res.url}`;
        }
      }
    }

    if (msg !== '') {
      // send status code also for checking
      throw Error(msg, { cause: res.status });
    }
  }

  static extractResponse<T>(resJson: unknown): ApiResponse<T> {
    return {
      status: get(resJson, 'status', undefined),
      data: get(resJson, 'data', undefined),
      message: get(resJson, 'message', undefined),
      total_rows: get(resJson, 'total_rows', undefined),
      return_rows: get(resJson, 'return_rows', undefined),
    };
  }

  static async gzipFetch(input: RequestInfo | URL, init?: RequestInit | undefined) {
    let blob: Blob | undefined = undefined;
    if (init?.body) {
      const compressedData = pako.gzip(init?.body as unknown as string);
      blob = new Blob([compressedData], { type: 'application/gzip' });
    }
    return await fetch(input, {
      ...init,
      headers: { ...init?.headers, 'Content-Encoding': 'gzip', 'Content-Type': 'application/json' },
      body: blob,
    });
  }

  // return user profile
  public static async getUserProfile() {
    const url = `${Uris.ExtensionApi.User.Profile}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<User>(resJson);
  }

  public static async getTwitterUserCategories(isProject?: boolean) {
    const url = `${Uris.ExtensionApi.TwitterUser.Categories}${
      typeof isProject === 'boolean' ? (isProject ? '?is_project=true' : '?is_project=false') : ''
    }`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<string[]>(resJson);
  }

  public static async getTwitterUserMetricsRange() {
    const url = `${Uris.ExtensionApi.TwitterUser.MetricsRange}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<MetricsRange>(resJson);
  }

  public static async getTwitterUser(userId: string) {
    const url = `${Uris.ExtensionApi.TwitterUser.Info.replace(':userId', userId)}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterUser>(resJson);
  }

  public static async getTwitterUserMetrics(userId: string) {
    const url = `${Uris.ExtensionApi.TwitterUser.Profile.replace(':userId', userId)}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterUserMetrics>(resJson);
  }

  public static async searchTwitterUser(searchParams: InfluencerSearchParams, paginationOptions?: PaginationOptions) {
    const url = `${Uris.ExtensionApi.TwitterUser.Search}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...searchParams,
        page: (paginationOptions || DefaultPaginationOptions).page + 1,
        rows: (paginationOptions || DefaultPaginationOptions).rowsPerPage,
      }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterUserSearchResult[]>(resJson);
  }

  public static async searchTwitterUserSentHistory(searchParams: InfluencerSearchParams) {
    const url = `${Uris.ExtensionApi.TwitterUser.SentHistorySearch}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...searchParams,
      }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterUserSentHistory[]>(resJson);
  }

  public static async getCollection(collectionId: number) {
    const url = `${Uris.ExtensionApi.Collection.GetCollection.replace(':collectionId', collectionId.toString())}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async getCollections() {
    const url = `${Uris.ExtensionApi.Collection.GetCollections}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection[]>(resJson);
  }

  public static async createCollection(collection: Collection) {
    const url = `${Uris.ExtensionApi.Collection.CreateCollection}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(collection),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async updateCollection(collection: Collection) {
    if (typeof collection.id !== 'number') throw Error('update collection failed, collection id is require');
    const url = `${Uris.ExtensionApi.Collection.UpdateCollection.replace(':collectionId', collection.id.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(collection),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async deleteCollection(collectionId: number) {
    const url = `${Uris.ExtensionApi.Collection.DeleteCollection.replace(':collectionId', collectionId.toString())}`;
    const res = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async addUsersToCollection(collection: Collection, twitterUserIds: string[]) {
    if (typeof collection.id !== 'number') throw Error('add user to collection failed, collection id is required');
    const url = `${Uris.ExtensionApi.Collection.AddCollectionUsers.replace(':collectionId', collection.id.toString())}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        twitter_user_ids: twitterUserIds,
        collection_name: collection.id === 0 ? collection.name : undefined,
      }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async deleteUserFromCollection(collection: Collection, twitterUserId: string) {
    if (typeof collection.id !== 'number') throw Error('delete user from collection failed, collection id is required');
    const url = `${Uris.ExtensionApi.Collection.DeleteCollectionUser.replace(
      ':collectionId',
      collection.id.toString(),
    )}`;
    const res = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        twitter_user_id: twitterUserId,
      }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async getInfluencerCampaigns() {
    const url = `${Uris.ExtensionApi.InfluencerCampaign.GetInfluencerCampaigns}`;
    const res = await fetch(url, { method: 'GET', credentials: 'include' });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerCampaign[]>(resJson);
  }

  public static async getInfluencerCampaign(campaignId: number) {
    const url = `${Uris.ExtensionApi.InfluencerCampaign.GetInfluencerCampaign.replace(
      ':campaignId',
      campaignId.toString(),
    )}`;
    const res = await fetch(url, { method: 'GET', credentials: 'include' });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerCampaign>(resJson);
  }

  public static async createInfluencerCampaign(campaign: InfluencerCampaign) {
    const url = `${Uris.ExtensionApi.InfluencerCampaign.CreateInfluencerCampaign}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(campaign),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerCampaign>(resJson);
  }

  public static async updateInfluencerCampaignBasicInfo(campaign: InfluencerCampaign) {
    if (typeof campaign.id !== 'number') throw Error('update campaign failed, campaign id is require');
    const url = `${Uris.ExtensionApi.InfluencerCampaign.UpdateInfluencerCampaignBasicInfo.replace(
      ':campaignId',
      campaign.id.toString(),
    )}`;
    const res = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pinned: campaign.pinned }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerCampaign>(resJson);
  }

  public static async updateInfluencerCampaign(campaign: InfluencerCampaign) {
    if (typeof campaign.id !== 'number') throw Error('update campaign failed, campaign id is require');
    const url = `${Uris.ExtensionApi.InfluencerCampaign.UpdateInfluencerCampaign.replace(
      ':campaignId',
      campaign.id.toString(),
    )}`;
    const res = await ExtensionAPI.gzipFetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(campaign),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerCampaign>(resJson);
  }

  public static async archiveInfluencerCampaign(campaignId: number) {
    const url = `${Uris.ExtensionApi.InfluencerCampaign.DeleteInfluencerCampaign.replace(
      ':campaignId',
      campaignId.toString(),
    )}`;
    const res = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async getTwitterCookies() {
    const url = `${Uris.ExtensionApi.TwitterCookie.GetTwitterCookies}`;
    const res = await fetch(url, { method: 'GET', credentials: 'include' });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterCookie[]>(resJson);
  }

  public static async createTwitterCookie(cookieStr: string) {
    const url = `${Uris.ExtensionApi.TwitterCookie.CreateTwitterCookie}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cookie_str: cookieStr }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterCookie>(resJson);
  }

  public static async checkTwitterCookie(cookie: TwitterCookie) {
    const url = `${Uris.ExtensionApi.TwitterCookie.CheckTwitterCookie}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cookie_str: `auth_token=${cookie.auth_token};ct0=${cookie.ct0}` }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterCookie>(resJson);
  }

  public static async checkTwitterUserExists(usernames: string[]) {
    const url = `${Uris.ExtensionApi.TwitterUser.CheckExist}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ usernames }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<TwitterUserCheck[]>(resJson);
  }

  public static async getInfluencerCampaignPostLinks(campaignId: number) {
    const url = `${Uris.ExtensionApi.InfluencerCampaign.GetInfluencerPostLinks.replace(
      ':campaignId',
      campaignId.toString(),
    )}`;
    const res = await fetch(url, { method: 'GET', credentials: 'include' });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerPostLink[]>(resJson);
  }

  public static async createInfluencerCampaignPostLink(influencerPostLink: InfluencerPostLink) {
    const url = `${Uris.ExtensionApi.InfluencerCampaign.CreateInfluencerPostLink.replace(
      ':campaignId',
      influencerPostLink.influencer_campaign_id.toString(),
    )}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(influencerPostLink),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerPostLink>(resJson);
  }

  public static async updateInfluencerCampaignPostLink(influencerPostLink: InfluencerPostLink) {
    if (typeof influencerPostLink.id !== 'number') throw Error('update post link failed, post link id is require');
    const url = `${Uris.ExtensionApi.InfluencerCampaign.UpdateInfluencerPostLink.replace(
      ':campaignId',
      influencerPostLink.influencer_campaign_id.toString(),
    ).replace(':postLinkId', influencerPostLink.id.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(influencerPostLink),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerPostLink>(resJson);
  }

  public static async deleteInfluencerCampaignPostLink(influencerPostLink: InfluencerPostLink) {
    if (typeof influencerPostLink.id !== 'number') throw Error('delete post link failed, post link id is require');
    const url = `${Uris.ExtensionApi.InfluencerCampaign.UpdateInfluencerPostLink.replace(
      ':campaignId',
      influencerPostLink.influencer_campaign_id.toString(),
    ).replace(':postLinkId', influencerPostLink.id.toString())}`;
    const res = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(influencerPostLink),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<InfluencerPostLink>(resJson);
  }

  public static async getPublicCollection(collectionPublicId: string) {
    const url = `${Uris.ExtensionApi.Public.GetCollection.replace(':collectionPublicId', collectionPublicId)}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async shareCollection(collection: Collection) {
    if (typeof collection.id !== 'number') throw Error('share collection failed, collection id is require');
    const url = `${Uris.ExtensionApi.Collection.ShareCollection.replace(':collectionId', collection.id.toString())}`;
    const res = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(collection),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<Collection>(resJson);
  }

  public static async getTwitterUserLanguages() {
    const url = `${Uris.ExtensionApi.TwitterUser.Languages}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<string[]>(resJson);
  }

  public static async getTwitterUserLocations() {
    const url = `${Uris.ExtensionApi.TwitterUser.Locations}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<string[]>(resJson);
  }

  public static async getFeatureLimits() {
    const url = `${Uris.ExtensionApi.FeatureLimits.GetFeatureLimits}`;
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<FeatureLimit[]>(resJson);
  }

  public static async getUserFeatureUsage(
    featureName: string,
    subModule?: string,
    featureExtra?: Record<string, string>,
  ) {
    const url = `${Uris.Api.User.Feature.GetUsage}`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feature_name: featureName, sub_module: subModule, feature_extra: featureExtra }),
    });
    const resJson = await ExtensionAPI.checkFetchResponse(res);
    return ExtensionAPI.extractResponse<FeatureUsage>(resJson);
  }
}

export default ExtensionAPI;
