import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import {
  DefaultInfluencerCampaign,
  InfluencerCampaign,
  InfluencerCampaignStatus,
  InfluencerMessage,
  InfluencerThread,
} from 'common/types/Extension/InfluencerCampaign';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AccountsContext } from 'contexts/AccountsContext';
import { ArrowBackOutlined } from '@mui/icons-material';
import { CampaignCookieEdit } from 'components/InfluencerCampaign/Edit/CampaignCookieEdit';
import { CampaignInfoEdit } from 'components/InfluencerCampaign/Edit/CampaignInfoEdit';
import { CampaignLaunch } from 'components/InfluencerCampaign/Edit/CampaignLaunch';
import { CampaignMessageEdit } from 'components/InfluencerCampaign/Edit/CampaignMessageEdit';
import { CampaignThreadListEdit } from 'components/InfluencerCampaign/Edit/CampaignThreadListEdit';
import ExtensionAPI from 'common/ExtensionAPI';
import { Spinner } from 'components/common/Spinner';
import { TimeEstimateDialog } from 'components/InfluencerCampaign/Edit/Dialog/TimeEstimateDialog';
import { Uris } from 'Uris';
import classes from './EditCampaign.module.scss';
import { useAsync } from 'react-use';
import { useLeavePageConfirm } from 'common/hooks/useLeavePageConfirm';
import { useMessage } from 'components/message/useMessage';

const tabs: string[] = ['1. Basic Info', '2. Add Influencers', '3. Messages', '4. Connect Account', '5. Launch'];

export const EditCampaign: FC = () => {
  const { campaignId } = useParams();

  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { refetchUser$ } = useContext(AccountsContext);
  const [step, setStep] = useState<number>(0);
  const [saving, setSaving] = useState<boolean>(false);
  const [editCampaign, setEditCampaign] = useState<InfluencerCampaign>(DefaultInfluencerCampaign);
  const [excludedThreads, setExcludedThreads] = useState<InfluencerThread[]>([]);
  const [readonlyMessages, setReadonlyMessages] = useState<InfluencerMessage[]>([]);
  const [init, setInit] = useState<boolean>(false);

  const [estimateDialogOpened, setEstimateDialogOpened] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  /** Window close with edited content not saved */
  useLeavePageConfirm(!saveSuccess); // when success, disable leave page confirm

  useEffect(() => {
    if (!saveSuccess) return;
    setSaveSuccess(false);
    navigate(Uris.Pages.InfluencerMatcher.Index, { state: { tab: 'Campaign' } });
    refetchUser$.next(true);
  }, [saveSuccess, refetchUser$, navigate]);

  const { loading, value } = useAsync(async () => {
    const id = Number.parseInt(campaignId || '');
    if (Number.isNaN(id)) return undefined;
    return (await ExtensionAPI.getInfluencerCampaign(id)).data;
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!value) {
      navigate(Uris.Pages.InfluencerMatcher.Index, { state: { tab: 'Campaign' } });
      return;
    }
    if (value.status === InfluencerCampaignStatus.DRAFT) {
      // in draft mode -> no excluded threads
      setEditCampaign(value);
      setExcludedThreads([]);
      setReadonlyMessages([]);
      if (value.threads?.some((thread) => !!thread.integration_auth_id)) {
        // when any thread bind to twitter cookie -> jump to connect account
        setStep(3);
      } else if (value.messages?.length) {
        // when messages length larget than 0 -> jump to message edit
        setStep(2);
      } else if (value.threads?.length) {
        // when threads length larget than 0 -> jump to thread edit
        setStep(1);
      } else if (value.name) {
        // all criteria above not fulfill and name is filled -> jump to thread edit
        setStep(1);
      } else {
        setStep(0);
      }
    } else {
      // not in draft mode -> original threads are excluded
      setEditCampaign({ ...value, threads: [] });
      setExcludedThreads(value.threads || []);
      setReadonlyMessages(value.messages || []);
      setStep(1);
    }
    setInit(true);
  }, [loading, navigate, value]);

  const handleStepChange = useCallback((_: React.SyntheticEvent, newStep: number) => {
    setStep(newStep);
  }, []);

  const saveCampaign = useCallback(
    async (send?: boolean) => {
      setSaving(true);
      await ExtensionAPI.updateInfluencerCampaign({
        ...editCampaign,
        status: send ? InfluencerCampaignStatus.IN_PROGRESS : editCampaign.status,
      })
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            if (send) {
              setEstimateDialogOpened(true);
              return;
            }
            setSaveSuccess(true);
            return;
          }
          showMessage(`Save campaign failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [editCampaign, showMessage],
  );

  const content = useMemo(() => {
    const isDraft = editCampaign.status === InfluencerCampaignStatus.DRAFT;
    switch (step) {
      case 0:
        return (
          <CampaignInfoEdit
            campaign={editCampaign}
            onCampaignInfoEdit={(campaign) => setEditCampaign(campaign)}
            onNextBtnClicked={() => setStep((old) => old + 1)}
          />
        );
      case 1:
        return (
          <CampaignThreadListEdit
            draft={isDraft}
            threads={editCampaign.threads || []}
            excludedThreads={excludedThreads}
            onCampaignThreadListEdit={(threads) => setEditCampaign((old) => ({ ...old, threads: threads }))}
            onNextBtnClicked={() => setStep((old) => old + 1)}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onSaveBtnClicked={() => {
              // draft mode save -> do not send
              saveCampaign(!isDraft);
            }}
          />
        );
      case 2:
        // Edit campaign
        // 1. draft campaign edit -> free edit messages
        // 2. in_progress campaign add new influencers -> can not edit messages but link
        return (
          <CampaignMessageEdit
            draft={isDraft}
            readonlyMessages={readonlyMessages}
            saving={saving}
            threads={editCampaign.threads || []}
            messages={editCampaign.messages || []}
            onCampaignThreadEdit={(threads) => setEditCampaign((old) => ({ ...old, threads: threads }))}
            onCampaignMessageEdit={(messages) => setEditCampaign((old) => ({ ...old, messages: messages }))}
            onNextBtnClicked={() => setStep((old) => old + 1)}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onSaveBtnClicked={() => {
              // draft mode save -> do not send
              saveCampaign(!isDraft);
            }}
          />
        );
      case 3:
        return (
          <CampaignCookieEdit
            draft={isDraft}
            saving={saving}
            onCampaignCookieEdit={(cookie) =>
              setEditCampaign((old) => ({
                ...old,
                threads: old.threads?.map((thread) => ({
                  ...thread,
                  integration_auth_id: cookie.id,
                  sender_id: cookie.user_id,
                })),
              }))
            }
            onNextBtnClicked={() => setStep((old) => old + 1)}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onSaveBtnClicked={() => {
              // draft mode save -> do not send
              saveCampaign(!isDraft);
            }}
          />
        );
      case 4:
        return (
          <CampaignLaunch
            draft={isDraft}
            saving={saving}
            campaign={editCampaign}
            onSendBtnClicked={() => {
              saveCampaign(true);
            }}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onSaveBtnClicked={() => {
              saveCampaign();
            }}
          ></CampaignLaunch>
        );
    }
  }, [step, saving, excludedThreads, readonlyMessages, editCampaign, saveCampaign]);

  if (loading || !init) return <Spinner />;

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row'>
        <Button startIcon={<ArrowBackOutlined />} onClick={() => navigate(-1)}>
          Back to list
        </Button>
      </Stack>
      <Typography variant='h3'>{editCampaign.name}</Typography>
      <Tabs value={step} onChange={handleStepChange} centered className={classes.tabs}>
        {tabs.map((tab, idx) => (
          <Tab
            key={tab}
            className={classes.tab}
            style={{ width: `${100 / tabs.length}%` }}
            disabled={idx > step || (idx === 0 && editCampaign.status !== InfluencerCampaignStatus.DRAFT)}
            label={
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='subtitle2'>{tab}</Typography>
              </Stack>
            }
          />
        ))}
      </Tabs>
      {content}
      {estimateDialogOpened ? (
        <TimeEstimateDialog open campaign={editCampaign} onBack={() => setSaveSuccess(true)} />
      ) : null}
    </Stack>
  );
};
