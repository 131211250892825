import { FC, useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import classes from './TutorialHighlight.module.scss';

interface TutorialHightlightProps {
  component: Element;
}

export const TutorialHighlight: FC<TutorialHightlightProps> = ({ component }) => {
  const [rect, setRect] = useState<DOMRect | undefined>(undefined);

  useEffect(() => {
    if (!component) return;
    const calculateBoundingRect = () => {
      const rect = component.getBoundingClientRect();
      setRect(rect);
    };
    window.addEventListener('resize', calculateBoundingRect);
    calculateBoundingRect();
    return () => {
      window.removeEventListener('resize', calculateBoundingRect);
    };
  }, [component]);

  const componentStyle = useMemo(() => {
    if (!component) return undefined;
    return window.getComputedStyle(component);
  }, [component]);

  if (!rect || !componentStyle) return null;

  return (
    <Stack className={classes.root}>
      <Stack
        className={classes.center}
        style={{
          left: rect.left,
          top: rect.top,
          right: rect.right,
          bottom: rect.bottom,
          width: rect.width,
          height: rect.height,
        }}
      >
        <Stack className={classes.block} style={{ borderRadius: componentStyle?.borderRadius || '8px' }}></Stack>
      </Stack>
      <Stack
        className={classes.up}
        style={{
          left: rect.left,
          width: rect.right - rect.left,
          height: rect.top,
        }}
      ></Stack>
      <Stack className={classes.right} style={{ width: `calc(100vw - ${rect.right}px)`, height: '100vh' }}></Stack>
      <Stack
        className={classes.bottom}
        style={{ left: rect.left, width: rect.right - rect.left, height: `calc(100vh - ${rect.bottom}px)` }}
      ></Stack>
      <Stack className={classes.left} style={{ width: rect.left, height: '100vh' }}></Stack>
    </Stack>
  );
};
