import { OutlinedInput, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { colorDisabled } from 'common/params';
import { label1 } from 'theme/typography';

interface BetweenValueInputProps {
  lower?: string;
  upper?: string;
  disabled?: boolean;
  onChange?: (lower: string, upper: string) => void;
}

// for between value usage in filter
export const BetweenValueInput: FC<BetweenValueInputProps> = ({ lower, upper, disabled, onChange }) => {
  return (
    <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
      <OutlinedInput
        sx={{ width: '90px', height: '32px', borderRadius: '8px', ...label1 }}
        disabled={disabled}
        value={lower}
        onChange={(e) => onChange?.(e.target.value, upper || '')}
      ></OutlinedInput>
      <Typography variant='label1' style={{ color: disabled ? colorDisabled : 'inherit' }}>
        AND
      </Typography>
      <OutlinedInput
        sx={{ width: '90px', height: '32px', borderRadius: '8px', ...label1 }}
        disabled={disabled}
        value={upper}
        onChange={(e) => onChange?.(lower || '', e.target.value)}
      ></OutlinedInput>
      <Typography variant='label1' style={{ color: disabled ? colorDisabled : 'inherit' }}>
        USD
      </Typography>
    </Stack>
  );
};
