import { Button, Stack, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { FC, HTMLAttributes, useContext, useMemo } from 'react';
import { dateFormatter, datetimeFormatter } from 'common/formatters';

import AccountsAPI from 'common/AccountsAPI';
import { Credit } from 'common/types/Accounts/Credit';
import { DataContext } from 'contexts/DataContext';
import classes from './CreditList.module.scss';
import { useAsync } from 'react-use';

interface CreditListProps extends HTMLAttributes<HTMLElement> {}

export const CreditList: FC<CreditListProps> = ({ ...rest }) => {
  const { openCreditDialog$ } = useContext(DataContext);
  const { loading, value: credits } = useAsync(async () => {
    return (await AccountsAPI.getCredits()).data;
  }, []);

  const totalCredits = useMemo(() => {
    return (credits || []).map((credit) => credit.current_amount).reduce((prev, curr) => prev + curr, 0);
  }, [credits]);

  const columnDefs: CommonTableColumn<Credit>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'name',
      label: 'Reason',
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'init_amount',
      label: 'Credit amount',
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.plus}>
            +{value}
          </Typography>
        );
      },
    },
    {
      id: 'current_amount',
      label: 'Remain / Total',
      render: (value, _, data) => {
        return (
          <Typography variant='body1'>
            {typeof value === 'number' ? value : '-'} / {data?.init_amount || '-'}
          </Typography>
        );
      },
    },
    {
      id: 'created_at',
      label: 'Date',
      sortable: true,
      render: (value) => {
        if (!value) return '-';
        return <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>;
      },
    },
    {
      id: 'expired_at',
      label: 'Expiry date',
      sortable: true,
      render: (value) => {
        if (!value) return <Typography>Never expires</Typography>;
        return <Typography variant='body1'>{dateFormatter.format(value * 1000)}</Typography>;
      },
    },
  ];

  const tableHeader = useMemo(() => {
    return (
      <Stack direction='row' justifyContent='space-between' className={classes.tableHeader}>
        <Typography variant='h6'>Your credits: {totalCredits}</Typography>
        <Button variant='outlined' onClick={() => openCreditDialog$.next(true)}>
          <Typography variant='label1'>Earn more</Typography>
        </Button>
      </Stack>
    );
  }, [totalCredits, openCreditDialog$]);

  return <CommonTable {...rest} loading={loading} data={credits || []} columns={columnDefs} header={tableHeader} />;
};
