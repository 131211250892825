import { Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { CheckOutlined, LockOutlined } from '@mui/icons-material';
import { FC, useContext, useMemo } from 'react';

import { CreditList } from './CreditList';
import { CreditTransactionList } from './CreditTransactionList';
import { DataContext } from 'contexts/DataContext';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { Spinner } from 'components/common/Spinner';
import classes from './UserSubscription.module.scss';
import { dateFormatter } from 'common/formatters';
import { useAccountsUserProfile } from 'common/hooks/Accounts/useAccountsUserProfile';
import { useCollections } from 'common/hooks/Extension/useCollections';
import { useObservable } from 'react-use';

export const UserSubscription: FC = () => {
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const { collections$ } = useContext(ExtensionContext);
  const collections = useObservable(collections$);
  const { loading, value: user } = useAccountsUserProfile();

  useCollections(true);

  const collectionLength = useMemo(() => {
    return (collections || []).length;
  }, [collections]);

  const collectionLimitLength = useMemo(() => {
    if (!user?.subscription) return 1;
    if (user?.subscription.plan_name === 'Base') return 3;
    if (user?.subscription.plan_name === 'Plus') return 10;
    return undefined;
  }, [user]);

  const collectionValid = useMemo(() => {
    return (collectionLimitLength || Number.MAX_VALUE) >= collectionLength;
  }, [collectionLength, collectionLimitLength]);

  if (loading) return <Spinner />;

  return (
    <Stack className={classes.root} spacing={3}>
      <Typography variant='h5'>Subscription</Typography>
      <Stack spacing={3} className={classes.subscription}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack spacing={1}>
            <Typography>Your plan</Typography>
            {user?.subscription ? (
              <>
                <Typography variant='h6'>
                  {user.subscription.plan_name} - {user.subscription.duration_months === 1 ? 'Monthly' : 'Annual'}
                </Typography>
                <Typography variant='body2'>
                  Your plan renew on {dateFormatter.format(user.subscription.renew_date * 1000)}
                </Typography>
              </>
            ) : user?.trial ? (
              <>
                <Typography variant='h6'>
                  {Math.floor((user.trial.end_date - user.trial.start_date) / 86400)}-day trial
                </Typography>
                <Typography variant='body2'>
                  Trial expires in {Math.floor((user.trial.end_date - new Date().getTime() / 1000) / 86400)}-day
                </Typography>
              </>
            ) : (
              <Typography variant='h6'>-</Typography>
            )}
          </Stack>
          {/* remove display none after cancel plan is ready */}
          <Stack direction='row' style={{ display: 'none' }}>
            <Button>
              <Typography variant='label1'>Cancel Plan</Typography>
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={1.5}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <CheckOutlined className={classes.icon} />
              <Typography variant='body2'>Influencers matcher list </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={0.5}>
              <Typography variant='body2'>
                <span className={!collectionValid ? classes.invalid : undefined}>{collectionLength}</span>/
                {collectionLimitLength}
              </Typography>
              {!collectionValid ? (
                <Tooltip arrow placement='top' title='Your quota is full. Upgrade for more'>
                  <LockOutlined className={classes.icon} />
                </Tooltip>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction='row' justifyContent='space-between'>
          <Stack spacing={0.5}>
            <Typography variant='label1'>Looking for more?</Typography>
            <Typography variant='body2'>Upgrade your plan to enable more features</Typography>
          </Stack>
          <Stack direction='row'>
            <Button variant='outlined' onClick={() => openSubscriptionDialog$.next(true)}>
              <Typography variant='label1'>Compare all plan</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classes.creditList}>
        <CreditList />
      </Stack>
      <Stack className={classes.creditTransactionList}>
        <CreditTransactionList />
      </Stack>
    </Stack>
  );
};
