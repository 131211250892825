import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import { CalendarMonthOutlined, UpdateOutlined } from '@mui/icons-material';
import { FC, useEffect } from 'react';
import { numberToPercentage, numberToSecondFraction } from 'common/utils';

import ExtensionAPI from 'common/ExtensionAPI';
import { Spinner } from 'components/common/Spinner';
import { TwitterUser } from 'common/types/Extension/TwitterUser';
import { TwitterUserMetrics } from 'common/types/Extension/TwitterUserMetrics';
import { UserMetrics } from './UserMetrics';
import classes from './UserProfile.module.scss';
import { numberFormatter } from 'common/formatters';
import { useAsync } from 'react-use';

interface UserProfileProps {
  user: TwitterUser;
  onUserMetricsUpdate?: (userMetrics?: TwitterUserMetrics) => void;
}

export const UserProfile: FC<UserProfileProps> = ({ user, onUserMetricsUpdate }) => {
  const { loading, value: userMetrics } = useAsync(async () => {
    return (await ExtensionAPI.getTwitterUserMetrics(user.id)).data;
  }, [user]);

  useEffect(() => {
    onUserMetricsUpdate?.(userMetrics);
  }, [userMetrics, onUserMetricsUpdate]);

  return (
    <Stack className={classes.root} spacing={6}>
      <Stack className={classes.profile} spacing={2}>
        <Stack direction='row' spacing={4} alignItems='center'>
          <Avatar
            alt='TwitterUserAvatar'
            className={classes.avatar}
            src={user.profile_image_url?.replace('_normal.', '_400x400.')}
          />
          <Stack spacing={0.5} className={classes.basic}>
            <Stack direction='row'>
              <Tooltip title={user.display_name} placement='top' arrow>
                <Typography variant='h6' className={classes.name}>
                  {user.display_name}
                </Typography>
              </Tooltip>
            </Stack>
            <Stack direction='row' spacing={3} className={classes.info}>
              <Typography variant='subtitle1'>@{user.username}</Typography>
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <CalendarMonthOutlined color='inherit' fontSize='small' />
                <Typography variant='subtitle1'>
                  Joined{' '}
                  {new Date((user.account_created_at || 0) * 1000).toLocaleString('en-US', {
                    month: 'short',
                    year: 'numeric',
                  })}
                </Typography>
              </Stack>
            </Stack>
            {user.description ? (
              <Tooltip title={user.description} placement='top' arrow>
                <Typography variant='subtitle1' className={classes.bio}>
                  {user.description}
                </Typography>
              </Tooltip>
            ) : null}
            {userMetrics?.categories?.length ? (
              <Stack direction='row' spacing={1}>
                {userMetrics.categories.length > 3 ? (
                  <>
                    {userMetrics.categories.slice(0, 3).map((category, idx) => (
                      <Stack className={classes.category} alignItems='center' key={idx}>
                        <Typography variant='label1'>{category}</Typography>
                      </Stack>
                    ))}
                    <Tooltip title={userMetrics.categories.slice(3).join(', ')} arrow placement='top'>
                      <Stack className={classes.category} alignItems='center'>
                        <Typography variant='label1'>+{userMetrics.categories.slice(3).length} more</Typography>
                      </Stack>
                    </Tooltip>
                  </>
                ) : (
                  userMetrics.categories.map((category, idx) => (
                    <Stack className={classes.category} alignItems='center' key={idx}>
                      <Typography variant='label1'>{category}</Typography>
                    </Stack>
                  ))
                )}
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <Stack direction='row' className={classes.details} justifyContent='space-between'>
          <Tooltip arrow placement='top' title='follower counts'>
            <Stack>
              <Typography variant='label1'>Followers</Typography>
              <Typography variant='h6'>{numberFormatter.format(user.follower_count || 0)}</Typography>
            </Stack>
          </Tooltip>
          <Tooltip arrow placement='top' title='total tweet counts / days'>
            <Stack>
              <Typography variant='label1'>Daily Tweet Frequency</Typography>
              <Typography variant='h6'>
                {typeof userMetrics?.daily_tweet_frequency === 'number'
                  ? numberToSecondFraction(userMetrics.daily_tweet_frequency)
                  : '*'}
              </Typography>
            </Stack>
          </Tooltip>
          <Tooltip arrow placement='top' title='original tweet counts / (original + retweet counts)'>
            <Stack>
              <Typography variant='label1'>Original Tweets Ratio</Typography>
              <Typography variant='h6'>
                {typeof userMetrics?.original_tweets_ratio === 'number'
                  ? numberToPercentage(userMetrics.original_tweets_ratio)
                  : '*'}
              </Typography>
            </Stack>
          </Tooltip>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.tips} alignItems='center'>
          <UpdateOutlined fontSize='small' />
          <Typography variant='label1'>Update daily at GTM+0</Typography>
        </Stack>
      </Stack>
      <Stack className={classes.metrics}>
        {loading ? <Spinner /> : userMetrics?.has_profile ? <UserMetrics userMetrics={userMetrics} /> : <></>}
      </Stack>
    </Stack>
  );
};
