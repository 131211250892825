export const Config = Object.freeze({
  // currently leave blank since we put frontend/backend in same site
  // later if we would like to separate it
  // we should add a .env.production to this frontend root folder
  // it will have the parameter when executing docker build
  Version: process.env.REACT_APP_VERSION || '0.0.1',
  ApiServer: process.env.REACT_APP_API_SERVER || '/api',
  AuthServer: window.location.host.startsWith('localhost')
    ? 'http://localhost:3000'
    : window.location.origin.replace('console', 'accounts'),
  ExtensionApiServer: window.location.host.startsWith('localhost')
    ? 'http://localhost:3001/extension_api'
    : window.location.origin.replace('console', 'kol.ext') + '/api',
  AccountsApiServer: window.location.host.startsWith('localhost')
    ? 'http://localhost:3001/accounts_api'
    : window.location.origin.replace('console', 'accounts') + '/api',
  CookieDomain: window.location.host.startsWith('localhost')
    ? 'localhost'
    : window.location.host.includes('dev223')
    ? '.dev223.growing3.ai'
    : '.growing3.ai',
});
