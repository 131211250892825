import { FC, HTMLAttributes, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import ClientAPI from 'common/ClientAPI';
import { DataContext } from 'contexts/DataContext';
import { useAccountsUserProfile } from 'common/hooks/Accounts/useAccountsUserProfile';
import { useExtensionUserProfile } from 'common/hooks/Extension/useExtensionUserProfile';
import { useUserProfile } from 'common/hooks/useUserProfile';

export const DataInitializer: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  const { isBlock$ } = useContext(DataContext);
  const [init, setInit] = useState<boolean>(false);

  const { loading } = useUserProfile();
  const { loading: accountsUserLoading } = useAccountsUserProfile();
  const { loading: extensionUserLoading } = useExtensionUserProfile();

  const initData = useCallback(async () => {
    ClientAPI.isBlock$ = isBlock$;
    setInit(true);
  }, [isBlock$]);

  useEffect(() => {
    initData();
  }, [initData]);

  const ready = useMemo(() => {
    return init && !loading && !accountsUserLoading && !extensionUserLoading;
  }, [init, loading, accountsUserLoading, extensionUserLoading]);

  return <>{ready ? children : null}</>;
};
