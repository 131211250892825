import { Button, Popover, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';

import { TutorialStep } from 'common/types/Tutorial';
import classNames from 'classnames';
import classes from './TutorialHint.module.scss';

interface TutorialHintProps {
  component: Element;
  tutorialStep: TutorialStep;
  onSkipClick?: () => void;
  onNextClick?: (tutorialStep: TutorialStep) => void;
}

export const TutorialHint: FC<TutorialHintProps> = ({ component, tutorialStep, onSkipClick, onNextClick }) => {
  const [rect, setRect] = useState<DOMRect | undefined>(undefined);

  useEffect(() => {
    if (!component) return;
    const calculateBoundingRect = () => {
      const rect = component.getBoundingClientRect();
      setRect(rect);
    };
    window.addEventListener('resize', calculateBoundingRect);
    calculateBoundingRect();
    return () => {
      window.removeEventListener('resize', calculateBoundingRect);
    };
  }, [component]);

  const tipOnTop = useMemo(() => {
    if ((rect?.bottom || 0) > window.innerHeight - 300) return true;
    return (rect?.top || 0) > window.innerHeight / 2;
  }, [rect]);

  const tipAlignRight = useMemo(() => {
    if ((rect?.right || 0) > window.innerWidth - 300) return true;
    return (rect?.left || 0) > window.innerWidth / 2;
  }, [rect]);

  if (!rect) return null;

  return (
    <Popover
      open
      anchorEl={component}
      anchorOrigin={{ horizontal: tipAlignRight ? 'right' : 'left', vertical: tipOnTop ? 'top' : 'bottom' }}
      transformOrigin={{ horizontal: tipAlignRight ? 'right' : 'left', vertical: tipOnTop ? 'bottom' : 'top' }}
      style={{
        zIndex: 1501,
      }}
      sx={{
        '.MuiPopover-paper': {
          backgroundColor: 'transparent',
          paddingTop: tipOnTop ? 'unset' : '24px',
          paddingBottom: tipOnTop ? '24px' : 'unset',
          boxShadow: 'unset',
        },
      }}
    >
      <Stack
        className={classNames(
          classes.tips,
          tipOnTop ? classes.top : classes.bottom,
          tipAlignRight ? classes.right : classes.left,
        )}
      >
        <Stack spacing={0.5} className={classes.content}>
          <Typography variant='h6' className={classes.title}>
            {tutorialStep.hintTitle}
          </Typography>
          {tutorialStep.hintText ? <Typography className={classes.text}>{tutorialStep.hintText}</Typography> : null}
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' className={classes.action}>
          <Typography className={classes.skip} onClick={onSkipClick}>
            Skip
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              if (tutorialStep.autoClick) (component as HTMLElement).click();
              onNextClick?.(tutorialStep);
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};
