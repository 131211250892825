import { Button, Stack, Typography } from '@mui/material';
import { Collection, CollectionTwitterUser } from 'common/types/Extension/Collection';
import { FC, useCallback } from 'react';

import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import ExtensionAPI from 'common/ExtensionAPI';
import classes from './DeleteUserFromCollection.module.scss';
import { useMessage } from 'components/message/useMessage';

interface DeleteUserFromCollectionProps {
  twitterUser: CollectionTwitterUser;
  collection: Collection;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const DeleteUserFromCollection: FC<DeleteUserFromCollectionProps> = ({
  collection,
  twitterUser,
  onSuccess,
  onCancel,
}) => {
  const { showMessage } = useMessage();
  const deleteUserFromCollection = useCallback(async () => {
    await ExtensionAPI.deleteUserFromCollection(collection, twitterUser.id)
      .then(({ status }) => {
        if (status === 'success') {
          onSuccess?.();
          return;
        }
        showMessage('Delete user from collection failed');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Delete user from collection failed', 'error');
      });
  }, [collection, twitterUser, onSuccess, showMessage]);

  return (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={2} alignItems='center' className={classes.dialog}>
          <CommonChip>
            <DeleteOutlineOutlined fontSize='large' />
          </CommonChip>
          <Stack alignItems='center'>
            <Typography variant='h6' className={classes.title}>
              Remove <span className={classes.highlight}>"{twitterUser.display_name || twitterUser.username}"</span>{' '}
              from the list <span className={classes.highlight}>"{collection.name}"</span>?
            </Typography>
            <Typography variant='body1' className={classes.subtitle}>
              Once deleted it cannot be retrieved.
            </Typography>
          </Stack>
        </Stack>
      }
      footer={
        <Stack direction='row' justifyContent='center' spacing={1}>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              deleteUserFromCollection();
            }}
          >
            Remove
          </Button>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  );
};
