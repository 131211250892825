import { ArrowCircleRightOutlined, CheckOutlined, FiberManualRecord, UploadOutlined } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn } from 'components/table/CommonTable';
import { FC, HTMLAttributes, useState } from 'react';

import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { TwitterAdGroupPublishRecord } from 'common/types/TwitterPublishRecord';
import { Uris } from 'Uris';
import classes from './TwitterAdGroupPublishList.module.scss';
import { datetimeFormatter } from 'common/formatters';

interface TwitterAdGroupPublishListProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
  records: TwitterAdGroupPublishRecord[];
  publishBtnDisabled?: boolean;
  onAudiencePublish?: () => void;
}

interface AdGroupDetail {
  campaign_id: string;
  ad_group_id: string;
  ad_group_name: string;
}

export const TwitterAdGroupPublishList: FC<TwitterAdGroupPublishListProps> = ({
  loading,
  records,
  publishBtnDisabled,
  onAudiencePublish,
  ...rest
}) => {
  const [adGroups, setAdGroups] = useState<AdGroupDetail[] | undefined>(undefined);
  const [accountId, setAccountId] = useState<string | undefined>(undefined);
  const columnDefs: CommonTableColumn<TwitterAdGroupPublishRecord>[] = [
    {
      id: 'idx',
      label: '#',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{value}</Typography>;
      },
    },
    {
      id: 'created_at',
      label: 'Publish Date',
      sortable: true,
      render: (value) => {
        return <Typography variant='body1'>{datetimeFormatter.format(value * 1000)}</Typography>;
      },
    },
    {
      id: 'ad_groups',
      label: 'Publish Ad Groups',
      render: (value: Record<string, AdGroupDetail>, _, data) => {
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography variant='body1'>{Object.keys(value).length} Ad Groups</Typography>
            <ArrowCircleRightOutlined
              className={classes.pointer}
              fontSize='small'
              onClick={() => {
                const adGroups: AdGroupDetail[] = [];
                Object.keys(value).forEach((key) => {
                  if (!(key in value)) return;
                  if (!('campaign_id' in value[key])) return;
                  if (!('ad_group_name' in value[key])) return;
                  adGroups.push({
                    campaign_id: value[key].campaign_id,
                    ad_group_id: key,
                    ad_group_name: value[key].ad_group_name,
                  });
                });
                setAdGroups(adGroups);
                setAccountId(data?.account_id);
              }}
            />
          </Stack>
        );
      },
    },
    {
      id: 'influencer_count',
      label: 'Publish Results',
      render: (value) => {
        return <Typography variant='body1'>{value} Influencers</Typography>;
      },
    },
    {
      id: 'selected_levels',
      label: 'Recommend Degree',
      tooltip:
        'Recommend Degree indicates the relevance between the influencer and the selected wallet cohort, recording your selection of high, medium, or low at the time of publishing.',
      render: (value?: string[]) => {
        return (
          <Stack spacing={0.5}>
            {value?.map((level, idx) => {
              return (
                <Stack key={idx} direction='row' alignItems='center' spacing={1}>
                  <FiberManualRecord
                    fontSize='small'
                    color={level === 'High' ? 'success' : level === 'Medium' ? 'warning' : 'error'}
                  />
                  <Typography variant='body1'>{level}</Typography>
                </Stack>
              );
            })}
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack className={classes.root} spacing={4}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography {...rest} variant='h3'>
          Publish History
        </Typography>
        <Button
          variant='contained'
          startIcon={<UploadOutlined />}
          disabled={publishBtnDisabled}
          onClick={onAudiencePublish}
        >
          Publish
        </Button>
      </Stack>
      <CommonTable
        {...rest}
        loading={loading}
        data={records}
        totalDataCnt={records.length}
        columns={columnDefs}
        classes={classes}
      />
      {adGroups?.length && accountId ? (
        <CommonDialog
          open
          mainContent={
            <Stack spacing={4} alignItems='center' className={classes.dialog}>
              <CommonChip>
                <CheckOutlined fontSize='large' />
              </CommonChip>
              <Stack spacing={2} className={classes.title} alignItems='center'>
                <Typography variant='h6'>{adGroups.length} Ad groups published</Typography>
                <Stack direction='row' alignItems='center' spacing={1}>
                  {adGroups.map((adGroup) => (
                    <Typography
                      key={adGroup.ad_group_id}
                      className={classes.link}
                      onClick={() =>
                        window.open(
                          Uris.External.TwitterAdGroupEdit.replace(':accountId', accountId)
                            .replace(':campaignId', parseInt(adGroup.campaign_id, 36).toString())
                            .replace(':adGroupId', parseInt(adGroup.ad_group_id, 36).toString()),
                        )
                      }
                    >
                      {adGroup.ad_group_name}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          }
          footer={
            <Button variant='contained' onClick={() => setAdGroups(undefined)}>
              Close
            </Button>
          }
          onDialogClose={() => setAdGroups(undefined)}
        />
      ) : null}
    </Stack>
  );
};
