import { Typography } from '@mui/material';
import { getColorProgress } from 'common/colors/getColorProgress';
import { Color } from 'common/types/Color';
import { Arrow } from 'components/Guage/Arrow';
import { Bar } from 'components/Guage/Bar';
import { Ticks } from 'components/Guage/Ticks';
import { DIMENSION } from 'components/Guage/constants';
import styles from 'components/Guage/Guage.module.scss';

const ProgressText = ({ progress, color }: { progress: number; color: string }) => {
  return (
    <foreignObject x={0} y={0} height={DIMENSION} width={DIMENSION}>
      {/* Using Typography internally to keep consistent theming */}
      <Typography className={styles.progressText} variant='h3' style={{ color }}>
        {progress}%
      </Typography>
    </foreignObject>
  );
};

/**
 * Description should be right below the guage component. As the guage component is a 270deg ring, to
 * stay as close as possible, the margin should be (1 - sin(45deg)) * radius:
 *       /|
 *    r / | r * sin(deg)
 *     /__|
 *        | (1 - sin(deg)) * r
 */
const DESCRIPTION_MARGIN_FACTOR = 1 - Math.sin(Math.PI / 4);

export const Gauge = ({
  size,
  progress,
  colors,
  description,
}: {
  progress: number;
  size: number;
  colors: Color[];
  description?: React.ReactNode;
}) => {
  const color = getColorProgress(colors, progress);

  return (
    <div style={{ width: size }}>
      <svg viewBox={`0 0 ${DIMENSION} ${DIMENSION}`} width={size} height={size}>
        <Arrow progress={progress} color={color} />
        <ProgressText progress={progress} color={color} />
        <Bar colors={colors} />
        <Ticks />
      </svg>
      {description && <div style={{ marginTop: (-DESCRIPTION_MARGIN_FACTOR * size) / 2 }}>{description}</div>}
    </div>
  );
};
