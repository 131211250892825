import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import {
  DefaultInfluencerCampaign,
  InfluencerCampaign,
  InfluencerCampaignStatus,
  InfluencerThread,
  InfluencerThreadStatus,
} from 'common/types/Extension/InfluencerCampaign';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AccountsContext } from 'contexts/AccountsContext';
import { ArrowBackOutlined } from '@mui/icons-material';
import { CampaignCookieEdit } from 'components/InfluencerCampaign/Edit/CampaignCookieEdit';
import { CampaignLaunch } from 'components/InfluencerCampaign/Edit/CampaignLaunch';
import { CampaignMessageEdit } from 'components/InfluencerCampaign/Edit/CampaignMessageEdit';
import ExtensionAPI from 'common/ExtensionAPI';
import { Spinner } from 'components/common/Spinner';
import { TimeEstimateDialog } from 'components/InfluencerCampaign/Edit/Dialog/TimeEstimateDialog';
import { Uris } from 'Uris';
import classes from './CampaignSendMessage.module.scss';
import { useAsync } from 'react-use';
import { useLeavePageConfirm } from 'common/hooks/useLeavePageConfirm';
import { useMessage } from 'components/message/useMessage';

const tabs: string[] = ['1. Messages', '2. Connect Account', '3. Launch'];

export const CampaignSendMessage: FC = () => {
  const { campaignId } = useParams();
  const { state } = useLocation();

  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { refetchUser$ } = useContext(AccountsContext);
  const [step, setStep] = useState<number>(0);
  const [saving, setSaving] = useState<boolean>(false);
  const [editCampaign, setEditCampaign] = useState<InfluencerCampaign>(DefaultInfluencerCampaign);
  const [init, setInit] = useState<boolean>(false);

  const [estimateDialogOpened, setEstimateDialogOpened] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  /** Window close with edited content not saved */
  useLeavePageConfirm(!saveSuccess); // when success, disable leave page confirm

  useEffect(() => {
    if (!saveSuccess) return;
    setSaveSuccess(false);
    navigate(Uris.Pages.InfluencerMatcher.Index, { state: { tab: 'Campaign' } });
    refetchUser$.next(true);
  }, [saveSuccess, refetchUser$, navigate]);

  const { loading, value } = useAsync(async () => {
    const id = Number.parseInt(campaignId || '');
    if (Number.isNaN(id)) return undefined;
    return (await ExtensionAPI.getInfluencerCampaign(id)).data;
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!value) {
      navigate(Uris.Pages.InfluencerMatcher.Index, { state: { tab: 'Campaign' } });
      return;
    }
    const filterThread = value.threads?.filter((thread) =>
      ((state?.threads as InfluencerThread[]) || []).some((t) => thread.id === t.id),
    );
    if (!filterThread?.length) {
      navigate(Uris.Pages.InfluencerMatcher.Index, { state: { tab: 'Campaign' } });
      return;
    }
    // insert threads that store in state to campaign
    // clear old messages in campaign to make sure that message should always be new messages
    // clear user bind message id and mark status to SENDING
    setEditCampaign({
      ...value,
      threads: filterThread.map((thread) => ({
        ...thread,
        status: InfluencerThreadStatus.SENDING,
        influencer_message_id: undefined,
      })),
      messages: [],
    });
    setInit(true);
  }, [loading, state?.threads, navigate, value]);

  const handleStepChange = useCallback((_: React.SyntheticEvent, newStep: number) => {
    setStep(newStep);
  }, []);

  const saveCampaign = useCallback(
    async (send?: boolean) => {
      setSaving(true);
      await ExtensionAPI.updateInfluencerCampaign({
        ...editCampaign,
        status: send ? InfluencerCampaignStatus.IN_PROGRESS : editCampaign.status,
      })
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            setEstimateDialogOpened(true);
            return;
          }
          showMessage(`Save campaign failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [editCampaign, showMessage],
  );

  const content = useMemo(() => {
    switch (step) {
      case 0:
        // Send message -> can only add new message, can not edit old message or link
        return (
          <CampaignMessageEdit
            saving={saving}
            threads={editCampaign.threads || []}
            messages={editCampaign.messages || []}
            readonlyMessages={[]}
            onCampaignThreadEdit={(threads) => setEditCampaign((old) => ({ ...old, threads: threads }))}
            onCampaignMessageEdit={(messages) => setEditCampaign((old) => ({ ...old, messages: messages }))}
            onNextBtnClicked={() => setStep((old) => old + 1)}
          />
        );
      case 1:
        return (
          <CampaignCookieEdit
            draft={false}
            saving={saving}
            onCampaignCookieEdit={(cookie) =>
              setEditCampaign((old) => ({
                ...old,
                threads: old.threads?.map((thread) => ({
                  ...thread,
                  integration_auth_id: cookie.id,
                  sender_id: cookie.user_id,
                })),
              }))
            }
            onNextBtnClicked={() => setStep((old) => old + 1)}
            onBackBtnClicked={() => setStep((old) => old - 1)}
          />
        );
      case 2:
        return (
          <CampaignLaunch
            draft={false}
            saving={saving}
            campaign={editCampaign}
            onSendBtnClicked={() => {
              saveCampaign();
            }}
            onBackBtnClicked={() => setStep((old) => old - 1)}
          ></CampaignLaunch>
        );
    }
  }, [step, saving, editCampaign, saveCampaign]);

  if (loading || !init) return <Spinner />;

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row'>
        <Button startIcon={<ArrowBackOutlined />} onClick={() => navigate(-1)}>
          Back to list
        </Button>
      </Stack>
      <Typography variant='h3'>{editCampaign.name}</Typography>
      <Tabs value={step} onChange={handleStepChange} centered className={classes.tabs}>
        {tabs.map((tab, idx) => (
          <Tab
            key={tab}
            className={classes.tab}
            style={{ width: `${100 / tabs.length}%` }}
            disabled={idx > step}
            label={
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='subtitle2'>{tab}</Typography>
              </Stack>
            }
          />
        ))}
      </Tabs>
      {content}
      {estimateDialogOpened ? (
        <TimeEstimateDialog open campaign={editCampaign} onBack={() => setSaveSuccess(true)} />
      ) : null}
    </Stack>
  );
};
