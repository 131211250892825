import { Tutorial, tutorials } from 'common/types/Tutorial';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import ClientAPI from 'common/ClientAPI';
import { TutorialContext } from 'contexts/TutorialContext';
import { useMessage } from 'components/message/useMessage';
import { useObservable } from 'react-use';

export const useCompletedTutorials = () => {
  const { showMessage } = useMessage();
  const { tutorial$, completedTutorials$, landingCompleted$, refetchCompletedTutorials$ } = useContext(TutorialContext);
  const [completedTutorials, setCompletedTutorials] = useState<Tutorial[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const refetchCompletedTutorials = useObservable(refetchCompletedTutorials$);
  const initFirstTutorial = useRef(false);

  const fetchCompletedTutorials = useCallback(async () => {
    setLoading(true);
    await ClientAPI.getCompletedTutorials()
      .then(({ data }) => {
        completedTutorials$.next(data || []);
        setCompletedTutorials(data || []);
        refetchCompletedTutorials$.next(false);
        landingCompleted$.next((data || []).findIndex((t) => t.key === 'landing-page') > -1);
        if (!initFirstTutorial.current) {
          for (const t of tutorials) {
            if ((data || []).findIndex((d) => t.key === d.key) > -1) continue;
            tutorial$.next(t);
            break;
          }
          initFirstTutorial.current = true;
        }
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, tutorial$, completedTutorials$, refetchCompletedTutorials$, landingCompleted$]);

  useEffect(() => {
    if (!refetchCompletedTutorials) return;
    fetchCompletedTutorials();
  }, [refetchCompletedTutorials, fetchCompletedTutorials]);

  return { loading, value: completedTutorials };
};
