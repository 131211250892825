import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import { InfluencerMessage } from 'common/types/Extension/InfluencerCampaign';
import classes from './RemoveMessageDialog.module.scss';

interface RemoveMessageDialogProps extends DialogProps {
  message: InfluencerMessage;
  onRemove?: () => void;
  onCancel?: () => void;
}

export const RemoveMessageDialog: FC<RemoveMessageDialogProps> = ({ open, message, onRemove, onCancel, ...props }) => {
  const onBtnRemoveClicked = useCallback(() => {
    onRemove?.();
  }, [onRemove]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <DeleteOutlineOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1} className={classes.detail}>
              <Typography variant='h6' textAlign='center'>
                Remove Message
              </Typography>
              <Typography variant='body1' className={classes.text}>
                {message.content}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onBtnRemoveClicked}>
            Remove
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
