import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { CloseOutlined, InfoOutlined, OutboundOutlined } from '@mui/icons-material';
import { FC, useCallback, useEffect, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { TweetEmbed } from './TweetEmbed';
import { Uris } from 'Uris';
import classes from './TweetsDialog.module.scss';

interface TweetsDialogProps extends DialogProps {
  username: string;
  tweetIds: string[];
  mentionedCount: number;
  onCancel?: () => void;
}

const embedOptions = { cards: 'hidden', conversation: 'none', width: 550, dnt: true };

export const TweetsDialog: FC<TweetsDialogProps> = ({
  open,
  username,
  tweetIds,
  mentionedCount,
  onCancel,
  ...props
}) => {
  const [loadMoreDiv, setLoadMoreDiv] = useState<HTMLDivElement | undefined>(undefined);
  const [showTweetIds, setShowTweetIds] = useState<string[]>(tweetIds.slice(0, 3));

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  useEffect(() => {
    if (!loadMoreDiv) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowTweetIds((old) => {
              if (old.length === tweetIds.length) return old;
              const showTweetIds = tweetIds.slice(0, old.length + 3);
              return showTweetIds;
            });
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.9, // Trigger when the sentinel is 90% visible
      },
    );
    observer.observe(loadMoreDiv);
    return () => {
      observer.unobserve(loadMoreDiv);
    };
  }, [loadMoreDiv, tweetIds]);

  return (
    <Dialog
      open={open}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      maxWidth='md'
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography variant='h6'>
              <span className={classes.highlight}>{mentionedCount}</span> Tweets mentioned
            </Typography>
            <Tooltip title="Tweets shown below might be slightly different from user's profile">
              <InfoOutlined />
            </Tooltip>
          </Stack>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1.5}>
        <Stack className={classes.tweets}>
          {showTweetIds.map((tweetId) => (
            <TweetEmbed
              key={tweetId}
              tweetId={tweetId}
              username={username}
              options={embedOptions}
              className={classes.tweet}
            />
          ))}
          <Stack height='40px' ref={(div) => div && setLoadMoreDiv(div)}></Stack>
        </Stack>
        <Stack direction='row' justifyContent='center'>
          <Button
            startIcon={<OutboundOutlined />}
            variant='contained'
            onClick={() => window.open(Uris.External.Twitter + `/${username}`, '_blank')}
          >
            Read more at Twitter
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
