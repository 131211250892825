import { Button, FormControl, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { CheckOutlined, FormatListBulletedOutlined } from '@mui/icons-material';
import { FC, useCallback, useContext, useState } from 'react';

import { Collection } from 'common/types/Extension/Collection';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import classNames from 'classnames';
import classes from './CreateCollection.module.scss';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

interface CreateCollectionProps {
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const CreateCollection: FC<CreateCollectionProps> = ({ onSuccess, onCancel }) => {
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { refetchCollections$ } = useContext(ExtensionContext);
  const [name, setName] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const createCollection = useCallback(async () => {
    const newCollection: Collection = { name, twitter_users: [] };
    await ExtensionAPI.createCollection(newCollection)
      .then(({ status, data }) => {
        if (status === 'success' && data) {
          setSuccess(true);
          track('click', {
            sub_event: 'list_created',
            custom_props: { entry_point: 'List page', list_id: data.id, list_name: name },
          });
          refetchCollections$.next(true);
          return;
        }
        showMessage('Create collection failed', 'error');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Create collection failed', 'error');
      });
  }, [name, track, refetchCollections$, showMessage]);

  return success ? (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={2} alignItems='center' className={classes.dialog}>
          <CommonChip>
            <CheckOutlined fontSize='large' />
          </CommonChip>
          <Stack direction='row' spacing={1}>
            <Typography variant='h6' className={classNames(classes.title, classes.highlight)}>
              "{name}"
            </Typography>
            <Typography variant='h6' className={classes.title}>
              is created successfully.
            </Typography>
          </Stack>
        </Stack>
      }
      footer={
        <Stack direction='row-reverse' spacing={1}>
          <Button variant='contained' onClick={() => onSuccess?.()}>
            View List
          </Button>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  ) : (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={2} alignItems='center' className={classes.dialog}>
          <CommonChip>
            <FormatListBulletedOutlined fontSize='large' />
          </CommonChip>
          <Typography variant='h6' className={classes.title}>
            Create a new list
          </Typography>
          <FormControl className={classes.input}>
            <InputLabel>Name of the list</InputLabel>
            <OutlinedInput
              size='medium'
              label='Name of the list'
              placeholder='Enter name of the list, eg. DeFi analysis'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
        </Stack>
      }
      footer={
        <Stack direction='row-reverse' spacing={1}>
          <Button
            variant='contained'
            onClick={() => {
              createCollection();
            }}
          >
            Create
          </Button>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  );
};
