import { Typography, TypographyPropsVariantOverrides } from '@mui/material';

import { Category } from 'common/types/Category';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { get } from 'lodash';
import { numberFormatter } from 'common/formatters';

export const renderCategory = (
  category: Category,
  variant: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> = 'label1',
) => {
  return (
    <Typography variant={variant}>
      {category.name}
      {get(category.result_meta, 'wallet_count')
        ? ` - ${numberFormatter.format(get(category.result_meta, 'wallet_count', NaN))}`
        : ''}
    </Typography>
  );
};
