import { JobStatus } from 'common/types/JobStatus';

export enum InfluencerUserStatus {
  EXIST_DB = 'exist_db',
  NEW = 'new',
  FAILED = 'failed',
}

export interface CustomizedInfluencerJob {
  id?: number;
  name?: string;
  existing_collection_id?: number;
  final_collection_id?: number;
  status: JobStatus;
  created_at?: number;
  updated_at?: number;
  user_id?: string;
  users: CustomizedInfluencerUser[];
}

export interface CustomizedInfluencerUser {
  job_id: number;
  username: string;
  status?: InfluencerUserStatus;
  is_handle: boolean;
  created_at?: number;
  updated_at?: number;
}
