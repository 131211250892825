import { Button, Stack, Typography } from '@mui/material';

import { CheckOutlined } from '@mui/icons-material';
import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import { GoogleSheet } from 'common/types/GoogleDrive';
import classes from './ExportSuccess.module.scss';

interface ExportSuccessProps {
  sheet: GoogleSheet;
  onBtnCloseClicked?: () => void;
  onBtnViewListClicked?: () => void;
}

export const ExportSuccess: FC<ExportSuccessProps> = ({ sheet, onBtnCloseClicked, onBtnViewListClicked }) => {
  return (
    <Stack className={classes.root} spacing={1} alignItems='center'>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <CheckOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            <Typography>
              <span className={classes.highlight}>"{sheet.name}"</span> is successfully exported
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
        <Button variant='outlined' onClick={onBtnCloseClicked}>
          Close
        </Button>
        <Button variant='contained' onClick={onBtnViewListClicked}>
          View List
        </Button>
      </Stack>
    </Stack>
  );
};
