import { Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CloseOutlined } from '@mui/icons-material';
import { SubscriptionPlanList } from 'components/Auth/SubscriptionPlanList';
import classNames from 'classnames';
import classes from './SubscriptionDialog.module.scss';

interface SubscriptionDialogProps extends DialogProps {
  onBtnCancelClicked?: () => void;
}

export const SubscriptionDialog: FC<SubscriptionDialogProps> = ({ open, onBtnCancelClicked, ...props }) => {
  const [monthly, setMonthly] = useState<boolean>(true);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      maxWidth='xl'
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <Stack direction='row' className={classes.closeBtn}>
        <IconButton onClick={onBtnCancelClicked}>
          <CloseOutlined style={{ color: colorPrimary40 }} />
        </IconButton>
      </Stack>
      <Stack className={classes.root} spacing={3}>
        <Stack className={classes.top} justifyContent='space-between'>
          <Stack spacing={1}>
            <Typography variant='h5' className={classes.title}>
              Unlock Growing3's most powerful features
            </Typography>
          </Stack>
          <Stack spacing={1} className={classes.info}>
            <Typography variant='body2' className={classes.tips}>
              Save up to 25% with an annual plan
            </Typography>
            <Stack direction='row' alignItems='center'>
              <Stack direction='row' justifyContent='center' className={classes.btn} onClick={() => setMonthly(true)}>
                <Typography variant='subtitle2' className={classNames(classes.btnText, monthly && classes.selected)}>
                  Monthly
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='center' className={classes.btn} onClick={() => setMonthly(false)}>
                <Typography variant='subtitle2' className={classNames(classes.btnText, !monthly && classes.selected)}>
                  Annual
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <SubscriptionPlanList monthly={monthly} />
      </Stack>
    </Dialog>
  );
};
