import { Drawer, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAsync, useObservable } from 'react-use';

import { DrawerHeader } from 'pages/InfluencerMatcher/Drawer/DrawerHeader';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { SingleCollection } from 'components/InfluencerMatcher/collection/SingleCollection';
import { Spinner } from 'components/common/Spinner';
import { TwitterUser } from 'common/types/Extension/TwitterUser';
import { TwitterUserMetrics } from 'common/types/Extension/TwitterUserMetrics';
import { UserProfile } from 'components/InfluencerMatcher/twitter/UserProfile';
import classNames from 'classnames';
import classes from './ExtensionDrawer.module.scss';
import { colorSurface1 } from 'common/params';

interface ExtensionDrawerProps {
  twitterUserId?: string;
  collectionId?: number;
  onClose?: () => void;
  onAddTwitterUser?: (twitterUser: TwitterUser) => void;
}

export enum ExtensionDrawerStep {
  PROFILE = 'profile',
  SINGLE_COLLECTION = 'singleCollection',
}

export const ExtensionDrawer: FC<ExtensionDrawerProps> = ({
  twitterUserId,
  collectionId,
  onClose,
  onAddTwitterUser,
}) => {
  const { collections$ } = useContext(ExtensionContext);
  const collections = useObservable(collections$);
  const [step, setStep] = useState<ExtensionDrawerStep>(
    typeof collectionId === 'number' ? ExtensionDrawerStep.SINGLE_COLLECTION : ExtensionDrawerStep.PROFILE,
  );
  const [userId, setUserId] = useState<string | undefined>(twitterUserId);
  const [open, setOpen] = useState<boolean>(false);
  const [hasCategory, setHasCategory] = useState<boolean>(false);

  const { loading, value: twitterUser } = useAsync(async () => {
    if (!userId) return undefined;
    return (await ExtensionAPI.getTwitterUser(userId)).data;
  }, [userId]);

  useEffect(() => {
    if (typeof collectionId !== 'number') return;
    setStep(ExtensionDrawerStep.SINGLE_COLLECTION);
  }, [collectionId]);

  useEffect(() => {
    if (!twitterUserId) return;
    setStep(ExtensionDrawerStep.PROFILE);
  }, [twitterUserId]);

  useEffect(() => {
    setUserId(twitterUserId);
  }, [twitterUserId]);

  const collection = useMemo(() => {
    return collections?.find((collection) => collection.id === collectionId);
  }, [collectionId, collections]);

  useEffect(() => {
    // in order to make this drawer open more smoothly
    // we set open to true after 1 msec when component is mounted
    const timeout = setTimeout(() => setOpen(true), 1);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onUserMetricsUpdate = useCallback((userMetrics?: TwitterUserMetrics) => {
    setHasCategory(!!userMetrics?.categories?.length);
  }, []);

  const mainContent = useMemo(() => {
    switch (step) {
      case ExtensionDrawerStep.PROFILE:
        if (loading) return <Spinner />;
        if (!twitterUser)
          return (
            <Typography color='error' variant='h6'>
              Can not fetch user data from database
            </Typography>
          );
        return <UserProfile user={twitterUser} onUserMetricsUpdate={onUserMetricsUpdate} />;
      case ExtensionDrawerStep.SINGLE_COLLECTION:
        if (!collection)
          return (
            <Stack alignItems='center'>
              <Spinner />
            </Stack>
          );
        return (
          <SingleCollection
            collections={collections || []}
            collection={collection}
            onTwitterViewClicked={(userId) => {
              setUserId(userId);
              setStep(ExtensionDrawerStep.PROFILE);
            }}
          />
        );
      default:
        return null;
    }
  }, [step, loading, twitterUser, collections, collection, onUserMetricsUpdate]);

  const headerText = useMemo(() => {
    switch (step) {
      case ExtensionDrawerStep.PROFILE:
        return collection?.name;
      default:
        return undefined;
    }
  }, [step, collection]);

  return (
    <Drawer
      ModalProps={{
        sx: {
          pointerEvents: 'none',
        },
      }}
      PaperProps={{
        sx: {
          width: '700px',
          minWidth: '700px',
          height: '100%',
          backgroundColor: colorSurface1,
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: '24px',
          padding: '12px',
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={open}
      onClose={onClose}
      onTransitionEnd={() => {
        // drawer opened will make html tag overflow become hidden
        // manually set it back to scroll
        document.documentElement.style.overflow = 'scroll';
      }}
    >
      <Stack
        className={classNames(
          classes.background,
          step === ExtensionDrawerStep.PROFILE && twitterUser && classes.dark,
          hasCategory && classes.hasCategory,
        )}
      ></Stack>
      <Stack className={classes.root} spacing={1}>
        <DrawerHeader
          twitterUser={twitterUser}
          dark={step === ExtensionDrawerStep.PROFILE}
          text={headerText}
          onAddTwitterUser={onAddTwitterUser}
          onBack={() => {
            switch (step) {
              case ExtensionDrawerStep.PROFILE:
                if (collection) {
                  setStep(ExtensionDrawerStep.SINGLE_COLLECTION);
                  setUserId(undefined);
                  return;
                }
                onClose?.();
                break;
              case ExtensionDrawerStep.SINGLE_COLLECTION:
                onClose?.();
                break;
            }
          }}
        />
        <Stack className={classes.content}>{mainContent}</Stack>
      </Stack>
    </Drawer>
  );
};
