import { Button, Stack, Typography } from '@mui/material';
import {
  DefaultInfluencerSearchParams,
  InfluencerSearchParams,
  OrderBy,
  OrderByField,
} from 'common/types/Extension/InfluencerFilter';
import { FC, useCallback, useContext, useState } from 'react';
import { SearchBar, SearchBarMenu } from 'pages/InfluencerMatcher/Search/SearchBar';
import { colorNeutralVariant50, colorPrimary40, colorPrimary90 } from 'common/params';
import { useLifecycles, useObservable } from 'react-use';

import { AddUserToCollectionDialog } from 'components/InfluencerMatcher/collection/AddUserToCollectionDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { ExtensionDrawer } from 'pages/InfluencerMatcher/Drawer/ExtensionDrawer';
import { InfluencerFilter } from 'components/Influencer/InfluencerFilter';
import { PaginationOptions } from 'components/table/CommonTable';
import { TwitterUserList } from 'pages/InfluencerMatcher/Search/TwitterUserList';
import { TwitterUserSearchResult } from 'common/types/Extension/TwitterUserSearchResult';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './SearchInfluencer.module.scss';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';
import { useUserFeatureLimits } from 'common/hooks/useUserFeatureLimits';
import { v4 as uuidv4 } from 'uuid';
import { validateTwitterUserUrl } from 'common/utils';

interface SearchInfluencerProps {}

export const SearchInfluencer: FC<SearchInfluencerProps> = () => {
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const {
    collections$,
    refetchCollections$,
    searchedTwitterUsers$,
    metricsRange$,
    projectCategories$,
    nonProjectCategories$,
    languages$,
    locations$,
  } = useContext(ExtensionContext);

  const collections = useObservable(collections$);
  const [searchParams, setSearchParams] = useState<InfluencerSearchParams>(DefaultInfluencerSearchParams);
  const [prevSearchParams, setPrevSearchParams] = useState<InfluencerSearchParams | undefined>(undefined);
  const [twitterUsers, setTwitterUsers] = useState<TwitterUserSearchResult[]>(searchedTwitterUsers$.getValue());
  const [addTwitterUsers, setAddTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalDataCnt, setTotalDataCnt] = useState<number>(0);
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions | undefined>({
    page: 0,
    rowsPerPage: 50,
  });
  const [searchId, setSearchId] = useState<string>(uuidv4());

  const [addedTwitterUsers, setAddedTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [viewTwitterUserId, setViewTwitterUserId] = useState<string | undefined>(undefined);
  const [viewCollectionId, setViewCollectionId] = useState<number | undefined>(undefined);

  const { value: searchLimit } = useUserFeatureLimits(
    'search',
    prevSearchParams?.keyword_search?.type || DefaultInfluencerSearchParams.keyword_search?.type,
  );

  const searchTwitterUsers = useCallback(
    async (source: string, searchParams: InfluencerSearchParams, paginationOptions?: PaginationOptions) => {
      if (searchParams.keyword_search?.keyword && searchParams.keyword_search?.type === 'similar_niche') {
        if (!validateTwitterUserUrl(searchParams.keyword_search.keyword)) {
          showMessage('X url format not correct for similar niche search', 'warning');
          return;
        }
      } else if (searchParams.keyword_search?.keyword && searchParams.keyword_search?.type === 'followers') {
        if (!validateTwitterUserUrl(searchParams.keyword_search.keyword)) {
          showMessage('X url format not correct for followers search', 'warning');
          return;
        }
      }
      let newSearchId = searchId;
      if (source === 'Search' || source === 'Apply') {
        newSearchId = uuidv4();
      }
      setPaginationOptions(paginationOptions);
      setLoading(true);
      setPrevSearchParams(searchParams);
      await ExtensionAPI.searchTwitterUser(searchParams, paginationOptions)
        .then(({ status, data, total_rows }) => {
          if (status === 'success') {
            setTwitterUsers(data || []);
            searchedTwitterUsers$.next(data || []);
            setTotalDataCnt(total_rows || 0);
            setSearchId(newSearchId);
            const filterConditions = [];
            if (searchParams.followers) filterConditions.push('followers');
            if (searchParams.engagement_rate) filterConditions.push('engagement_rate');
            if (searchParams.view_through_rate) filterConditions.push('view_through_rate');
            if (searchParams.campaign_term_ratio) filterConditions.push('campaign_term_ratio');
            if (searchParams.original_tweets_ratio) filterConditions.push('original_tweets_ratio');
            if (searchParams.average_tweet_impression) filterConditions.push('average_tweet_impression');
            if (searchParams.categories) filterConditions.push('categories');
            track('click', {
              sub_event: 'influencer_searched',
              custom_props: {
                trigger_button: source,
                search_id: newSearchId,
                search_query: searchParams.keyword_search?.keyword,
                search_bar_select: searchParams.keyword_search?.type,
                pagination_number:
                  typeof paginationOptions?.page === 'number' ? paginationOptions?.page + 1 : undefined,
                returned_results_number: total_rows,
                filter_condition: filterConditions,
                sort_field: searchParams.order_by?.field,
                sort_order: searchParams.order_by?.order,
              },
            });
            return;
          }
          showMessage(`Search twitter users failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [searchedTwitterUsers$, showMessage, track, searchId],
  );

  useLifecycles(
    () => {
      searchTwitterUsers('Default', DefaultInfluencerSearchParams, {
        page: 0,
        rowsPerPage: 50,
      });
    },
    () => {},
  );

  const onTwitterUserListPageChange = useCallback(
    (paginationOptions: PaginationOptions) => {
      searchTwitterUsers('Pagination', searchParams, paginationOptions);
    },
    [searchParams, searchTwitterUsers],
  );

  return (
    <Stack className={classes.root} spacing={4}>
      <Stack direction='row' justifyContent='space-between' spacing={1}>
        <Typography variant='h3'>Discover {searchParams.is_project ? 'Projects' : 'Influencers'}</Typography>
        <Stack direction='row' className={classes.mode}>
          <Button
            variant={!searchParams.is_project ? 'contained' : 'outlined'}
            className={classNames(classes.left, !searchParams.is_project && classes.selected)}
            sx={{
              '&.MuiButton-contained': {
                backgroundColor: colorPrimary90,
                color: colorPrimary40,
                border: `1px solid ${colorNeutralVariant50}`,
              },
            }}
            onClick={() => {
              setSearchParams((old) => ({ ...old, is_project: false }));
              searchTwitterUsers(
                'Search',
                { ...searchParams, is_project: false },
                { page: 0, rowsPerPage: paginationOptions?.rowsPerPage || 50 },
              );
            }}
          >
            Influencers
          </Button>
          <Button
            variant={searchParams.is_project ? 'contained' : 'outlined'}
            className={classNames(classes.right, searchParams.is_project && classes.selected)}
            sx={{
              '&.MuiButton-contained': {
                backgroundColor: colorPrimary90,
                color: colorPrimary40,
                border: `1px solid ${colorNeutralVariant50}`,
              },
            }}
            onClick={() => {
              setSearchParams((old) => ({ ...old, is_project: true }));
              searchTwitterUsers(
                'Search',
                { ...searchParams, is_project: true },
                {
                  page: 0,
                  rowsPerPage: paginationOptions?.rowsPerPage || 50,
                },
              );
            }}
          >
            Projects
          </Button>
        </Stack>
      </Stack>
      <SearchBar
        data={searchParams.keyword_search}
        disabled={loading}
        onSearchClicked={() =>
          searchTwitterUsers('Search', searchParams, { page: 0, rowsPerPage: paginationOptions?.rowsPerPage || 50 })
        }
        onChange={(data, search) =>
          setSearchParams((old) => {
            const tweet_order_by: OrderBy = { field: OrderByField.TWEET_MENTIONED, order: 'desc' };
            const default_order_by: OrderBy = { field: OrderByField.AVERAGE_TWEET_IMPRESSION, order: 'desc' };
            // switch to tweet mentioned -> auto change sort by to tweet mentioned
            // switch from tweet mentioned to other -> change sort by to default sort
            // since tweet mentioned is not allowed in other type
            const newParams = {
              ...old,
              keyword_search: data,
              order_by:
                data?.type === SearchBarMenu.TWEET_MENTIONED
                  ? tweet_order_by
                  : old.order_by?.field === OrderByField.TWEET_MENTIONED
                  ? default_order_by
                  : old.order_by,
            };
            search &&
              searchTwitterUsers('Apply', newParams, {
                page: 0,
                rowsPerPage: paginationOptions?.rowsPerPage || 50,
              });
            return newParams;
          })
        }
      />
      <Stack spacing={4}>
        <InfluencerFilter
          showTop
          searchParams={searchParams}
          metricsRange={metricsRange$.getValue()}
          categories={searchParams.is_project ? projectCategories$.getValue() : nonProjectCategories$.getValue()}
          languages={languages$.getValue()}
          locations={locations$.getValue()}
          onSearchParamsChanged={(searchParams) => {
            searchTwitterUsers('Apply', searchParams, {
              page: 0,
              rowsPerPage: paginationOptions?.rowsPerPage || 50,
            });
            setSearchParams(searchParams);
          }}
        />
        <Stack className={classes.table} alignItems='center'>
          {twitterUsers.length ? (
            <TwitterUserList
              showLimit={searchLimit !== -1 && twitterUsers.length === 10}
              dataPagination
              orderBy={searchParams.order_by}
              orderByOptions={
                searchParams.keyword_search?.type === SearchBarMenu.TWEET_MENTIONED
                  ? Object.values(OrderByField)
                  : Object.values(OrderByField).filter((field) => field !== OrderByField.TWEET_MENTIONED)
              }
              pageNum={paginationOptions?.page}
              pageNumLimit={searchLimit === 0 ? 0 : undefined}
              defaultRowsPerPage={50}
              rowsPerPageOptions={[50, 100, 200]}
              loading={loading}
              twitterUsers={twitterUsers}
              addedTwitterUsers={addedTwitterUsers}
              totalDataCnt={totalDataCnt}
              onEditOrderBy={(orderBy) => {
                const newSearchParams = { ...searchParams, order_by: orderBy };
                setSearchParams(newSearchParams);
                searchTwitterUsers('Sort', newSearchParams, {
                  page: 0,
                  rowsPerPage: paginationOptions?.rowsPerPage || 10,
                });
              }}
              onAddTwitterUsersToList={(twitterUsers) => {
                setAddTwitterUsers(twitterUsers);
              }}
              onTwitterUserViewClicked={(twitterUser: TwitterUserSearchResult) => {
                track('click', {
                  sub_event: 'influencer_clicked',
                  custom_props: {
                    entry_search_id: searchId,
                    entry_point: 'Search',
                    influencer_id: twitterUser.id,
                    influencer_username: twitterUser.username,
                  },
                });
                setViewTwitterUserId(twitterUser.id);
                setViewCollectionId(undefined);
              }}
              onPageChange={onTwitterUserListPageChange}
            />
          ) : (
            <Stack spacing={3} className={classes.empty} alignItems='center'>
              <img alt='empty' src={Uris.Public.Image.InfluencerMatcher.Loudspeaker} className={classes.img} />
              <Typography variant='h4'>No influencers matching your criteria.</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>

      {addTwitterUsers.length ? (
        <AddUserToCollectionDialog
          source='Search table'
          twitterUsers={addTwitterUsers}
          collections={collections || []}
          onSuccess={(collectionId) => {
            setAddedTwitterUsers((old) => {
              const filteredTwitterUsers = addTwitterUsers.filter(
                (addTwitterUser) => old.findIndex((user) => user.id === addTwitterUser.id) === -1,
              );
              return [...old, ...filteredTwitterUsers];
            });
            setAddTwitterUsers([]);
            refetchCollections$.next(true);
            setViewTwitterUserId(undefined);
            setViewCollectionId(collectionId);
          }}
          onCancel={() => {
            setAddTwitterUsers([]);
          }}
        />
      ) : null}

      {viewTwitterUserId || viewCollectionId ? (
        <ExtensionDrawer
          twitterUserId={viewTwitterUserId}
          collectionId={viewCollectionId}
          onClose={() => {
            setViewTwitterUserId(undefined);
            setViewCollectionId(undefined);
          }}
          onAddTwitterUser={(twitterUser) => setAddTwitterUsers([twitterUser])}
        />
      ) : null}
    </Stack>
  );
};
