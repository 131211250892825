import { Button, OutlinedInput, Stack, Typography } from '@mui/material';
import { CheckCircle, CloseOutlined, ReportProblem } from '@mui/icons-material';
import { DefaultInfluencerThread, InfluencerThread } from 'common/types/Extension/InfluencerCampaign';
import { FC, useCallback, useState } from 'react';

import { CommonDialog } from 'components/dialog/CommonDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { Spinner } from 'components/common/Spinner';
import { TwitterUserCheck } from 'common/types/Extension/TwitterUser';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './AddBulkInfluencersDialog.module.scss';
import { useMessage } from 'components/message/useMessage';
import { v4 as uuidv4 } from 'uuid';

interface AddBulkInfluencersDialogProps {
  includedThreads: InfluencerThread[];
  excludedThreads: InfluencerThread[];
  onAdd?: (threads: InfluencerThread[]) => void;
  onCancel?: () => void;
}

export const AddBulkInfluencersDialog: FC<AddBulkInfluencersDialogProps> = ({
  includedThreads,
  excludedThreads,
  onAdd,
  onCancel,
}) => {
  const { showMessage } = useMessage();
  const [searchString, setSearchString] = useState<string>('');
  const [cachedTwitterUsers, setCachedTwitterUsers] = useState<TwitterUserCheck[]>([]);
  const [checking, setChecking] = useState<boolean>(false);

  const checkTwitterUsersExist = useCallback(
    async (usernames: string[]) => {
      const filterUsernames = usernames.filter((username) =>
        cachedTwitterUsers.every(
          (cachedTwitterUser) => cachedTwitterUser.username?.toLowerCase() !== username.toLowerCase(),
        ),
      );
      if (!filterUsernames.length) return;
      setChecking(true);
      await ExtensionAPI.checkTwitterUserExists(filterUsernames)
        .then(({ status, data }) => {
          if (status === 'success') {
            if (!data?.length) return;
            const dedupData = data.filter(
              (d, i, a) => a.findIndex((d2) => d.username?.toLowerCase() === d2.username?.toLowerCase()) === i,
            );
            setCachedTwitterUsers((old) => [
              ...old,
              ...dedupData
                .filter((d) => includedThreads.every((thread) => thread.receiver_id !== d.id))
                .map((d) => ({
                  ...d,
                  exclude: excludedThreads.findIndex((thread) => thread.receiver_id === d.id) > -1,
                })),
            ]);
            return;
          }
          showMessage(`Check twitter users exist failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        })
        .finally(() => {
          setChecking(false);
        });
    },
    [showMessage, cachedTwitterUsers, includedThreads, excludedThreads],
  );

  return (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={1} alignItems='center' className={classes.dialog}>
          <Typography variant='h6' className={classes.title}>
            Add Bulk Influencers
          </Typography>
          <Typography className={classes.subtitle}>
            Copy and paste @username separated by a comma or a new line.
          </Typography>
          <Stack className={classes.container} spacing={1}>
            <Stack className={classes.chips} direction='row'>
              {cachedTwitterUsers.map((cachedTwitterUser) => {
                return (
                  <Stack
                    className={classNames(
                      classes.chip,
                      (cachedTwitterUser.exclude || !cachedTwitterUser.exist) && classes.notExist,
                    )}
                    key={cachedTwitterUser.username}
                    direction='row'
                    alignItems='center'
                    spacing={1}
                  >
                    <Typography className={classes.text} variant='label1'>
                      @{cachedTwitterUser.username}
                    </Typography>
                    <CloseOutlined
                      className={classes.cancel}
                      onClick={() => {
                        setCachedTwitterUsers((old) => [
                          ...old.filter((twitterUser) => twitterUser.username !== cachedTwitterUser.username),
                        ]);
                      }}
                    />
                  </Stack>
                );
              })}
            </Stack>
            <OutlinedInput
              value={searchString}
              endAdornment={
                checking ? (
                  <Stack>
                    <Spinner size={24} />
                  </Stack>
                ) : null
              }
              placeholder='Type username here'
              onPaste={(e) => {
                const rawText = e.clipboardData.getData('Text').replaceAll('@', '');
                const usernames = Array.from(
                  new Set(rawText.split(/[,\n]+/).map((username) => username.trim().toLowerCase())),
                ).filter(
                  (username) =>
                    username.length &&
                    cachedTwitterUsers.every(
                      (cachedTwitterUser) => cachedTwitterUser.username?.toLowerCase() !== username.toLowerCase(),
                    ),
                );
                setSearchString('');
                e.preventDefault();

                if (!usernames.length) return;
                checkTwitterUsersExist(usernames);
              }}
              onKeyDown={(e) => {
                if (e.key !== 'Enter' && e.key !== ',' && e.key !== ' ') return;
                const username = searchString.replaceAll('@', '').trim();
                setSearchString('');
                e.preventDefault();

                if (!username.length) return;
                if (cachedTwitterUsers.some((cachedTwitterUser) => cachedTwitterUser.username === username)) return;
                checkTwitterUsersExist([username]);
              }}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
            />
            <Stack spacing={0.5}>
              {cachedTwitterUsers.filter((cachedTwitterUser) => !cachedTwitterUser.exclude && cachedTwitterUser.exist)
                .length ? (
                <Stack direction='row' spacing={1} alignItems='center'>
                  <CheckCircle fontSize='small' className={classes.success} />
                  <Typography>
                    <span className={classes.success}>
                      {
                        cachedTwitterUsers.filter(
                          (cachedTwitterUser) => !cachedTwitterUser.exclude && cachedTwitterUser.exist,
                        ).length
                      }
                    </span>{' '}
                    @username valid
                  </Typography>
                </Stack>
              ) : null}
              {cachedTwitterUsers.filter((cachedTwitterUser) => cachedTwitterUser.exclude || !cachedTwitterUser.exist)
                .length ? (
                <Stack direction='row' spacing={1} alignItems='flex-start'>
                  <ReportProblem fontSize='small' className={classes.failed} />
                  <Stack spacing={1}>
                    {cachedTwitterUsers.filter(
                      (cachedTwitterUser) => !cachedTwitterUser.exclude && !cachedTwitterUser.exist,
                    ).length ? (
                      <Stack>
                        <Typography>
                          <span className={classes.failed}>
                            {
                              cachedTwitterUsers.filter(
                                (cachedTwitterUser) => !cachedTwitterUser.exclude && !cachedTwitterUser.exist,
                              ).length
                            }{' '}
                            out of {cachedTwitterUsers.length}
                          </span>{' '}
                          @username are invalid in the database.
                        </Typography>
                        <Typography>
                          You can add them via{' '}
                          <span
                            className={classes.link}
                            onClick={() => window.open(Uris.External.SupportTicket, '_blank')}
                          >
                            support ticket
                          </span>
                          .
                        </Typography>
                      </Stack>
                    ) : null}
                    {cachedTwitterUsers.filter((cachedTwitterUser) => cachedTwitterUser.exclude).length ? (
                      <Stack>
                        <Typography>
                          <span className={classes.failed}>
                            {cachedTwitterUsers.filter((cachedTwitterUser) => cachedTwitterUser.exclude).length} out of{' '}
                            {cachedTwitterUsers.length}
                          </span>{' '}
                          @username have already been added in the campaign.
                        </Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      }
      footer={
        <Stack direction='row-reverse' spacing={1} className={classes.actions}>
          <Button
            variant='contained'
            disabled={
              !cachedTwitterUsers.filter((cachedTwitterUser) => cachedTwitterUser.exist && !cachedTwitterUser.exclude)
                .length
            }
            onClick={() => {
              onAdd?.(
                cachedTwitterUsers
                  .filter((cachedTwitterUser) => cachedTwitterUser.exist && !cachedTwitterUser.exclude)
                  .map((user) => ({
                    ...DefaultInfluencerThread,
                    id: uuidv4(),
                    receiver_id: user.id,

                    receiver: {
                      id: user.id,
                      username: user.username,
                      display_name: user.display_name,
                      profile_image_url: user.profile_image_url,
                      verified: user.verified,
                      verified_type: user.verified_type,
                      follower_count: user.follower_count,
                      following_count: user.following_count,
                    },
                  })),
              );
            }}
          >
            Save
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              onCancel?.();
            }}
          >
            Cancel
          </Button>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  );
};
