import { Dialog, DialogProps, DialogTitle, IconButton, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TwitterAdsAccount, TwitterAuth } from 'common/types/TwitterAds';
import { colorPrimary40, colorSurface1 } from 'common/params';

import ClientAPI from 'common/ClientAPI';
import { CloseOutlined } from '@mui/icons-material';
import { DeleteConfirm } from './content/DeleteConfirm';
import { EmptyAuth } from './content/EmptyAuth';
import { ManageAuth } from './content/ManageAuth';
import { SetDefaultAccount } from './content/SetDefaultAccount';
import { SetDefaultAuth } from './content/SetDefaultAuth';
import { SetSuccess } from './content/SetSuccess';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classes from './TwitterAdsSettingDialog.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';

interface TwitterAdsSettingDialogProps extends DialogProps {
  defaultAuth?: TwitterAuth;
  onDialogClose?: () => void;
}

enum TwitterAdsStep {
  SET_DEFAULT_AUTH = 'setDefaultAuth',
  SET_DEFAULT_ACCOUNT = 'setDefaultAccount',
  SET_SUCCESS = 'setSuccess',
  MANAGE_AUTH = 'manageAuth',
  CONFIRM_DELETE = 'confirmDelete',
  EMPTY_AUTH = 'emptyAuth',
}

export const TwitterAdsSettingDialog: FC<TwitterAdsSettingDialogProps> = ({ defaultAuth, onDialogClose, ...props }) => {
  const { showMessage } = useMessage();
  const navigate = useNavigate();

  const [selectedAuth, setSelectedAuth] = useState<TwitterAuth | undefined>(undefined);
  const [deletedAuth, setDeletedAuth] = useState<TwitterAuth | undefined>(undefined);
  const [selectedAccount, setSelectedAccount] = useState<TwitterAdsAccount | undefined>(undefined);
  const [step, setStep] = useState<TwitterAdsStep>(TwitterAdsStep.SET_DEFAULT_AUTH);
  const [refetch, setRefetch] = useState<boolean>(false);

  const {
    loading,
    error,
    value: auths,
  } = useAsync(async () => {
    return (await ClientAPI.getTwitterAuths()).data;
  }, [refetch]);

  useEffect(() => {
    const auth = auths?.find((auth) => auth.id === defaultAuth?.id);

    if (auth) {
      // find default auth -> set it if current no selected auth
      setSelectedAuth((old) => (old ? old : auth));
      return;
    }

    // no default auth -> set the first one in array
    if (auths?.length) setSelectedAuth(auths[0]);

    // array is empty, show twitter auth empty screen
    if (auths && !auths.length) setStep(TwitterAdsStep.EMPTY_AUTH);
  }, [defaultAuth, auths]);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const updateUserPreference = useCallback(
    async (authId: string, accountId: string) => {
      await ClientAPI.updateDefaultTwitterAuthAccount(authId, accountId)
        .then(({ status, message }) => {
          if (status === 'success') {
            setStep(TwitterAdsStep.SET_SUCCESS);
            return;
          }
          showMessage(`Update user preference failed, ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    },
    [showMessage],
  );

  const deleteTwitterAuth = useCallback(
    async (auth: TwitterAuth) => {
      await ClientAPI.deleteTwitterAuth(auth.id)
        .then(({ status, message }) => {
          if (status === 'success') {
            setRefetch((old) => !old);
            setDeletedAuth(undefined);
            // need to clear selected auth
            setSelectedAuth(undefined);
            setStep(TwitterAdsStep.MANAGE_AUTH);
            return;
          }
          showMessage(`Delete twitter auth failed, ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    },
    [showMessage],
  );

  const onBtnManageAuthClicked = useCallback((auth: TwitterAuth) => {
    setStep(TwitterAdsStep.MANAGE_AUTH);
  }, []);

  const content = useMemo(() => {
    // fetching auths -> return spinner
    if (loading)
      return (
        <Stack alignItems='center' className={classes.spinner}>
          <Spinner />
        </Stack>
      );
    switch (step) {
      case TwitterAdsStep.SET_DEFAULT_AUTH:
        return (
          <SetDefaultAuth
            auths={auths || []}
            defaultAuth={selectedAuth}
            onBtnCancelClicked={onDialogClose}
            onBtnManageAuthClicked={onBtnManageAuthClicked}
            onBtnNextClicked={(auth: TwitterAuth) => {
              setSelectedAuth(auth);
              setStep(TwitterAdsStep.SET_DEFAULT_ACCOUNT);
            }}
          />
        );
      case TwitterAdsStep.SET_DEFAULT_ACCOUNT:
        if (!selectedAuth) return null;
        return (
          <SetDefaultAccount
            auth={selectedAuth}
            onBtnBackClicked={() => {
              setStep(TwitterAdsStep.SET_DEFAULT_AUTH);
            }}
            onBtnNextClicked={(account: TwitterAdsAccount) => {
              setSelectedAccount(account);
              updateUserPreference(selectedAuth.id, account.id);
            }}
          />
        );
      case TwitterAdsStep.SET_SUCCESS:
        if (!selectedAuth || !selectedAccount) return null;
        return (
          <SetSuccess
            auth={selectedAuth}
            account={selectedAccount}
            onBtnCloseClicked={onDialogClose}
            onBtnGettingStartedClicked={() => {
              navigate(Uris.Pages.AdsAudience.Index);
            }}
          />
        );
      case TwitterAdsStep.MANAGE_AUTH:
        return (
          <ManageAuth
            auths={auths || []}
            onBtnBackClicked={() => {
              setStep(TwitterAdsStep.SET_DEFAULT_AUTH);
            }}
            onBtnDeleteClicked={(auth: TwitterAuth) => {
              setDeletedAuth(auth);
              setStep(TwitterAdsStep.CONFIRM_DELETE);
            }}
          />
        );
      case TwitterAdsStep.CONFIRM_DELETE:
        if (!deletedAuth) return null;
        return (
          <DeleteConfirm
            auth={deletedAuth}
            onBtnCancelClicked={() => {
              setDeletedAuth(undefined);
              setStep(TwitterAdsStep.MANAGE_AUTH);
            }}
            onBtnDeleteClicked={(auth: TwitterAuth) => {
              deleteTwitterAuth(auth);
            }}
          />
        );
      case TwitterAdsStep.EMPTY_AUTH:
        return (
          <EmptyAuth
            onBtnCancelClicked={() => {
              onDialogClose?.();
            }}
          />
        );
      default:
        return null;
    }
  }, [
    loading,
    auths,
    step,
    selectedAuth,
    selectedAccount,
    deletedAuth,
    deleteTwitterAuth,
    navigate,
    updateUserPreference,
    onBtnManageAuthClicked,
    onDialogClose,
  ]);

  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onDialogClose?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onDialogClose}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.content}>{content}</Stack>
    </Dialog>
  );
};
