import { FC, useCallback } from 'react';

import { AssetTypeSelect } from 'components/select/AssetSelect';
import { CohortAssetFilterOperand } from 'common/types/CohortFilter';
import { Expression } from 'common/types/Expression';
import { ExpressionSelect } from 'components/select/ExpressionSelect';
import { Stack } from '@mui/material';

export interface AssetPortfolioOperandProps {
  data: CohortAssetFilterOperand;
  disabled?: boolean;
  onChange?: (data: CohortAssetFilterOperand) => void;
}

export const AssetPortfolioOperand: FC<AssetPortfolioOperandProps> = ({ data, disabled, onChange }) => {
  const onAssetTypesChange = useCallback(
    (chain: string, tokens: string[], all_tokens?: boolean) => {
      const newData = { ...data, chain, tokens };
      if (all_tokens) newData.all_tokens = all_tokens;
      else if ('all_tokens' in newData) {
        delete newData.all_tokens;
      }
      onChange?.(newData);
    },
    [data, onChange],
  );
  const onExpressionChange = useCallback(
    (expression: Expression) => {
      onChange?.({ ...data, expression });
    },
    [data, onChange],
  );
  return (
    <Stack direction='column' spacing={1}>
      <AssetTypeSelect
        chain={data.chain}
        tokens={data.tokens}
        allTokens={data.all_tokens}
        disabled={disabled}
        onChange={onAssetTypesChange}
      />
      <ExpressionSelect data={data.expression} disabled={disabled} onChange={onExpressionChange} />
    </Stack>
  );
};
