export enum Scheduler {
  DAILY = 'd',
  WEEKLY = 'w',
  ONCE = 'o',
}

export const DefaultSchedulerData = Scheduler.ONCE;

export const SchedulerNameMap = new Map<string, string>([
  [Scheduler.DAILY, 'Daily'],
  [Scheduler.WEEKLY, 'Weekly'],
  [Scheduler.ONCE, 'Once'],
]);
