import { Button, Stack, Typography } from '@mui/material';

import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import { WarningAmberOutlined } from '@mui/icons-material';
import classes from './ExportFail.module.scss';

interface ExportFailProps {
  failReason: string;
  onBtnCloseClicked?: () => void;
  onBtnReconnectClicked?: () => void;
}

export const ExportFail: FC<ExportFailProps> = ({ failReason, onBtnCloseClicked, onBtnReconnectClicked }) => {
  return (
    <Stack className={classes.root} spacing={1} alignItems='center'>
      <Stack spacing={3} className={classes.content}>
        <Stack alignItems='center' spacing={2}>
          <CommonChip>
            <WarningAmberOutlined fontSize='large' />
          </CommonChip>
          <Stack spacing={1} className={classes.textContainer}>
            <Typography className={classes.text}>Export fail: {failReason}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
        <Button variant='outlined' onClick={onBtnCloseClicked}>
          Close
        </Button>
        <Button variant='contained' onClick={onBtnReconnectClicked}>
          Reconnect
        </Button>
      </Stack>
    </Stack>
  );
};
