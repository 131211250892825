import { AverageTweetImpressionFilter, getValueFilterString } from 'common/types/Extension/InfluencerFilter';
import { Collapse, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

import { InfoOutlined } from '@mui/icons-material';
import { MetricsRange } from 'common/types/Extension/TwitterUserSearchResult';
import { MinMaxValueInput } from 'components/input/MinMaxValueInput';
import { PercentilesValueInput } from 'components/input/PercentilesValueInput';
import { TopValueInput } from 'components/input/TopValueInput';
import classes from './AverageTweetImpressionFilter.module.scss';
import { numberToAbbreviation } from 'common/utils';

interface AverageTweetImpressionFilterCollapseProps {
  showTop?: boolean;
  data?: AverageTweetImpressionFilter;
  metricsRange?: MetricsRange;
  onChange?: (data?: AverageTweetImpressionFilter) => void;
}

export const AverageTweetImpressionFilterCollapse: FC<AverageTweetImpressionFilterCollapseProps> = ({
  showTop,
  data,
  metricsRange,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack className={classes.root}>
      <Stack
        direction='row'
        className={classes.title}
        justifyContent='space-between'
        onClick={() => setOpen((old) => !old)}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <Typography>Avg. Tweet Impression</Typography>
            <Tooltip title='Average impressions in the past 90 days.' placement='top'>
              <InfoOutlined fontSize='small' />
            </Tooltip>
          </Stack>
          <Typography className={classes.value} variant='label1'>
            {getValueFilterString(data, numberToAbbreviation)}
          </Typography>
        </Stack>
        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Stack>
      <Collapse in={open} timeout='auto'>
        <Stack spacing={3}>
          <Divider className={classes.divider} />
          <Stack spacing={3} className={classes.input}>
            <MinMaxValueInput
              min={data?.min}
              max={data?.max}
              minLimit={metricsRange?.avg_tweet_impr_min}
              maxLimit={metricsRange?.avg_tweet_impr_max}
              onChange={(min, max) => {
                if (min === undefined && max === undefined) {
                  onChange?.(undefined);
                  return;
                }
                onChange?.({ ...data, min: min, max: max, top: undefined, percentiles: undefined });
              }}
            />
            {showTop ? (
              <TopValueInput
                top={typeof data?.top === 'number' ? data.top * 100 : undefined}
                onChange={(top) => {
                  if (top === undefined) {
                    onChange?.(undefined);
                    return;
                  }
                  onChange?.({
                    ...data,
                    min: undefined,
                    max: undefined,
                    top: top / 100,
                    percentiles: undefined,
                  });
                }}
              />
            ) : null}
            <Divider className={classes.divider} />
            <PercentilesValueInput
              percentiles={data?.percentiles}
              onChange={(percentiles) => {
                if (percentiles === undefined) {
                  onChange?.(undefined);
                  return;
                }
                onChange?.({
                  ...data,
                  min: undefined,
                  max: undefined,
                  top: undefined,
                  percentiles: percentiles,
                });
              }}
            />
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
