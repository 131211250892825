import {
  AddOutlined,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  CheckOutlined,
  CloseOutlined,
  OutboundOutlined,
} from '@mui/icons-material';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import ExtensionAPI from 'common/ExtensionAPI';
import { Growing3Icon } from 'components/icons/Growing3Icon';
import { Spinner } from 'components/common/Spinner';
import { TwitterCookie } from 'common/types/Extension/InfluencerCampaign';
import { Uris } from 'Uris';
import classes from './CampaignCookieEdit.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

interface CampaignCookieEditProps {
  draft?: boolean;
  saving?: boolean;
  onNextBtnClicked?: () => void;
  onBackBtnClicked?: () => void;
  onSaveBtnClicked?: () => void;
  onCampaignCookieEdit?: (cookie: TwitterCookie) => void;
}

export const CampaignCookieEdit: FC<CampaignCookieEditProps> = ({
  draft,
  saving,
  onNextBtnClicked,
  onBackBtnClicked,
  onSaveBtnClicked,
  onCampaignCookieEdit,
}) => {
  const { showMessage } = useMessage();

  const [showAddCookie, setShowAddCookie] = useState<boolean>(false);
  const [selectedCookie, setSelectedCookie] = useState<TwitterCookie | undefined>(undefined);
  const [cookieAvailable, setCookieAvailable] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string>('');
  const [ct0, setCt0] = useState<string>('');
  const [addedCookie, setAddedCookie] = useState<TwitterCookie | undefined>(undefined);
  const [cookieChecking, setCookieChecking] = useState<boolean>(false);
  const [cookieAdding, setCookieAdding] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const { value: cookies } = useAsync(async () => {
    return (await ExtensionAPI.getTwitterCookies()).data;
  }, [refetch]);

  const checkCookieAvailable = useCallback(
    async (cookie?: TwitterCookie) => {
      setCookieAvailable(false);
      setSelectedCookie(cookie);
      if (!cookie) return;

      setCookieChecking(true);
      await ExtensionAPI.checkTwitterCookie(cookie)
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            setCookieAvailable(true);
            onCampaignCookieEdit?.(cookie);
            return;
          }
          showMessage(`Check twitter cookie available failed: ${status}`, 'error');
        })
        .catch((error) => {
          console.error(error instanceof Error ? error.message : 'Unknow Error');
          showMessage('Cookie has expired or does not exist. Please get a new one.', 'error');
        })
        .finally(() => {
          setCookieChecking(false);
        });
    },
    [showMessage, onCampaignCookieEdit],
  );

  useEffect(() => {
    if (!addedCookie) return;
    if (!cookies?.length) return;
    const cookie = cookies.find((cookie) => cookie.user_id === addedCookie.user_id);
    if (!cookie) return;
    setSelectedCookie((old) => {
      // new cookie same as old cookie -> re-check cookie available
      if (old?.user_id === cookie?.user_id) {
        checkCookieAvailable({ ...addedCookie, id: old?.id });
      } else {
        checkCookieAvailable(cookie);
      }
      return cookie;
    });
    setAddedCookie(undefined);
  }, [addedCookie, cookies, checkCookieAvailable]);

  useEffect(() => {
    if (!cookies?.length) return;
    if (selectedCookie) return;
    checkCookieAvailable(cookies[0]);
  }, [cookies, selectedCookie, checkCookieAvailable]);

  const addTwiiterCookie = useCallback(
    async (cookieStr: string) => {
      setCookieAdding(true);
      await ExtensionAPI.createTwitterCookie(cookieStr)
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            setRefetch((old) => !old);
            showMessage('Add twitter cookie success');
            setAddedCookie(data);
            setAuthToken('');
            setCt0('');
            return;
          }
          showMessage(`Add twitter cookie failed: ${status}`, 'error');
        })
        .catch((error) => {
          console.error(error instanceof Error ? error.message : 'Unknow Error');
          showMessage('Cookie has expired or does not exist. Please get a new one.', 'error');
        })
        .finally(() => {
          setCookieAdding(false);
        });
    },
    [showMessage],
  );

  const cookieStrValid = useMemo(() => {
    return !!authToken && !!ct0;
  }, [authToken, ct0]);

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>Connect Account</Typography>
        <Stack direction='row' spacing={1}>
          {/* show save btn only in draft mode */}
          {draft ? (
            <Button variant='outlined' disabled={saving} onClick={onSaveBtnClicked}>
              Save and exit
            </Button>
          ) : null}
          {!draft && onBackBtnClicked ? (
            <Button variant='outlined' disabled={saving} startIcon={<ArrowBackOutlined />} onClick={onBackBtnClicked}>
              Back
            </Button>
          ) : null}
          <Button
            variant='contained'
            disabled={!cookieAvailable || saving}
            startIcon={<ArrowForwardOutlined />}
            onClick={onNextBtnClicked}
          >
            Next
          </Button>
        </Stack>
      </Stack>
      <Stack id='area-create-campaign-connect-twitter' spacing={3} className={classes.container}>
        <Typography variant='h6'>Your Twitter session cookie</Typography>
        <Typography>
          Every time you log into Twitter on your browser, a new cookie is created for that “session”. If you log out or
          are disconnected, the cookie expires.
        </Typography>
        <Stack spacing={3}>
          {cookies?.length ? (
            <Stack spacing={3}>
              <Select
                className={classes.select}
                value={selectedCookie?.id || ''}
                onChange={(e) => {
                  const cookie = cookies.find((cookie) => cookie.id === e.target.value);
                  checkCookieAvailable(cookie);
                }}
                renderValue={(_) => {
                  if (!selectedCookie) return <></>;
                  return (
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Typography>
                        {selectedCookie.username} - {selectedCookie.user_id}
                      </Typography>
                      {cookieChecking ? (
                        <Stack direction='row'>
                          <Spinner size={24} />
                        </Stack>
                      ) : cookieAvailable ? (
                        <CheckOutlined color='success' />
                      ) : (
                        <CloseOutlined color='error' />
                      )}
                    </Stack>
                  );
                }}
              >
                {cookies.map((cookie) => (
                  <MenuItem key={cookie.id} value={cookie.id}>
                    {cookie.username} - {cookie.user_id}
                  </MenuItem>
                ))}
              </Select>
              <Stack direction='row'>
                <Button
                  variant='outlined'
                  startIcon={<AddOutlined />}
                  onClick={() => {
                    setShowAddCookie(true);
                  }}
                >
                  Add New
                </Button>
              </Stack>
            </Stack>
          ) : null}
          {!cookies?.length || showAddCookie ? (
            <Stack spacing={1}>
              <Stack direction='row' spacing={3} alignItems='center'>
                <FormControl className={classes.input}>
                  <InputLabel>auth_token</InputLabel>
                  <OutlinedInput label='auth_token' value={authToken} onChange={(e) => setAuthToken(e.target.value)} />
                </FormControl>
                <FormControl className={classes.input}>
                  <InputLabel>ct0</InputLabel>
                  <OutlinedInput label='ct0' value={ct0} onChange={(e) => setCt0(e.target.value)} />
                </FormControl>
                <Tooltip title={cookieAdding ? 'Connecting' : !cookieStrValid ? 'Cookie format not valid' : ''}>
                  <span>
                    <Button
                      variant='contained'
                      disabled={cookieAdding || !cookieStrValid}
                      onClick={() => {
                        addTwiiterCookie(`auth_token=${authToken};ct0=${ct0};`);
                      }}
                    >
                      Connect
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
              <Stack>
                <FormHelperText>
                  1. If you have the Growing3 extension installed: Click on the "Clone" button.
                </FormHelperText>
                <FormHelperText>2. If you don't have the Growing3 extension:</FormHelperText>
                <FormHelperText>
                  &nbsp;&nbsp;&nbsp;&nbsp;a. Manually find your cookies by searching for the name "auth_token” and
                  “ct0="
                </FormHelperText>
                <FormHelperText>
                  &nbsp;&nbsp;&nbsp;&nbsp;b. Copy "auth_token" and "ct0". Paste the values into a text editors
                </FormHelperText>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
        <Stack
          className={classes.extension}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
        >
          <Stack direction='row' spacing={2} alignItems='center'>
            <Stack className={classes.icon}>
              <Growing3Icon />
            </Stack>
            <Typography>Install our extension to get all your cookies instantly</Typography>
          </Stack>
          <Button
            variant='outlined'
            startIcon={<OutboundOutlined />}
            onClick={() => window.open(Uris.External.Extension, '_blank')}
          >
            Install the extension
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
