import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { colorNeutralVariant20, colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import ExtensionAPI from 'common/ExtensionAPI';
import { InfluencerPostLink } from 'common/types/Extension/InfluencerCampaign';
import classes from './DeletePostLinkDialog.module.scss';
import { useMessage } from 'components/message/useMessage';

interface DeletePostLinkDialogProps extends DialogProps {
  postLink: InfluencerPostLink;
  onDelete?: () => void;
  onCancel?: () => void;
}

export const DeletePostLinkDialog: FC<DeletePostLinkDialogProps> = ({
  open,
  postLink,
  onDelete,
  onCancel,
  ...props
}) => {
  const { showMessage } = useMessage();

  const deletePostLink = useCallback(async () => {
    await ExtensionAPI.deleteInfluencerCampaignPostLink(postLink)
      .then(({ status, data }) => {
        if (status === 'success' && data) {
          onDelete?.();
          return;
        }
        showMessage(`Delete post link failed: ${status}`, 'error');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      });
  }, [postLink, showMessage, onDelete]);

  const onBtnDeleteClicked = useCallback(() => {
    deletePostLink();
  }, [deletePostLink]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <DeleteOutlineOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Delete Post
              </Typography>
              <Typography variant='body1' textAlign='center' color={colorNeutralVariant20}>
                Deleting the <span className={classes.highlight}>"{postLink.name}"</span> is irreversible.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='contained' onClick={onBtnDeleteClicked}>
            Delete
          </Button>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
