import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import { FC } from 'react';
import classes from './AdjustCriteriaDialog.module.scss';

interface AdjustCriteriaDialogProps extends DialogProps {
  onEdit?: () => void;
  onCancel?: () => void;
}

export const AdjustCriteriaDialog: FC<AdjustCriteriaDialogProps> = ({ onEdit, onCancel, ...props }) => {
  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onCancel?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onCancel}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content} alignItems='center'>
          <CommonChip>
            <WarningAmberOutlined />
          </CommonChip>
          <Stack spacing={1} alignItems='center'>
            <Typography variant='h6'>Adjust the filter criteria</Typography>
            <Typography className={classes.subtitle} variant='body1'>
              Ensure the selected wallet address amount is less than 30 million
            </Typography>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='contained' onClick={() => onEdit?.()}>
            Edit
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
