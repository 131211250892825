import { Dialog, DialogProps, DialogTitle, IconButton, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IntegrationAuth, IntegrationAuthProvider } from 'common/types/IntegrationAuth';
import { colorPrimary40, colorSurface1 } from 'common/params';

import ClientAPI from 'common/ClientAPI';
import { CloseOutlined } from '@mui/icons-material';
import { Collection } from 'common/types/Extension/Collection';
import { DeleteConfirm } from 'components/GoogleDrive/setting/content/DeleteConfirm';
import { EmptyAuth } from 'components/GoogleDrive/setting/content/EmptyAuth';
import { ExportFail } from './content/ExportFail';
import { ExportSuccess } from './content/ExportSuccess';
import { GoogleSheet } from 'common/types/GoogleDrive';
import { ManageAuth } from 'components/GoogleDrive/setting/content/ManageAuth';
import { SelectAuth } from 'components/GoogleDrive/export/content/SelectAuth';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classes from './GoogleDriveExportDialog.module.scss';
import { setCookie } from 'common/utils';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

interface GoogleDriveExportDialogProps extends DialogProps {
  collection: Collection;
  defaultAuth?: IntegrationAuth;
  onDialogClose?: (success?: boolean) => void;
}

enum GoogleDriveExportStep {
  SELECTT_DEFAULT_AUTH = 'selectDefaultAuth',
  EXPORT_SUCCESS = 'exportSuccess',
  EXPORT_FAIL = 'exportFail',
  MANAGE_AUTH = 'manageAuth',
  CONFIRM_DELETE = 'confirmDelete',
  EMPTY_AUTH = 'emptyAuth',
}

export const GoogleDriveExportDialog: FC<GoogleDriveExportDialogProps> = ({
  collection,
  defaultAuth,
  onDialogClose,
  ...props
}) => {
  const { showMessage } = useMessage();

  const [selectedAuth, setSelectedAuth] = useState<IntegrationAuth | undefined>(undefined);
  const [deletedAuth, setDeletedAuth] = useState<IntegrationAuth | undefined>(undefined);
  const [step, setStep] = useState<GoogleDriveExportStep>(GoogleDriveExportStep.SELECTT_DEFAULT_AUTH);
  const [sheet, setSheet] = useState<GoogleSheet | undefined>(undefined);
  const [failReason, setFailReason] = useState<string>('');
  const [exporting, setExporting] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const {
    loading,
    error,
    value: auths,
  } = useAsync(async () => {
    return (await ClientAPI.getIntegrationAuths(IntegrationAuthProvider.GOOGLE_DRIVE)).data;
  }, [refetch]);

  useEffect(() => {
    const auth = auths?.find((auth) => auth.id === defaultAuth?.id);

    if (auth) {
      // find default auth -> set it if current no selected auth
      setSelectedAuth((old) => (old ? old : auth));
      return;
    }

    // no default auth -> set the first one in array
    if (auths?.length) setSelectedAuth(auths[0]);

    // array is empty, show google drive auth empty screen
    if (auths && !auths.length) setStep(GoogleDriveExportStep.EMPTY_AUTH);
  }, [defaultAuth, auths]);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const exportToGooleDrive = useCallback(
    async (authId: string) => {
      if (typeof collection.id !== 'number') return;
      setExporting(true);

      const now = new Date();
      // Extract components
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
      const date = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');

      const sheetName = `${collection.name}_${year}${month}${date}-${hours}${minutes}`;
      await ClientAPI.exportCollectionToGoogleDrive(collection.id, sheetName, authId)
        .then(({ status, message, data }) => {
          if (status === 'success' && data) {
            setSheet(data);
            setStep(GoogleDriveExportStep.EXPORT_SUCCESS);
            return;
          }
          setStep(GoogleDriveExportStep.EXPORT_FAIL);
          setFailReason(message || 'unknown error');
        })
        .catch((error) => {
          setStep(GoogleDriveExportStep.EXPORT_FAIL);
          setFailReason(error.message || 'unknown error');
        })
        .finally(() => {
          setExporting(false);
        });
    },
    [collection],
  );

  const reconnectAccount = useCallback(async () => {
    // no default auth -> redirect user to google drive authorization page
    await ClientAPI.integrationOauth2(IntegrationAuthProvider.GOOGLE_DRIVE)
      .then(({ data: oauth_url }) => {
        if (!oauth_url) return;
        setCookie(
          'redirect_url',
          `${window.location.origin}${window.location.pathname}?tab=collections&collectionId=${collection.id}`,
        );
        window.location.href = oauth_url;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      });
  }, [collection, showMessage]);

  const deleteIntegrationAuth = useCallback(
    async (auth: IntegrationAuth) => {
      await ClientAPI.deleteIntegrationAuth(IntegrationAuthProvider.GOOGLE_DRIVE, auth.id)
        .then(({ status, message }) => {
          if (status === 'success') {
            setRefetch((old) => !old);
            setDeletedAuth(undefined);
            // need to clear selected auth
            setSelectedAuth(undefined);
            setStep(GoogleDriveExportStep.MANAGE_AUTH);
            return;
          }
          showMessage(`Delete google drive auth failed, ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
        });
    },
    [showMessage],
  );

  const onBtnManageAuthClicked = useCallback((auth: IntegrationAuth) => {
    setStep(GoogleDriveExportStep.MANAGE_AUTH);
  }, []);

  const content = useMemo(() => {
    // fetching auths -> return spinner
    if (loading)
      return (
        <Stack alignItems='center' className={classes.spinner}>
          <Spinner />
        </Stack>
      );
    switch (step) {
      case GoogleDriveExportStep.SELECTT_DEFAULT_AUTH:
        return (
          <SelectAuth
            exporting={exporting}
            auths={auths || []}
            defaultAuth={selectedAuth}
            onBtnCancelClicked={onDialogClose}
            onBtnManageAuthClicked={onBtnManageAuthClicked}
            onBtnSaveClicked={(auth: IntegrationAuth) => {
              exportToGooleDrive(auth.id);
            }}
          />
        );
      case GoogleDriveExportStep.EXPORT_SUCCESS:
        if (!sheet) return <></>;
        return (
          <ExportSuccess
            sheet={sheet}
            onBtnCloseClicked={() => onDialogClose?.(true)}
            onBtnViewListClicked={() => {
              window.open(`${Uris.External.GoogleSheet}/d/${sheet.id}`, '_blank');
            }}
          />
        );
      case GoogleDriveExportStep.EXPORT_FAIL:
        if (!failReason) return <></>;
        return (
          <ExportFail
            failReason={failReason}
            onBtnCloseClicked={onDialogClose}
            onBtnReconnectClicked={() => {
              reconnectAccount();
            }}
          />
        );
      case GoogleDriveExportStep.MANAGE_AUTH:
        return (
          <ManageAuth
            urlParam={`tab=collections&collectionId=${collection.id}`}
            auths={auths || []}
            onBtnBackClicked={() => {
              setStep(GoogleDriveExportStep.SELECTT_DEFAULT_AUTH);
            }}
            onBtnDeleteClicked={(auth: IntegrationAuth) => {
              setDeletedAuth(auth);
              setStep(GoogleDriveExportStep.CONFIRM_DELETE);
            }}
          />
        );
      case GoogleDriveExportStep.CONFIRM_DELETE:
        if (!deletedAuth) return null;
        return (
          <DeleteConfirm
            auth={deletedAuth}
            onBtnCancelClicked={() => {
              setDeletedAuth(undefined);
              setStep(GoogleDriveExportStep.MANAGE_AUTH);
            }}
            onBtnDeleteClicked={(auth: IntegrationAuth) => {
              deleteIntegrationAuth(auth);
            }}
          />
        );
      case GoogleDriveExportStep.EMPTY_AUTH:
        return (
          <EmptyAuth
            onBtnCancelClicked={() => {
              onDialogClose?.();
            }}
          />
        );
      default:
        return null;
    }
  }, [
    loading,
    exporting,
    collection,
    auths,
    step,
    selectedAuth,
    deletedAuth,
    sheet,
    failReason,
    reconnectAccount,
    exportToGooleDrive,
    deleteIntegrationAuth,
    onBtnManageAuthClicked,
    onDialogClose,
  ]);

  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onDialogClose?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={() => onDialogClose?.()}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.content}>{content}</Stack>
    </Dialog>
  );
};
