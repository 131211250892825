import { Avatar, Chip, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { redirectToExternalPage, setCookie } from 'common/utils';

import { Uris } from 'Uris';
import { User } from 'common/types/User';
import classes from './UserAvatar.module.scss';
import { useNavigate } from 'react-router-dom';

interface UserAvatarProps {
  user: User;
  showLogoutOnly?: boolean;
}

export const UserAvatar: FC<UserAvatarProps> = ({ user, showLogoutOnly }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = useCallback(async () => {
    redirectToExternalPage(Uris.External.Logout, window.location.origin);
  }, []);

  return (
    <>
      <Chip
        className={classes.root}
        avatar={
          <Avatar alt='Avatar' className={classes.avatar} src={user.image_url || Uris.Public.Image.Header.Avatar} />
        }
        label={
          <Tooltip
            title={
              <Stack>
                <span>{user.name}</span>
                <span>{user.wallets?.length ? user.wallets[0].wallet_address : null}</span>
              </Stack>
            }
            placement='bottom'
            arrow
          >
            <Stack direction='column' className={classes.info}>
              <Typography variant='label1' className={classes.username}>
                {user.name}
              </Typography>
              <Typography variant='body2' className={classes.address}>
                {user.wallets?.length ? user.wallets[0].wallet_address : null}
              </Typography>
            </Stack>
          </Tooltip>
        }
        sx={{
          '.MuiChip-label': {
            padding: '0px',
          },
        }}
        variant='outlined'
        onClick={handleClick}
      />
      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
        {!showLogoutOnly ? (
          <MenuItem
            onClick={() => {
              setCookie('return_url', window.location.pathname);
              navigate(Uris.Pages.User.Info);
              handleClose();
            }}
          >
            Settings
          </MenuItem>
        ) : null}
        <MenuItem onClick={signOut}>Logout</MenuItem>
      </Menu>
    </>
  );
};
