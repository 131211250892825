import { Button, Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';

import { Collection } from 'common/types/Extension/Collection';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import ExtensionAPI from 'common/ExtensionAPI';
import classes from './DeleteCollection.module.scss';
import { useMessage } from 'components/message/useMessage';

interface DeleteCollectionProps {
  collection: Collection;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const DeleteCollection: FC<DeleteCollectionProps> = ({ collection, onSuccess, onCancel }) => {
  const { showMessage } = useMessage();
  const deleteCollection = useCallback(async () => {
    if (typeof collection.id !== 'number') return;
    await ExtensionAPI.deleteCollection(collection.id)
      .then(({ status }) => {
        if (status === 'success') {
          onSuccess?.();
          return;
        }
        showMessage('Delete collection failed');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Delete collection failed', 'error');
      });
  }, [collection, onSuccess, showMessage]);

  return (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={2} alignItems='center' className={classes.dialog}>
          <CommonChip>
            <DeleteOutlineOutlined fontSize='large' />
          </CommonChip>
          <Stack alignItems='center'>
            <Typography variant='h6' className={classes.title}>
              Are you sure you want to delete the list?
            </Typography>
            <Typography variant='body1' className={classes.subtitle}>
              Once deleted it cannot be retrieved.
            </Typography>
          </Stack>
        </Stack>
      }
      footer={
        <Stack direction='row' justifyContent='center' spacing={1}>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              deleteCollection();
            }}
          >
            Remove
          </Button>
        </Stack>
      }
      onDialogClose={onCancel}
    />
  );
};
