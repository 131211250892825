import { Uris } from 'Uris';

export interface TutorialGroupInfo {
  id: string;
  name: string;
  img?: string;
}

export interface Tutorial {
  key: string;
  name: string;
  groupId?: string;
  steps: TutorialStep[];
  completeAction?: TutorialCompleteAction;
}

export interface TutorialStep {
  key: string;
  url?: string;
  elementId: string;
  hintTitle: string;
  hintText?: string;
  autoClick?: boolean;
  waitAction?: boolean;
}

export interface TutorialCompleteAction {
  title: string;
  img?: string;
  text?: string;
  actionBtns?: TutorialCompleteActionBtn[];
}

export interface TutorialCompleteActionBtn {
  nextTutorialKey?: string;
  url?: string;
  text?: string;
  variant?: 'text' | 'contained' | 'outlined';
}

export const matchUrl = (pathname: string, url?: string) => {
  if (!url) return false;
  const regexPattern = url
    .replace(/:\w+/g, '[\\d\\w-]+') // Match digits, words, and hyphens
    .replace(/\//g, '\\/') // Escape forward slashes
    .replace(/\./g, '\\.'); // Escape dots
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(pathname);
};

export const tutorials: Tutorial[] = [
  {
    key: 'wallet-selector',
    name: 'Wallet Selector',
    groupId: 'effortlessly-targeted-ad-lists',
    steps: [
      {
        key: 'wallet-selector-1',
        url: Uris.Pages.WalletSelector.Index,
        elementId: 'btn-create-cohort',
        hintTitle: 'Create New Cohort',
        hintText: 'Start by creating a new cohort.',
      },
      {
        key: 'wallet-selector-2',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'area-create-cohort-project-nft',
        hintTitle: 'Choose Project Type',
        hintText: "Select the type of project you're working on.",
      },
      {
        key: 'wallet-selector-3',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'btn-create-cohort-growing3-category',
        hintTitle: 'Pre-curated Categories',
        hintText: "Select from Growing3's pre-curated categories.",
      },
      {
        key: 'wallet-selector-4',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'btn-create-cohort-customized-category-view-more',
        hintTitle: 'Customized Categroies',
        hintText: 'Alternatively, upload your own custom categories.',
      },
      {
        key: 'wallet-selector-5',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'btn-create-cohort-category-next',
        hintTitle: 'Skip Step',
        hintText: 'You can skip this step and include all the wallet addresses in Growing3 DB.',
        waitAction: true,
      },
      {
        key: 'wallet-selector-6',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'btn-create-cohort-filter-next',
        hintTitle: 'Decide Your Condition',
        hintText: 'You can determine the condition to get the filtered wallet list.',
        waitAction: true,
      },
      {
        key: 'wallet-selector-7',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'area-create-cohort-detail-view',
        hintTitle: 'Review Filters',
        hintText: 'Review your filter conditions before finalizing the cohort.',
      },
      {
        key: 'wallet-selector-8',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'area-create-cohort-wallet-predict',
        hintTitle: 'Selected Wallets',
        hintText: 'Wallets are chosen based on the conditions set in earlier steps.',
      },
      {
        key: 'wallet-selector-9',
        url: Uris.Pages.WalletSelector.CreateCohort,
        elementId: 'btn-create-cohort-save',
        hintTitle: 'Save Cohort',
        hintText: 'Save your progress to create the cohort.',
      },
      {
        key: 'wallet-selector-10',
        url: Uris.Pages.WalletSelector.Index,
        elementId: 'cohort-sync-status-0',
        hintTitle: 'Waiting',
        hintText:
          'Please wait while your cohort is being prepared. This typically takes 30 minutes to 1 hour. You can return and check the status in the cohort list table.',
      },
    ],
    completeAction: {
      title: 'Cohort successfully created',
      img: Uris.Public.Image.Tutorial.CreateSuccess,
      text: 'Now you are ready to create an audience based on the created cohort.',
      actionBtns: [
        {
          url: Uris.Pages.AdsAudience.Index,
          text: 'Create Ads Audience',
          nextTutorialKey: 'ads-audience',
        },
      ],
    },
  },
  {
    key: 'ads-audience',
    name: 'Ads Audience',
    groupId: 'effortlessly-targeted-ad-lists',
    steps: [
      {
        key: 'ads-audience-1',
        url: Uris.Pages.AdsAudience.Index,
        elementId: 'btn-create-ads-audience',
        hintTitle: 'Create Ads Audience',
        hintText: 'Start by creating an ads audience.',
      },
      {
        key: 'ads-audience-2',
        url: Uris.Pages.AdsAudience.CreateAudience,
        elementId: 'input-choose-target-cohorts',
        hintTitle: 'Select Cohorts',
        hintText: 'Choose the cohorts you created in the first step.',
      },
      {
        key: 'ads-audience-3',
        url: Uris.Pages.AdsAudience.CreateAudience,
        elementId: 'area-ads-audience-create-customer-list',
        hintTitle: 'Select Targeting',
        hintText: 'Pick the targeting approach that suits you best.',
      },
      {
        key: 'ads-audience-4',
        url: Uris.Pages.AdsAudience.Index,
        elementId: 'ads-audience-sync-status-0',
        hintTitle: 'Wait for Sync',
        hintText: 'Allow time for the synchronization to complete.',
      },
    ],
    completeAction: {
      title: 'Your ads audience has been successfully created',
      img: Uris.Public.Image.Tutorial.CreateSuccess,
      actionBtns: [
        {
          url: Uris.Pages.AdsAudience.Index,
          text: 'Publish to Twitter',
          nextTutorialKey: 'publish-to-twitter',
        },
      ],
    },
  },
  {
    key: 'publish-to-twitter',
    name: 'Publish to Twitter',
    groupId: 'effortlessly-targeted-ad-lists',
    steps: [
      {
        key: 'publish-to-twitter-1',
        url: Uris.Pages.AdsAudience.Index,
        elementId: 'ads-audience-sync-status-0',
        hintTitle: 'Wait for Sync',
        hintText:
          'Wait for syncing to complete. This typically takes 1 to 3 hour. You can return and check the status in the list table.',
      },
      {
        key: 'publish-to-twitter-2',
        url: Uris.Pages.AdsAudience.Index,
        elementId: 'btn-ads-audience-view-0',
        hintTitle: 'View Details',
        hintText: 'View the details to publish.',
      },
      {
        key: 'publish-to-twitter-3',
        url: Uris.Pages.AdsAudience.AudienceDetail,
        elementId: 'btn-ads-audience-publish',
        hintTitle: 'Publish to X Ads',
        hintText: 'Publish your Ads Audience to your X (Twitter) Ads account.',
      },
      {
        key: 'publish-to-twitter-4',
        url: Uris.Pages.AdsAudience.AudienceDetail,
        elementId: 'tab-ads-audience-publish-history',
        hintTitle: 'View History',
        hintText: 'Check the tabs to see your publishing history.',
      },
    ],
    completeAction: {
      title: 'You have completed 1 task!',
      img: Uris.Public.Image.Tutorial.CompleteFirstTask,
      text: 'Keep exploring to unlock all the features of Growing3.',
      actionBtns: [
        {
          url: Uris.Pages.AdsAudience.AudienceDetail,
          text: 'Next',
        },
      ],
    },
  },
  {
    key: 'create-influencer-list',
    name: 'Create Influencer List',
    groupId: 'quickly-connect-with-ideal-influencers',
    steps: [
      {
        key: 'create-influencer-list-1',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'input-search-influencers',
        hintTitle: 'Search Influencer and Create List',
        hintText: 'Search for relevant Influencers based on your criteria.',
      },
      {
        key: 'create-influencer-list-2',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'btn-search-influencers-filter',
        hintTitle: 'Set Up Filters',
        hintText: 'Define conditions (including metric and affinity) to filter the influencers.',
      },
      {
        key: 'create-influencer-list-3',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'check-box-select-influencer-0',
        hintTitle: 'Tick check box',
        hintText: 'Add influencers to your created collection list.',
      },
      {
        key: 'create-influencer-list-5',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'btn-create-collection-success-view-list',
        hintTitle: 'View the list',
        hintText: 'Review all the influencers you have added.',
      },
      {
        key: 'create-influencer-list-6',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'btn-create-collection-success-close',
        hintTitle: 'Add more influencers',
        hintText: 'Return to search for additional influencers.',
        autoClick: true,
      },
    ],
    completeAction: {
      title: 'Influencers successfully added to the list',
      img: Uris.Public.Image.Tutorial.CreateSuccess,
      text: 'Now create an campaign to reach out these influencer.',
      actionBtns: [
        {
          url: Uris.Pages.InfluencerMatcher.Index,
          text: 'Create Campaign',
          nextTutorialKey: 'create-campaign',
        },
      ],
    },
  },
  {
    key: 'create-campaign',
    name: 'Create Campaign',
    groupId: 'quickly-connect-with-ideal-influencers',
    steps: [
      {
        key: 'create-influencer-list-1',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'tab-influencer-matcher-campaign',
        hintTitle: 'Start New Campaign',
        hintText: 'Under the "Campaigns" tab, start a new Influencer collab campaign.',
      },
      {
        key: 'create-influencer-list-2',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'btn-influencer-matcher-create-campaign',
        hintTitle: 'Create Campaign',
        hintText: 'Start the campaign creation.',
      },
      {
        key: 'create-influencer-list-3',
        url: Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign,
        elementId: 'btn-campaign-add-threads-from-list',
        hintTitle: 'Add influencers',
        hintText: 'Add influencers from the lists you created in the previous step.',
      },
      {
        key: 'create-influencer-list-4',
        url: Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign,
        elementId: 'btn-campaign-add-threads-from-bulk',
        hintTitle: 'Custom List',
        hintText:
          'Alternatively, add influencers from your custom list. Please note that the influencer in your list should also match with our DB.',
      },
      {
        key: 'create-influencer-list-5',
        url: Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign,
        elementId: 'area-create-campaign-compose-message-0',
        hintTitle: 'Batch DMs',
        hintText: 'Send batch DMs to influencers with your customized messages.',
      },
      {
        key: 'create-influencer-list-6',
        url: Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign,
        elementId: 'area-create-campaign-assign-threads-0',
        hintTitle: 'Assign Influencers',
        hintText: 'Send different messages to the influencers in different segaments.',
      },
      {
        key: 'create-influencer-list-7',
        url: Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign,
        elementId: 'area-create-campaign-connect-twitter',
        hintTitle: 'Connect Account',
        hintText: 'Link your account to enable sending. You can simply add Session Cookie via our Web Extension.',
      },
      {
        key: 'create-influencer-list-8',
        url: Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign,
        elementId: 'area-create-campaign-launch-preview',
        hintTitle: 'Review Content',
        hintText: 'Review your DM content and recipients before sending.',
      },
      {
        key: 'create-influencer-list-9',
        url: Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign,
        elementId: 'btn-create-campaign-launch-actions',
        hintTitle: 'Launch and Send',
        hintText: 'Launch the campaign and the messages will be sent out immediately.',
      },
      {
        key: 'create-influencer-list-10',
        url: Uris.Pages.InfluencerMatcher.Index,
        elementId: 'area-campaign-list-filter-btn',
        hintTitle: 'Sending Status',
        hintText: 'View the status of the created campaign',
      },
    ],
    completeAction: {
      title: 'You have completed 1 task!',
      img: Uris.Public.Image.Tutorial.CompleteFirstTask,
      text: 'Keep exploring to unlock all the features of Growing3.',
      actionBtns: [
        {
          text: 'Next',
        },
      ],
    },
  },
];

export const tutorialGroupInfos: TutorialGroupInfo[] = [
  {
    id: 'effortlessly-targeted-ad-lists',
    name: 'Effortlessly Targeted Ad Lists',
    img: Uris.Public.Image.Tutorial.Task1,
  },
  {
    id: 'quickly-connect-with-ideal-influencers',
    name: 'Quickly Connect with Ideal Influencers',
    img: Uris.Public.Image.Tutorial.Task2,
  },
];
