import { useContext, useEffect } from 'react';

import ReactGA from 'react-ga4';
import { TrackingContext } from 'contexts/TrackingContext';
import { getUrlTitle } from 'Uris';
import { hexToBase36 } from 'common/utils';
import { useLocation } from 'react-router-dom';
import { useObservable } from 'react-use';
import { useTracking } from './useTracking';
import { useUserProfile } from './useUserProfile';

export const useTrackingInit = () => {
  const { pathname } = useLocation();

  const { pagePath$, clientId$ } = useContext(TrackingContext);
  const pagePath = useObservable(pagePath$);
  const { track } = useTracking();

  const { value: user } = useUserProfile();

  useEffect(() => {
    if (!user || !user.wallets?.length) {
      ReactGA.gtag('set', 'user_properties', {
        client_id: clientId$.getValue(),
      });
      return;
    }

    if (!user.wallets?.length) {
      ReactGA.gtag('set', 'user_properties', {
        client_id: clientId$.getValue(),
        customized_id: user.id,
      });
      return;
    }

    ReactGA.gtag('set', 'user_properties', {
      clientId: clientId$.getValue(),
      customized_id: user.id,
      chain: user.wallets[0].chain,
      wallet_address: hexToBase36(user.wallets[0].wallet_address.substring(2).toLowerCase()),
    });
  }, [user, clientId$]);

  useEffect(() => {
    const pagePath = pagePath$.getValue();
    if (pathname === pagePath.curr) return;
    // one pathname changed -> change title and pagePath
    document.title = getUrlTitle(pathname) || 'Growing3 Console';
    pagePath$.next({ prev: pagePath.curr, curr: pathname });
  }, [pathname, pagePath$]);

  useEffect(() => {
    if (!pagePath) return;
    if (pagePath.curr === pagePath.prev) return;
    // track page_view event when pagePath changed
    track('page_view');
  }, [track, pagePath]);

  return;
};
