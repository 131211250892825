export enum InteractType {
  TRUE = 'true',
  FALSE = 'false',
}

export const InteractTypeNameMap = new Map<string, string>([
  [InteractType.TRUE, 'Interact with'],
  [InteractType.FALSE, 'Not interact with'],
]);

export interface AddessInteraction {
  contract_addr: string;
  has_interaction: boolean;
}

export const DefaultAddessInteractionData: AddessInteraction = {
  contract_addr: '',
  has_interaction: true,
};
