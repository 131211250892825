import { SvgIcon, SvgIconProps } from '@mui/material';

export const RoundStarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 18 19'>
    <path
      fill='currentColor'
      d='M9 2a7.5 7.5 0 1 0 0 15A7.5 7.5 0 0 0 9 2Zm3.2 12L9 12l-3.2 2 .9-3.6L3.9 8l3.7-.3L9 4.2l1.4 3.4 3.7.4-2.8 2.4.9 3.6Z'
    />
  </SvgIcon>
);
